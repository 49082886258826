import Header from "../../components";
import React, { useEffect, useState, useRef, useContext } from "react";
import "./style.css";
import {
  Viewer,
  KmlDataSource,
  Entity,
  CameraFlyTo,
  CustomDataSource,
  ScreenSpaceEventHandler,
  ScreenSpaceEvent,
  ScreenSpaceCameraController,
} from "resium";
import Cesium, {
  Ion,
  Cartesian3,
  Math,
  Color,
  PointPrimitive,
  Transforms,
  NearFarScalar,
  ScreenSpaceEventType,
} from "cesium";
import { AuthContext } from "../../contexts/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../services/firebaseConnection";
import TLPopup from "../../components/terrenoLivrePopup";

export default function Home() {
  const [gurgLogged, setGurgLogged] = useState(false);
  const center = Cartesian3.fromDegrees(-75.59777, 40.03883);
  useEffect(() => {
    var userlg = JSON.parse(localStorage.getItem("@loggedAreaInsight"));
    if (userlg) {
      if (userlg.email === "gurgel@inova.unicamp.br") {
        setGurgLogged(true);
      }
    }
  }, []);
  Ion.defaultAccessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkZTNlMmJiNS04MGMxLTQzYzAtODE2Ni0yNzdiODkyNjA4Y2EiLCJpZCI6MTUxMDc1LCJpYXQiOjE2ODg0MDQxMjl9.EKAxKkENBoszW_JoDUtY4owQWPb5VCXHkHPdSt_xtOA";
  const [loading, setLoading] = useState(false);
  const [loadingview, setLoadingView] = useState(false);
  const loadingViewRef = useRef(false);
  const [outlineWidth, setOutlineWidth] = useState(1000);

  //POINT LABELS
  const [labelsVisible, setLabelsVisible] = useState(true);

  //PATHS
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [camadas, setCamadas] = useState("");
  const [tlpopup, setTLPopup] = useState(false);
  const [selectedTLId, setselectedTLId] = useState(null);

  const [kmlPath, setKmlPath] = useState("");
  const [kmlLPath, setKmlLPath] = useState("");
  const [kmltPath, setkmltPath] = useState("");
  const [topografiaPath, setTopografiaPath] = useState("");
  const [hidrografiaPath, setHidrografiaPath] = useState("");
  const [limitesaPath, setLimitesaPath] = useState("");
  const [permissaoPath, setPermissaoPath] = useState("");
  const [dadosPath, setDadosPath] = useState("");
  const [fluxoPath, setFluxoPath] = useState("");

  const [thejoyPath, setTheJoyPath] = useState("");
  const [competidores, setCompetidores] = useState("");
  const [areas, setAreas] = useState("");
  const [condominios, setCondominios] = useState("");
  const [influencia, setInfluencia] = useState("");
  const [pontosFluxo, setPontosFluxo] = useState("");
  const [imoveis, setImoveis] = useState("");

  //chapadao
  const [c1, setC1] = useState("");
  const [c2, setC2] = useState("");
  const [c3, setC3] = useState("");
  const [c4, setC4] = useState("");
  const [c5, setC5] = useState("");
  const [c6, setC6] = useState("");
  const [c7, setC7] = useState("");
  const [c8, setC8] = useState("");
  const [c9, setC9] = useState("");
  const [c10, setC10] = useState("");
  const [c11, setC11] = useState("");
  const [c12, setC12] = useState("");
  const [c13, setC13] = useState("");
  const [c14, setC14] = useState("");
  const [c15, setC15] = useState("");
  const [c16, setC16] = useState("");

  const [imoveis200lk, setImoveis200lk] = useState("");
  const [setoreslk, setSetoreslk] = useState("");
  const [raio1000lk, setraio1000lk] = useState("");
  const [raio500lk, setraio500lk] = useState("");
  const [viaslk, setviaslk] = useState("");
  const [competidoreslk, setcompetidoreslk] = useState("");
  const [hospitaislk, sethospitaislk] = useState("");
  const [pracaslk, setpracaslk] = useState("");
  const [mercadoslk, setmercadoslk] = useState("");
  const [restauranteslk, setrestauranteslk] = useState("");
  const [condominioslk, setcondominioslk] = useState("");
  const [farmacialk, setfarmacialk] = useState("");

  //gen - griffon living
  const [areaverdegl, setareaverdegl] = useState("");
  const [municipiogl, setmunicipiogl] = useState("");
  const [condominiogooglegl, setcondominiogooglegl] = useState("");
  const [condominiograpgl, setcondominiograpgl] = useState("");
  const [rendagl, setrendagl] = useState("");

  //campinas alea
  const [servidao, setservidao] = useState("");
  const [crescimento, setcrescimento] = useState("");

  // tj paulinia
  const [areasinfluenciatjp, setareasinfluenciatjp] = useState("");
  const [areasindicadastjp, setareasindicadastjp] = useState("");
  const [compdiretostjp, setcompdiretostjp] = useState("");
  const [compindiretostjp, setcompindiretostjp] = useState("");
  const [condominiostjp, setcondominiostjp] = useState("");
  const [imoveistjp, setimoveistjp] = useState("");
  const [limitetjp, setlimitetjp] = useState("");
  const [pontosfluxotjp, setpontosfluxotjp] = useState("");
  const [escolastjp, setescolastjp] = useState("");

  // tj americana

  const [areasinfluenciatja, setareasinfluenciatja] = useState("");
  const [areasindicadasinfantiltja, setareasindicadasinfantiltja] =
    useState("");
  const [areasindicadasfundamentaltja, setareasindicadasfundamentaltja] =
    useState("");
  const [compdiretosinfantiltja, setcompdiretosinfantiltja] = useState("");
  const [compdiretosfundamentaltja, setcompdiretosfundamentaltja] =
    useState("");
  const [compindiretosinfantiltja, setcompindiretosinfantiltja] = useState("");
  const [compindiretosfundamentaltja, setcompindiretosfundamentaltja] =
    useState("");
  const [concentracao0_5tja, setconcentracao0_5tja] = useState("");
  const [concentracao6_10tja, setconcentracao6_10tja] = useState("");
  const [escolatja, setescolatja] = useState("");
  const [limitetja, setlimitetja] = useState("");
  const [pontosfluxotja, setpontosfluxotja] = useState("");
  const [rendatja, setrendatja] = useState("");
  const [areainfantilseparadatja, setareainfantilseparadatja] = useState("");
  const [areafundaseparadatja, setareafundaseparadatja] = useState("");

  // HIDS

  const [ashidsgu, setashidsgu] = useState("");
  const [aspidsgu, setaspidsgu] = useState("");
  const [apidsgu, setapidsgu] = useState("");
  const [bempresasgu, setbempresasgu] = useState("");
  const [campusdagu, setcampusdagu] = useState("");
  const [campusgu, setcampusgu] = useState("");
  const [fzdarggu, setfzdarggu] = useState("");
  const [ferroviasgu, setferroviasgu] = useState("");
  const [ictsgu, setictsgu] = useState("");
  const [parquesgu, setparquesgu] = useState("");
  const [riosgu, setriosgu] = useState("");
  const [viasgu, setviasgu] = useState("");

  //tj guarulhos

  const [areasindicadasinfantiltjg, setareasindicadastjg] = useState("");
  const [areasindicadasfundamentaltjg, setareasindicadasfundamentaltjg] =
    useState("");
  const [compdiretosinfantiltjg, setcompdiretosinfantiltjg] = useState("");
  const [compdiretosfundamentaltjg, setcompdiretosfundamentaltjg] =
    useState("");
  const [compindiretosinfantiltjg, setcompindiretosinfantiltjg] = useState("");
  const [compindiretosfundamentaltjg, setcompindiretosfundamentaltjg] =
    useState("");
  const [concentracao0_5tjg, setconcentracao0_5tjg] = useState("");
  const [concentracao6_10tjg, setconcentracao6_10tjg] = useState("");
  const [familiastjg, setfamiliastjg] = useState("");
  const [rendatjg, setrendatjg] = useState("");
  const [imovelpretendidotjg, setimovelpretendidotjg] = useState("");
  const [limitetjg, setlimitetjg] = useState("");
  const [pontosfluxotjg, setpontosfluxotjg] = useState("");
  const [raioinfluenciatjg, setraioinfluenciatjg] = useState("");

  //edu
  const [edumap, setEdumap] = useState("");

  //crca
  const [bairroscrca, setbairroscrca] = useState("");
  const [dadossociosfamiliascampinascrca, setdadossociosfamiliascampinascrca] =
    useState("");
  const [dadossociosrendacampinascrca, setdadossociosrendacampinascrca] =
    useState("");
  const [zoneamentocampinascrca, setzoneamentocampinascrca] = useState("");
  const [limitecampinascrca, setlimitecampinascrca] = useState("");
  const [dadosporbairro, setdadosporbairro] = useState("");

  //sjbvalea
  const [sjbv, setsjbv] = useState("");
  const [sjbv1zoneamento, setsjbv1zoneamento] = useState("");
  const [sjbv2permissao, setsjbv2permissao] = useState("");
  const [sjbv3terrenos, setsjbv3terrenos] = useState("");
  const [sjbv5topografia, setsjbv5topografia] = useState("");
  const [sjbv6limitesa, setsjbv6limitesa] = useState("");
  const [sjbv7hidrografia, setsjbv7hidrografia] = useState("");
  const [sjbv8servidao, setsjbv8servidao] = useState("");
  const [sjbv9terrenosp, setsjbv9terrenosp] = useState("");
  const [sjbv10crescimento, setsjbv10crescimento] = useState("");
  const [sjbv11dadossocios, setsjbv11dadossocios] = useState("");
  const [sjbv12distviario, setsjbv12distviario] = useState("");
  const [sjbv13malha, setsjbv13malha] = useState("");
  const [sjbv14pontos, setsjbv14pontos] = useState("");

  //rp

  const [rp, setrp] = useState("");
  const [rp1zoneamento, setrp1zoneamento] = useState("");
  const [rp3terrenos, setrp3terrenos] = useState("");
  const [rp5topografia, setrp5topografia] = useState("");
  const [rp6limitesambientais, setrp6limitesambientais] = useState("");
  const [rp7hidrografia, setrp7hidrografia] = useState("");
  const [rp8servidao, setrp8servidao] = useState("");
  const [rp10crescimento, setrp10crescimento] = useState("");
  const [rp11dadossocioeconomicosrenda, setrp11dadossocioeconomicosrenda] =
    useState("");
  const [
    rp12dadossocioeconomicosfamilias,
    setrp12dadossocioeconomicosfamilias,
  ] = useState("");

  //crca2
  const [abrangenciacrascrca2, setabrangenciacrascrca2] = useState("");
  const [assistenciateccrca2, setassistenciateccrca2] = useState("");
  const [bairroscrca2, setbairroscrca2] = useState("");
  const [
    campinasdadossociosfamiliascrca2,
    setcampinasdadossociosfamiliascrca2,
  ] = useState("");
  const [campinasdadossociosrendacrca2, setcampinasdadossociosrendacrca2] =
    useState("");
  const [campinaslimitecrca2, setcampinaslimitecrca2] = useState("");
  const [campinaszoneamentocrca2, setcampinaszoneamentocrca2] = useState("");
  const [comercioalimentoscrca2, setcomercioalimentoscrca2] = useState("");
  const [dadosporbairrocrca2, setdadosporbairrocrca2] = useState("");
  const [impressao3dcrca2, setimpressao3dcrca2] = useState("");
  const [lojaderoupascrca2, setlojaderoupascrca2] = useState("");
  const [lojasartesanatocrca2, setlojasartesanatocrca2] = useState("");
  const [oficinasmecanicascrca2, setoficinasmecanicascrca2] = useState("");
  const [orgcivilcrca2, setorgcivilcrca2] = useState("");
  const [padariasconfeitariascrca2, setpadariasconfeitariascrca2] =
    useState("");
  const [petshopscrca2, setpetshopscrca2] = useState("");
  const [regioesvulcrca2, setregioesvulcrca2] = useState("");
  const [salaobelezacrc2, setsalaobelezacrc2] = useState("");

  //edu pizza
  const [educomp, seteducomp] = useState("");
  const [edufamilia, setedufamilia] = useState("");
  const [edurenda, setedurenda] = useState("");
  const [eduimoveis, seteduimoveis] = useState("");

  //tjd
  const [areasindicadasinfantiltjd, setareasindicadasinfantiltjd] =
    useState(false);
  const [areasindicadasfundamentaltjd, setareasindicadasfundamentaltjd] =
    useState(false);
  const [compdiretosinfantiltjd, setcompdiretosinfantiltjd] = useState(false);
  const [compdiretosfundamentaltjd, setcompdiretosfundamentaltjd] =
    useState(false);
  const [compindiretosinfantiltjd, setcompindiretosinfantiltjd] =
    useState(false);
  const [compindiretosfundamentaltjd, setcompindiretosfundamentaltjd] =
    useState(false);
  const [concentracaocriancasinfantiltgd, setconcentracaocriancasinfantiltgd] =
    useState(false);
  const [concentracaocriancasfundtgd, setconcentracaocriancasfundtgd] =
    useState(false);
  const [familiastjd, setfamiliastjd] = useState(false);
  const [rendatjd, setrendatjd] = useState(false);
  const [limitetjd, setlimitetjd] = useState(false);
  const [pontosfluxotjd, setpontosfluxotjd] = useState(false);
  const [raioinfluenciatjd, setraioinfluenciatjd] = useState("");

  //Lavras

  const [legalizacaolavras, setlegalizacaolavras] = useState("");

  //manapoke
  const [academiasmanapoke, setacademiasmanapoke] = useState("");
  const [centrosempresariasmanapoke, setcentrosempresariasmanapoke] =
    useState("");
  const [centrosesportivosmanapoke, setcentrosesportivosmanapoke] =
    useState("");
  const [comerciomanapoke, setcomerciomanapoke] = useState("");
  const [compcomidajaponesamanapoke, setcompcomidajaponesamanapoke] =
    useState("");
  const [compcomidasaudavelmanapoke, setcompcomidasaudavelmanapoke] =
    useState("");
  const [imoveiscomerciaismanapoke, setimoveiscomerciaismanapoke] =
    useState("");
  const [pontosculturaismanapoke, setpontosculturaismanapoke] = useState("");
  const [pontoseducacaomanapoke, setpontoseducacaomanapoke] = useState("");
  const [rendamanapoke, setrendamanapoke] = useState("");
  const [areaspotenciaismanapoke, setareaspotenciaismanapoke] = useState("");

  //Lavras
  const [zoneamentolavras, setzoneamentolavras] = useState("");
  const [permissaolavras, setpermissaolavras] = useState("");
  const [terrenoslavras, setterrenoslavras] = useState("");
  const [topografialavras, settopografialavras] = useState("");
  const [applavras, setapplavras] = useState("");
  const [hidrografialavras, sethidrografialavras] = useState("");
  const [servidaolavras, setservidaolavras] = useState("");
  const [terrenosinteresselavras, setterrenosinteresselavras] = useState("");
  const [crescimentolavras, setcrescimentolavras] = useState("");
  const [dadossocioslavras, setdadossocioslavras] = useState("");
  const [distviariolavras, setdistviariolavras] = useState("");
  const [disturbanalavras, setdisturbanalavras] = useState("");
  const [pontoslavras, setpontoslavras] = useState("");

  //buddahspa
  const [clubesbs, setclubesbs] = useState("");
  const [familiasbs, setfamiliasbs] = useState("");
  const [lojasdepartamentobs, setlojasdepartamentobs] = useState("");
  const [parquesbs, setparquesbs] = useState("");
  const [rendabs, setrendabs] = useState("");
  const [pontosbuddhaspabs, setpontosbuddhaspabs] = useState("");
  const [restaurantesbs, setrestaurantesbs] = useState("");
  const [shoppingsbs, setshoppingsbs] = useState("");
  const [supermercadosbs, setsupermercadosbs] = useState("");
  const [estacionamentosbs, setestacionamentosbs] = useState("");
  const [imoveismenos18bs, setimoveismenos18bs] = useState("");
  const [imoveismais18bs, setimoveismais18bs] = useState("");

  const [areainflucompbs, setareainflucompbs] = useState("");
  const [areapotcomrecorte, setareapotcomrecorte] = useState("");
  const [areapotsemrecorte, setareapotsemrecorte] = useState("");
  const [competidoresbs, setcompetidoresbs] = useState("");
  const [popadultabs, setpopadultabs] = useState("");

  //baldoni
  const [areaspotbd, setareaspotbd] = useState("");
  const [baldonibd, setbaldonibd] = useState("");
  const [baldoni2030bd, setbaldoni2030bd] = useState("");
  const [lojaspescabd, setlojaspescabd] = useState("");
  const [pesquesbd, setpesquesbd] = useState("");
  const [petshopsbd, setpetshopsbd] = useState("");
  const [popadultabd, setpopadultabd] = useState("");
  const [rendabd, setrendabd] = useState("");
  const [restaurantesbd, setrestaurantesbd] = useState("");
  const [vetsbd, setvetsbd] = useState("");

  // caribu
  const [academiascb, setacademiascb] = useState("");
  const [areaspotcb, setareaspotcb] = useState("");
  const [cafescb, setcafescb] = useState("");
  const [comerciocb, setcomerciocb] = useState("");
  const [culturalcb, setculturalcb] = useState("");
  const [escolascb, setescolascb] = useState("");
  const [esportescb, setesportescb] = useState("");
  const [faculdadescb, setfaculdadescb] = useState("");
  const [imoveiscb, setimoveiscb] = useState("");
  const [lanchonetescb, setlanchonetescb] = useState("");
  const [rendasetorescb, setrendasetorescb] = useState("");
  const [comidasaudavelcb, setcomidasaudavelcb] = useState("");

  //buddah
  const [zonasusobs, setzonasusobs] = useState("");

  //rioverde
  const [Rio_Verde_1_zoneamento, setRio_Verde_1_zoneamento] = useState("");
  const [Rio_Verde_2_permissao, setRio_Verde_2_permissao] = useState("");
  const [Rio_Verde_3_terrenos, setRio_Verde_3_terrenos] = useState("");
  const [Rio_Verde_4_imoveis_disponiveis, setRio_Verde_4_imoveis_disponiveis] =
    useState("");
  const [Rio_Verde_5_topografia, setRio_Verde_5_topografia] = useState("");
  const [Rio_Verde_6_Limites_Ambientais, setRio_Verde_6_Limites_Ambientais] =
    useState("");
  const [Rio_Verde_7_Hidrografia, setRio_Verde_7_Hidrografia] = useState("");
  const [Rio_Verde_8_Servidao, setRio_Verde_8_Servidao] = useState("");
  const [Rio_Verde_9_terrenos_potenciais, setRio_Verde_9_terrenos_potenciais] =
    useState("");
  const [Rio_Verde_10_crescimento, setRio_Verde_10_crescimento] = useState("");
  const [
    Rio_Verde_11_dados_socioeconomicos,
    setRio_Verde_11_dados_socioeconomicos,
  ] = useState("");
  const [Rio_verde_12_dist_viario, setRio_verde_12_dist_viario] = useState("");
  const [Rio_Verde_13_Malha_Urbana, setRio_Verde_13_Malha_Urbana] =
    useState("");
  const [
    Rio_Verde_14_Pontos_de_Interesse,
    setRio_Verde_14_Pontos_de_Interesse,
  ] = useState("");
  const [Rio_Verde, setRio_Verde] = useState("");

  //tony
  const [Cruzeiro_1_Zoneamento, setCruzeiro_1_Zoneamento] = useState("");
  const [Guaxupe_1_zoneamento, setGuaxupe_1_zoneamento] = useState("");
  const [Lavras_zoneamento_TESTE, setLavras_zoneamento_TESTE] = useState("");
  const [SSParaiso_1_Zoneamento, setSSParaiso_1_Zoneamento] = useState("");

  //accenture
  const [angradosreisacc, setangradosreisacc] = useState("");
  const [br101km414acc, setbr101km414acc] = useState("");
  const [br101km447acc, setbr101km447acc] = useState("");
  const [br101km538acc, setbr101km538acc] = useState("");
  const [itaguaiacc, setitaguaiacc] = useState("");
  const [mangaratibaacc, setmangaratibaacc] = useState("");
  const [paratyacc, setparatyacc] = useState("");
  const [trechobr101acc, settrechobr101acc] = useState("");
  const [rendaacc, setrendaacc] = useState("");
  const [areasconmataatlaacc, setareasconmataatlaacc] = useState("");
  const [appacc, setappacc] = useState("");
  const [vegetacaonativaacc, setvegetacaonativaacc] = useState("");
  const [uniconsfederaisacc, setuniconsfederaisacc] = useState("");
  const [reservalegalacc, setreservalegalacc] = useState("");
  const [areasprotacc, setareasprotacc] = useState("");

  //caribu
  const [acaiteriacb, setacaiteriacb] = useState("");
  const [sorveteriacb, setsorveteriacb] = useState("");

  // accenture
  const [moteisac, setmoteisac] = useState("");
  const [pedagiosac, setpedagiosac] = useState("");
  const [postosac, setpostosac] = useState("");
  const [restaurantesac, setrestaurantesac] = useState("");
  const [hoteisac, sethoteisac] = useState("");
  const [pousadasac, setpousadasac] = useState("");

  //rsg
  const [areaspotpucrsg, setareaspotpucrsg] = useState("");
  const [areaspotunicampport2rsg, setareaspotunicampport2rsg] = useState("");
  const [areaspotunicampprincrsg, setareaspotunicampprincrsg] = useState("");
  const [portariasrsg, setportariasrsg] = useState("");
  const [raio200rsg, setraio200rsg] = useState("");
  const [terrenosdisprsg, setterrenosdisprsg] = useState("");

  //caribu
  const [fluxokernelcb, setfluxokernelcb] = useState("");
  const [perimetrourbanocb, setperimetrourbanocb] = useState("");
  const [concentrafluxocb, setconcentrafluxocb] = useState("");
  const [raiofluxocb, setraiofluxocb] = useState("");
  const [fluxoconcocb, setfluxoconcocb] = useState("");

  //peconi
  const [zoneamentopec, setzoneamentopec] = useState("");
  const [pontosintpec, setpontosintpec] = useState("");
  const [permissaopec, setpermissaopec] = useState("");
  const [topografiapec, settopografiapec] = useState("");
  const [crescimentoanopec, setcrescimentoanopec] = useState("");
  const [graprohab, setgraprohab] = useState("");
  const [densidadepec, setdensidadepec] = useState("");
  const [familiaspec, setfamiliaspec] = useState("");
  const [rendapec, setrendapec] = useState("");
  const [corpospec, setcorpospec] = useState("");
  const [cursospec, setcursospec] = useState("");
  const [nascentespec, setnascentespec] = useState("");
  const [apppec, setapppec] = useState("");
  const [reservapec, setreservapec] = useState("");
  const [vegpec, setvegpec] = useState("");
  const [terrenos2k4kpec, setterrenos2k4kpec] = useState("");
  const [terrenos5kmaispec, setterrenos5kmaispec] = useState("");
  const [terrenosnaopec, setterrenosnaopec] = useState("");
  const [terrenospot5kmaispec, setterrenospot5kmaispec] = useState("");
  const [terrenospot150kmaispec, setterrenospot150kmaispec] = useState("");
  const [mapaconcentracaopec, setmapaconcentracaopec] = useState("");

  //voto map
  const [limitevmap, setlimitevmap] = useState("");
  const [popfemvmap, setpopfemvmap] = useState("");
  const [popmasvmap, setpopmasvmap] = useState("");
  const [poptotvmap, setpoptotvmap] = useState("");
  const [prefeitovmap, setprefeitovmap] = useState("");
  const [prefeitovmap2, setprefeitovmap2] = useState("");
  const [vereadorvmap, setvereadorvmap] = useState("");
  const [vereadorvmap2, setvereadorvmap2] = useState("");
  const [rendavmap, setrendavmap] = useState("");

  // pesquisa unicamp
  const [demandaespacopu, setdemandaespacopu] = useState("");
  const [demandaqualidadepu, setdemandaqualidadepu] = useState("");
  const [faixafaturamentopu, setfaixafaturamentopu] = useState("");
  const [leispu, setleispu] = useState("");
  const [mapageralpu, setmapageralpu] = useState("");
  const [modelopu, setmodelopu] = useState("");
  const [preferenpu, setpreferenpu] = useState("");
  const [necessidadepu, setnecessidadepu] = useState("");
  const [relacaopu, setrelacaopu] = useState("");
  const [setorpu, setsetorpu] = useState("");

  //peconi
  const [divisaoregpec, setdivisaoregpec] = useState("");
  const [vaziospec, setvaziospec] = useState("");
  const [usosolopec, setusosolopec] = useState("");
  const [concenfluxospec, setconcenfluxospec] = useState("");
  const [topografiapec2, settopografiapec2] = useState("");

  const [faixaac, setfaixaac] = useState("");

  const [areaintbb, setareaintbb] = useState("");
  const [boibrabobb, setboibrabobb] = useState("");
  const [classesbb, setclassesbb] = useState("");
  const [compsbb, setcompsbb] = useState("");
  const [conccompsbb, setconccompsbb] = useState("");
  const [concpfbb, setconcpfbb] = useState("");
  const [familiasbb, setfamiliasbb] = useState("");
  const [fluxocompsbb, setfluxocompsbb] = useState("");
  const [imoveisbb, setimoveisbb] = useState("");
  const [pontosfluxobb, setpontosfluxobb] = useState("");
  const [rendabb, setrendabb] = useState("");
  const [metrobb, setmetrobb] = useState("");
  const [onibusbb, setonibusbb] = useState("");
  const [trembb, settrembb] = useState("");
  const [areapotbb, setareapotbb] = useState("");

  const [areapotdp, setareapotdp] = useState("");
  const [compsdp, setcompsdp] = useState("");
  const [conccompsdp, setconccompsdp] = useState("");
  const [concpontofluxodp, setconcpontofluxodp] = useState("");
  const [familiasdp, setfamiliasdp] = useState("");
  const [gastodp, setgastodp] = useState("");
  const [pontofluxodp, setpontofluxodp] = useState("");
  const [pop20a69dp, setpop20a69dp] = useState("");
  const [rendadp, setrendadp] = useState("");

  const [armazensacc2, setarmazensacc2] = useState("");
  const [balsasacc2, setbalsasacc2] = useState("");
  const [concarmazensacc2, setconcarmazensacc2] = useState("");
  const [formosoacc2, setformosoacc2] = useState("");
  const [lagoaacc2, setlagoaacc2] = useState("");
  const [miranorteacc2, setmiranorteacc2] = useState("");
  const [portoacc2, setportoacc2] = useState("");
  const [silvanopolisacc2, setsilvanopolisacc2] = useState("");
  const [tassoacc2, settassoacc2] = useState("");
  const [usosoloacc2, setusosoloacc2] = useState("");
  const [hidroviasgeralacc2, sethidroviasgeralacc2] = useState("");
  const [viasgeralacc2, setviasgeralacc2] = useState("");
  const [portsaoluisacc2, setportsaoluisacc2] = useState("");
  const [portnacionalacc2, setportnacionalacc2] = useState("");
  const [intpalemeiranteacc2, setintpalemeiranteacc2] = useState("");
  const [rodoviasacc2, setrodoviasacc2] = useState("");
  const [instalacoesportacc2, setinstalacoesportacc2] = useState("");
  const [hidroviasofcacc2, sethidroviasofcacc2] = useState("");
  const [ferroviasacc2, setferroviasacc2] = useState("");
  const [usosolomiranorteacc2, setusosolomiranorteacc2] = useState("");
  const [usosolosilvanopolisacc2, setusosolosilvanopolisacc2] = useState("");
  const [usosololagoaacc2, setusosololagoaacc2] = useState("");
  const [usosoloformosoacc2, setusosoloformosoacc2] = useState("");
  const [usosoloportoacc2, setusosoloportoacc2] = useState("");
  const [usosolobalsasacc2, setusosolobalsasacc2] = useState("");
  const [usosolotassoacc2, setusosolotassoacc2] = useState("");

  const [lmsaltosc, setlmsaltosc] = useState("");
  const [compsaltosc, setcompsaltosc] = useState("");
  const [r1saltosc, setr1saltosc] = useState("");
  const [r2saltosc, setr2saltosc] = useState("");
  const [pfsaltosc, setpfsaltosc] = useState("");
  const [scsaltosc, setscsaltosc] = useState("");
  const [lmmarasc, setlmmarasc] = useState("");
  const [compmarasc, setcompmarasc] = useState("");
  const [buffermarasc, setbuffermarasc] = useState("");
  const [pfmarasc, setpfmarasc] = useState("");
  const [scmarasc, setscmarasc] = useState("");
  const [lmjacaresc, setlmjacaresc] = useState("");
  const [compjacaresc, setcompjacaresc] = useState("");
  const [r1jacaresc, setr1jacaresc] = useState("");
  const [pfjacaresc, setpfjacaresc] = useState("");
  const [scjacaresc, setscjacaresc] = useState("");

  const [terrenospec, setterrenospec] = useState("");
  const [terrenospecpau, setterrenospecpau] = useState("");

  const [amoarmazensacc2, setamoarmazensacc2] = useState("");
  const [prestaacc2, setprestaacc2] = useState("");
  const [habacc2, sethabacc2] = useState("");
  const [capacc2, setcapacc2] = useState("");
  const [tipoacc2, settipoacc2] = useState("");
  const [porteacc2, setporteacc2] = useState("");
  const [formacc2, setformacc2] = useState("");
  const [distferroacc2, setdistferroacc2] = useState("");
  const [distermacc2, setdistermacc2] = useState("");
  const [estosoacc2, setestosoacc2] = useState("");
  const [precosoacc2, setprecosoacc2] = useState("");
  const [popacc2, setpopacc2] = useState("");
  const [rendaacc2, setrendaacc2] = useState("");
  const [aeroacc2, setaeroacc2] = useState("");
  const [declacc2, setdeclacc2] = useState("");

  const [dadossociosaltossc, setdadossociosaltossc] = useState("");
  const [fluxo1marajasc, setfluxo1marajasc] = useState("");
  const [kernelfluxo1marajasc, setkernelfluxo1marajasc] = useState("");
  const [familiasmarajassc, setfamiliasmarajassc] = useState("");
  const [rendamarajassc, setrendamarajassc] = useState("");
  const [dadossociojacaresc, setdadossociojacaresc] = useState("");

  const [crescimentobrz, setcrescimentobrz] = useState("");
  const [domiciliosaluguelbrz, setdomiciliosaluguelbrz] = useState("");
  const [domicioliosbrz, setdomicioliosbrz] = useState("");
  const [empreendimentosbrz, setempreendimentosbrz] = useState("");
  const [lavrasbrz, setlavrasbrz] = useState("");
  const [popbrz, setpopbrz] = useState("");
  const [raio15brz, setraio15brz] = useState("");
  const [rendabrz, setrendabrz] = useState("");
  const [vetorbrz, setvetorbrz] = useState("");
  const [prop1toacc2, setprop1toacc2] = useState("");
  const [prop2toacc2, setprop2toacc2] = useState("");
  const [prop1maacc2, setprop1maacc2] = useState("");
  const [prop2maacc2, setprop2maacc2] = useState("");
  const [pontointbrz, setpontointbrz] = useState("");

  const [crescimento1brz, setcrescimento1brz] = useState("");
  const [deficitbrz, setdeficitbrz] = useState("");
  const [taxahabbrz, settaxahabbrz] = useState("");

  const [gtdcamacircu, setgtdcamacircu] = useState("");
  const [distcamacircu, setdistcamacircu] = useState("");
  const [arearedcamacircu, setarearedcamacircu] = useState("");
  const [areaspotcamacircu, setareaspotcamacircu] = useState("");
  const [assocamacircu, setassocamacircu] = useState("");
  const [camacircu, setcamacircu] = useState("");
  const [empscamacircu, setempscamacircu] = useState("");
  const [concempcamacircu, setconcempcamacircu] = useState("");
  const [areaspotvargcircu, setareaspotvargcircu] = useState("");
  const [assovargcircu, setassovargcircu] = useState("");
  const [vargcircu, setvargcircu] = useState("");
  const [empsvargcircu, setempsvargcircu] = useState("");
  const [gtdcampcircu, setgtdcampcircu] = useState("");
  const [distcampcircu, setdistcampcircu] = useState("");
  const [distecocampcircu, setdistecocampcircu] = useState("");
  const [arearedcampcircu, setarearedcampcircu] = useState("");
  const [areaspotcampcircu, setareaspotcampcircu] = useState("");
  const [assocampcircu, setassocampcircu] = useState("");
  const [campcircu, setcampcircu] = useState("");
  const [empscampcircu, setempscampcircu] = useState("");
  const [densempscampcircu, setdensempscampcircu] = useState("");
  const [ecoscampcircu, setecoscampcircu] = useState("");
  const [gtdjundcircu, setgtdjundcircu] = useState("");
  const [distjundcircu, setdistjundcircu] = useState("");
  const [distecojundcircu, setdistecojundcircu] = useState("");
  const [arearedjundcircu, setarearedjundcircu] = useState("");
  const [areaspotjundcircu, setareaspotjundcircu] = useState("");
  const [assojundcircu, setassojundcircu] = useState("");
  const [jundcircu, setjundcircu] = useState("");
  const [empsjundcircu, setempsjundcircu] = useState("");
  const [densempsjundcircu, setdensempsjundcircu] = useState("");
  const [ecosjundcircu, setecosjundcircu] = useState("");
  const [gtdsorocircu, setgtdsorocircu] = useState("");
  const [distsorocircu, setdistsorocircu] = useState("");
  const [distecosorocircu, setdistecosorocircu] = useState("");
  const [arearedsorocircu, setarearedsorocircu] = useState("");
  const [areaspotsorocircu, setareaspotsorocircu] = useState("");
  const [assosorocircu, setassosorocircu] = useState("");
  const [sorocircu, setsorocircu] = useState("");
  const [empssorocircu, setempssorocircu] = useState("");
  const [densempssorocircu, setdensempssorocircu] = useState("");
  const [ecossorocircu, setecossorocircu] = useState("");

  const [terrainsTL, setTerrainsTL] = useState([]);
  const [areapotafasdp, setareapotafasdp] = useState("");
  const [imoveisdp, setimoveisdp] = useState("");

  const [jundiaimrv, setjundiaimrv] = useState("");
  const [appjundiaimrv, setappjundiaimrv] = useState("");
  const [vegjundiaimrv, setvegjundiaimrv] = useState("");
  const [resjundiaimrv, setresjundiaimrv] = useState("");
  const [areaprojundiaimrv, setareaprojundiaimrv] = useState("");
  const [nascjundiaimrv, setnascjundiaimrv] = useState("");
  const [cursjundiaimrv, setcursjundiaimrv] = useState("");
  const [corposjundiaimrv, setcorposjundiaimrv] = useState("");
  const [topojundiaimrv, settopojundiaimrv] = useState("");
  const [urbjundiaimrv, seturbjundiaimrv] = useState("");
  const [areapoujundiaimrv, setareapoujundiaimrv] = useState("");
  const [servjundiaimrv, setservjundiaimrv] = useState("");
  const [usorestjundiaimrv, setusorestjundiaimrv] = useState("");
  const [areaconsjundiaimrv, setareaconsjundiaimrv] = useState("");
  const [areacontjundiaimrv, setareacontjundiaimrv] = useState("");
  const [dutojundiaimrv, setdutojundiaimrv] = useState("");
  const [linhaojundiaimrv, setlinhaojundiaimrv] = useState("");
  const [zoneajundiaimrv, setzoneajundiaimrv] = useState("");
  const [legavertjundiaimrv, setlegavertjundiaimrv] = useState("");
  const [legahorjundiaimrv, setlegahorjundiaimrv] = useState("");
  const [rendapopjundiaimrv, setrendapopjundiaimrv] = useState("");
  const [rendajundiaimrv, setrendajundiaimrv] = useState("");
  const [popjundiaimrv, setpopjundiaimrv] = useState("");
  const [domicjundiaimrv, setdomicjundiaimrv] = useState("");
  const [domicalujundiaimrv, setdomicalujundiaimrv] = useState("");
  const [expurbjundiaimrv, setexpurbjundiaimrv] = useState("");
  const [taxahabjundiaimrv, settaxahabjundiaimrv] = useState("");
  const [graprohabjundiaimrv, setgraprohabjundiaimrv] = useState("");
  const [areaurbjundiaimrv, setareaurbjundiaimrv] = useState("");
  const [arearujundiaimrv, setarearujundiaimrv] = useState("");
  const [vaziosjundiaimrv, setvaziosjundiaimrv] = useState("");
  const [consumoaguajundiaimrv, setconsumoaguajundiaimrv] = useState("");
  const [dispaguajundiaimrv, setdispaguajundiaimrv] = useState("");
  const [custojundiaimrv, setcustojundiaimrv] = useState("");
  const [terrenosjundiaimrv, setterrenosjundiaimrv] = useState("");
  const [pontoajundiaimrv, setpontoajundiaimrv] = useState("");
  const [pontodjundiaimrv, setpontodjundiaimrv] = useState("");
  const [limeiramrv, setlimeiramrv] = useState("");
  const [applimeiramrv, setapplimeiramrv] = useState("");
  const [veglimeiramrv, setveglimeiramrv] = useState("");
  const [reslimeiramrv, setreslimeiramrv] = useState("");
  const [areaprolimeiramrv, setareaprolimeiramrv] = useState("");
  const [nasclimeiramrv, setnasclimeiramrv] = useState("");
  const [curslimeiramrv, setcurslimeiramrv] = useState("");
  const [corposlimeiramrv, setcorposlimeiramrv] = useState("");
  const [topolimeiramrv, settopolimeiramrv] = useState("");
  const [urblimeiramrv, seturblimeiramrv] = useState("");
  const [areapoulimeiramrv, setareapoulimeiramrv] = useState("");
  const [servlimeiramrv, setservlimeiramrv] = useState("");
  const [usorestlimeiramrv, setusorestlimeiramrv] = useState("");
  const [areaconslimeiramrv, setareaconslimeiramrv] = useState("");
  const [areacontlimeiramrv, setareacontlimeiramrv] = useState("");
  const [dutolimeiramrv, setdutolimeiramrv] = useState("");
  const [linhaolimeiramrv, setlinhaolimeiramrv] = useState("");
  const [zonealimeiramrv, setzonealimeiramrv] = useState("");
  const [legavertlimeiramrv, setlegavertlimeiramrv] = useState("");
  const [legahorlimeiramrv, setlegahorlimeiramrv] = useState("");
  const [rendapoplimeiramrv, setrendapoplimeiramrv] = useState("");
  const [rendalimeiramrv, setrendalimeiramrv] = useState("");
  const [poplimeiramrv, setpoplimeiramrv] = useState("");
  const [domiclimeiramrv, setdomiclimeiramrv] = useState("");
  const [domicalulimeiramrv, setdomicalulimeiramrv] = useState("");
  const [expurblimeiramrv, setexpurblimeiramrv] = useState("");
  const [taxahablimeiramrv, settaxahablimeiramrv] = useState("");
  const [graprohablimeiramrv, setgraprohablimeiramrv] = useState("");
  const [areaurblimeiramrv, setareaurblimeiramrv] = useState("");
  const [arearulimeiramrv, setarearulimeiramrv] = useState("");
  const [vazioslimeiramrv, setvazioslimeiramrv] = useState("");
  const [consumoagualimeiramrv, setconsumoagualimeiramrv] = useState("");
  const [dispagualimeiramrv, setdispagualimeiramrv] = useState("");
  const [custolimeiramrv, setcustolimeiramrv] = useState("");
  const [terrenoslimeiramrv, setterrenoslimeiramrv] = useState("");
  const [pontoalimeiramrv, setpontoalimeiramrv] = useState("");
  const [pontodlimeiramrv, setpontodlimeiramrv] = useState("");

  const [veloprecojundiaimrv, setveloprecojundiaimrv] = useState("");
  const [veloprecolimeiramrv, setveloprecolimeiramrv] = useState("");

  const [bdgu, setbdgu] = useState("");

  const [boticampcircu, setboticampcircu] = useState("");
  const [botijundcircu, setbotijundcircu] = useState("");
  const [botisorocircu, setbotisorocircu] = useState("");
  const [botigrandespcircu, setbotigrandespcircu] = useState("");
  const [grandespcircu, setgrandespcircu] = useState("");

  const [imoveisc1ic, setimoveisc1ic] = useState("");
  const [raioc1ic, setraioc1ic] = useState("");
  const [areaestudoc2ic, setareaestudoc2ic] = useState("");
  const [rendac2ic, setrendac2ic] = useState("");
  const [recortec2ic, setrecortec2ic] = useState("");
  const [pontosfluxc2ic, setpontosfluxc2ic] = useState("");
  const [concfluxoc2ic, setconcfluxoc2ic] = useState("");
  const [oticasc2ic, setoticasc2ic] = useState("");
  const [concfluxooticasc2ic, setconcfluxooticasc2ic] = useState("");
  const [imoveisc2ic, setimoveisc2ic] = useState("");
  const [shoppingsc2ic, setshoppingsc2ic] = useState("");

  const [kernelsaltosc, setkernelsaltosc] = useState("");
  const [kerneljacaresc, setkerneljacaresc] = useState("");
  const [ponibusmarasc, setponibusmarasc] = useState("");
  const [lonibusmarasc, setlonibusmarasc] = useState("");

  const [redesdrenalimeiramrv, setredesdrenalimeiramrv] = useState("");
  const [recargahidricalimeiramrv, setrecargahidricalimeiramrv] = useState("");
  const [ferroviaslimeiramrv, setferroviaslimeiramrv] = useState("");
  const [viaslimeiramrv, setviaslimeiramrv] = useState("");
  const [viasligalimeiramrv, setviasligalimeiramrv] = useState("");
  const [rankingterlimeiramrv, setrankingterlimeiramrv] = useState("");
  const [redesdrenajundiaimrv, setredesdrenajundiaimrv] = useState("");
  const [recargahidricajundiaimrv, setrecargahidricajundiaimrv] = useState("");
  const [viasjundiaimrv, setviasjundiaimrv] = useState("");
  const [viasligajundiaimrv, setviasligajundiaimrv] = useState("");
  const [ferroviasjundiaimrv, setferroviasjundiaimrv] = useState("");
  const [equipamentosjundiaimrv, setequipamentosjundiaimrv] = useState("");
  const [rankingterjundiaimrv, setrankingterjundiaimrv] = useState("");
  const [poluicaosonorajundiaimrv, setpoluicaosonorajundiaimrv] = useState("");

  const [acadmelten, setacadmelten] = useState("");
  const [areasafastmelten, setareasafastmelten] = useState("");
  const [areasproxmelten, setareasproxmelten] = useState("");
  const [comerciosmelten, setcomerciosmelten] = useState("");
  const [concconcmelten, setconcconcmelten] = useState("");
  const [concmelten, setconcmelten] = useState("");
  const [crescurbanomelten, setcrescurbanomelten] = useState("");
  const [domiciliosmelten, setdomiciliosmelten] = useState("");
  const [equipublimelten, setequipublimelten] = useState("");
  const [escolasprivadasmelten, setescolasprivadasmelten] = useState("");
  const [hidroviasmelten, sethidroviasmelten] = useState("");
  const [hospitaismelten, sethospitaismelten] = useState("");
  const [imoveismelten, setimoveismelten] = useState("");
  const [infraviariamelten, setinfraviariamelten] = useState("");
  const [melten, setmelten] = useState("");
  const [palmasmelten, setpalmasmelten] = useState("");
  const [popmelten, setpopmelten] = useState("");
  const [rendamelten, setrendamelten] = useState("");
  const [restaurantesmelten, setrestaurantesmelten] = useState("");
  const [transportemelten, settransportemelten] = useState("");
  const [viasmelten, setviasmelten] = useState("");

  const [gtdipocircu, setgtdipocircu] = useState("");
  const [arearedipocircu, setarearedipocircu] = useState("");
  const [areaspotipocircu, setareaspotipocircu] = useState("");
  const [coopreciipocircu, setcoopreciipocircu] = useState("");
  const [ipocircu, setipocircu] = useState("");
  const [empsipocircu, setempsipocircu] = useState("");
  const [densempsipocircu, setdensempsipocircu] = useState("");
  const [ecosipocircu, setecosipocircu] = useState("");

  const [correcondcamacircu, setcorrecondcamacircu] = useState("");
  const [correcondvargcircu, setcorrecondvargcircu] = useState("");
  const [correcondcampcircu, setcorrecondcampcircu] = useState("");
  const [correcondjundcircu, setcorrecondjundcircu] = useState("");
  const [correcondsorocircu, setcorrecondsorocircu] = useState("");

  const [regionalizacaomelten, setregionalizacaomelten] = useState("");

  const [crescpecpau, setcrescpecpau] = useState("");
  const [dadossociospecpau, setdadossociospecpau] = useState("");
  const [hidropecpau, sethidropecpau] = useState("");
  const [limitesambpecpau, setlimitesambpecpau] = useState("");
  const [pontosfluxopecpau, setpontosfluxopecpau] = useState("");
  const [raios500pecpau, setraios500pecpau] = useState("");
  const [servidaopecpau, setservidaopecpau] = useState("");
  const [terrenospotpecpau, setterrenospotpecpau] = useState("");
  const [terrenosranqpecpau, setterrenosranqpecpau] = useState("");
  const [topopecpau, settopopecpau] = useState("");
  const [vaziospecpau, setvaziospecpau] = useState("");

  const [playersagrovh, setplayersagrovh] = useState("");
  const [campinasvh, setcampinasvh] = useState("");
  const [parquesteccampvh, setparquesteccampvh] = useState("");

  const [demandagu, setdemandagu] = useState("");
  const [ativpesquisagu, setativpesquisagu] = useState("");
  const [interessehubgu, setinteressehubgu] = useState("");
  const [modelotrabalhogu, setmodelotrabalhogu] = useState("");
  const [capitalpropriogu, setcapitalpropriogu] = useState("");
  const [fundestaduaisgu, setfundestaduaisgu] = useState("");
  const [sebraegu, setsebraegu] = useState("");
  const [bndesgu, setbndesgu] = useState("");
  const [investidoranjogu, setinvestidoranjogu] = useState("");
  const [fundosinvestgu, setfundosinvestgu] = useState("");
  const [aceleradorasgu, setaceleradorasgu] = useState("");
  const [semrespostagu, setsemrespostagu] = useState("");

  const [ictsvh, setictsvh] = useState("");
  const [ictsagrovh, setictsagrovh] = useState("");

  const [areaspreservavespemcp, setareaspreservavespemcp] = useState("");
  const [vegnativavespemcp, setvegnativavespemcp] = useState("");
  const [reservalegalvespemcp, setreservalegalvespemcp] = useState("");
  const [nascentesvespemcp, setnascentesvespemcp] = useState("");
  const [cursosaguavespemcp, setcursosaguavespemcp] = useState("");
  const [topografiavespemcp, settopografiavespemcp] = useState("");
  const [limitevespemcp, setlimitevespemcp] = useState("");
  const [zoneamentovespemcp, setzoneamentovespemcp] = useState("");
  const [usovertvespemcp, setusovertvespemcp] = useState("");
  const [permissaovespemcp, setpermissaovespemcp] = useState("");
  const [locterrenovespemcp, setlocterrenovespemcp] = useState("");
  const [servidaovespemcp, setservidaovespemcp] = useState("");
  const [areaconsovespemcp, setareaconsovespemcp] = useState("");
  const [areascontavespemcp, setareascontavespemcp] = useState("");
  const [dutoviasvespemcp, setdutoviasvespemcp] = useState("");
  const [linhaovespemcp, setlinhaovespemcp] = useState("");
  const [novoscondvespemcp, setnovoscondvespemcp] = useState("");
  const [rendavespemcp, setrendavespemcp] = useState("");
  const [popvespemcp, setpopvespemcp] = useState("");
  const [domicivespemcp, setdomicivespemcp] = useState("");
  const [domicialuvespemcp, setdomicialuvespemcp] = useState("");
  const [expurbanavespemcp, setexpurbanavespemcp] = useState("");
  const [veloeprecovespemcp, setveloeprecovespemcp] = useState("");
  const [taxahabvespemcp, settaxahabvespemcp] = useState("");
  const [concorrenciavespemcp, setconcorrenciavespemcp] = useState("");
  const [areaurbavespemcp, setareaurbavespemcp] = useState("");
  const [areasruraisvespemcp, setareasruraisvespemcp] = useState("");
  const [consuaguavespemcp, setconsuaguavespemcp] = useState("");
  const [dispaguavespemcp, setdispaguavespemcp] = useState("");
  const [custoprojetovespemcp, setcustoprojetovespemcp] = useState("");
  const [terrenosanunvespemcp, setterrenosanunvespemcp] = useState("");
  const [imoveisanunvespemcp, setimoveisanunvespemcp] = useState("");
  const [pontosatravespemcp, setpontosatravespemcp] = useState("");
  const [pontosdetravespemcp, setpontosdetravespemcp] = useState("");

  const [tjchpdtjd, settjchpdtjd] = useState("");
  const [compdiretoschpdtjd, setcompdiretoschpdtjd] = useState("");
  const [criancas05chpdtjd, setcriancas05chpdtjd] = useState("");
  const [escolasidiomaschpdtjd, setescolasidiomaschpdtjd] = useState("");
  const [escolasinfantischpdtjd, setescolasinfantischpdtjd] = useState("");
  const [escolaschpdtjd, setescolaschpdtjd] = useState("");
  const [imovelindicadochpdtjd, setimovelindicadochpdtjd] = useState("");
  const [pontosfluxochpdtjd, setpontosfluxochpdtjd] = useState("");
  const [principaisviaschpdtjd, setprincipaisviaschpdtjd] = useState("");
  const [raio1kmcompchpdtjd, setraio1kmcompchpdtjd] = useState("");
  const [raio2kmcompchpdtjd, setraio2kmcompchpdtjd] = useState("");
  const [rendafamiliarchpdtjd, setrendafamiliarchpdtjd] = useState("");

  const [areainflurctjd, setareainflurctjd] = useState("");
  const [areasindirctjd, setareasindirctjd] = useState("");
  const [competidoresrctjd, setcompetidoresrctjd] = useState("");
  const [condominiosrctjd, setcondominiosrctjd] = useState("");
  const [imoveisrctjd, setimoveisrctjd] = useState("");
  const [pontosfluxirctjd, setpontosfluxirctjd] = useState("");
  const [compdiretosvptjd, setcompdiretosvptjd] = useState("");
  const [imoveissapopembavptjd, setimoveissapopembavptjd] = useState("");
  const [imoveisvpvptjd, setimoveisvpvptjd] = useState("");
  const [pontosfluxovptjd, setpontosfluxovptjd] = useState("");
  const [rendafamiliarvptjd, setrendafamiliarvptjd] = useState("");
  const [tjschoolvptjd, settjschoolvptjd] = useState("");

  const [appwbgi, setappwbgi] = useState("");
  const [areacontwbgi, setareacontwbgi] = useState("");
  const [curvasnivelwbgi, setcurvasnivelwbgi] = useState("");
  const [declividadewbgi, setdeclividadewbgi] = useState("");
  const [distcorposaguawbgi, setdistcorposaguawbgi] = useState("");
  const [distcorposhidricoswbgi, setdistcorposhidricoswbgi] = useState("");
  const [hidrografiawbgi, sethidrografiawbgi] = useState("");
  const [limeirawbgi, setlimeirawbgi] = useState("");
  const [reservalegalwbgi, setreservalegalwbgi] = useState("");
  const [vegnativawbgi, setvegnativawbgi] = useState("");
  const [viveiristaswbgi, setviveiristaswbgi] = useState("");
  
  const [limiteosascotnd, setlimiteosascotnd] = useState("");
  const [areapreserosascotnd, setareapreserosascotnd] = useState("");
  const [vegnaivaosascotnd, setvegnaivaosascotnd] = useState("");
  const [reservaosascotnd, setreservaosascotnd] = useState("");
  const [nascentesosascotnd, setnascentesosascotnd] = useState("");
  const [cursososascotnd, setcursososascotnd] = useState("");
  const [corpososascotnd, setcorpososascotnd] = useState("");
  const [topografiaosascotnd, settopografiaosascotnd] = useState("");
  const [zoneamentoosascotnd, setzoneamentoosascotnd] = useState("");
  const [usovertosascotnd, setusovertosascotnd] = useState("");
  const [implantacaohishmposascotnd, setimplantacaohishmposascotnd] = useState("");
  const [servadmosascotnd, setservadmosascotnd] = useState("");
  const [areaconsoosascotnd, setareaconsoosascotnd] = useState("");
  const [areascontaosascotnd, setareascontaosascotnd] = useState("");
  const [dutoviasosascotnd, setdutoviasosascotnd] = useState("");
  const [linhaoosascotnd, setlinhaoosascotnd] = useState("");
  const [pontosatratosascotnd, setpontosatratosascotnd] = useState("");
  const [pontosdetratosascotnd, setpontosdetratosascotnd] = useState("");
  const [rendaosascotnd, setrendaosascotnd] = useState("");
  const [domiciliososascotnd, setdomiciliososascotnd] = useState("");
  const [domiciliosaluguelosascotnd, setdomiciliosaluguelosascotnd] = useState("");
  const [expcidadesosascotnd, setexpcidadesosascotnd] = useState("");
  const [precomedioosascotnd, setprecomedioosascotnd] = useState("");
  const [taxahabosascotnd, settaxahabosascotnd] = useState("");
  const [concoosascotnd, setconcoosascotnd] = useState("");
  const [areaurbanizadaosascotnd, setareaurbanizadaosascotnd] = useState("");
  const [areasruraisosascotnd, setareasruraisosascotnd] = useState("");
  const [vaziosurbanososascotnd, setvaziosurbanososascotnd] = useState("");
  const [grapohabosascotnd, setgrapohabosascotnd] = useState("");
  const [terrenososascotnd, setterrenososascotnd] = useState("");
  const [imoveisosascotnd, setimoveisosascotnd] = useState("");
  const [tendenciassetorosascotnd, settendenciassetorosascotnd] = useState("");
  const [sinaismudancaosascotnd, setsinaismudancaosascotnd] = useState("");
  const [oportunidadeosascotnd, setoportunidadeosascotnd] = useState("");
  const [areausorestosascotnd, setareausorestosascotnd] = useState("");
  const [areapotlimeiramrv, setareapotlimeiramrv] = useState("");

  const [areapreserslgb, setareapreserslgb] = useState("");
  const [vegnativaslgb, setvegnativaslgb] = useState("");
  const [reservaslgb, setreservaslgb] = useState("");
  const [nascentesslgb, setnascentesslgb] = useState("");
  const [hidrografiaslgb, sethidrografiaslgb] = useState("");
  const [topografiaslgb, settopografiaslgb] = useState("");
  const [zoneamentoslgb, setzoneamentoslgb] = useState("");
  const [usovertslgb, setusovertslgb] = useState("");
  const [usohorslgb, setusohorslgb] = useState("");
  const [viasslgb, setviasslgb] = useState("");
  const [dutoviasslgb, setdutoviasslgb] = useState("");
  const [linhaoslgb, setlinhaoslgb] = useState("");
  const [pontosatratosslgb, setpontosatratosslgb] = useState("");
  const [pontosdetratslgb, setpontosdetratslgb] = useState("");
  const [rendaslgb, setrendaslgb] = useState("");
  const [domiciliosslgb, setdomiciliosslgb] = useState("");
  const [concoslgb, setconcoslgb] = useState("");
  const [areasruraisslgb, setareasruraisslgb] = useState("");
  const [terrenosslgb, setterrenosslgb] = useState("");
  const [imoveisslgb, setimoveisslgb] = useState("");

  const [raiopesquisaslgb, setraiopesquisaslgb] = useState("");
  const [terrenosanaslgb, setterrenosanaslgb] = useState("");

  const [limitestatnd,setlimitestatnd ] = useState("");
  const [areapreserstatnd,setareapreserstatnd ] = useState("");
  const [vegnaivastatnd,setvegnaivastatnd ] = useState("");
  const [reservastatnd,setreservastatnd ] = useState("");
  const [nascentesstatnd,setnascentesstatnd ] = useState("");
  const [hidrocarstatnd,sethidrocarstatnd ] = useState("");
  const [hidrocompstatnd,sethidrocompstatnd ] = useState("");
  const [topografiastatnd,settopografiastatnd ] = useState("");
  const [zoneamentostatnd,setzoneamentostatnd ] = useState("");
  const [usovertstatnd,setusovertstatnd ] = useState("");
  const [implantacaohishmpstatnd,setimplantacaohishmpstatnd ] = useState("");
  const [servadmstatnd,setservadmstatnd ] = useState("");
  const [areausoreststatnd,setareausoreststatnd ] = useState("");
  const [areaconsostatnd,setareaconsostatnd ] = useState("");
  const [areascontastatnd,setareascontastatnd ] = useState("");
  const [dutoviasstatnd,setdutoviasstatnd ] = useState("");
  const [linhaostatnd,setlinhaostatnd ] = useState("");
  const [rededrenagemstatnd,setrededrenagemstatnd ] = useState("");
  const [pontosatratstatnd,setpontosatratstatnd ] = useState("");
  const [pontosdetratstatnd,setpontosdetratstatnd ] = useState("");
  const [domiciliosstatnd,setdomiciliosstatnd ] = useState("");
  const [rendastatnd,setrendastatnd ] = useState("");
  const [domiciliosaluguelstatnd,setdomiciliosaluguelstatnd ] = useState("");
  const [expcidadesstatnd,setexpcidadesstatnd ] = useState("");
  const [expverticalstatnd,setexpverticalstatnd ] = useState("");
  const [precomediostatnd,setprecomediostatnd ] = useState("");
  const [taxahabstatnd,settaxahabstatnd ] = useState("");
  const [areaurbanizadastatnd,setareaurbanizadastatnd ] = useState("");
  const [areasruraisstatnd,setareasruraisstatnd ] = useState("");
  const [vaziosurbanosstatnd,setvaziosurbanosstatnd ] = useState("");
  const [grapohabstatnd,setgrapohabstatnd ] = useState("");
  const [terrenosstatnd,setterrenosstatnd ] = useState("");
  const [imoveisstatnd,setimoveisstatnd ] = useState("");
  
  const [limiteriva,setlimiteriva ] = useState("");
  const [recorteriva,setrecorteriva ] = useState("");
  const [areapreserriva,setareapreserriva ] = useState("");
  const [conservacaoriva,setconservacaoriva ] = useState("");
  const [cursosriva,setcursosriva ] = useState("");
  const [nascentesriva,setnascentesriva ] = useState("");
  const [areasriscoriva,setareasriscoriva ] = useState("");
  const [areasriscocontriva,setareasriscocontriva ] = useState("");
  const [topografiariva,settopografiariva ] = useState("");
  const [zoneamentoriva,setzoneamentoriva ] = useState("");
  const [usovertriva,setusovertriva ] = useState("");
  const [ aeroportosriva,setaeroportosriva ] = useState("");
  const [dutoviasriva,setdutoviasriva ] = useState("");
  const [linhaoriva,setlinhaoriva ] = useState("");
  const [protculturalriva,setprotculturalriva ] = useState("");
  const [bemcultriva,setbemcultriva ] = useState("");
  const [ bemcultimovelriva,setbemcultimovelriva ] = useState("");
  const [bemcultnaturalriva,setbemcultnaturalriva ] = useState("");
  const [diretrizriva,setdiretrizriva ] = useState("");
  const [tombamentoriva,settombamentoriva ] = useState("");
  const [pontosatratriva,setpontosatratriva ] = useState("");
  const [pontosdetratriva,setpontosdetratriva ] = useState("");
  const [ocupacaoriva,setocupacaoriva ] = useState("");
  const [rendariva,setrendariva ] = useState("");
  const [pmedioriva,setpmedioriva ] = useState("");
  const [taxahabriva,settaxahabriva ] = useState("");
  const [ concoriva,setconcoriva ] = useState("");
  const [vaziosriva,setvaziosriva ] = useState("");
  const [imoveisanunriva,setimoveisanunriva ] = useState("");

  const [limitemacapaic,setlimitemacapaic ] = useState("");
  const [recortemacapaic,setrecortemacapaic ] = useState("");
  const [varejomacapaic,setvarejomacapaic ] = useState("");
  const [concvarejomacapaic,setconcvarejomacapaic ] = useState("");
  const [concfluxomacapaic,setconcfluxomacapaic ] = useState("");
  const [shoppingsmacapaic,setshoppingsmacapaic ] = useState("");
  const [escolasmacapaic,setescolasmacapaic ] = useState("");
  const [hospitaismacapaic,sethospitaismacapaic ] = useState("");
  const [estacionamentosmacapaic,setestacionamentosmacapaic ] = useState("");
  const [concorrentesmacapaic,setconcorrentesmacapaic ] = useState("");
  const [universidadesmacapaic,setuniversidadesmacapaic ] = useState("");
  const [assaimacapaic,setassaimacapaic ] = useState("");
  const [rendamacapaic,setrendamacapaic ] = useState("");
  const [domiciliosmacapaic,setdomiciliosmacapaic ] = useState("");
  const [popmacapaic,setpopmacapaic ] = useState("");
  const [imoveismacapaic,setimoveismacapaic ] = useState("");

  const [catimoveisriva,setcatimoveisriva ] = useState("");

  const [domiciliosaureo,setdomiciliosaureo ] = useState("");
  const [empresasaureo,setempresasaureo ] = useState("");
  const [locinteresseaureo,setlocinteresseaureo ] = useState("");
  const [marcasaureo,setmarcasaureo ] = useState("");
  const [pontosaureo,setpontosaureo ] = useState("");
  const [raio1kmaureo,setraio1kmaureo ] = useState("");
  const [raio2kmaureo,setraio2kmaureo ] = useState("");
  const [rendaaureo,setrendaaureo ] = useState("");
  const [viasaureo,setviasaureo ] = useState("");
  const [zoneamentoaureo,setzoneamentoaureo ] = useState("");

  const [piscininha,setpiscininha ] = useState("");
  const [piscinao,setpiscinao ] = useState("");

  const [imoveisvendidosemcp,setimoveisvendidosemcp ] = useState("");
  const [outrossetoresemcp,setoutrossetoresemcp ] = useState("");
  const [setorescomimoveisemcp,setsetorescomimoveisemcp ] = useState("");
  const [vendasemcp,setvendasemcp ] = useState("");
  
  const [comerciosaureopet1,setcomerciosaureopet1 ] = useState("");
  const [domiciliosaureopet1,setdomiciliosaureopet1 ] = useState("");
  const [empreendimentoaureopet1,setempreendimentoaureopet1 ] = useState("");
  const [limiteaureopet1,setlimiteaureopet1 ] = useState("");
  const [localizacaoaureopet1,setlocalizacaoaureopet1 ] = useState("");
  const [marcasvareaureopet1,setmarcasvareaureopet1 ] = useState("");
  const [r1kmaureopet1,setr1kmaureopet1 ] = useState("");
  const [r2kmaureopet1,setr2kmaureopet1 ] = useState("");
  const [rendaaureopet1,setrendaaureopet1 ] = useState("");
  const [viasacessoaureopet1,setviasacessoaureopet1 ] = useState("");
  const [comerciosaureopet2,setcomerciosaureopet2 ] = useState("");
  const [domiciliosaureopet2,setdomiciliosaureopet2 ] = useState("");
  const [empreendimentoaureopet2,setempreendimentoaureopet2 ] = useState("");
  const [localizacaoaureopet2,setlocalizacaoaureopet2 ] = useState("");
  const [r1kmaureopet2,setr1kmaureopet2 ] = useState("");
  const [r2kmaureopet2,setr2kmaureopet2 ] = useState("");
  const [rendaaureopet2,setrendaaureopet2 ] = useState("");
  const [viasacessoaureopet2,setviasacessoaureopet2 ] = useState("");
  const [zoneamentoaureopet2,setzoneamentoaureopet2 ] = useState("");

  const [hidrologiaaureopet1,sethidrologiaaureopet1 ] = useState("");
  const [topografiaaureopet1,settopografiaaureopet1 ] = useState("");
  const [hidrologiaaureopet2,sethidrologiaaureopet2 ] = useState("");
  const [topografiaaureopet2,settopografiaaureopet2 ] = useState("");

  const [topografiaacc3,settopografiaacc3 ] = useState("");
  const [zoneamentoacc3,setzoneamentoacc3 ] = useState("");
  const [areapousoacc3,setareapousoacc3 ] = useState("");
  const [rotaaviaoacc3,setrotaaviaoacc3 ] = useState("");
  const [areascontacc3,setareascontacc3 ] = useState("");
  const [dutoviasacc3,setdutoviasacc3 ] = useState("");
  const [estabelecimentosacc3,setestabelecimentosacc3 ] = useState("");
  const [veiculosacc3,setveiculosacc3 ] = useState("");
  const [fluxoveicacc3,setfluxoveicacc3 ] = useState("");
  const [movimentacaoacc3,setmovimentacaoacc3 ] = useState("");
  const [rendaacc3,setrendaacc3 ] = useState("");
  const [populacaoacc3,setpopulacaoacc3 ] = useState("");
  const [domiciliosacc3,setdomiciliosacc3 ] = useState("");
  const [domialuguelacc3,setdomialuguelacc3 ] = useState("");
  const [areaurbaacc3,setareaurbaacc3 ] = useState("");

  const [spacc3,setspacc3 ] = useState("");
  const [guarulhosacc3,setguarulhosacc3 ] = useState("");
  const [trechoacc3,settrechoacc3 ] = useState("");

  const	[aeror2soroaureo,setaeror2soroaureo] = useState("");
  const	[shoppingr2soroaureo,setshoppingr2soroaureo] = useState("");
  const	[condor2soroaureo,setcondor2soroaureo] = useState("");
  const	[domicilior3soroaureo,setdomicilior3soroaureo] = useState("");
  const	[popr3soroaureo,setpopr3soroaureo] = useState("");
  const	[varejor3soroaureo,setvarejor3soroaureo] = useState("");
  const	[industriar3soroaureo,setindustriar3soroaureo] = useState("");
  const [domicilior4soroaureo,setdomicilior4soroaureo] = useState("");
  const [popr4soroaureo,setpopr4soroaureo] = useState("");
  const [varejor4soroaureo,setvarejor4soroaureo] = useState("");
  const [industriar4soroaureo,setindustriar4soroaureo] = useState("");
  const [domicilior5soroaureo,setdomicilior5soroaureo] = useState("");
  const [popr5soroaureo,setpopr5soroaureo] = useState("");
  const [varejor5soroaureo,setvarejor5soroaureo] = useState("");
  const [industriar5soroaureo,setindustriar5soroaureo] = useState("");
  const [sud1petrop1aureo,setsud1petrop1aureo] = useState("");
  const [sud2petrop1aureo,setsud2petrop1aureo] = useState("");
  const [sud1petrop2aureo,setsud1petrop2aureo] = useState("");
  const [sud2petrop2aureo,setsud2petrop2aureo] = useState("");
  
  const [areascontastacetesbtnd, setareascontastacetesbtnd] = useState("");
  const [areascontastasgmptnd, setareascontastasgmptnd] = useState("");
  const [limitebairrosstatnd, setlimitebairrosstatnd] = useState("");
  const [fichasstatnd, setfichasstatnd] = useState("");
  const [zoneamentostaeixotamandtnd, setzoneamentostaeixotamandtnd] = useState("");
  
  const [galpoesjaguariaivaic, setgalpoesjaguariaivaic] = useState("");

  const [zoneamentocuritibatrply, setzoneamentocuritibatrply] = useState("")
  const [permissaocuritibatrply, setpermissaocuritibatrply] = useState("")
  const [terrenoscuritibatrply, setterrenoscuritibatrply] = useState("")
  const [topografiacuritibatrply, settopografiacuritibatrply] = useState("")
  const [limitesambcuritibatrply, setlimitesambcuritibatrply] = useState("")
  const [hidrografiacuritibatrply, sethidrografiacuritibatrply] = useState("")
  const [servidaocuritibatrply, setservidaocuritibatrply] = useState("")
  const [terrenospotenciascuritibatrply, setterrenospotenciascuritibatrply] = useState("")
  const [crescimentocuritibatrply, setcrescimentocuritibatrply] = useState("")
  const [dadossocioscuritibatrply, setdadossocioscuritibatrply] = useState("")
  const [pontosdeinteressecuritibatrply, setpontosdeinteressecuritibatrply] = useState("")


  //SHOW

  const [showterrenoslivre, setshowterrenoslivre] = useState(false);
  const [showKmlL, setShowKmlL] = useState(false);
  const [showKml, setShowKml] = useState(false);
  const [showKmlT, setShowKmlT] = useState(false);
  const [showTopografia, setShowTopografia] = useState(false);
  const [showHidrografia, setShowHidrografia] = useState(false);
  const [showLimitesa, setShowLimitesa] = useState(false);
  const [showPermissao, setShowPermissao] = useState(false);
  const [showDados, setShowDados] = useState(false);
  const [showFluxo, setShowFluxo] = useState(false);
  const [showTheJ, setShowTheJ] = useState(false);

  const [showCompetidores, setShowCompetidores] = useState(false);

  const [showAreas, setShowAreas] = useState(false);
  const [showCondominios, setShowCondominios] = useState(false);
  const [showInfluencia, setShowInfluencia] = useState(false);
  const [showPontosFluxo, setShowPontosFluxo] = useState(false);
  const [showImoveis, setShowImoveis] = useState(false);

  //chapadao
  const [showC1, setShowC1] = useState(false);
  const [showC2, setShowC2] = useState(false);
  const [showC3, setShowC3] = useState(false);
  const [showC4, setShowC4] = useState(false);
  const [showC5, setShowC5] = useState(false);
  const [showC6, setShowC6] = useState(false);
  const [showC7, setShowC7] = useState(false);
  const [showC8, setShowC8] = useState(false);
  const [showC9, setShowC9] = useState(false);
  const [showC10, setShowC10] = useState(false);
  const [showC11, setShowC11] = useState(false);
  const [showC12, setShowC12] = useState(false);
  const [showC13, setShowC13] = useState(false);
  const [showC14, setShowC14] = useState(false);
  const [showC15, setShowC15] = useState(false);
  const [showC16, setShowC16] = useState(false);

  const [showimoveis200lk, setshowImoveis200lk] = useState(false);
  const [showsetoreslk, setShowsetoreslk] = useState(false);
  const [showraio1000lk, setShowraio1000lk] = useState(false);
  const [showraio500lk, setShowraio500lk] = useState(false);
  const [showviaslk, setShowviaslk] = useState(false);
  const [showcompetidoreslk, setShowcompetidoreslk] = useState(false);
  const [showhospitaislk, setShowhospitaislk] = useState(false);
  const [showpracaslk, setShowpracaslk] = useState(false);
  const [showmercadoslk, setShowmercadoslk] = useState(false);
  const [showrestauranteslk, setShowrestauranteslk] = useState(false);
  const [showcondominioslk, setShowcondominioslk] = useState(false);
  const [showfarmacialk, setShowfarmacialk] = useState(false);

  //gen - griffon living
  const [showareaverdegl, setShowareaverdegl] = useState(false);
  const [showmunicipiogl, setShowmunicipiogl] = useState(false);
  const [showcondominiogooglegl, setShowcondominiogooglegl] = useState(false);
  const [showcondominiograpgl, setShowshowcondominiograpgl] = useState(false);
  const [showrendagl, setShowrendagl] = useState(false);

  // alea campinas
  const [showservidao, setShowservidao] = useState(false);
  const [showcrescimento, setShowcrescimento] = useState(false);

  // tj paulinia
  const [showareasinfluenciatjp, setshowareasinfluenciatjp] = useState(false);
  const [showareasindicadastjp, setshowareasindicadastjp] = useState(false);
  const [showcompdiretostjp, setshowcompdiretostjp] = useState(false);
  const [showcompindiretostjp, setshowcompindiretostjp] = useState(false);
  const [showcondominiostjp, setshowcondominiostjp] = useState(false);
  const [showimoveistjp, setshowimoveistjp] = useState(false);
  const [showlimitetjp, setshowlimitetjp] = useState(false);
  const [showpontosfluxotjp, setshowpontosfluxotjp] = useState(false);
  const [showescolastjp, setshowescolastjp] = useState(false);

  // tj americana

  const [showareasinfluenciatja, setshowareasinfluenciatja] = useState(false);
  const [showareasindicadasinfantiltja, setshowareasindicadasinfantiltja] =
    useState(false);
  const [
    showareasindicadasfundamentaltja,
    setshowareasindicadasfundamentaltja,
  ] = useState(false);
  const [showcompdiretosinfantiltja, setshowcompdiretosinfantiltja] =
    useState(false);
  const [showcompdiretosfundamentaltja, setshowcompdiretosfundamentaltja] =
    useState(false);
  const [showcompindiretosinfantiltja, setshowcompindiretosinfantiltja] =
    useState(false);
  const [showcompindiretosfundamentaltja, setshowcompindiretosfundamentaltja] =
    useState(false);
  const [showconcentracao0_5tja, setshowconcentracao0_5tja] = useState(false);
  const [showconcentracao6_10tja, setshowconcentracao6_10tja] = useState(false);
  const [showescolatja, setshowescolatja] = useState(false);
  const [showlimitetja, setshowlimitetja] = useState(false);
  const [showpontosfluxotja, setshowpontosfluxotja] = useState(false);
  const [showrendatja, setshowrendatja] = useState(false);
  const [showrendaareainfantiltja, setshowrendaareainfantiltja] =
    useState(false);
  const [showrendaareafundatja, setshowrendaareafundatja] = useState(false);

  // HIDS

  const [showashidsgu, setshowashidsgu] = useState(false);
  const [showaspidsgu, setshowaspidsgu] = useState(false);
  const [showapidsgu, setshowapidsgu] = useState(false);
  const [showbempresasgu, setshowbempresasgu] = useState(false);
  const [showcampusdagu, setshowcampusdagu] = useState(false);
  const [showcampusgu, setshowcampusgu] = useState(false);
  const [showfzdarggu, setshowfzdarggu] = useState(false);
  const [showferroviasgu, setshowferroviasgu] = useState(false);
  const [showictsgu, setshowictsgu] = useState(false);
  const [showparquesgu, setshowparquesgu] = useState(false);
  const [showriosgu, setshowriosgu] = useState(false);
  const [showviasgu, setshowviasgu] = useState(false);

  //tj guarulhos
  const [showareasindicadasinfantiltjg, setshowareasindicadastjg] =
    useState(false);
  const [
    showareasindicadasfundamentaltjg,
    setshowareasindicadasfundamentaltjg,
  ] = useState(false);
  const [showcompdiretosinfantiltjg, setshowcompdiretosinfantiltjg] =
    useState(false);
  const [showcompdiretosfundamentaltjg, setshowcompdiretosfundamentaltjg] =
    useState(false);
  const [showcompindiretosinfantiltjg, setshowcompindiretosinfantiltjg] =
    useState(false);
  const [showcompindiretosfundamentaltjg, setshowcompindiretosfundamentaltjg] =
    useState(false);
  const [showconcentracao0_5tjg, setshowconcentracao0_5tjg] = useState(false);
  const [showconcentracao6_10tjg, setshowconcentracao6_10tjg] = useState(false);
  const [showfamiliastjg, setshowfamiliastjg] = useState(false);
  const [showrendatjg, setshowrendatjg] = useState(false);
  const [showimovelpretendidotjg, setshowimovelpretendidotjg] = useState(false);
  const [showlimitetjg, setshowlimitetjg] = useState(false);
  const [showpontosfluxotjg, setshowpontosfluxotjg] = useState(false);
  const [showraioinfluenciatjg, setshowraioinfluenciatjg] = useState(false);

  //edu
  const [showedumap, setShowEdumap] = useState(false);

  //crca
  const [showbairroscrca, setshowbairroscrca] = useState("");
  const [
    showdadossociosfamiliascampinascrca,
    setshowdadossociosfamiliascampinascrca,
  ] = useState("");
  const [
    showdadossociosrendacampinascrca,
    setshowdadossociosrendacampinascrca,
  ] = useState("");
  const [showzoneamentocampinascrca, setshowzoneamentocampinascrca] =
    useState("");
  const [showlimitecampinascrca, setshowlimitecampinascrca] = useState("");
  const [showdadosporbairro, setshowdadosporbairro] = useState("");

  //sjbvalea
  const [showsjbv, setshowsjbv] = useState("");
  const [showsjbv1zoneamento, setshowsjbv1zoneamento] = useState("");
  const [showsjbv2permissao, setshowsjbv2permissao] = useState("");
  const [showsjbv3terrenos, setshowsjbv3terrenos] = useState("");
  const [showsjbv5topografia, setshowsjbv5topografia] = useState("");
  const [showsjbv6limitesa, setshowsjbv6limitesa] = useState("");
  const [showsjbv7hidrografia, setshowsjbv7hidrografia] = useState("");
  const [showsjbv8servidao, setshowsjbv8servidao] = useState("");
  const [showsjbv9terrenosp, setshowsjbv9terrenosp] = useState("");
  const [showsjbv10crescimento, setshowsjbv10crescimento] = useState("");
  const [showsjbv11dadossocios, setshowsjbv11dadossocios] = useState("");
  const [showsjbv12distviario, setshowsjbv12distviario] = useState("");
  const [showsjbv13malha, setshowsjbv13malha] = useState("");
  const [showsjbv14pontos, setshowsjbv14pontos] = useState("");

  //rp
  const [showrp, setshowrp] = useState("");
  const [showrp1zoneamento, setshowrp1zoneamento] = useState("");
  const [showrp3terrenos, setshowrp3terrenos] = useState("");
  const [showrp5topografia, setshowrp5topografia] = useState("");
  const [showrp6limitesambientais, setshowrp6limitesambientais] = useState("");
  const [showrp7hidrografia, setshowrp7hidrografia] = useState("");
  const [showrp8servidao, setshowrp8servidao] = useState("");
  const [showrp10crescimento, setshowrp10crescimento] = useState("");
  const [
    showrp11dadossocioeconomicosrenda,
    setshowrp11dadossocioeconomicosrenda,
  ] = useState("");
  const [
    showrp12dadossocioeconomicosfamilias,
    setshowrp12dadossocioeconomicosfamilias,
  ] = useState("");

  //crca2
  const [showabrangenciacrascrca2, setshowabrangenciacrascrca2] = useState("");
  const [showassistenciateccrca2, setshowassistenciateccrca2] = useState("");
  const [showbairroscrca2, setshowbairroscrca2] = useState("");
  const [
    showcampinasdadossociosfamiliascrca2,
    setshowcampinasdadossociosfamiliascrca2,
  ] = useState("");
  const [
    showcampinasdadossociosrendacrca2,
    setshowcampinasdadossociosrendacrca2,
  ] = useState("");
  const [showcampinaslimitecrca2, setshowcampinaslimitecrca2] = useState("");
  const [showcampinaszoneamentocrca2, setshowcampinaszoneamentocrca2] =
    useState("");
  const [showcomercioalimentoscrca2, setshowcomercioalimentoscrca2] =
    useState("");
  const [showdadosporbairrocrca2, setshowdadosporbairrocrca2] = useState("");
  const [showimpressao3dcrca2, setshowimpressao3dcrca2] = useState("");
  const [showlojaderoupascrca2, setshowlojaderoupascrca2] = useState("");
  const [showlojasartesanatocrca2, setshowlojasartesanatocrca2] = useState("");
  const [showoficinasmecanicascrca2, setshowoficinasmecanicascrca2] =
    useState("");
  const [showorgcivilcrca2, setshoworgcivilcrca2] = useState("");
  const [showpadariasconfeitariascrca2, setshowpadariasconfeitariascrca2] =
    useState("");
  const [showpetshopscrca2, setshowpetshopscrca2] = useState("");
  const [showregioesvulcrca2, setshowregioesvulcrca2] = useState("");
  const [showsalaobelezacrc2, setshowsalaobelezacrc2] = useState("");

  //edu pizza
  const [showeducomp, setshoweducomp] = useState("");
  const [showedufamilia, setshowedufamilia] = useState("");
  const [showedurenda, setshowedurenda] = useState("");
  const [showeduimoveis, setshoweduimoveis] = useState("");

  //tjd
  const [showareasindicadasinfantiltjd, setshowareasindicadasinfantiltjd] =
    useState(false);
  const [
    showareasindicadasfundamentaltjd,
    setshowareasindicadasfundamentaltjd,
  ] = useState(false);
  const [showcompdiretosinfantiltjd, setshowcompdiretosinfantiltjd] =
    useState(false);
  const [showcompdiretosfundamentaltjd, setshowcompdiretosfundamentaltjd] =
    useState(false);
  const [showcompindiretosinfantiltjd, setshowcompindiretosinfantiltjd] =
    useState(false);
  const [showcompindiretosfundamentaltjd, setshowcompindiretosfundamentaltjd] =
    useState(false);
  const [
    showconcentracaocriancasinfantiltgd,
    setshowconcentracaocriancasinfantiltgd,
  ] = useState(false);
  const [showconcentracaocriancasfundtgd, setshowconcentracaocriancasfundtgd] =
    useState(false);
  const [showfamiliastjd, setshowfamiliastjd] = useState(false);
  const [showrendatjd, setshowrendatjd] = useState(false);
  const [showlimitetjd, setshowlimitetjd] = useState(false);
  const [showpontosfluxotjd, setshowpontosfluxotjd] = useState(false);
  const [showraioinfluenciatjd, setshowraioinfluenciatjd] = useState(false);

  // lavras
  const [showlegalizacaolavras, setshowlegalizacaolavras] = useState(false);

  //manapoke
  const [showacademiasmanapoke, setshowacademiasmanapoke] = useState("");
  const [showcentrosempresariasmanapoke, setshowcentrosempresariasmanapoke] =
    useState("");
  const [showcentrosesportivosmanapoke, setshowcentrosesportivosmanapoke] =
    useState("");
  const [showcomerciomanapoke, setshowcomerciomanapoke] = useState("");
  const [showcompcomidajaponesamanapoke, setshowcompcomidajaponesamanapoke] =
    useState("");
  const [showcompcomidasaudavelmanapoke, setshowcompcomidasaudavelmanapoke] =
    useState("");
  const [showimoveiscomerciaismanapoke, setshowimoveiscomerciaismanapoke] =
    useState("");
  const [showpontosculturaismanapoke, setshowpontosculturaismanapoke] =
    useState("");
  const [showpontoseducacaomanapoke, setshowpontoseducacaomanapoke] =
    useState("");
  const [showrendamanapoke, setshowrendamanapoke] = useState("");
  const [showareaspotenciaismanapoke, setshowareaspotenciaismanapoke] =
    useState("");

  //Lavras
  const [showzoneamentolavras, setshowzoneamentolavras] = useState("");
  const [showpermissaolavras, setshowpermissaolavras] = useState("");
  const [showterrenoslavras, setshowterrenoslavras] = useState("");
  const [showtopografialavras, setshowtopografialavras] = useState("");
  const [showapplavras, setshowapplavras] = useState("");
  const [showhidrografialavras, setshowhidrografialavras] = useState("");
  const [showservidaolavras, setshowservidaolavras] = useState("");
  const [showterrenosinteresselavras, setshowterrenosinteresselavras] =
    useState("");
  const [showcrescimentolavras, setshowcrescimentolavras] = useState("");
  const [showdadossocioslavras, setshowdadossocioslavras] = useState("");
  const [showdistviariolavras, showsetdistviariolavras] = useState("");
  const [showdisturbanalavras, setshowdisturbanalavras] = useState("");
  const [showpontoslavras, setshowpontoslavras] = useState("");

  //buddahspa
  const [showclubesbs, setshowclubesbs] = useState("");
  const [showfamiliasbs, setshowfamiliasbs] = useState("");
  const [showlojasdepartamentobs, setshowlojasdepartamentobs] = useState("");
  const [showparquesbs, setshowparquesbs] = useState("");
  const [showrendabs, setshowrendabs] = useState("");
  const [showpontosbuddhaspabs, setshowpontosbuddhaspabs] = useState("");
  const [showrestaurantesbs, setshowrestaurantesbs] = useState("");
  const [showshoppingsbs, setshowshoppingsbs] = useState("");
  const [showsupermercadosbs, setshowsupermercadosbs] = useState("");
  const [showestacionamentosbs, setshowestacionamentosbs] = useState("");
  const [showimoveismenos18bs, setshowimoveismenos18bs] = useState("");
  const [showimoveismais18bs, setshowimoveismais18bs] = useState("");

  const [showareainflucompbs, setshowareainflucompbs] = useState("");
  const [showareapotcomrecorte, setshowareapotcomrecorte] = useState("");
  const [showareapotsemrecorte, setshowareapotsemrecorte] = useState("");
  const [showcompetidoresbs, setshowcompetidoresbs] = useState("");
  const [showpopadultabs, setshowpopadultabs] = useState("");

  //baldoni
  const [showareaspotbd, setshowareaspotbd] = useState("");
  const [showbaldonibd, setshowbaldonibd] = useState("");
  const [showbaldoni2030bd, setshowbaldoni2030bd] = useState("");
  const [showlojaspescabd, setshowlojaspescabd] = useState("");
  const [showpesquesbd, setshowpesquesbd] = useState("");
  const [showpetshopsbd, setshowpetshopsbd] = useState("");
  const [showpopadultabd, setshowpopadultabd] = useState("");
  const [showrendabd, setshowrendabd] = useState("");
  const [showrestaurantesbd, setshowrestaurantesbd] = useState("");
  const [showvetsbd, setshowvetsbd] = useState("");

  // caribu
  const [showacademiascb, setshowacademiascb] = useState("");
  const [showareaspotcb, setshowareaspotcb] = useState("");
  const [showcafescb, setshowcafescb] = useState("");
  const [showcomerciocb, setshowcomerciocb] = useState("");
  const [showculturalcb, setshowculturalcb] = useState("");
  const [showescolascb, setshowescolascb] = useState("");
  const [showesportescb, setshowesportescb] = useState("");
  const [showfaculdadescb, setshowfaculdadescb] = useState("");
  const [showimoveiscb, setshowimoveiscb] = useState("");
  const [showlanchonetescb, setshowlanchonetescb] = useState("");
  const [showrendasetorescb, setshowrendasetorescb] = useState("");
  const [showcomidasaudavelcb, setshowcomidasaudavelcb] = useState("");

  //buddah
  const [showzonasusobs, setshowzonasusobs] = useState("");

  //rioverde
  const [showRio_Verde_1_zoneamento, setshowRio_Verde_1_zoneamento] =
    useState("");
  const [showRio_Verde_2_permissao, setshowRio_Verde_2_permissao] =
    useState("");
  const [showRio_Verde_3_terrenos, setshowRio_Verde_3_terrenos] = useState("");
  const [
    showRio_Verde_4_imoveis_disponiveis,
    setshowRio_Verde_4_imoveis_disponiveis,
  ] = useState("");
  const [showRio_Verde_5_topografia, setshowRio_Verde_5_topografia] =
    useState("");
  const [
    showRio_Verde_6_Limites_Ambientais,
    setshowRio_Verde_6_Limites_Ambientais,
  ] = useState("");
  const [showRio_Verde_7_Hidrografia, setshowRio_Verde_7_Hidrografia] =
    useState("");
  const [showRio_Verde_8_Servidao, setshowRio_Verde_8_Servidao] = useState("");
  const [
    showRio_Verde_9_terrenos_potenciais,
    setshowRio_Verde_9_terrenos_potenciais,
  ] = useState("");
  const [showRio_Verde_10_crescimento, setshowRio_Verde_10_crescimento] =
    useState("");
  const [
    showRio_Verde_11_dados_socioeconomicos,
    setshowRio_Verde_11_dados_socioeconomicos,
  ] = useState("");
  const [showRio_verde_12_dist_viario, setshowRio_verde_12_dist_viario] =
    useState("");
  const [showRio_Verde_13_Malha_Urbana, setshowRio_Verde_13_Malha_Urbana] =
    useState("");
  const [
    showRio_Verde_14_Pontos_de_Interesse,
    setshowRio_Verde_14_Pontos_de_Interesse,
  ] = useState("");
  const [showRio_Verde, setshowRio_Verde] = useState("");

  //tony
  const [showCruzeiro_1_Zoneamento, setshowCruzeiro_1_Zoneamento] =
    useState("");
  const [showGuaxupe_1_zoneamento, setshowGuaxupe_1_zoneamento] = useState("");
  const [showLavras_zoneamento_TESTE, setshowLavras_zoneamento_TESTE] =
    useState("");
  const [showSSParaiso_1_Zoneamento, setshowSSParaiso_1_Zoneamento] =
    useState("");

  //accenture
  const [showangradosreisacc, setshowangradosreisacc] = useState("");
  const [showbr101km414acc, setshowbr101km414acc] = useState("");
  const [showbr101km447acc, setshowbr101km447acc] = useState("");
  const [showbr101km538acc, setshowbr101km538acc] = useState("");
  const [showitaguaiacc, setshowitaguaiacc] = useState("");
  const [showmangaratibaacc, setshowmangaratibaacc] = useState("");
  const [showparatyacc, setshowparatyacc] = useState("");
  const [showtrechobr101acc, setshowtrechobr101acc] = useState("");

  const [showrendaacc, setshowrendaacc] = useState("");
  const [showareasconmataatlaacc, setshowareasconmataatlaacc] = useState("");
  const [showappacc, setshowappacc] = useState("");
  const [showvegetacaonativaacc, setshowvegetacaonativaacc] = useState("");
  const [showuniconsfederaisacc, setshowuniconsfederaisacc] = useState("");
  const [showreservalegalacc, setshowreservalegalacc] = useState("");
  const [showareasprotacc, setshowareasprotacc] = useState("");

  //caribu
  const [showacaiteriacb, setshowacaiteriacb] = useState("");
  const [showsorveteriacb, setshowsorveteriacb] = useState("");

  // accenture
  const [showmoteisac, setshowmoteisac] = useState("");
  const [showpedagiosac, setshowpedagiosac] = useState("");
  const [showpostosac, setshowpostosac] = useState("");
  const [showrestaurantesac, setshowrestaurantesac] = useState("");
  const [showhoteisac, setshowhoteisac] = useState("");
  const [showpousadasac, setshowpousadasac] = useState("");

  //rsg
  const [showareaspotpucrsg, setshowareaspotpucrsg] = useState("");
  const [showareaspotunicampport2rsg, setshowareaspotunicampport2rsg] =
    useState("");
  const [showareaspotunicampprincrsg, setshowareaspotunicampprincrsg] =
    useState("");
  const [showportariasrsg, setshowportariasrsg] = useState("");
  const [showraio200rsg, setshowraio200rsg] = useState("");
  const [showterrenosdisprsg, setshowterrenosdisprsg] = useState("");

  //caribu
  const [showfluxokernelcb, setshowfluxokernelcb] = useState("");
  const [showperimetrourbanocb, setshowperimetrourbanocb] = useState("");

  const [showconcentrafluxocb, setshowconcentrafluxocb] = useState("");
  const [showraiofluxocb, setshowraiofluxocb] = useState("");
  const [showfluxoconcocb, setshowfluxoconcocb] = useState("");

  //peconi
  const [showzoneamentopec, setshowzoneamentopec] = useState("");
  const [showpontosintpec, setshowpontosintpec] = useState("");
  const [showpermissaopec, setshowpermissaopec] = useState("");
  const [showtopografiapec, setshowtopografiapec] = useState("");
  const [showcrescimentoanopec, setshowcrescimentoanopec] = useState("");
  const [showgraprohab, setshowgraprohab] = useState("");
  const [showdensidadepec, setshowdensidadepec] = useState("");
  const [showfamiliaspec, setshowfamiliaspec] = useState("");
  const [showrendapec, setshowrendapec] = useState("");
  const [showcorpospec, setshowcorpospec] = useState("");
  const [showcursospec, setshowcursospec] = useState("");
  const [shownascentespec, setshownascentespec] = useState("");
  const [showapppec, setshowapppec] = useState("");
  const [showreservapec, setshowreservapec] = useState("");
  const [showvegpec, setshowvegpec] = useState("");
  const [showterrenos2k4kpec, setshowterrenos2k4kpec] = useState("");
  const [showterrenos5kmaispec, setshowterrenos5kmaispec] = useState("");
  const [showterrenosnaopec, setshowterrenosnaopec] = useState("");
  const [showterrenospot5kmaispec, setshowterrenospot5kmaispec] = useState("");
  const [showterrenospot150kmaispec, setshowterrenospot150kmaispec] =
    useState("");
  const [showmapaconcentracaopec, setshowmapaconcentracaopec] = useState("");

  //voto map
  const [showlimitevmap, setshowlimitevmap] = useState("");
  const [showpopfemvmap, setshowpopfemvmap] = useState("");
  const [showpopmasvmap, setshowpopmasvmap] = useState("");
  const [showpoptotvmap, setshowpoptotvmap] = useState("");
  const [showprefeitovmap, setshowprefeitovmap] = useState("");
  const [showprefeitovmap2, setshowprefeitovmap2] = useState("");
  const [showvereadorvmap, setshowvereadorvmap] = useState("");
  const [showvereadorvmap2, setshowvereadorvmap2] = useState("");
  const [showrendavmap, setshowrendavmap] = useState("");

  //pesquisa unicamp
  const [showdemandaespacopu, setshowdemandaespacopu] = useState("");
  const [showdemandaqualidadepu, setshowdemandaqualidadepu] = useState("");
  const [showfaixafaturamentopu, setshowfaixafaturamentopu] = useState("");
  const [showleispu, setshowleispu] = useState("");
  const [showmapageralpu, setshowmapageralpu] = useState("");
  const [showmodelopu, setshowmodelopu] = useState("");
  const [showpreferenpu, setshowpreferenpu] = useState("");
  const [shownecessidadepu, setshownecessidadepu] = useState("");
  const [showrelacaopu, setshowrelacaopu] = useState("");
  const [showsetorpu, setshowsetorpu] = useState("");

  //peconi
  const [showdivisaoregpec, setshowdivisaoregpec] = useState("");
  const [showvaziospec, setshowvaziospec] = useState("");
  const [showusosolopec, setshowusosolopec] = useState("");
  const [showconcenfluxospec, setshowconcenfluxospec] = useState("");
  const [showtopografiapec2, setshowtopografiapec2] = useState("");

  const [showfaixaac, setshowfaixaac] = useState("");

  const [showareaintbb, setshowareaintbb] = useState("");
  const [showboibrabobb, setshowboibrabobb] = useState("");
  const [showclassesbb, setshowclassesbb] = useState("");
  const [showcompsbb, setshowcompsbb] = useState("");
  const [showconccompsbb, setshowconccompsbb] = useState("");
  const [showconcpfbb, setshowconcpfbb] = useState("");
  const [showfamiliasbb, setshowfamiliasbb] = useState("");
  const [showfluxocompsbb, setshowfluxocompsbb] = useState("");
  const [showimoveisbb, setshowimoveisbb] = useState("");
  const [showpontosfluxobb, setshowpontosfluxobb] = useState("");
  const [showrendabb, setshowrendabb] = useState("");
  const [showmetrobb, setshowmetrobb] = useState("");
  const [showonibusbb, setshowonibusbb] = useState("");
  const [showtrembb, setshowtrembb] = useState("");
  const [showareapotbb, setshowareapotbb] = useState("");

  const [showareapotdp, setshowareapotdp] = useState("");
  const [showcompsdp, setshowcompsdp] = useState("");
  const [showconccompsdp, setshowconccompsdp] = useState("");
  const [showconcpontofluxodp, setshowconcpontofluxodp] = useState("");
  const [showfamiliasdp, setshowfamiliasdp] = useState("");
  const [showgastodp, setshowgastodp] = useState("");
  const [showpontofluxodp, setshowpontofluxodp] = useState("");
  const [showpop20a69dp, setshowpop20a69dp] = useState("");
  const [showrendadp, setshowrendadp] = useState("");

  const [showarmazensacc2, setshowarmazensacc2] = useState("");
  const [showbalsasacc2, setshowbalsasacc2] = useState("");
  const [showconcarmazensacc2, setshowconcarmazensacc2] = useState("");
  const [showformosoacc2, setshowformosoacc2] = useState("");
  const [showlagoaacc2, setshowlagoaacc2] = useState("");
  const [showmiranorteacc2, setshowmiranorteacc2] = useState("");
  const [showportoacc2, setshowportoacc2] = useState("");
  const [showsilvanopolisacc2, setshowsilvanopolisacc2] = useState("");
  const [showtassoacc2, setshowtassoacc2] = useState("");
  const [showusosoloacc2, setshowusosoloacc2] = useState("");
  const [showhidroviasgeralacc2, setshowhidroviasgeralacc2] = useState("");
  const [showviasgeralacc2, setshowviasgeralacc2] = useState("");
  const [showportsaoluisacc2, setshowportsaoluisacc2] = useState("");
  const [showportnacionalacc2, setshowportnacionalacc2] = useState("");
  const [showintpalemeiranteacc2, setshowintpalemeiranteacc2] = useState("");
  const [showrodoviasacc2, setshowrodoviasacc2] = useState("");
  const [showinstalacoesportacc2, setshowinstalacoesportacc2] = useState("");
  const [showhidroviasofcacc2, setshowhidroviasofcacc2] = useState("");
  const [showferroviasacc2, setshowferroviasacc2] = useState("");

  const [showusosolomiranorteacc2, setshowusosolomiranorteacc2] = useState("");
  const [showusosolosilvanopolisacc2, setshowusosolosilvanopolisacc2] =
    useState("");
  const [showusosololagoaacc2, setshowusosololagoaacc2] = useState("");
  const [showusosoloformosoacc2, setshowusosoloformosoacc2] = useState("");
  const [showusosoloportoacc2, setshowusosoloportoacc2] = useState("");
  const [showusosolobalsasacc2, setshowusosolobalsasacc2] = useState("");
  const [showusosolotassoacc2, setshowusosolotassoacc2] = useState("");

  const [showlmsaltosc, setshowlmsaltosc] = useState("");
  const [showcompsaltosc, setshowcompsaltosc] = useState("");
  const [showr1saltosc, setshowr1saltosc] = useState("");
  const [showr2saltosc, setshowr2saltosc] = useState("");
  const [showpfsaltosc, setshowpfsaltosc] = useState("");
  const [showscsaltosc, setshowscsaltosc] = useState("");
  const [showlmmarasc, setshowlmmarasc] = useState("");
  const [showcompmarasc, setshowcompmarasc] = useState("");
  const [showbuffermarasc, setshowbuffermarasc] = useState("");
  const [showpfmarasc, setshowpfmarasc] = useState("");
  const [showscmarasc, setshowscmarasc] = useState("");
  const [showlmjacaresc, setshowlmjacaresc] = useState("");
  const [showcompjacaresc, setshowcompjacaresc] = useState("");
  const [showr1jacaresc, setshowr1jacaresc] = useState("");
  const [showpfjacaresc, setshowpfjacaresc] = useState("");
  const [showscjacaresc, setshowscjacaresc] = useState("");
  const [showterrenospec, setshowterrenospec] = useState("");
  const [showterrenospecpau, setshowterrenospecpau] = useState("");

  const [showamoarmazensacc2, setshowamoarmazensacc2] = useState("");
  const [showprestaacc2, setshowprestaacc2] = useState("");
  const [showhabacc2, setshowhabacc2] = useState("");
  const [showcapacc2, setshowcapacc2] = useState("");
  const [showtipoacc2, setshowtipoacc2] = useState("");
  const [showporteacc2, setshowporteacc2] = useState("");
  const [showformacc2, setshowformacc2] = useState("");
  const [showdistferroacc2, setshowdistferroacc2] = useState("");
  const [showdistermacc2, setshowdistermacc2] = useState("");
  const [showestosoacc2, setshowestosoacc2] = useState("");
  const [showprecosoacc2, setshowprecosoacc2] = useState("");
  const [showpopacc2, setshowpopacc2] = useState("");
  const [showrendaacc2, setshowrendaacc2] = useState("");
  const [showaeroacc2, setshowaeroacc2] = useState("");
  const [showdeclacc2, setshowdeclacc2] = useState("");
  const [showdadossociosaltossc, setshowdadossociosaltossc] = useState("");
  const [showfluxo1marajasc, setshowfluxo1marajasc] = useState("");
  const [showkernelfluxo1marajasc, setshowkernelfluxo1marajasc] = useState("");
  const [showfamiliasmarajassc, setshowfamiliasmarajassc] = useState("");
  const [showrendamarajassc, setshowrendamarajassc] = useState("");
  const [showdadossociojacaresc, setshowdadossociojacaresc] = useState("");

  const [showcrescimentobrz, setshowcrescimentobrz] = useState("");
  const [showdomiciliosaluguelbrz, setshowdomiciliosaluguelbrz] = useState("");
  const [showdomicioliosbrz, setshowdomicioliosbrz] = useState("");
  const [showempreendimentosbrz, setshowempreendimentosbrz] = useState("");
  const [showlavrasbrz, setshowlavrasbrz] = useState("");
  const [showpopbrz, setshowpopbrz] = useState("");
  const [showraio15brz, setshowraio15brz] = useState("");
  const [showrendabrz, setshowrendabrz] = useState("");
  const [showvetorbrz, setshowvetorbrz] = useState("");

  const [showprop1toacc2, setshowprop1toacc2] = useState("");
  const [showprop2toacc2, setshowprop2toacc2] = useState("");
  const [showprop1maacc2, setshowprop1maacc2] = useState("");
  const [showprop2maacc2, setshowprop2maacc2] = useState("");

  const [showpontointbrz, setshowpontointbrz] = useState("");
  const [showcrescimento1brz, setshowcrescimento1brz] = useState("");
  const [showdeficitbrz, setshowdeficitbrz] = useState("");
  const [showtaxahabbrz, setshowtaxahabbrz] = useState("");

  const [showgtdcamacircu, setshowgtdcamacircu] = useState("");
  const [showdistcamacircu, setshowdistcamacircu] = useState("");
  const [showarearedcamacircu, setshowarearedcamacircu] = useState("");
  const [showareaspotcamacircu, setshowareaspotcamacircu] = useState("");
  const [showassocamacircu, setshowassocamacircu] = useState("");
  const [showcamacircu, setshowcamacircu] = useState("");
  const [showempscamacircu, setshowempscamacircu] = useState("");
  const [showconcempcamacircu, setshowconcempcamacircu] = useState("");
  const [showareaspotvargcircu, setshowareaspotvargcircu] = useState("");
  const [showassovargcircu, setshowassovargcircu] = useState("");
  const [showvargcircu, setshowvargcircu] = useState("");
  const [showempsvargcircu, setshowempsvargcircu] = useState("");
  const [showgtdcampcircu, setshowgtdcampcircu] = useState("");
  const [showdistcampcircu, setshowdistcampcircu] = useState("");
  const [showdistecocampcircu, setshowdistecocampcircu] = useState("");
  const [showarearedcampcircu, setshowarearedcampcircu] = useState("");
  const [showareaspotcampcircu, setshowareaspotcampcircu] = useState("");
  const [showassocampcircu, setshowassocampcircu] = useState("");
  const [showcampcircu, setshowcampcircu] = useState("");
  const [showempscampcircu, setshowempscampcircu] = useState("");
  const [showdensempscampcircu, setshowdensempscampcircu] = useState("");
  const [showecoscampcircu, setshowecoscampcircu] = useState("");
  const [showgtdjundcircu, setshowgtdjundcircu] = useState("");
  const [showdistjundcircu, setshowdistjundcircu] = useState("");
  const [showdistecojundcircu, setshowdistecojundcircu] = useState("");
  const [showarearedjundcircu, setshowarearedjundcircu] = useState("");
  const [showareaspotjundcircu, setshowareaspotjundcircu] = useState("");
  const [showassojundcircu, setshowassojundcircu] = useState("");
  const [showjundcircu, setshowjundcircu] = useState("");
  const [showempsjundcircu, setshowempsjundcircu] = useState("");
  const [showdensempsjundcircu, setshowdensempsjundcircu] = useState("");
  const [showecosjundcircu, setshowecosjundcircu] = useState("");
  const [showgtdsorocircu, setshowgtdsorocircu] = useState("");
  const [showdistsorocircu, setshowdistsorocircu] = useState("");
  const [showdistecosorocircu, setshowdistecosorocircu] = useState("");
  const [showarearedsorocircu, setshowarearedsorocircu] = useState("");
  const [showareaspotsorocircu, setshowareaspotsorocircu] = useState("");
  const [showassosorocircu, setshowassosorocircu] = useState("");
  const [showsorocircu, setshowsorocircu] = useState("");
  const [showempssorocircu, setshowempssorocircu] = useState("");
  const [showdensempssorocircu, setshowdensempssorocircu] = useState("");
  const [showecossorocircu, setshowecossorocircu] = useState("");
  const [showareapotafasdp, setshowareapotafasdp] = useState();
  const [showimoveisdp, setshowimoveisdp] = useState();

  const [showjundiaimrv, setshowjundiaimrv] = useState("");
  const [showappjundiaimrv, setshowappjundiaimrv] = useState("");
  const [showvegjundiaimrv, setshowvegjundiaimrv] = useState("");
  const [showresjundiaimrv, setshowresjundiaimrv] = useState("");
  const [showareaprojundiaimrv, setshowareaprojundiaimrv] = useState("");
  const [shownascjundiaimrv, setshownascjundiaimrv] = useState("");
  const [showcursjundiaimrv, setshowcursjundiaimrv] = useState("");
  const [showcorposjundiaimrv, setshowcorposjundiaimrv] = useState("");
  const [showtopojundiaimrv, setshowtopojundiaimrv] = useState("");
  const [showurbjundiaimrv, setshowurbjundiaimrv] = useState("");
  const [showareapoujundiaimrv, setshowareapoujundiaimrv] = useState("");
  const [showservjundiaimrv, setshowservjundiaimrv] = useState("");
  const [showusorestjundiaimrv, setshowusorestjundiaimrv] = useState("");
  const [showareaconsjundiaimrv, setshowareaconsjundiaimrv] = useState("");
  const [showareacontjundiaimrv, setshowareacontjundiaimrv] = useState("");
  const [showdutojundiaimrv, setshowdutojundiaimrv] = useState("");
  const [showlinhaojundiaimrv, setshowlinhaojundiaimrv] = useState("");
  const [showzoneajundiaimrv, setshowzoneajundiaimrv] = useState("");
  const [showlegavertjundiaimrv, setshowlegavertjundiaimrv] = useState("");
  const [showlegahorjundiaimrv, setshowlegahorjundiaimrv] = useState("");
  const [showrendapopjundiaimrv, setshowrendapopjundiaimrv] = useState("");
  const [showrendajundiaimrv, setshowrendajundiaimrv] = useState("");
  const [showpopjundiaimrv, setshowpopjundiaimrv] = useState("");
  const [showdomicjundiaimrv, setshowdomicjundiaimrv] = useState("");
  const [showdomicalujundiaimrv, setshowdomicalujundiaimrv] = useState("");
  const [showexpurbjundiaimrv, setshowexpurbjundiaimrv] = useState("");
  const [showtaxahabjundiaimrv, setshowtaxahabjundiaimrv] = useState("");
  const [showgraprohabjundiaimrv, setshowgraprohabjundiaimrv] = useState("");
  const [showareaurbjundiaimrv, setshowareaurbjundiaimrv] = useState("");
  const [showarearujundiaimrv, setshowarearujundiaimrv] = useState("");
  const [showvaziosjundiaimrv, setshowvaziosjundiaimrv] = useState("");
  const [showconsumoaguajundiaimrv, setshowconsumoaguajundiaimrv] =
    useState("");
  const [showdispaguajundiaimrv, setshowdispaguajundiaimrv] = useState("");
  const [showcustojundiaimrv, setshowcustojundiaimrv] = useState("");
  const [showterrenosjundiaimrv, setshowterrenosjundiaimrv] = useState("");
  const [showpontoajundiaimrv, setshowpontoajundiaimrv] = useState("");
  const [showpontodjundiaimrv, setshowpontodjundiaimrv] = useState("");
  const [showlimeiramrv, setshowlimeiramrv] = useState("");
  const [showapplimeiramrv, setshowapplimeiramrv] = useState("");
  const [showveglimeiramrv, setshowveglimeiramrv] = useState("");
  const [showreslimeiramrv, setshowreslimeiramrv] = useState("");
  const [showareaprolimeiramrv, setshowareaprolimeiramrv] = useState("");
  const [shownasclimeiramrv, setshownasclimeiramrv] = useState("");
  const [showcurslimeiramrv, setshowcurslimeiramrv] = useState("");
  const [showcorposlimeiramrv, setshowcorposlimeiramrv] = useState("");
  const [showtopolimeiramrv, setshowtopolimeiramrv] = useState("");
  const [showurblimeiramrv, setshowurblimeiramrv] = useState("");
  const [showareapoulimeiramrv, setshowareapoulimeiramrv] = useState("");
  const [showservlimeiramrv, setshowservlimeiramrv] = useState("");
  const [showusorestlimeiramrv, setshowusorestlimeiramrv] = useState("");
  const [showareaconslimeiramrv, setshowareaconslimeiramrv] = useState("");
  const [showareacontlimeiramrv, setshowareacontlimeiramrv] = useState("");
  const [showdutolimeiramrv, setshowdutolimeiramrv] = useState("");
  const [showlinhaolimeiramrv, setshowlinhaolimeiramrv] = useState("");
  const [showzonealimeiramrv, setshowzonealimeiramrv] = useState("");
  const [showlegavertlimeiramrv, setshowlegavertlimeiramrv] = useState("");
  const [showlegahorlimeiramrv, setshowlegahorlimeiramrv] = useState("");
  const [showrendapoplimeiramrv, setshowrendapoplimeiramrv] = useState("");
  const [showrendalimeiramrv, setshowrendalimeiramrv] = useState("");
  const [showpoplimeiramrv, setshowpoplimeiramrv] = useState("");
  const [showdomiclimeiramrv, setshowdomiclimeiramrv] = useState("");
  const [showdomicalulimeiramrv, setshowdomicalulimeiramrv] = useState("");
  const [showexpurblimeiramrv, setshowexpurblimeiramrv] = useState("");
  const [showtaxahablimeiramrv, setshowtaxahablimeiramrv] = useState("");
  const [showgraprohablimeiramrv, setshowgraprohablimeiramrv] = useState("");
  const [showareaurblimeiramrv, setshowareaurblimeiramrv] = useState("");
  const [showarearulimeiramrv, setshowarearulimeiramrv] = useState("");
  const [showvazioslimeiramrv, setshowvazioslimeiramrv] = useState("");
  const [showconsumoagualimeiramrv, setshowconsumoagualimeiramrv] =
    useState("");
  const [showdispagualimeiramrv, setshowdispagualimeiramrv] = useState("");
  const [showcustolimeiramrv, setshowcustolimeiramrv] = useState("");
  const [showterrenoslimeiramrv, setshowterrenoslimeiramrv] = useState("");
  const [showpontoalimeiramrv, setshowpontoalimeiramrv] = useState("");
  const [showpontodlimeiramrv, setshowpontodlimeiramrv] = useState("");
  const [showveloprecojundiaimrv, setshowveloprecojundiaimrv] = useState("");
  const [showveloprecolimeiramrv, setshowveloprecolimeiramrv] = useState("");

  const [showbdgu, setshowbdgu] = useState("");

  const [showboticampcircu, setshowboticampcircu] = useState("");
  const [showbotijundcircu, setshowbotijundcircu] = useState("");
  const [showbotisorocircu, setshowbotisorocircu] = useState("");
  const [showbotigrandespcircu, setshowbotigrandespcircu] = useState("");
  const [showgrandespcircu, setshowgrandespcircu] = useState("");

  const [showimoveisc1ic, setshowimoveisc1ic] = useState("");
  const [showraioc1ic, setshowraioc1ic] = useState("");
  const [showareaestudoc2ic, setshowareaestudoc2ic] = useState("");
  const [showrendac2ic, setshowrendac2ic] = useState("");
  const [showrecortec2ic, setshowrecortec2ic] = useState("");
  const [showpontosfluxc2ic, setshowpontosfluxc2ic] = useState("");
  const [showconcfluxoc2ic, setshowconcfluxoc2ic] = useState("");
  const [showoticasc2ic, setshowoticasc2ic] = useState("");
  const [showconcfluxooticasc2ic, setshowconcfluxooticasc2ic] = useState("");
  const [showimoveisc2ic, setshowimoveisc2ic] = useState("");
  const [showshoppingsc2ic, setshowshoppingsc2ic] = useState("");

  const [showkernelsaltosc, setshowkernelsaltosc] = useState("");
  const [showkerneljacaresc, setshowkerneljacaresc] = useState("");
  const [showponibusmarasc, setshowponibusmarasc] = useState("");
  const [showlonibusmarasc, setshowlonibusmarasc] = useState("");

  const [showredesdrenalimeiramrv, setshowredesdrenalimeiramrv] = useState("");
  const [showrecargahidricalimeiramrv, setshowrecargahidricalimeiramrv] =
    useState("");
  const [showferroviaslimeiramrv, setshowferroviaslimeiramrv] = useState("");
  const [showviaslimeiramrv, setshowviaslimeiramrv] = useState("");
  const [showviasligalimeiramrv, setshowviasligalimeiramrv] = useState("");
  const [showrankingterlimeiramrv, setshowrankingterlimeiramrv] = useState("");
  const [showredesdrenajundiaimrv, setshowredesdrenajundiaimrv] = useState("");
  const [showrecargahidricajundiaimrv, setshowrecargahidricajundiaimrv] =
    useState("");
  const [showviasjundiaimrv, setshowviasjundiaimrv] = useState("");
  const [showviasligajundiaimrv, setshowviasligajundiaimrv] = useState("");
  const [showferroviasjundiaimrv, setshowferroviasjundiaimrv] = useState("");
  const [showequipamentosjundiaimrv, setshowequipamentosjundiaimrv] =
    useState("");
  const [showrankingterjundiaimrv, setshowrankingterjundiaimrv] = useState("");
  const [showpoluicaosonorajundiaimrv, setshowpoluicaosonorajundiaimrv] =
    useState("");

  const [showacadmelten, setshowacadmelten] = useState("");
  const [showareasafastmelten, setshowareasafastmelten] = useState("");
  const [showareasproxmelten, setshowareasproxmelten] = useState("");
  const [showcomerciosmelten, setshowcomerciosmelten] = useState("");
  const [showconcconcmelten, setshowconcconcmelten] = useState("");
  const [showconcmelten, setshowconcmelten] = useState("");
  const [showcrescurbanomelten, setshowcrescurbanomelten] = useState("");
  const [showdomiciliosmelten, setshowdomiciliosmelten] = useState("");
  const [showequipublimelten, setshowequipublimelten] = useState("");
  const [showescolasprivadasmelten, setshowescolasprivadasmelten] =
    useState("");
  const [showhidroviasmelten, setshowhidroviasmelten] = useState("");
  const [showhospitaismelten, setshowhospitaismelten] = useState("");
  const [showimoveismelten, setshowimoveismelten] = useState("");
  const [showinfraviariamelten, setshowinfraviariamelten] = useState("");
  const [showmelten, setshowmelten] = useState("");
  const [showpalmasmelten, setshowpalmasmelten] = useState("");
  const [showpopmelten, setshowpopmelten] = useState("");
  const [showrendamelten, setshowrendamelten] = useState("");
  const [showrestaurantesmelten, setshowrestaurantesmelten] = useState("");
  const [showtransportemelten, setshowtransportemelten] = useState("");
  const [showviasmelten, setshowviasmelten] = useState("");

  const [showgtdipocircu, setshowgtdipocircu] = useState("");
  const [showarearedipocircu, setshowarearedipocircu] = useState("");
  const [showareaspotipocircu, setshowareaspotipocircu] = useState("");
  const [showcoopreciipocircu, setshowcoopreciipocircu] = useState("");
  const [showipocircu, setshowipocircu] = useState("");
  const [showempsipocircu, setshowempsipocircu] = useState("");
  const [showdensempsipocircu, setshowdensempsipocircu] = useState("");
  const [showecosipocircu, setshowecosipocircu] = useState("");

  const [showcorrecondcamacircu, setshowcorrecondcamacircu] = useState("");
  const [showcorrecondvargcircu, setshowcorrecondvargcircu] = useState("");
  const [showcorrecondcampcircu, setshowcorrecondcampcircu] = useState("");
  const [showcorrecondjundcircu, setshowcorrecondjundcircu] = useState("");
  const [showcorrecondsorocircu, setshowcorrecondsorocircu] = useState("");

  const [showregionalizacaomelten, setshowregionalizacaomelten] = useState("");

  const [showcrescpecpau, setshowcrescpecpau] = useState("");
  const [showdadossociospecpau, setshowdadossociospecpau] = useState("");
  const [showhidropecpau, setshowhidropecpau] = useState("");
  const [showlimitesambpecpau, setshowlimitesambpecpau] = useState("");
  const [showpontosfluxopecpau, setshowpontosfluxopecpau] = useState("");
  const [showraios500pecpau, setshowraios500pecpau] = useState("");
  const [showservidaopecpau, setshowservidaopecpau] = useState("");
  const [showterrenospotpecpau, setshowterrenospotpecpau] = useState("");
  const [showterrenosranqpecpau, setshowterrenosranqpecpau] = useState("");
  const [showtopopecpau, setshowtopopecpau] = useState("");
  const [showvaziospecpau, setshowvaziospecpau] = useState("");

  const [showplayersagrovh, setshowplayersagrovh] = useState("");
  const [showcampinasvh, setshowcampinasvh] = useState("");
  const [showparquesteccampvh, setshowparquesteccampvh] = useState("");

  const [showdemandagu, setshowdemandagu] = useState("");
  const [showativpesquisagu, setshowativpesquisagu] = useState("");
  const [showinteressehubgu, setshowinteressehubgu] = useState("");
  const [showmodelotrabalhogu, setshowmodelotrabalhogu] = useState("");
  const [showcapitalpropriogu, setshowcapitalpropriogu] = useState("");
  const [showfundestaduaisgu, setshowfundestaduaisgu] = useState("");
  const [showsebraegu, setshowsebraegu] = useState("");
  const [showbndesgu, setshowbndesgu] = useState("");
  const [showinvestidoranjogu, setshowinvestidoranjogu] = useState("");
  const [showfundosinvestgu, setshowfundosinvestgu] = useState("");
  const [showaceleradorasgu, setshowaceleradorasgu] = useState("");
  const [showsemrespostagu, setshowsemrespostagu] = useState("");

  const [showictsvh, setshowictsvh] = useState("");
  const [showictsagrovh, setshowictsagrovh] = useState("");

  const [showareaspreservavespemcp, setshowareaspreservavespemcp] =
    useState("");
  const [showvegnativavespemcp, setshowvegnativavespemcp] = useState("");
  const [showreservalegalvespemcp, setshowreservalegalvespemcp] = useState("");
  const [shownascentesvespemcp, setshownascentesvespemcp] = useState("");
  const [showcursosaguavespemcp, setshowcursosaguavespemcp] = useState("");
  const [showtopografiavespemcp, setshowtopografiavespemcp] = useState("");
  const [showlimitevespemcp, setshowlimitevespemcp] = useState("");
  const [showzoneamentovespemcp, setshowzoneamentovespemcp] = useState("");
  const [showusovertvespemcp, setshowusovertvespemcp] = useState("");
  const [showpermissaovespemcp, setshowpermissaovespemcp] = useState("");
  const [showlocterrenovespemcp, setshowlocterrenovespemcp] = useState("");
  const [showservidaovespemcp, setshowservidaovespemcp] = useState("");
  const [showareaconsovespemcp, setshowareaconsovespemcp] = useState("");
  const [showareascontavespemcp, setshowareascontavespemcp] = useState("");
  const [showdutoviasvespemcp, setshowdutoviasvespemcp] = useState("");
  const [showlinhaovespemcp, setshowlinhaovespemcp] = useState("");
  const [shownovoscondvespemcp, setshownovoscondvespemcp] = useState("");
  const [showrendavespemcp, setshowrendavespemcp] = useState("");
  const [showpopvespemcp, setshowpopvespemcp] = useState("");
  const [showdomicivespemcp, setshowdomicivespemcp] = useState("");
  const [showdomicialuvespemcp, setshowdomicialuvespemcp] = useState("");
  const [showexpurbanavespemcp, setshowexpurbanavespemcp] = useState("");
  const [showveloeprecovespemcp, setshowveloeprecovespemcp] = useState("");
  const [showtaxahabvespemcp, setshowtaxahabvespemcp] = useState("");
  const [showconcorrenciavespemcp, setshowconcorrenciavespemcp] = useState("");
  const [showareaurbavespemcp, setshowareaurbavespemcp] = useState("");
  const [showareasruraisvespemcp, setshowareasruraisvespemcp] = useState("");
  const [showconsuaguavespemcp, setshowconsuaguavespemcp] = useState("");
  const [showdispaguavespemcp, setshowdispaguavespemcp] = useState("");
  const [showcustoprojetovespemcp, setshowcustoprojetovespemcp] = useState("");
  const [showterrenosanunvespemcp, setshowterrenosanunvespemcp] = useState("");
  const [showimoveisanunvespemcp, setshowimoveisanunvespemcp] = useState("");
  const [showpontosatravespemcp, setshowpontosatravespemcp] = useState("");
  const [showpontosdetravespemcp, setshowpontosdetravespemcp] = useState("");

  const [showtjchpdtjd, setshowtjchpdtjd] = useState("");
  const [showcompdiretoschpdtjd, setshowcompdiretoschpdtjd] = useState("");
  const [showcriancas05chpdtjd, setshowcriancas05chpdtjd] = useState("");
  const [showescolasidiomaschpdtjd, setshowescolasidiomaschpdtjd] =
    useState("");
  const [showescolasinfantischpdtjd, setshowescolasinfantischpdtjd] =
    useState("");
  const [showescolaschpdtjd, setshowescolaschpdtjd] = useState("");
  const [showimovelindicadochpdtjd, setshowimovelindicadochpdtjd] =
    useState("");
  const [showpontosfluxochpdtjd, setshowpontosfluxochpdtjd] = useState("");
  const [showprincipaisviaschpdtjd, setshowprincipaisviaschpdtjd] =
    useState("");
  const [showraio1kmcompchpdtjd, setshowraio1kmcompchpdtjd] = useState("");
  const [showraio2kmcompchpdtjd, setshowraio2kmcompchpdtjd] = useState("");
  const [showrendafamiliarchpdtjd, setshowrendafamiliarchpdtjd] = useState("");

  const [showareainflurctjd, setshowareainflurctjd] = useState("");
  const [showareasindirctjd, setshowareasindirctjd] = useState("");
  const [showcompetidoresrctjd, setshowcompetidoresrctjd] = useState("");
  const [showcondominiosrctjd, setshowcondominiosrctjd] = useState("");
  const [showimoveisrctjd, setshowimoveisrctjd] = useState("");
  const [showpontosfluxirctjd, setshowpontosfluxirctjd] = useState("");
  const [showcompdiretosvptjd, setshowcompdiretosvptjd] = useState("");
  const [showimoveissapopembavptjd, setshowimoveissapopembavptjd] =
    useState("");
  const [showimoveisvpvptjd, setshowimoveisvpvptjd] = useState("");
  const [showpontosfluxovptjd, setshowpontosfluxovptjd] = useState("");
  const [showrendafamiliarvptjd, setshowrendafamiliarvptjd] = useState("");
  const [showtjschoolvptjd, setshowtjschoolvptjd] = useState("");

  const [showappwbgi, setshowappwbgi] = useState("");
  const [showareacontwbgi, setshowareacontwbgi] = useState("");
  const [showcurvasnivelwbgi, setshowcurvasnivelwbgi] = useState("");
  const [showdeclividadewbgi, setshowdeclividadewbgi] = useState("");
  const [showdistcorposaguawbgi, setshowdistcorposaguawbgi] = useState("");
  const [showdistcorposhidricoswbgi, setshowdistcorposhidricoswbgi] =
    useState("");
  const [showhidrografiawbgi, setshowhidrografiawbgi] = useState("");
  const [showlimeirawbgi, setshowlimeirawbgi] = useState("");
  const [showreservalegalwbgi, setshowreservalegalwbgi] = useState("");
  const [showvegnativawbgi, setshowvegnativawbgi] = useState("");
  const [showviveiristaswbgi, setshowviveiristaswbgi] = useState("");

  const [showlimiteosascotnd, setshowlimiteosascotnd] = useState("");
  const [showareapreserosascotnd, setshowareapreserosascotnd] = useState("");
  const [showvegnaivaosascotnd, setshowvegnaivaosascotnd] = useState("");
  const [showreservaosascotnd, setshowreservaosascotnd] = useState("");
  const [shownascentesosascotnd, setshownascentesosascotnd] = useState("");
  const [showcursososascotnd, setshowcursososascotnd] = useState("");
  const [showcorpososascotnd, setshowcorpososascotnd] = useState("");
  const [showtopografiaosascotnd, setshowtopografiaosascotnd] = useState("");
  const [showzoneamentoosascotnd, setshowzoneamentoosascotnd] = useState("");
  const [showusovertosascotnd, setshowusovertosascotnd] = useState("");
  const [showimplantacaohishmposascotnd, setshowimplantacaohishmposascotnd] = useState("");
  const [showservadmosascotnd, setshowservadmosascotnd] = useState("");
  const [showareaconsoosascotnd, setshowareaconsoosascotnd] = useState("");
  const [showareascontaosascotnd, setshowareascontaosascotnd] = useState("");
  const [showdutoviasosascotnd, setshowdutoviasosascotnd] = useState("");
  const [showlinhaoosascotnd, setshowlinhaoosascotnd] = useState("");
  const [showpontosatratosascotnd, setshowpontosatratosascotnd] = useState("");
  const [showpontosdetratosascotnd, setshowpontosdetratosascotnd] = useState("");
  const [showrendaosascotnd, setshowrendaosascotnd] = useState("");
  const [showdomiciliososascotnd, setshowdomiciliososascotnd] = useState("");
  const [showdomiciliosaluguelosascotnd, setshowdomiciliosaluguelosascotnd] = useState("");
  const [showexpcidadesosascotnd, setshowexpcidadesosascotnd] = useState("");
  const [showprecomedioosascotnd, setshowprecomedioosascotnd] = useState("");
  const [showtaxahabosascotnd, setshowtaxahabosascotnd] = useState("");
  const [showconcoosascotnd, setshowconcoosascotnd] = useState("");
  const [showareaurbanizadaosascotnd, setshowareaurbanizadaosascotnd] = useState("");
  const [showareasruraisosascotnd, setshowareasruraisosascotnd] = useState("");
  const [showvaziosurbanososascotnd, setshowvaziosurbanososascotnd] = useState("");
  const [showgrapohabosascotnd, setshowgrapohabosascotnd] = useState("");
  const [showterrenososascotnd, setshowterrenososascotnd] = useState("");
  const [showimoveisosascotnd, setshowimoveisosascotnd] = useState("");
  const [showtendenciassetorosascotnd, setshowtendenciassetorosascotnd] = useState("");
  const [showsinaismudancaosascotnd, setshowsinaismudancaosascotnd] = useState("");
  const [showoportunidadeosascotnd, setshowoportunidadeosascotnd] = useState("");
  const [showareausorestosascotnd, setshowareausorestosascotnd] = useState("");
  const [showareapotlimeiramrv, setshowareapotlimeiramrv] = useState("");

  const [showareapreserslgb, setshowareapreserslgb] = useState("");
  const [showvegnativaslgb, setshowvegnativaslgb] = useState("");
  const [showreservaslgb, setshowreservaslgb] = useState("");
  const [shownascentesslgb, setshownascentesslgb] = useState("");
  const [showhidrografiaslgb, setshowhidrografiaslgb] = useState("");
  const [showtopografiaslgb, setshowtopografiaslgb] = useState("");
  const [showzoneamentoslgb, setshowzoneamentoslgb] = useState("");
  const [showusovertslgb, setshowusovertslgb] = useState("");
  const [showusohorslgb, setshowusohorslgb] = useState("");
  const [showviasslgb, setshowviasslgb] = useState("");
  const [showdutoviasslgb, setshowdutoviasslgb] = useState("");
  const [showlinhaoslgb, setshowlinhaoslgb] = useState("");
  const [showpontosatratosslgb, setshowpontosatratosslgb] = useState("");
  const [showpontosdetratslgb, setshowpontosdetratslgb] = useState("");
  const [showrendaslgb, setshowrendaslgb] = useState("");
  const [showdomiciliosslgb, setshowdomiciliosslgb] = useState("");
  const [showconcoslgb, setshowconcoslgb] = useState("");
  const [showareasruraisslgb, setshowareasruraisslgb] = useState("");
  const [showterrenosslgb, setshowterrenosslgb] = useState("");
  const [showimoveisslgb, setshowimoveisslgb] = useState("");

  const [showraiopesquisaslgb, setshowraiopesquisaslgb] = useState("");
  const [showterrenosanaslgb, setshowterrenosanaslgb] = useState("");

  const [showlimitestatnd,setshowlimitestatnd ] = useState("");
  const [showareapreserstatnd,setshowareapreserstatnd ] = useState("");
  const [showvegnaivastatnd,setshowvegnaivastatnd ] = useState("");
  const [showreservastatnd,setshowreservastatnd ] = useState("");
  const [shownascentesstatnd,setshownascentesstatnd ] = useState("");
  const [showhidrocarstatnd,setshowhidrocarstatnd ] = useState("");
  const [showhidrocompstatnd,setshowhidrocompstatnd ] = useState("");
  const [showtopografiastatnd,setshowtopografiastatnd ] = useState("");
  const [showzoneamentostatnd,setshowzoneamentostatnd ] = useState("");
  const [showusovertstatnd,setshowusovertstatnd ] = useState("");
  const [showimplantacaohishmpstatnd,setshowimplantacaohishmpstatnd ] = useState("");
  const [showservadmstatnd,setshowservadmstatnd ] = useState("");
  const [showareausoreststatnd,setshowareausoreststatnd ] = useState("");
  const [showareaconsostatnd,setshowareaconsostatnd ] = useState("");
  const [showareascontastatnd,setshowareascontastatnd ] = useState("");
  const [showdutoviasstatnd,setshowdutoviasstatnd ] = useState("");
  const [showlinhaostatnd,setshowlinhaostatnd ] = useState("");
  const [showrededrenagemstatnd,setshowrededrenagemstatnd ] = useState("");
  const [showpontosatratstatnd,setshowpontosatratstatnd ] = useState("");
  const [showpontosdetratstatnd,setshowpontosdetratstatnd ] = useState("");
  const [showdomiciliosstatnd,setshowdomiciliosstatnd ] = useState("");
  const [showrendastatnd,setshowrendastatnd ] = useState("");
  const [showdomiciliosaluguelstatnd,setshowdomiciliosaluguelstatnd ] = useState("");
  const [showexpcidadesstatnd,setshowexpcidadesstatnd ] = useState("");
  const [showexpverticalstatnd,setshowexpverticalstatnd ] = useState("");
  const [showprecomediostatnd,setshowprecomediostatnd ] = useState("");
  const [showtaxahabstatnd,setshowtaxahabstatnd ] = useState("");
  const [showareaurbanizadastatnd,setshowareaurbanizadastatnd ] = useState("");
  const [showareasruraisstatnd,setshowareasruraisstatnd ] = useState("");
  const [showvaziosurbanosstatnd,setshowvaziosurbanosstatnd ] = useState("");
  const [showgrapohabstatnd,setshowgrapohabstatnd ] = useState("");
  const [showterrenosstatnd,setshowterrenosstatnd ] = useState("");
  const [showimoveisstatnd,setshowimoveisstatnd ] = useState("");

  const [showlimiteriva,setshowlimiteriva ] = useState("");
  const [showrecorteriva,setshowrecorteriva ] = useState("");
  const [showareapreserriva,setshowareapreserriva ] = useState("");
  const [showconservacaoriva,setshowconservacaoriva ] = useState("");
  const [showcursosriva,setshowcursosriva ] = useState("");
  const [shownascentesriva,setshownascentesriva ] = useState("");
  const [showareasriscoriva,setshowareasriscoriva ] = useState("");
  const [showareasriscocontriva,setshowareasriscocontriva ] = useState("");
  const [showtopografiariva,setshowtopografiariva ] = useState("");
  const [showzoneamentoriva,setshowzoneamentoriva ] = useState("");
  const [showusovertriva,setshowusovertriva ] = useState("");
  const [showaeroportosriva,setshowaeroportosriva ] = useState("");
  const [showdutoviasriva,setshowdutoviasriva ] = useState("");
  const [showlinhaoriva,setshowlinhaoriva ] = useState("");
  const [showprotculturalriva,setshowprotculturalriva ] = useState("");
  const [showbemcultriva,setshowbemcultriva ] = useState("");
  const [showbemcultimovelriva,setshowbemcultimovelriva ] = useState("");
  const [showbemcultnaturalriva,setshowbemcultnaturalriva ] = useState("");
  const [showdiretrizriva,setshowdiretrizriva ] = useState("");
  const [showtombamentoriva,setshowtombamentoriva ] = useState("");
  const [showpontosatratriva,setshowpontosatratriva ] = useState("");
  const [showpontosdetratriva,setshowpontosdetratriva ] = useState("");
  const [showocupacaoriva,setshowocupacaoriva ] = useState("");
  const [showrendariva,setshowrendariva ] = useState("");
  const [showpmedioriva,setshowpmedioriva ] = useState("");
  const [showtaxahabriva,setshowtaxahabriva ] = useState("");
  const [showconcoriva,setshowconcoriva ] = useState("");
  const [showvaziosriva,setshowvaziosriva ] = useState("");
  const [showimoveisanunriva,setshowimoveisanunriva ] = useState("");

  const [showlimitemacapaic,setshowlimitemacapaic ] = useState("");
  const [showrecortemacapaic,setshowrecortemacapaic ] = useState("");
  const [showvarejomacapaic,setshowvarejomacapaic ] = useState("");
  const [showconcvarejomacapaic,setshowconcvarejomacapaic ] = useState("");
  const [showconcfluxomacapaic,setshowconcfluxomacapaic ] = useState("");
  const [showshoppingsmacapaic,setshowshoppingsmacapaic ] = useState("");
  const [showescolasmacapaic,setshowescolasmacapaic ] = useState("");
  const [showhospitaismacapaic,setshowhospitaismacapaic ] = useState("");
  const [showestacionamentosmacapaic,setshowestacionamentosmacapaic ] = useState("");
  const [showconcorrentesmacapaic,setshowconcorrentesmacapaic ] = useState("");
  const [showuniversidadesmacapaic,setshowuniversidadesmacapaic ] = useState("");
  const [showassaimacapaic,setshowassaimacapaic ] = useState("");
  const [showrendamacapaic,setshowrendamacapaic ] = useState("");
  const [showdomiciliosmacapaic,setshowdomiciliosmacapaic ] = useState("");
  const [showpopmacapaic,setshowpopmacapaic ] = useState("");
  const [showimoveismacapaic,setshowimoveismacapaic ] = useState("");

  const [showcatimoveisriva,setshowcatimoveisriva ] = useState("");

  const [showdomiciliosaureo,setshowdomiciliosaureo ] = useState("");
  const [showempresasaureo,setshowempresasaureo ] = useState("");
  const [showlocinteresseaureo,setshowlocinteresseaureo ] = useState("");
  const [showmarcasaureo,setshowmarcasaureo ] = useState("");
  const [showpontosaureo,setshowpontosaureo ] = useState("");
  const [showraio1kmaureo,setshowraio1kmaureo ] = useState("");
  const [showraio2kmaureo,setshowraio2kmaureo ] = useState("");
  const [showrendaaureo,setshowrendaaureo ] = useState("");
  const [showviasaureo,setshowviasaureo ] = useState("");
  const [showzoneamentoaureo,setshowzoneamentoaureo ] = useState("");
  const [showpiscininha,setshowpiscininha ] = useState("");
  const [showpiscinao,setshowpiscinao ] = useState("");

  const [showimoveisvendidosemcp,setshowimoveisvendidosemcp ] = useState("");
  const [showoutrossetoresemcp,setshowoutrossetoresemcp ] = useState("");
  const [showsetorescomimoveisemcp,setshowsetorescomimoveisemcp ] = useState("");
  const [showvendasemcp,setshowvendasemcp ] = useState("");

  const [showcomerciosaureopet1,setshowcomerciosaureopet1 ] = useState("");
  const [showdomiciliosaureopet1,setshowdomiciliosaureopet1 ] = useState("");
  const [showempreendimentoaureopet1,setshowempreendimentoaureopet1 ] = useState("");
  const [showlimiteaureopet1,setshowlimiteaureopet1 ] = useState("");
  const [showlocalizacaoaureopet1,setshowlocalizacaoaureopet1 ] = useState("");
  const [showmarcasvareaureopet1,setshowmarcasvareaureopet1 ] = useState("");
  const [showr1kmaureopet1,setshowr1kmaureopet1 ] = useState("");
  const [showr2kmaureopet1,setshowr2kmaureopet1 ] = useState("");
  const [showrendaaureopet1,setshowrendaaureopet1 ] = useState("");
  const [showviasacessoaureopet1,setshowviasacessoaureopet1 ] = useState("");
  const [showcomerciosaureopet2,setshowcomerciosaureopet2 ] = useState("");
  const [showdomiciliosaureopet2,setshowdomiciliosaureopet2 ] = useState("");
  const [showempreendimentoaureopet2,setshowempreendimentoaureopet2 ] = useState("");
  const [showlocalizacaoaureopet2,setshowlocalizacaoaureopet2 ] = useState("");
  const [showr1kmaureopet2,setshowr1kmaureopet2 ] = useState("");
  const [showr2kmaureopet2,setshowr2kmaureopet2 ] = useState("");
  const [showrendaaureopet2,setshowrendaaureopet2 ] = useState("");
  const [showviasacessoaureopet2,setshowviasacessoaureopet2 ] = useState("");
  const [showzoneamentoaureopet2,setshowzoneamentoaureopet2 ] = useState("");
  const [showhidrologiaaureopet1,setshowhidrologiaaureopet1 ] = useState("");
  const [showtopografiaaureopet1,setshowtopografiaaureopet1 ] = useState("");
  const [showhidrologiaaureopet2,setshowhidrologiaaureopet2 ] = useState("");
  const [showtopografiaaureopet2,setshowtopografiaaureopet2 ] = useState("");

  const [showtopografiaacc3,setshowtopografiaacc3 ] = useState("");
  const [showzoneamentoacc3,setshowzoneamentoacc3 ] = useState("");
  const [showareapousoacc3,setshowareapousoacc3 ] = useState("");
  const [showrotaaviaoacc3,setshowrotaaviaoacc3 ] = useState("");
  const [showareascontacc3,setshowareascontacc3 ] = useState("");
  const [showdutoviasacc3,setshowdutoviasacc3 ] = useState("");
  const [showestabelecimentosacc3,setshowestabelecimentosacc3 ] = useState("");
  const [showveiculosacc3,setshowveiculosacc3 ] = useState("");
  const [showfluxoveicacc3,setshowfluxoveicacc3 ] = useState("");
  const [showmovimentacaoacc3,setshowmovimentacaoacc3 ] = useState("");
  const [showrendaacc3,setshowrendaacc3 ] = useState("");
  const [showpopulacaoacc3,setshowpopulacaoacc3 ] = useState("");
  const [showdomiciliosacc3,setshowdomiciliosacc3 ] = useState("");
  const [showdomialuguelacc3,setshowdomialuguelacc3 ] = useState("");
  const [showareaurbaacc3,setshowareaurbaacc3 ] = useState("");

  const [showaeror2soroaureo, setshowaeror2soroaureo] = useState(false);
  const [showshoppingr2soroaureo, setshowshoppingr2soroaureo] = useState(false);
  const [showcondor2soroaureo, setshowcondor2soroaureo] = useState(false);
  const [showdomicilior3soroaureo, setshowdomicilior3soroaureo] = useState(false);
  const [showpopr3soroaureo, setshowpopr3soroaureo] = useState(false);
  const [showvarejor3soroaureo, setshowvarejor3soroaureo] = useState(false);
  const [showindustriar3soroaureo, setshowindustriar3soroaureo] = useState(false);
  const [showdomicilior4soroaureo, setshowdomicilior4soroaureo] = useState(false);
  const [showpopr4soroaureo, setshowpopr4soroaureo] = useState(false);
  const [showvarejor4soroaureo, setshowvarejor4soroaureo] = useState(false);
  const [showindustriar4soroaureo, setshowindustriar4soroaureo] = useState(false);
  const [showdomicilior5soroaureo, setshowdomicilior5soroaureo] = useState(false);
  const [showpopr5soroaureo, setshowpopr5soroaureo] = useState(false);
  const [showvarejor5soroaureo, setshowvarejor5soroaureo] = useState(false);
  const [showindustriar5soroaureo, setshowindustriar5soroaureo] = useState(false);
  const [showsud1petrop1aureo, setshowsud1petrop1aureo] = useState(false);
  const [showsud2petrop1aureo, setshowsud2petrop1aureo] = useState(false);
  
  const [showspacc3,setshowspacc3 ] = useState("");
  const [showguarulhosacc3,setshowguarulhosacc3 ] = useState("");
  const [showtrechoacc3,setshowtrechoacc3 ] = useState("");

  const [showsud1petrop2aureo,setshowsud1petrop2aureo] = useState("");
  const [showsud2petrop2aureo,setshowsud2petrop2aureo] = useState("");
  const [showareascontastacetesbtnd, setshowareascontastacetesbtnd] = useState("");
  const [showareascontastasgmptnd, setshowareascontastasgmptnd] = useState("");
  const [showlimitebairrosstatnd, setshowlimitebairrosstatnd] = useState("");
  const [showfichasstatnd, setshowfichasstatnd] = useState("");
  const [showzoneamentostaeixotamandtnd, setshowzoneamentostaeixotamandtnd] = useState("");
  
  const [showgalpoesjaguariaivaic, setshowgalpoesjaguariaivaic] = useState("");

  const [showzoneamentocuritibatrply, setshowzoneamentocuritibatrply] = useState("")
  const [showpermissaocuritibatrply, setshowpermissaocuritibatrply] = useState("")
  const [showterrenoscuritibatrply, setshowterrenoscuritibatrply] = useState("")
  const [showtopografiacuritibatrply, setshowtopografiacuritibatrply] = useState("")
  const [showlimitesambcuritibatrply, setshowlimitesambcuritibatrply] = useState("")
  const [showhidrografiacuritibatrply, setshowhidrografiacuritibatrply] = useState("")
  const [showservidaocuritibatrply, setshowservidaocuritibatrply] = useState("")
  const [showterrenospotenciascuritibatrply, setshowterrenospotenciascuritibatrply] = useState("")
  const [showcrescimentocuritibatrply, setshowcrescimentocuritibatrply] = useState("")
  const [showdadossocioscuritibatrply, setshowdadossocioscuritibatrply] = useState("")
  const [showpontosdeinteressecuritibatrply, setshowpontosdeinteressecuritibatrply] = useState("")
  

  const kmlDataSourceRef = useRef();

  useEffect(() => {
    getTLTerrains(municipio, showterrenoslivre);
  }, [municipio, showterrenoslivre]);

  async function getTLTerrains(municipio, showterrenoslivre) {
    if (estado && municipio.value) {
      if (showterrenoslivre) {
        const terrainsRef = collection(db, "terrains");
        const q = query(terrainsRef, where("cidade", "==", municipio.value));
        const querySnapshot = await getDocs(q);
        const terrainList = [];
        querySnapshot.forEach((doc) => {
          terrainList.push(doc.data());
        });
        setTerrainsTL(terrainList);
      } else {
        setTerrainsTL([]);
      }
    }
  }

  const handleEstadoChange = (value) => {
    setEstado(value);
  };

  const handleTLPopupChange = () => {
    setTLPopup(!tlpopup);
  };

  const handleMunicipioChange = (value) => {
    setshowterrenoslivre(false);

    setMunicipio(value);
    setShowKmlL(false);
    setShowKml(false);
    setShowTopografia(false);
    setShowHidrografia(false);
    setShowLimitesa(false);
    setShowPermissao(false);
    setShowDados(false);
    setShowFluxo(false);

    setShowTheJ(false);
    setShowCompetidores(false);
    setShowAreas(false);
    setShowCondominios(false);
    setShowInfluencia(false);
    setShowPontosFluxo(false);
    setShowImoveis(false);

    setShowC1(false);
    setShowC2(false);
    setShowC3(false);
    setShowC4(false);
    setShowC5(false);
    setShowC6(false);
    setShowC7(false);
    setShowC8(false);
    setShowC9(false);
    setShowC10(false);
    setShowC11(false);
    setShowC12(false);
    setShowC13(false);
    setShowC14(false);
    setShowC15(false);
    setShowC16(false);

    setshowImoveis200lk(false);
    setShowsetoreslk(false);
    setShowraio1000lk(false);
    setShowraio500lk(false);
    setShowviaslk(false);
    setShowcompetidoreslk(false);
    setShowhospitaislk(false);
    setShowpracaslk(false);
    setShowmercadoslk(false);
    setShowrestauranteslk(false);
    setShowcondominioslk(false);
    setShowfarmacialk(false);

    setShowareaverdegl(false);
    setShowmunicipiogl(false);
    setShowcondominiogooglegl(false);
    setShowshowcondominiograpgl(false);
    setShowrendagl(false);

    setShowservidao(false);
    setShowcrescimento(false);

    setshowareasinfluenciatjp(false);
    setshowareasindicadastjp(false);
    setshowcompdiretostjp(false);
    setshowcompindiretostjp(false);
    setshowcondominiostjp(false);
    setshowimoveistjp(false);
    setshowlimitetjp(false);
    setshowpontosfluxotjp(false);
    setshowescolastjp(false);

    setshowareasinfluenciatja(false);
    setshowareasindicadasinfantiltja(false);
    setshowareasindicadasfundamentaltja(false);
    setshowcompdiretosinfantiltja(false);
    setshowcompdiretosfundamentaltja(false);
    setshowcompindiretosinfantiltja(false);
    setshowcompindiretosfundamentaltja(false);
    setshowconcentracao0_5tja(false);
    setshowconcentracao6_10tja(false);
    setshowescolatja(false);
    setshowlimitetja(false);
    setshowpontosfluxotja(false);
    setshowrendatja(false);
    setshowrendaareainfantiltja(false);
    setshowrendaareafundatja(false);

    setshowashidsgu(false);
    setshowaspidsgu(false);
    setshowapidsgu(false);
    setshowbempresasgu(false);
    setshowcampusdagu(false);
    setshowcampusgu(false);
    setshowfzdarggu(false);
    setshowferroviasgu(false);
    setshowictsgu(false);
    setshowparquesgu(false);
    setshowriosgu(false);
    setshowviasgu(false);

    setshowareasindicadastjg(false);
    setshowareasindicadasfundamentaltjg(false);
    setshowcompdiretosinfantiltjg(false);
    setshowcompdiretosfundamentaltjg(false);
    setshowcompindiretosinfantiltjg(false);
    setshowcompindiretosfundamentaltjg(false);
    setshowconcentracao0_5tjg(false);
    setshowconcentracao6_10tjg(false);
    setshowfamiliastjg(false);
    setshowrendatjg(false);
    setshowimovelpretendidotjg(false);
    setshowlimitetjg(false);
    setshowpontosfluxotjg(false);
    setshowraioinfluenciatjg(false);

    setShowEdumap(false);

    setshowbairroscrca(false);
    setshowdadossociosfamiliascampinascrca(false);
    setshowdadossociosrendacampinascrca(false);
    setshowzoneamentocampinascrca(false);
    setshowlimitecampinascrca(false);
    setshowdadosporbairro(false);

    setshowsjbv(false);
    setshowsjbv1zoneamento(false);
    setshowsjbv2permissao(false);
    setshowsjbv3terrenos(false);
    setshowsjbv5topografia(false);
    setshowsjbv6limitesa(false);
    setshowsjbv7hidrografia(false);
    setshowsjbv8servidao(false);
    setshowsjbv9terrenosp(false);
    setshowsjbv10crescimento(false);
    setshowsjbv11dadossocios(false);
    setshowsjbv12distviario(false);
    setshowsjbv13malha(false);
    setshowsjbv14pontos(false);

    setshowrp(false);
    setshowrp1zoneamento(false);
    setshowrp3terrenos(false);
    setshowrp5topografia(false);
    setshowrp6limitesambientais(false);
    setshowrp7hidrografia(false);
    setshowrp8servidao(false);
    setshowrp10crescimento(false);
    setshowrp11dadossocioeconomicosrenda(false);
    setshowrp12dadossocioeconomicosfamilias(false);

    setshowabrangenciacrascrca2(false);
    setshowassistenciateccrca2(false);
    setshowbairroscrca2(false);
    setshowcampinasdadossociosrendacrca2(false);
    setshowcampinasdadossociosfamiliascrca2(false);
    setshowcampinaslimitecrca2(false);
    setshowcampinaszoneamentocrca2(false);
    setshowcomercioalimentoscrca2(false);
    setshowdadosporbairrocrca2(false);
    setshowimpressao3dcrca2(false);
    setshowlojaderoupascrca2(false);
    setshowlojasartesanatocrca2(false);
    setshowoficinasmecanicascrca2(false);
    setshoworgcivilcrca2(false);
    setshowpadariasconfeitariascrca2(false);
    setshowpetshopscrca2(false);
    setshowregioesvulcrca2(false);
    setshowsalaobelezacrc2(false);

    setshoweducomp(false);
    setshowedufamilia(false);
    setshowedurenda(false);
    setshoweduimoveis(false);
    setShowKmlT(false);

    setshowareasindicadasinfantiltjd(false);
    setshowareasindicadasfundamentaltjd(false);
    setshowcompdiretosinfantiltjd(false);
    setshowcompdiretosfundamentaltjd(false);
    setshowcompindiretosinfantiltjd(false);
    setshowcompindiretosfundamentaltjd(false);
    setshowconcentracaocriancasinfantiltgd(false);
    setshowconcentracaocriancasfundtgd(false);
    setshowfamiliastjd(false);
    setshowlimitetjd(false);
    setshowrendatjd(false);
    setshowpontosfluxotjd(false);
    setshowraioinfluenciatjd(false);

    setshowlegalizacaolavras(false);

    setshowacademiasmanapoke(false);
    setshowcentrosempresariasmanapoke(false);
    setshowcentrosesportivosmanapoke(false);
    setshowcomerciomanapoke(false);
    setshowcompcomidajaponesamanapoke(false);
    setshowcompcomidasaudavelmanapoke(false);
    setshowimoveiscomerciaismanapoke(false);
    setshowpontosculturaismanapoke(false);
    setshowpontoseducacaomanapoke(false);
    setshowrendamanapoke(false);
    setshowareaspotenciaismanapoke(false);

    setshowzoneamentolavras(false);
    setshowpermissaolavras(false);
    setshowterrenoslavras(false);
    setshowtopografialavras(false);
    setshowapplavras(false);
    setshowhidrografialavras(false);
    setshowservidaolavras(false);
    setshowterrenosinteresselavras(false);
    setshowcrescimentolavras(false);
    setshowdadossocioslavras(false);
    showsetdistviariolavras(false);
    setshowdisturbanalavras(false);
    setshowpontoslavras(false);

    //buddahspa
    setshowclubesbs(false);
    setshowfamiliasbs(false);
    setshowlojasdepartamentobs(false);
    setshowparquesbs(false);
    setshowrendabs(false);
    setshowpontosbuddhaspabs(false);
    setshowrestaurantesbs(false);
    setshowshoppingsbs(false);
    setshowsupermercadosbs(false);
    setshowestacionamentosbs(false);
    setshowimoveismenos18bs(false);
    setshowimoveismais18bs(false);

    setshowareainflucompbs(false);
    setshowareapotcomrecorte(false);
    setshowareapotsemrecorte(false);
    setshowcompetidoresbs(false);
    setshowpopadultabs(false);

    setshowareaspotbd(false);
    setshowbaldonibd(false);
    setshowbaldoni2030bd(false);
    setshowlojaspescabd(false);
    setshowpesquesbd(false);
    setshowpetshopsbd(false);
    setshowpopadultabd(false);
    setshowrendabd(false);
    setshowrestaurantesbd(false);
    setshowvetsbd(false);

    setshowacademiascb(false);
    setshowareaspotcb(false);
    setshowcafescb(false);
    setshowcomerciocb(false);
    setshowculturalcb(false);
    setshowescolascb(false);
    setshowesportescb(false);
    setshowfaculdadescb(false);
    setshowimoveiscb(false);
    setshowlanchonetescb(false);
    setshowrendasetorescb(false);
    setshowcomidasaudavelcb(false);

    setshowzonasusobs(false);

    setshowRio_Verde_1_zoneamento(false);
    setshowRio_Verde_2_permissao(false);
    setshowRio_Verde_3_terrenos(false);
    setshowRio_Verde_4_imoveis_disponiveis(false);
    setshowRio_Verde_5_topografia(false);
    setshowRio_Verde_6_Limites_Ambientais(false);
    setshowRio_Verde_7_Hidrografia(false);
    setshowRio_Verde_8_Servidao(false);
    setshowRio_Verde_9_terrenos_potenciais(false);
    setshowRio_Verde_10_crescimento(false);
    setshowRio_Verde_11_dados_socioeconomicos(false);
    setshowRio_verde_12_dist_viario(false);
    setshowRio_Verde_13_Malha_Urbana(false);
    setshowRio_Verde_14_Pontos_de_Interesse(false);
    setshowRio_Verde(false);

    setshowCruzeiro_1_Zoneamento(false);
    setshowGuaxupe_1_zoneamento(false);
    setshowLavras_zoneamento_TESTE(false);
    setshowSSParaiso_1_Zoneamento(false);

    setshowangradosreisacc(false);
    setshowbr101km414acc(false);
    setshowbr101km447acc(false);
    setshowbr101km538acc(false);
    setshowitaguaiacc(false);
    setshowmangaratibaacc(false);
    setshowparatyacc(false);
    setshowtrechobr101acc(false);
    setshowrendaacc(false);
    setshowareasconmataatlaacc(false);
    setshowappacc(false);
    setshowvegetacaonativaacc(false);
    setshowuniconsfederaisacc(false);
    setshowreservalegalacc(false);
    setshowareasprotacc(false);

    setshowacaiteriacb(false);
    setshowsorveteriacb(false);

    setshowmoteisac(false);
    setshowpedagiosac(false);
    setshowpostosac(false);
    setshowrestaurantesac(false);
    setshowhoteisac(false);
    setshowpousadasac(false);

    setshowareaspotpucrsg(false);
    setshowareaspotunicampport2rsg(false);
    setshowareaspotunicampprincrsg(false);
    setshowportariasrsg(false);
    setshowraio200rsg(false);
    setshowterrenosdisprsg(false);

    setshowperimetrourbanocb(false);
    setshowfluxokernelcb(false);

    setshowconcentrafluxocb(false);
    setshowraiofluxocb(false);
    setshowfluxoconcocb(false);

    setshowzoneamentopec(false);
    setshowpontosintpec(false);
    setshowpermissaopec(false);
    setshowtopografiapec(false);
    setshowcrescimentoanopec(false);
    setshowgraprohab(false);
    setshowdensidadepec(false);
    setshowfamiliaspec(false);
    setshowrendapec(false);
    setshowcorpospec(false);
    setshowcursospec(false);
    setshownascentespec(false);
    setshowapppec(false);
    setshowreservapec(false);
    setshowvegpec(false);
    setshowterrenos2k4kpec(false);
    setshowterrenos5kmaispec(false);
    setshowterrenosnaopec(false);
    setshowterrenospot5kmaispec(false);
    setshowterrenospot150kmaispec(false);
    setshowmapaconcentracaopec(false);

    setshowlimitevmap(false);
    setshowpopfemvmap(false);
    setshowpopmasvmap(false);
    setshowpoptotvmap(false);
    setshowprefeitovmap(false);
    setshowprefeitovmap2(false);
    setshowvereadorvmap(false);
    setshowvereadorvmap2(false);
    setshowrendavmap(false);

    setshowdemandaespacopu(false);
    setshowdemandaqualidadepu(false);
    setshowfaixafaturamentopu(false);
    setshowleispu(false);
    setshowmapageralpu(false);
    setshowmodelopu(false);
    setshowpreferenpu(false);
    setshownecessidadepu(false);
    setshowrelacaopu(false);
    setshowsetorpu(false);

    setshowdivisaoregpec(false);
    setshowvaziospec(false);
    setshowusosolopec(false);
    setshowconcenfluxospec(false);
    setshowtopografiapec2(false);

    setshowfaixaac(false);

    setshowareaintbb(false);
    setshowboibrabobb(false);
    setshowclassesbb(false);
    setshowcompsbb(false);
    setshowconccompsbb(false);
    setshowconcpfbb(false);
    setshowfamiliasbb(false);
    setshowfluxocompsbb(false);
    setshowimoveisbb(false);
    setshowpontosfluxobb(false);
    setshowrendabb(false);
    setshowmetrobb(false);
    setshowonibusbb(false);
    setshowtrembb(false);
    setshowareapotbb(false);

    setshowareapotdp(false);
    setshowcompsdp(false);
    setshowconccompsdp(false);
    setshowconcpontofluxodp(false);
    setshowfamiliasdp(false);
    setshowgastodp(false);
    setshowpontofluxodp(false);
    setshowpop20a69dp(false);
    setshowrendadp(false);

    setshowarmazensacc2(false);
    setshowbalsasacc2(false);
    setshowconcarmazensacc2(false);
    setshowformosoacc2(false);
    setshowlagoaacc2(false);
    setshowmiranorteacc2(false);
    setshowportoacc2(false);
    setshowsilvanopolisacc2(false);
    setshowtassoacc2(false);
    setshowusosoloacc2(false);
    setshowhidroviasgeralacc2(false);
    setshowviasgeralacc2(false);
    setshowportsaoluisacc2(false);
    setshowportnacionalacc2(false);
    setshowintpalemeiranteacc2(false);
    setshowrodoviasacc2(false);
    setshowinstalacoesportacc2(false);
    setshowhidroviasofcacc2(false);
    setshowferroviasacc2(false);
    setshowusosolomiranorteacc2(false);
    setshowusosolosilvanopolisacc2(false);
    setshowusosololagoaacc2(false);
    setshowusosoloformosoacc2(false);
    setshowusosoloportoacc2(false);
    setshowusosolobalsasacc2(false);
    setshowusosolotassoacc2(false);

    setshowlmsaltosc(false);
    setshowcompsaltosc(false);
    setshowr1saltosc(false);
    setshowr2saltosc(false);
    setshowpfsaltosc(false);
    setshowscsaltosc(false);
    setshowlmmarasc(false);
    setshowcompmarasc(false);
    setshowbuffermarasc(false);
    setshowpfmarasc(false);
    setshowscmarasc(false);
    setshowlmjacaresc(false);
    setshowcompjacaresc(false);
    setshowr1jacaresc(false);
    setshowpfjacaresc(false);
    setshowscjacaresc(false);

    setshowterrenospec(false);
    setshowterrenospecpau(false);

    setshowamoarmazensacc2(false);
    setshowprestaacc2(false);
    setshowhabacc2(false);
    setshowcapacc2(false);
    setshowtipoacc2(false);
    setshowporteacc2(false);
    setshowformacc2(false);
    setshowdistferroacc2(false);
    setshowdistermacc2(false);
    setshowestosoacc2(false);
    setshowprecosoacc2(false);
    setshowpopacc2(false);
    setshowrendaacc2(false);
    setshowaeroacc2(false);
    setshowdeclacc2(false);

    setshowdadossociosaltossc(false);
    setshowfluxo1marajasc(false);
    setshowkernelfluxo1marajasc(false);
    setshowfamiliasmarajassc(false);
    setshowrendamarajassc(false);
    setshowdadossociojacaresc(false);

    setshowcrescimentobrz(false);
    setshowdomiciliosaluguelbrz(false);
    setshowdomicioliosbrz(false);
    setshowempreendimentosbrz(false);
    setshowlavrasbrz(false);
    setshowpopbrz(false);
    setshowraio15brz(false);
    setshowrendabrz(false);
    setshowvetorbrz(false);

    setshowprop1toacc2(false);
    setshowprop2toacc2(false);
    setshowprop1maacc2(false);
    setshowprop2maacc2(false);

    setshowpontointbrz(false);

    setshowcrescimento1brz(false);
    setshowdeficitbrz(false);
    setshowtaxahabbrz(false);

    setshowgtdcamacircu(false);
    setshowdistcamacircu(false);
    setshowarearedcamacircu(false);
    setshowareaspotcamacircu(false);
    setshowassocamacircu(false);
    setshowcamacircu(false);
    setshowempscamacircu(false);
    setshowconcempcamacircu(false);
    setshowareaspotvargcircu(false);
    setshowassovargcircu(false);
    setshowvargcircu(false);
    setshowempsvargcircu(false);
    setshowgtdcampcircu(false);
    setshowdistcampcircu(false);
    setshowdistecocampcircu(false);
    setshowarearedcampcircu(false);
    setshowareaspotcampcircu(false);
    setshowassocampcircu(false);
    setshowcampcircu(false);
    setshowempscampcircu(false);
    setshowdensempscampcircu(false);
    setshowecoscampcircu(false);
    setshowgtdjundcircu(false);
    setshowdistjundcircu(false);
    setshowdistecojundcircu(false);
    setshowarearedjundcircu(false);
    setshowareaspotjundcircu(false);
    setshowassojundcircu(false);
    setshowjundcircu(false);
    setshowempsjundcircu(false);
    setshowdensempsjundcircu(false);
    setshowecosjundcircu(false);
    setshowgtdsorocircu(false);
    setshowdistsorocircu(false);
    setshowdistecosorocircu(false);
    setshowarearedsorocircu(false);
    setshowareaspotsorocircu(false);
    setshowassosorocircu(false);
    setshowsorocircu(false);
    setshowempssorocircu(false);
    setshowdensempssorocircu(false);
    setshowecossorocircu(false);

    setshowareapotafasdp(false);
    setshowimoveisdp(false);

    setshowjundiaimrv(false);
    setshowappjundiaimrv(false);
    setshowvegjundiaimrv(false);
    setshowresjundiaimrv(false);
    setshowareaprojundiaimrv(false);
    setshownascjundiaimrv(false);
    setshowcursjundiaimrv(false);
    setshowcorposjundiaimrv(false);
    setshowtopojundiaimrv(false);
    setshowurbjundiaimrv(false);
    setshowareapoujundiaimrv(false);
    setshowservjundiaimrv(false);
    setshowusorestjundiaimrv(false);
    setshowareaconsjundiaimrv(false);
    setshowareacontjundiaimrv(false);
    setshowdutojundiaimrv(false);
    setshowlinhaojundiaimrv(false);
    setshowzoneajundiaimrv(false);
    setshowlegavertjundiaimrv(false);
    setshowlegahorjundiaimrv(false);
    setshowrendapopjundiaimrv(false);
    setshowrendajundiaimrv(false);
    setshowpopjundiaimrv(false);
    setshowdomicjundiaimrv(false);
    setshowdomicalujundiaimrv(false);
    setshowexpurbjundiaimrv(false);
    setshowtaxahabjundiaimrv(false);
    setshowgraprohabjundiaimrv(false);
    setshowareaurbjundiaimrv(false);
    setshowarearujundiaimrv(false);
    setshowvaziosjundiaimrv(false);
    setshowconsumoaguajundiaimrv(false);
    setshowdispaguajundiaimrv(false);
    setshowcustojundiaimrv(false);
    setshowterrenosjundiaimrv(false);
    setshowpontoajundiaimrv(false);
    setshowpontodjundiaimrv(false);
    setshowlimeiramrv(false);
    setshowapplimeiramrv(false);
    setshowveglimeiramrv(false);
    setshowreslimeiramrv(false);
    setshowareaprolimeiramrv(false);
    setshownasclimeiramrv(false);
    setshowcurslimeiramrv(false);
    setshowcorposlimeiramrv(false);
    setshowtopolimeiramrv(false);
    setshowurblimeiramrv(false);
    setshowareapoulimeiramrv(false);
    setshowservlimeiramrv(false);
    setshowusorestlimeiramrv(false);
    setshowareaconslimeiramrv(false);
    setshowareacontlimeiramrv(false);
    setshowdutolimeiramrv(false);
    setshowlinhaolimeiramrv(false);
    setshowzonealimeiramrv(false);
    setshowlegavertlimeiramrv(false);
    setshowlegahorlimeiramrv(false);
    setshowrendapoplimeiramrv(false);
    setshowrendalimeiramrv(false);
    setshowpoplimeiramrv(false);
    setshowdomiclimeiramrv(false);
    setshowdomicalulimeiramrv(false);
    setshowexpurblimeiramrv(false);
    setshowtaxahablimeiramrv(false);
    setshowgraprohablimeiramrv(false);
    setshowareaurblimeiramrv(false);
    setshowarearulimeiramrv(false);
    setshowvazioslimeiramrv(false);
    setshowconsumoagualimeiramrv(false);
    setshowdispagualimeiramrv(false);
    setshowcustolimeiramrv(false);
    setshowterrenoslimeiramrv(false);
    setshowpontoalimeiramrv(false);
    setshowpontodlimeiramrv(false);

    setshowveloprecojundiaimrv(false);
    setshowveloprecolimeiramrv(false);
    setshowbdgu(false);

    setshowboticampcircu(false);
    setshowbotijundcircu(false);
    setshowbotisorocircu(false);
    setshowbotigrandespcircu(false);
    setshowgrandespcircu(false);

    setshowimoveisc1ic(false);
    setshowraioc1ic(false);
    setshowareaestudoc2ic(false);
    setshowrendac2ic(false);
    setshowrecortec2ic(false);
    setshowpontosfluxc2ic(false);
    setshowconcfluxoc2ic(false);
    setshowoticasc2ic(false);
    setshowconcfluxooticasc2ic(false);
    setshowimoveisc2ic(false);
    setshowshoppingsc2ic(false);

    setshowkernelsaltosc(false);
    setshowkerneljacaresc(false);
    setshowponibusmarasc(false);
    setshowlonibusmarasc(false);

    setshowredesdrenalimeiramrv(false);
    setshowrecargahidricalimeiramrv(false);
    setshowferroviaslimeiramrv(false);
    setshowviaslimeiramrv(false);
    setshowviasligalimeiramrv(false);
    setshowrankingterlimeiramrv(false);
    setshowredesdrenajundiaimrv(false);
    setshowrecargahidricajundiaimrv(false);
    setshowviasjundiaimrv(false);
    setshowviasligajundiaimrv(false);
    setshowferroviasjundiaimrv(false);
    setshowequipamentosjundiaimrv(false);
    setshowrankingterjundiaimrv(false);
    setshowpoluicaosonorajundiaimrv(false);

    setshowacadmelten(false);
    setshowareasafastmelten(false);
    setshowareasproxmelten(false);
    setshowcomerciosmelten(false);
    setshowconcconcmelten(false);
    setshowconcmelten(false);
    setshowcrescurbanomelten(false);
    setshowdomiciliosmelten(false);
    setshowequipublimelten(false);
    setshowescolasprivadasmelten(false);
    setshowhidroviasmelten(false);
    setshowhospitaismelten(false);
    setshowimoveismelten(false);
    setshowinfraviariamelten(false);
    setshowmelten(false);
    setshowpalmasmelten(false);
    setshowpopmelten(false);
    setshowrendamelten(false);
    setshowrestaurantesmelten(false);
    setshowtransportemelten(false);
    setshowviasmelten(false);

    setshowgtdipocircu(false);
    setshowarearedipocircu(false);
    setshowareaspotipocircu(false);
    setshowcoopreciipocircu(false);
    setshowipocircu(false);
    setshowempsipocircu(false);
    setshowdensempsipocircu(false);
    setshowecosipocircu(false);

    setshowcorrecondcamacircu(false);
    setshowcorrecondvargcircu(false);
    setshowcorrecondcampcircu(false);
    setshowcorrecondjundcircu(false);
    setshowcorrecondsorocircu(false);

    setshowregionalizacaomelten(false);

    setshowcrescpecpau(false);
    setshowdadossociospecpau(false);
    setshowhidropecpau(false);
    setshowlimitesambpecpau(false);
    setshowpontosfluxopecpau(false);
    setshowraios500pecpau(false);
    setshowservidaopecpau(false);
    setshowterrenospotpecpau(false);
    setshowterrenosranqpecpau(false);
    setshowtopopecpau(false);
    setshowvaziospecpau(false);

    setshowplayersagrovh(false);
    setshowcampinasvh(false);
    setshowparquesteccampvh(false);

    setshowdemandagu(false);
    setshowativpesquisagu(false);
    setshowinteressehubgu(false);
    setshowmodelotrabalhogu(false);
    setshowcapitalpropriogu(false);
    setshowfundestaduaisgu(false);
    setshowsebraegu(false);
    setshowbndesgu(false);
    setshowinvestidoranjogu(false);
    setshowfundosinvestgu(false);
    setshowaceleradorasgu(false);
    setshowsemrespostagu(false);

    setshowictsvh(false);
    setshowictsagrovh(false);

    setshowareaspreservavespemcp(false);
    setshowvegnativavespemcp(false);
    setshowreservalegalvespemcp(false);
    setshownascentesvespemcp(false);
    setshowcursosaguavespemcp(false);
    setshowtopografiavespemcp(false);
    setshowlimitevespemcp(false);
    setshowzoneamentovespemcp(false);
    setshowusovertvespemcp(false);
    setshowpermissaovespemcp(false);
    setshowlocterrenovespemcp(false);
    setshowservidaovespemcp(false);
    setshowareaconsovespemcp(false);
    setshowareascontavespemcp(false);
    setshowdutoviasvespemcp(false);
    setshowlinhaovespemcp(false);
    setshownovoscondvespemcp(false);
    setshowrendavespemcp(false);
    setshowpopvespemcp(false);
    setshowdomicivespemcp(false);
    setshowdomicialuvespemcp(false);
    setshowexpurbanavespemcp(false);
    setshowveloeprecovespemcp(false);
    setshowtaxahabvespemcp(false);
    setshowconcorrenciavespemcp(false);
    setshowareaurbavespemcp(false);
    setshowareasruraisvespemcp(false);
    setshowconsuaguavespemcp(false);
    setshowdispaguavespemcp(false);
    setshowcustoprojetovespemcp(false);
    setshowterrenosanunvespemcp(false);
    setshowimoveisanunvespemcp(false);
    setshowpontosatravespemcp(false);
    setshowpontosdetravespemcp(false);

    setshowtjchpdtjd(false);
    setshowcompdiretoschpdtjd(false);
    setshowcriancas05chpdtjd(false);
    setshowescolasidiomaschpdtjd(false);
    setshowescolasinfantischpdtjd(false);
    setshowescolaschpdtjd(false);
    setshowimovelindicadochpdtjd(false);
    setshowpontosfluxochpdtjd(false);
    setshowprincipaisviaschpdtjd(false);
    setshowraio1kmcompchpdtjd(false);
    setshowraio2kmcompchpdtjd(false);
    setshowrendafamiliarchpdtjd(false);

    setshowareainflurctjd(false);
    setshowareasindirctjd(false);
    setshowcompetidoresrctjd(false);
    setshowcondominiosrctjd(false);
    setshowimoveisrctjd(false);
    setshowpontosfluxirctjd(false);
    setshowcompdiretosvptjd(false);
    setshowimoveissapopembavptjd(false);
    setshowimoveisvpvptjd(false);
    setshowpontosfluxovptjd(false);
    setshowrendafamiliarvptjd(false);
    setshowtjschoolvptjd(false);

    setshowappwbgi(false);
    setshowareacontwbgi(false);
    setshowcurvasnivelwbgi(false);
    setshowdeclividadewbgi(false);
    setshowdistcorposaguawbgi(false);
    setshowdistcorposhidricoswbgi(false);
    setshowhidrografiawbgi(false);
    setshowlimeirawbgi(false);
    setshowreservalegalwbgi(false);
    setshowvegnativawbgi(false);
    setshowviveiristaswbgi(false);

    setshowlimiteosascotnd(false)
setshowareapreserosascotnd(false)
setshowvegnaivaosascotnd(false)
setshowreservaosascotnd(false)
setshownascentesosascotnd(false)
setshowcursososascotnd(false)
setshowcorpososascotnd(false)
setshowtopografiaosascotnd(false)
setshowzoneamentoosascotnd(false)
setshowusovertosascotnd(false)
setshowimplantacaohishmposascotnd(false)
setshowservadmosascotnd(false)
setshowareaconsoosascotnd(false)
setshowareascontaosascotnd(false)
setshowdutoviasosascotnd(false)
setshowlinhaoosascotnd(false)
setshowpontosatratosascotnd(false)
setshowpontosdetratosascotnd(false)
setshowrendaosascotnd(false)
setshowdomiciliososascotnd(false)
setshowdomiciliosaluguelosascotnd(false)
setshowexpcidadesosascotnd(false)
setshowprecomedioosascotnd(false)
setshowtaxahabosascotnd(false)
setshowconcoosascotnd(false)
setshowareaurbanizadaosascotnd(false)
setshowareasruraisosascotnd(false)
setshowvaziosurbanososascotnd(false)
setshowgrapohabosascotnd(false)
setshowterrenososascotnd(false)
setshowimoveisosascotnd(false)
setshowtendenciassetorosascotnd(false)
setshowsinaismudancaosascotnd(false)
setshowoportunidadeosascotnd(false)
setshowareausorestosascotnd(false)

setshowareapotlimeiramrv(false)

setshowareapreserslgb(false)
setshowvegnativaslgb(false)
setshowreservaslgb(false)
setshownascentesslgb(false)
setshowhidrografiaslgb(false)
setshowtopografiaslgb(false)
setshowzoneamentoslgb(false)
setshowusovertslgb(false)
setshowusohorslgb(false)
setshowviasslgb(false)
setshowdutoviasslgb(false)
setshowlinhaoslgb(false)
setshowpontosatratosslgb(false)
setshowpontosdetratslgb(false)
setshowrendaslgb(false)
setshowdomiciliosslgb(false)
setshowconcoslgb(false)
setshowareasruraisslgb(false)
setshowterrenosslgb(false)
setshowimoveisslgb(false)

setshowraiopesquisaslgb(false)
setshowterrenosanaslgb(false)

setshowlimitestatnd(false)
setshowareapreserstatnd(false)
setshowvegnaivastatnd(false)
setshowreservastatnd(false)
setshownascentesstatnd(false)
setshowhidrocarstatnd(false)
setshowhidrocompstatnd(false)
setshowtopografiastatnd(false)
setshowzoneamentostatnd(false)
setshowusovertstatnd(false)
setshowimplantacaohishmpstatnd(false)
setshowservadmstatnd(false)
setshowareausoreststatnd(false)
setshowareaconsostatnd(false)
setshowareascontastatnd(false)
setshowdutoviasstatnd(false)
setshowlinhaostatnd(false)
setshowrededrenagemstatnd(false)
setshowpontosatratstatnd(false)
setshowpontosdetratstatnd(false)
setshowdomiciliosstatnd(false)
setshowrendastatnd(false)
setshowdomiciliosaluguelstatnd(false)
setshowexpcidadesstatnd(false)
setshowexpverticalstatnd(false)
setshowprecomediostatnd(false)
setshowtaxahabstatnd(false)
setshowareaurbanizadastatnd(false)
setshowareasruraisstatnd(false)
setshowvaziosurbanosstatnd(false)
setshowgrapohabstatnd(false)
setshowterrenosstatnd(false)
setshowimoveisstatnd(false)

setshowlimiteriva(false)
setshowrecorteriva(false)
setshowareapreserriva(false)
setshowconservacaoriva(false)
setshowcursosriva(false)
setshownascentesriva(false)
setshowareasriscoriva(false)
setshowareasriscocontriva(false)
setshowtopografiariva(false)
setshowzoneamentoriva(false)
setshowusovertriva(false)
setshowaeroportosriva(false)
setshowdutoviasriva(false)
setshowlinhaoriva(false)
setshowprotculturalriva(false)
setshowbemcultriva(false)
setshowbemcultimovelriva(false)
setshowbemcultnaturalriva(false)
setshowdiretrizriva(false)
setshowtombamentoriva(false)
setshowpontosatratriva(false)
setshowpontosdetratriva(false)
setshowocupacaoriva(false)
setshowrendariva(false)
setshowpmedioriva(false)
setshowtaxahabriva(false)
setshowconcoriva(false)
setshowvaziosriva(false)
setshowimoveisanunriva(false)

setshowlimitemacapaic(false)
setshowrecortemacapaic(false)
setshowvarejomacapaic(false)
setshowconcvarejomacapaic(false)
setshowconcfluxomacapaic(false)
setshowshoppingsmacapaic(false)
setshowescolasmacapaic(false)
setshowhospitaismacapaic(false)
setshowestacionamentosmacapaic(false)
setshowconcorrentesmacapaic(false)
setshowuniversidadesmacapaic(false)
setshowassaimacapaic(false)
setshowrendamacapaic(false)
setshowdomiciliosmacapaic(false)
setshowpopmacapaic(false)
setshowimoveismacapaic(false)

setshowcatimoveisriva(false)

setshowdomiciliosaureo(false)
setshowempresasaureo(false)
setshowlocinteresseaureo(false)
setshowmarcasaureo(false)
setshowpontosaureo(false)
setshowraio1kmaureo(false)
setshowraio2kmaureo(false)
setshowrendaaureo(false)
setshowviasaureo(false)
setshowzoneamentoaureo(false)

setshowpiscininha(false)
setshowpiscinao(false)

setshowimoveisvendidosemcp(false)
setshowoutrossetoresemcp(false)
setshowsetorescomimoveisemcp(false)
setshowvendasemcp(false)

setshowcomerciosaureopet1(false)
setshowdomiciliosaureopet1(false)
setshowempreendimentoaureopet1(false)
setshowlimiteaureopet1(false)
setshowlocalizacaoaureopet1(false)
setshowmarcasvareaureopet1(false)
setshowr1kmaureopet1(false)
setshowr2kmaureopet1(false)
setshowrendaaureopet1(false)
setshowviasacessoaureopet1(false)
setshowcomerciosaureopet2(false)
setshowdomiciliosaureopet2(false)
setshowempreendimentoaureopet2(false)
setshowlocalizacaoaureopet2(false)
setshowr1kmaureopet2(false)
setshowr2kmaureopet2(false)
setshowrendaaureopet2(false)
setshowviasacessoaureopet2(false)
setshowzoneamentoaureopet2(false)  
setshowhidrologiaaureopet1(false)
setshowtopografiaaureopet1(false)
setshowhidrologiaaureopet2(false)
setshowtopografiaaureopet2(false)

setshowtopografiaacc3(false)
setshowzoneamentoacc3(false)
setshowareapousoacc3(false)
setshowrotaaviaoacc3(false)
setshowareascontacc3(false)
setshowdutoviasacc3(false)
setshowestabelecimentosacc3(false)
setshowveiculosacc3(false)
setshowfluxoveicacc3(false)
setshowmovimentacaoacc3(false)
setshowrendaacc3(false)
setshowpopulacaoacc3(false)
setshowdomiciliosacc3(false)
setshowdomialuguelacc3(false)
setshowareaurbaacc3(false)

setshowspacc3(false)
setshowguarulhosacc3(false)
setshowtrechoacc3(false)

setshowaeror2soroaureo(false)
setshowshoppingr2soroaureo(false)
setshowcondor2soroaureo(false)
setshowdomicilior3soroaureo(false)
setshowpopr3soroaureo(false)
setshowvarejor3soroaureo(false)
setshowindustriar3soroaureo(false)
setshowdomicilior4soroaureo(false)
setshowpopr4soroaureo(false)
setshowvarejor4soroaureo(false)
setshowindustriar4soroaureo(false)
setshowdomicilior5soroaureo(false)
setshowpopr5soroaureo(false)
setshowvarejor5soroaureo(false)
setshowindustriar5soroaureo(false)
setshowsud1petrop1aureo(false)
setshowsud2petrop1aureo(false)
setshowsud1petrop2aureo(false)
setshowsud2petrop2aureo(false)

setshowareascontastacetesbtnd(false)
setshowareascontastasgmptnd(false)
setshowlimitebairrosstatnd(false)
setshowfichasstatnd(false)
setshowzoneamentostaeixotamandtnd(false)
setshowgalpoesjaguariaivaic(false)

setshowzoneamentocuritibatrply(false)
setshowpermissaocuritibatrply(false)
setshowterrenoscuritibatrply(false)
setshowtopografiacuritibatrply(false)
setshowlimitesambcuritibatrply(false)
setshowhidrografiacuritibatrply(false)
setshowservidaocuritibatrply(false)
setshowterrenospotenciascuritibatrply(false)
setshowcrescimentocuritibatrply(false)
setshowdadossocioscuritibatrply(false)
setshowpontosdeinteressecuritibatrply(false)
};

  const handleImprimir = (value) => {
    const viewer = viewerRef.current.cesiumElement;
    const scene = viewer.scene;
    const screenshot = function (scene) {
      const canvas = scene.canvas;
      const fileName = "areainsight-print";
      const link = document.createElement("a");
      link.download = fileName + ".png";
      canvas.toBlob(function (blob) {
        link.href = URL.createObjectURL(blob);
        link.click();
      });
      link.remove();
      scene.postRender.removeEventListener(screenshot);
    };
    scene.postRender.addEventListener(screenshot);
  };

  const handleCamadasChange = (value) => {
    loadingViewRef.current = true;
    const viewer = viewerRef.current.cesiumElement;

    setshowterrenoslivre(value.terrenoslivre);

    setCamadas(value);
    setShowKmlL(value.limite);
    setShowKml(value.zoneamento);
    setShowTopografia(value.topografia);
    setShowHidrografia(value.hidrografia);
    setShowLimitesa(value.limitesa);
    setShowPermissao(value.permissao);
    setShowDados(value.dados);
    setShowFluxo(value.fluxo);
    setShowTheJ(value.thej);

    setShowCompetidores(value.competidorestj);
    setShowAreas(value.areastj);
    setShowCondominios(value.condominiostj);
    setShowInfluencia(value.influenciatj);
    setShowPontosFluxo(value.pontosfluxotj);
    setShowImoveis(value.imoveis);

    setShowC1(value.c1);
    setShowC2(value.c2);
    setShowC3(value.c3);
    setShowC4(value.c4);
    setShowC5(value.c5);
    setShowC6(value.c6);
    setShowC7(value.c7);
    setShowC8(value.c8);
    setShowC9(value.c9);
    setShowC10(value.c10);
    setShowC11(value.c11);
    setShowC12(value.c12);
    setShowC13(value.c13);
    setShowC14(value.c14);
    setShowC15(value.c15);
    setShowC16(value.c16);

    setshowImoveis200lk(value.imoveis200);
    setShowsetoreslk(value.setoreslk);
    setShowraio1000lk(value.raio1000lk);
    setShowraio500lk(value.raio500lk);
    setShowviaslk(value.viaslk);
    setShowcompetidoreslk(value.competidoreslk);
    setShowhospitaislk(value.hospitaislk);
    setShowpracaslk(value.pracaslk);
    setShowmercadoslk(value.mercadoslk);
    setShowrestauranteslk(value.restauranteslk);
    setShowcondominioslk(value.condominioslk);
    setShowfarmacialk(value.farmacialk);

    setShowareaverdegl(value.areaverdegl);
    setShowmunicipiogl(value.municipiogl);
    setShowcondominiogooglegl(value.condominiogooglegl);
    setShowshowcondominiograpgl(value.condominiograpgl);
    setShowrendagl(value.rendagl);

    setShowservidao(value.servidao);
    setShowcrescimento(value.crescimento);

    setshowareasinfluenciatjp(value.areasinfluenciatjp);
    setshowareasindicadastjp(value.areasindicadastjp);
    setshowcompdiretostjp(value.compdiretostjp);
    setshowcompindiretostjp(value.compindiretostjp);
    setshowcondominiostjp(value.condominiostjp);
    setshowimoveistjp(value.imoveistjp);
    setshowlimitetjp(value.limitetjp);
    setshowpontosfluxotjp(value.pontosfluxotjp);
    setshowescolastjp(value.escolastjp);

    setshowareasinfluenciatja(value.areasinfluenciatja);
    setshowareasindicadasinfantiltja(value.areasindicadasinfantiltja);
    setshowareasindicadasfundamentaltja(value.areasindicadasfundamentaltja);
    setshowcompdiretosinfantiltja(value.compdiretosinfantiltja);
    setshowcompdiretosfundamentaltja(value.compdiretosfundamentaltja);
    setshowcompindiretosinfantiltja(value.compindiretosinfantiltja);
    setshowcompindiretosfundamentaltja(value.compindiretosfundamentaltja);
    setshowconcentracao0_5tja(value.concentracao0_5tja);
    setshowconcentracao6_10tja(value.concentracao6_10tja);
    setshowescolatja(value.escolatja);
    setshowlimitetja(value.limitetja);
    setshowpontosfluxotja(value.pontosfluxotja);
    setshowrendatja(value.rendatja);
    setshowrendaareainfantiltja(value.rendaareainfantiltja);
    setshowrendaareafundatja(value.rendaareafundatja);

    setshowashidsgu(value.ashidsgu);
    setshowaspidsgu(value.aspidsgu);
    setshowapidsgu(value.apidsgu);
    setshowbempresasgu(value.bempresasgu);
    setshowcampusdagu(value.campusdagu);
    setshowcampusgu(value.campusgu);
    setshowfzdarggu(value.fzdarggu);
    setshowferroviasgu(value.ferroviasgu);
    setshowictsgu(value.ictsgu);
    setshowparquesgu(value.parquesgu);
    setshowriosgu(value.riosgu);
    setshowviasgu(value.viasgu);

    setshowareasindicadastjg(value.areasindicadasinfantiltjg);
    setshowareasindicadasfundamentaltjg(value.areasindicadasfundamentaltjg);
    setshowcompdiretosinfantiltjg(value.compdiretosinfantiltjg);
    setshowcompdiretosfundamentaltjg(value.compdiretosfundamentaltjg);
    setshowcompindiretosinfantiltjg(value.compindiretosinfantiltjg);
    setshowcompindiretosfundamentaltjg(value.compindiretosfundamentaltjg);
    setshowconcentracao0_5tjg(value.concentracao0_5tjg);
    setshowconcentracao6_10tjg(value.concentracao6_10tjg);
    setshowfamiliastjg(value.familiastjg);
    setshowrendatjg(value.rendatjg);
    setshowimovelpretendidotjg(value.imovelpretendidotjg);
    setshowlimitetjg(value.limitetjg);
    setshowpontosfluxotjg(value.pontosfluxotjg);
    setshowraioinfluenciatjg(value.raioinfluenciatjg);

    setShowEdumap(value.edumap);

    setshowbairroscrca(value.bairroscrca);
    setshowdadossociosfamiliascampinascrca(
      value.dadossociosfamiliascampinascrca
    );
    setshowdadossociosrendacampinascrca(value.dadossociosrendacampinascrca);
    setshowzoneamentocampinascrca(value.zoneamentocampinascrca);
    setshowlimitecampinascrca(value.limitecampinascrca);
    setshowdadosporbairro(value.dadosporbairro);

    setshowsjbv(value.sjbv);
    setshowsjbv1zoneamento(value.sjbv1zoneamento);
    setshowsjbv2permissao(value.sjbv2permissao);
    setshowsjbv3terrenos(value.sjbv3terrenos);
    setshowsjbv5topografia(value.sjbv5topografia);
    setshowsjbv6limitesa(value.sjbv6limitesa);
    setshowsjbv7hidrografia(value.sjbv7hidrografia);
    setshowsjbv8servidao(value.sjbv8servidao);
    setshowsjbv9terrenosp(value.sjbv9terrenosp);
    setshowsjbv10crescimento(value.sjbv10crescimento);
    setshowsjbv11dadossocios(value.sjbv11dadossocios);
    setshowsjbv12distviario(value.sjbv12distviario);
    setshowsjbv13malha(value.sjbv13malha);
    setshowsjbv14pontos(value.sjbv14pontos);

    setshowrp(value.rp);
    setshowrp1zoneamento(value.rp1zoneamento);
    setshowrp3terrenos(value.rp3terrenos);
    setshowrp5topografia(value.rp5topografia);
    setshowrp6limitesambientais(value.rp6limitesambientais);
    setshowrp7hidrografia(value.rp7hidrografia);
    setshowrp8servidao(value.rp8servidao);
    setshowrp10crescimento(value.rp10crescimento);
    setshowrp11dadossocioeconomicosrenda(value.rp11dadossocioeconomicosrenda);
    setshowrp12dadossocioeconomicosfamilias(
      value.rp12dadossocioeconomicosfamilias
    );

    setshowabrangenciacrascrca2(value.abrangenciacrascrca2);
    setshowassistenciateccrca2(value.assistenciateccrca2);
    setshowbairroscrca2(value.bairroscrca2);
    setshowcampinasdadossociosrendacrca2(value.campinasdadossociosrendacrca2);
    setshowcampinasdadossociosfamiliascrca2(
      value.campinasdadossociosfamiliascrca2
    );
    setshowcampinaslimitecrca2(value.campinaslimitecrca2);
    setshowcampinaszoneamentocrca2(value.campinaszoneamentocrca2);
    setshowcomercioalimentoscrca2(value.comercioalimentoscrca2);
    setshowdadosporbairrocrca2(value.dadosporbairrocrca2);
    setshowimpressao3dcrca2(value.impressao3dcrca2);
    setshowlojaderoupascrca2(value.lojaderoupascrca2);
    setshowlojasartesanatocrca2(value.lojasartesanatocrca2);
    setshowoficinasmecanicascrca2(value.oficinasmecanicascrca2);
    setshoworgcivilcrca2(value.orgcivilcrca2);
    setshowpadariasconfeitariascrca2(value.padariasconfeitariascrca2);
    setshowpetshopscrca2(value.petshopscrca2);
    setshowregioesvulcrca2(value.regioesvulcrca2);
    setshowsalaobelezacrc2(value.salaobelezacrc2);

    setshoweducomp(value.educomp);
    setshowedufamilia(value.edufamilia);
    setshowedurenda(value.edurenda);
    setshoweduimoveis(value.eduimoveis);

    setshowareasindicadasinfantiltjd(value.areasindicadasinfantiltjd);
    setshowareasindicadasfundamentaltjd(value.areasindicadasfundamentaltjd);
    setshowcompdiretosinfantiltjd(value.compdiretosinfantiltjd);
    setshowcompdiretosfundamentaltjd(value.compdiretosfundamentaltjd);
    setshowcompindiretosinfantiltjd(value.compindiretosinfantiltjd);
    setshowcompindiretosfundamentaltjd(value.compindiretosfundamentaltjd);
    setshowconcentracaocriancasinfantiltgd(
      value.concentracaocriancasinfantiltgd
    );
    setshowconcentracaocriancasfundtgd(value.concentracaocriancasfundtgd);
    setshowfamiliastjd(value.familiastjd);
    setshowlimitetjd(value.limitetjd);
    setshowrendatjd(value.rendatjd);
    setshowpontosfluxotjd(value.pontosfluxotjd);
    setshowraioinfluenciatjd(value.raioinfluenciatjd);
    setshowlegalizacaolavras(value.legalizacaolavras);

    setshowacademiasmanapoke(value.academiasmanapoke);
    setshowcentrosempresariasmanapoke(value.centrosempresariasmanapoke);
    setshowcentrosesportivosmanapoke(value.centrosesportivosmanapoke);
    setshowcomerciomanapoke(value.comerciomanapoke);
    setshowcompcomidajaponesamanapoke(value.compcomidajaponesamanapoke);
    setshowcompcomidasaudavelmanapoke(value.compcomidasaudavelmanapoke);
    setshowimoveiscomerciaismanapoke(value.imoveiscomerciaismanapoke);
    setshowpontosculturaismanapoke(value.pontosculturaismanapoke);
    setshowpontoseducacaomanapoke(value.pontoseducacaomanapoke);
    setshowrendamanapoke(value.rendamanapoke);
    setshowareaspotenciaismanapoke(value.areaspotenciaismanapoke);

    setshowzoneamentolavras(value.zoneamentolavras);
    setshowpermissaolavras(value.permissaolavras);
    setshowterrenoslavras(value.terrenoslavras);
    setshowtopografialavras(value.topografialavras);
    setshowapplavras(value.applavras);
    setshowhidrografialavras(value.hidrografialavras);
    setshowservidaolavras(value.servidaolavras);
    setshowterrenosinteresselavras(value.terrenosinteresselavras);
    setshowcrescimentolavras(value.crescimentolavras);
    setshowdadossocioslavras(value.dadossocioslavras);
    showsetdistviariolavras(value.distviariolavras);
    setshowdisturbanalavras(value.disturbanalavras);
    setshowpontoslavras(value.pontoslavras);

    //buddahspa
    setshowclubesbs(value.clubesbs);
    setshowfamiliasbs(value.familiasbs);
    setshowlojasdepartamentobs(value.lojasdepartamentobs);
    setshowparquesbs(value.parquesbs);
    setshowrendabs(value.rendabs);
    setshowpontosbuddhaspabs(value.pontosbuddhaspabs);
    setshowrestaurantesbs(value.restaurantesbs);
    setshowshoppingsbs(value.shoppingsbs);
    setshowsupermercadosbs(value.supermercadosbs);
    setshowestacionamentosbs(value.estacionamentosbs);
    setshowimoveismenos18bs(value.imoveismenos18bs);
    setshowimoveismais18bs(value.imoveismais18bs);

    setshowareainflucompbs(value.areainflucompbs);
    setshowareapotcomrecorte(value.areapotcomrecorte);
    setshowareapotsemrecorte(value.areapotsemrecorte);
    setshowcompetidoresbs(value.competidoresbs);
    setshowpopadultabs(value.popadultabs);

    setshowareaspotbd(value.areaspotbd);
    setshowbaldonibd(value.baldonibd);
    setshowbaldoni2030bd(value.baldoni2030bd);
    setshowlojaspescabd(value.lojaspescabd);
    setshowpesquesbd(value.pesquesbd);
    setshowpetshopsbd(value.petshopsbd);
    setshowpopadultabd(value.popadultabd);
    setshowrendabd(value.rendabd);
    setshowrestaurantesbd(value.restaurantesbd);
    setshowvetsbd(value.vetsbd);

    setshowacademiascb(value.academiascb);
    setshowareaspotcb(value.areaspotcb);
    setshowcafescb(value.cafescb);
    setshowcomerciocb(value.comerciocb);
    setshowculturalcb(value.culturalcb);
    setshowescolascb(value.escolascb);
    setshowesportescb(value.esportescb);
    setshowfaculdadescb(value.faculdadescb);
    setshowimoveiscb(value.imoveiscb);
    setshowlanchonetescb(value.lanchonetescb);
    setshowrendasetorescb(value.rendasetorescb);
    setshowcomidasaudavelcb(value.comidasaudavelcb);

    setshowzonasusobs(value.zonasusobs);

    setshowRio_Verde_1_zoneamento(value.Rio_Verde_1_zoneamento);
    setshowRio_Verde_2_permissao(value.Rio_Verde_2_permissao);
    setshowRio_Verde_3_terrenos(value.Rio_Verde_3_terrenos);
    setshowRio_Verde_4_imoveis_disponiveis(
      value.Rio_Verde_4_imoveis_disponiveis
    );
    setshowRio_Verde_5_topografia(value.Rio_Verde_5_topografia);
    setshowRio_Verde_6_Limites_Ambientais(value.Rio_Verde_6_Limites_Ambientais);
    setshowRio_Verde_7_Hidrografia(value.Rio_Verde_7_Hidrografia);
    setshowRio_Verde_8_Servidao(value.Rio_Verde_8_Servidao);
    setshowRio_Verde_9_terrenos_potenciais(
      value.Rio_Verde_9_terrenos_potenciais
    );
    setshowRio_Verde_10_crescimento(value.Rio_Verde_10_crescimento);
    setshowRio_Verde_11_dados_socioeconomicos(
      value.Rio_Verde_11_dados_socioeconomicos
    );
    setshowRio_verde_12_dist_viario(value.Rio_verde_12_dist_viario);
    setshowRio_Verde_13_Malha_Urbana(value.Rio_Verde_13_Malha_Urbana);
    setshowRio_Verde_14_Pontos_de_Interesse(
      value.Rio_Verde_14_Pontos_de_Interesse
    );
    setshowRio_Verde(value.Rio_Verde);

    setshowCruzeiro_1_Zoneamento(value.Cruzeiro_1_Zoneamento);
    setshowGuaxupe_1_zoneamento(value.Guaxupe_1_zoneamento);
    setshowLavras_zoneamento_TESTE(value.Lavras_zoneamento_TESTE);
    setshowSSParaiso_1_Zoneamento(value.SSParaiso_1_Zoneamento);

    setshowangradosreisacc(value.angradosreisacc);
    setshowbr101km414acc(value.br101km414acc);
    setshowbr101km447acc(value.br101km447acc);
    setshowbr101km538acc(value.br101km538acc);
    setshowitaguaiacc(value.itaguaiacc);
    setshowmangaratibaacc(value.mangaratibaacc);
    setshowparatyacc(value.paratyacc);
    setshowtrechobr101acc(value.trechobr101acc);

    setshowrendaacc(value.rendaacc);
    setshowareasconmataatlaacc(value.areasconmataatlaacc);
    setshowappacc(value.appacc);
    setshowvegetacaonativaacc(value.vegetacaonativaacc);
    setshowuniconsfederaisacc(value.uniconsfederaisacc);
    setshowreservalegalacc(value.reservalegalacc);
    setshowareasprotacc(value.areasprotacc);

    setshowacaiteriacb(value.acaiteriacb);
    setshowsorveteriacb(value.sorveteriacb);

    setshowmoteisac(value.moteisac);
    setshowpedagiosac(value.pedagiosac);
    setshowpostosac(value.postosac);
    setshowrestaurantesac(value.restaurantesac);
    setshowhoteisac(value.hoteisac);
    setshowpousadasac(value.pousadasac);

    setshowareaspotpucrsg(value.areaspotpucrsg);
    setshowareaspotunicampport2rsg(value.areaspotunicampport2rsg);
    setshowareaspotunicampprincrsg(value.areaspotunicampprincrsg);
    setshowportariasrsg(value.portariasrsg);
    setshowraio200rsg(value.raio200rsg);
    setshowterrenosdisprsg(value.terrenosdisprsg);

    setshowperimetrourbanocb(value.perimetrourbanocb);
    setshowfluxokernelcb(value.fluxokernelcb);

    setshowconcentrafluxocb(value.concentrafluxocb);
    setshowraiofluxocb(value.raiofluxocb);
    setshowfluxoconcocb(value.fluxoconcocb);

    setshowzoneamentopec(value.zoneamentopec);
    setshowpontosintpec(value.pontosintpec);
    setshowpermissaopec(value.permissaopec);
    setshowtopografiapec(value.topografiapec);
    setshowcrescimentoanopec(value.crescimentoanopec);
    setshowgraprohab(value.graprohab);
    setshowdensidadepec(value.densidadepec);
    setshowfamiliaspec(value.familiaspec);
    setshowrendapec(value.rendapec);
    setshowcorpospec(value.corpospec);
    setshowcursospec(value.cursospec);
    setshownascentespec(value.nascentespec);
    setshowapppec(value.apppec);
    setshowreservapec(value.reservapec);
    setshowvegpec(value.vegpec);
    setshowterrenos2k4kpec(value.terrenos2k4kpec);
    setshowterrenos5kmaispec(value.terrenos5kmaispec);
    setshowterrenosnaopec(value.terrenosnaopec);
    setshowterrenospot5kmaispec(value.terrenospot5kmaispec);
    setshowterrenospot150kmaispec(value.terrenospot150kmaispec);
    setshowmapaconcentracaopec(value.mapaconcentracaopec);

    setshowlimitevmap(value.limitevmap);
    setshowpopfemvmap(value.popfemvmap);
    setshowpopmasvmap(value.popmasvmap);
    setshowpoptotvmap(value.poptotvmap);
    setshowprefeitovmap(value.prefeitovmap);
    setshowprefeitovmap2(value.prefeitovmap2);
    setshowvereadorvmap(value.vereadorvmap);
    setshowvereadorvmap2(value.vereadorvmap2);
    setshowrendavmap(value.rendavmap);

    setshowdemandaespacopu(value.demandaespacopu);
    setshowdemandaqualidadepu(value.demandaqualidadepu);
    setshowfaixafaturamentopu(value.faixafaturamentopu);
    setshowleispu(value.leispu);
    setshowmapageralpu(value.mapageralpu);
    setshowmodelopu(value.modelopu);
    setshowpreferenpu(value.preferenpu);
    setshownecessidadepu(value.necessidadepu);
    setshowrelacaopu(value.relacaopu);
    setshowsetorpu(value.setorpu);

    setshowdivisaoregpec(value.divisaoregpec);
    setshowvaziospec(value.vaziospec);
    setshowusosolopec(value.usosolopec);
    setshowconcenfluxospec(value.concenfluxospec);
    setshowtopografiapec2(value.topografiapec2);

    setshowfaixaac(value.faixaac);

    setshowareaintbb(value.areaintbb);
    setshowboibrabobb(value.boibrabobb);
    setshowclassesbb(value.classesbb);
    setshowcompsbb(value.compsbb);
    setshowconccompsbb(value.conccompsbb);
    setshowconcpfbb(value.concpfbb);
    setshowfamiliasbb(value.familiasbb);
    setshowfluxocompsbb(value.fluxocompsbb);
    setshowimoveisbb(value.imoveisbb);
    setshowpontosfluxobb(value.pontosfluxobb);
    setshowrendabb(value.rendabb);
    setshowmetrobb(value.metrobb);
    setshowonibusbb(value.onibusbb);
    setshowtrembb(value.trembb);
    setshowareapotbb(value.areapotbb);

    setshowareapotdp(value.areapotdp);
    setshowcompsdp(value.compsdp);
    setshowconccompsdp(value.conccompsdp);
    setshowconcpontofluxodp(value.concpontofluxodp);
    setshowfamiliasdp(value.familiasdp);
    setshowgastodp(value.gastodp);
    setshowpontofluxodp(value.pontofluxodp);
    setshowpop20a69dp(value.pop20a69dp);
    setshowrendadp(value.rendadp);

    setshowarmazensacc2(value.armazensacc2);
    setshowbalsasacc2(value.balsasacc2);
    setshowconcarmazensacc2(value.concarmazensacc2);
    setshowformosoacc2(value.formosoacc2);
    setshowlagoaacc2(value.lagoaacc2);
    setshowmiranorteacc2(value.miranorteacc2);
    setshowportoacc2(value.portoacc2);
    setshowsilvanopolisacc2(value.silvanopolisacc2);
    setshowtassoacc2(value.tassoacc2);

    setshowusosoloacc2(value.usosoloacc2);
    setshowhidroviasgeralacc2(value.hidroviasgeralacc2);
    setshowviasgeralacc2(value.viasgeralacc2);
    setshowportsaoluisacc2(value.portsaoluisacc2);
    setshowportnacionalacc2(value.portnacionalacc2);
    setshowintpalemeiranteacc2(value.intpalemeiranteacc2);
    setshowrodoviasacc2(value.rodoviasacc2);
    setshowinstalacoesportacc2(value.instalacoesportacc2);
    setshowhidroviasofcacc2(value.hidroviasofcacc2);
    setshowferroviasacc2(value.ferroviasacc2);
    setshowusosolomiranorteacc2(value.usosolomiranorteacc2);
    setshowusosolosilvanopolisacc2(value.usosolosilvanopolisacc2);
    setshowusosololagoaacc2(value.usosololagoaacc2);
    setshowusosoloformosoacc2(value.usosoloformosoacc2);
    setshowusosoloportoacc2(value.usosoloportoacc2);
    setshowusosolobalsasacc2(value.usosolobalsasacc2);
    setshowusosolotassoacc2(value.usosolotassoacc2);
    setShowKmlT(value.terrenos);

    setshowlmsaltosc(value.lmsaltosc);
    setshowcompsaltosc(value.compsaltosc);
    setshowr1saltosc(value.r1saltosc);
    setshowr2saltosc(value.r2saltosc);
    setshowpfsaltosc(value.pfsaltosc);
    setshowscsaltosc(value.scsaltosc);
    setshowlmmarasc(value.lmmarasc);
    setshowcompmarasc(value.compmarasc);
    setshowbuffermarasc(value.buffermarasc);
    setshowpfmarasc(value.pfmarasc);
    setshowscmarasc(value.scmarasc);
    setshowlmjacaresc(value.lmjacaresc);
    setshowcompjacaresc(value.compjacaresc);
    setshowr1jacaresc(value.r1jacaresc);
    setshowpfjacaresc(value.pfjacaresc);
    setshowscjacaresc(value.scjacaresc);
    setshowterrenospec(value.terrenospec);
    setshowterrenospecpau(value.terrenospecpau);

    setshowamoarmazensacc2(value.amoarmazensacc2);
    setshowprestaacc2(value.prestaacc2);
    setshowhabacc2(value.habacc2);
    setshowcapacc2(value.capacc2);
    setshowtipoacc2(value.tipoacc2);
    setshowporteacc2(value.porteacc2);
    setshowformacc2(value.formacc2);
    setshowdistferroacc2(value.distferroacc2);
    setshowdistermacc2(value.distermacc2);
    setshowestosoacc2(value.estosoacc2);
    setshowprecosoacc2(value.precosoacc2);
    setshowpopacc2(value.popacc2);
    setshowrendaacc2(value.rendaacc2);
    setshowaeroacc2(value.aeroacc2);
    setshowdeclacc2(value.declacc2);

    setshowdadossociosaltossc(value.dadossociosaltossc);
    setshowfluxo1marajasc(value.fluxo1marajasc);
    setshowkernelfluxo1marajasc(value.kernelfluxo1marajasc);
    setshowfamiliasmarajassc(value.familiasmarajassc);
    setshowrendamarajassc(value.rendamarajassc);
    setshowdadossociojacaresc(value.dadossociojacaresc);

    setshowcrescimentobrz(value.crescimentobrz);
    setshowdomiciliosaluguelbrz(value.domiciliosaluguelbrz);
    setshowdomicioliosbrz(value.domicioliosbrz);
    setshowempreendimentosbrz(value.empreendimentosbrz);
    setshowlavrasbrz(value.lavrasbrz);
    setshowpopbrz(value.popbrz);
    setshowraio15brz(value.raio15brz);
    setshowrendabrz(value.rendabrz);
    setshowvetorbrz(value.vetorbrz);

    setshowprop1toacc2(value.prop1toacc2);
    setshowprop2toacc2(value.prop2toacc2);
    setshowprop1maacc2(value.prop1maacc2);
    setshowprop2maacc2(value.prop2maacc2);

    setshowpontointbrz(value.pontointbrz);

    setshowcrescimento1brz(value.crescimento1brz);
    setshowdeficitbrz(value.deficitbrz);
    setshowtaxahabbrz(value.taxahabbrz);

    setshowgtdcamacircu(value.gtdcamacircu);
    setshowdistcamacircu(value.distcamacircu);
    setshowarearedcamacircu(value.arearedcamacircu);
    setshowareaspotcamacircu(value.areaspotcamacircu);
    setshowassocamacircu(value.assocamacircu);
    setshowcamacircu(value.camacircu);
    setshowempscamacircu(value.empscamacircu);
    setshowconcempcamacircu(value.concempcamacircu);
    setshowareaspotvargcircu(value.areaspotvargcircu);
    setshowassovargcircu(value.assovargcircu);
    setshowvargcircu(value.vargcircu);
    setshowempsvargcircu(value.empsvargcircu);
    setshowgtdcampcircu(value.gtdcampcircu);
    setshowdistcampcircu(value.distcampcircu);
    setshowdistecocampcircu(value.distecocampcircu);
    setshowarearedcampcircu(value.arearedcampcircu);
    setshowareaspotcampcircu(value.areaspotcampcircu);
    setshowassocampcircu(value.assocampcircu);
    setshowcampcircu(value.campcircu);
    setshowempscampcircu(value.empscampcircu);
    setshowdensempscampcircu(value.densempscampcircu);
    setshowecoscampcircu(value.ecoscampcircu);
    setshowgtdjundcircu(value.gtdjundcircu);
    setshowdistjundcircu(value.distjundcircu);
    setshowdistecojundcircu(value.distecojundcircu);
    setshowarearedjundcircu(value.arearedjundcircu);
    setshowareaspotjundcircu(value.areaspotjundcircu);
    setshowassojundcircu(value.assojundcircu);
    setshowjundcircu(value.jundcircu);
    setshowempsjundcircu(value.empsjundcircu);
    setshowdensempsjundcircu(value.densempsjundcircu);
    setshowecosjundcircu(value.ecosjundcircu);
    setshowgtdsorocircu(value.gtdsorocircu);
    setshowdistsorocircu(value.distsorocircu);
    setshowdistecosorocircu(value.distecosorocircu);
    setshowarearedsorocircu(value.arearedsorocircu);
    setshowareaspotsorocircu(value.areaspotsorocircu);
    setshowassosorocircu(value.assosorocircu);
    setshowsorocircu(value.sorocircu);
    setshowempssorocircu(value.empssorocircu);
    setshowdensempssorocircu(value.densempssorocircu);
    setshowecossorocircu(value.ecossorocircu);

    setshowareapotafasdp(value.areapotafasdp);
    setshowimoveisdp(value.imoveisdp);

    setshowjundiaimrv(value.jundiaimrv);
    setshowappjundiaimrv(value.appjundiaimrv);
    setshowvegjundiaimrv(value.vegjundiaimrv);
    setshowresjundiaimrv(value.resjundiaimrv);
    setshowareaprojundiaimrv(value.areaprojundiaimrv);
    setshownascjundiaimrv(value.nascjundiaimrv);
    setshowcursjundiaimrv(value.cursjundiaimrv);
    setshowcorposjundiaimrv(value.corposjundiaimrv);
    setshowtopojundiaimrv(value.topojundiaimrv);
    setshowurbjundiaimrv(value.urbjundiaimrv);
    setshowareapoujundiaimrv(value.areapoujundiaimrv);
    setshowservjundiaimrv(value.servjundiaimrv);
    setshowusorestjundiaimrv(value.usorestjundiaimrv);
    setshowareaconsjundiaimrv(value.areaconsjundiaimrv);
    setshowareacontjundiaimrv(value.areacontjundiaimrv);
    setshowdutojundiaimrv(value.dutojundiaimrv);
    setshowlinhaojundiaimrv(value.linhaojundiaimrv);
    setshowzoneajundiaimrv(value.zoneajundiaimrv);
    setshowlegavertjundiaimrv(value.legavertjundiaimrv);
    setshowlegahorjundiaimrv(value.legahorjundiaimrv);
    setshowrendapopjundiaimrv(value.rendapopjundiaimrv);
    setshowrendajundiaimrv(value.rendajundiaimrv);
    setshowpopjundiaimrv(value.popjundiaimrv);
    setshowdomicjundiaimrv(value.domicjundiaimrv);
    setshowdomicalujundiaimrv(value.domicalujundiaimrv);
    setshowexpurbjundiaimrv(value.expurbjundiaimrv);
    setshowtaxahabjundiaimrv(value.taxahabjundiaimrv);
    setshowgraprohabjundiaimrv(value.graprohabjundiaimrv);
    setshowareaurbjundiaimrv(value.areaurbjundiaimrv);
    setshowarearujundiaimrv(value.arearujundiaimrv);
    setshowvaziosjundiaimrv(value.vaziosjundiaimrv);
    setshowconsumoaguajundiaimrv(value.consumoaguajundiaimrv);
    setshowdispaguajundiaimrv(value.dispaguajundiaimrv);
    setshowcustojundiaimrv(value.custojundiaimrv);
    setshowterrenosjundiaimrv(value.terrenosjundiaimrv);
    setshowpontoajundiaimrv(value.pontoajundiaimrv);
    setshowpontodjundiaimrv(value.pontodjundiaimrv);
    setshowlimeiramrv(value.limeiramrv);
    setshowapplimeiramrv(value.applimeiramrv);
    setshowveglimeiramrv(value.veglimeiramrv);
    setshowreslimeiramrv(value.reslimeiramrv);
    setshowareaprolimeiramrv(value.areaprolimeiramrv);
    setshownasclimeiramrv(value.nasclimeiramrv);
    setshowcurslimeiramrv(value.curslimeiramrv);
    setshowcorposlimeiramrv(value.corposlimeiramrv);
    setshowtopolimeiramrv(value.topolimeiramrv);
    setshowurblimeiramrv(value.urblimeiramrv);
    setshowareapoulimeiramrv(value.areapoulimeiramrv);
    setshowservlimeiramrv(value.servlimeiramrv);
    setshowusorestlimeiramrv(value.usorestlimeiramrv);
    setshowareaconslimeiramrv(value.areaconslimeiramrv);
    setshowareacontlimeiramrv(value.areacontlimeiramrv);
    setshowdutolimeiramrv(value.dutolimeiramrv);
    setshowlinhaolimeiramrv(value.linhaolimeiramrv);
    setshowzonealimeiramrv(value.zonealimeiramrv);
    setshowlegavertlimeiramrv(value.legavertlimeiramrv);
    setshowlegahorlimeiramrv(value.legahorlimeiramrv);
    setshowrendapoplimeiramrv(value.rendapoplimeiramrv);
    setshowrendalimeiramrv(value.rendalimeiramrv);
    setshowpoplimeiramrv(value.poplimeiramrv);
    setshowdomiclimeiramrv(value.domiclimeiramrv);
    setshowdomicalulimeiramrv(value.domicalulimeiramrv);
    setshowexpurblimeiramrv(value.expurblimeiramrv);
    setshowtaxahablimeiramrv(value.taxahablimeiramrv);
    setshowgraprohablimeiramrv(value.graprohablimeiramrv);
    setshowareaurblimeiramrv(value.areaurblimeiramrv);
    setshowarearulimeiramrv(value.arearulimeiramrv);
    setshowvazioslimeiramrv(value.vazioslimeiramrv);
    setshowconsumoagualimeiramrv(value.consumoagualimeiramrv);
    setshowdispagualimeiramrv(value.dispagualimeiramrv);
    setshowcustolimeiramrv(value.custolimeiramrv);
    setshowterrenoslimeiramrv(value.terrenoslimeiramrv);
    setshowpontoalimeiramrv(value.pontoalimeiramrv);
    setshowpontodlimeiramrv(value.pontodlimeiramrv);

    setshowveloprecojundiaimrv(value.veloprecojundiaimrv);
    setshowveloprecolimeiramrv(value.veloprecolimeiramrv);
    setshowbdgu(value.bdgu);
    setshowboticampcircu(value.boticampcircu);
    setshowbotijundcircu(value.botijundcircu);
    setshowbotisorocircu(value.botisorocircu);
    setshowbotigrandespcircu(value.botigrandespcircu);
    setshowgrandespcircu(value.grandespcircu);

    setshowimoveisc1ic(value.imoveisc1ic);
    setshowraioc1ic(value.raioc1ic);
    setshowareaestudoc2ic(value.areaestudoc2ic);
    setshowrendac2ic(value.rendac2ic);
    setshowrecortec2ic(value.recortec2ic);
    setshowpontosfluxc2ic(value.pontosfluxc2ic);
    setshowconcfluxoc2ic(value.concfluxoc2ic);
    setshowoticasc2ic(value.oticasc2ic);
    setshowconcfluxooticasc2ic(value.concfluxooticasc2ic);
    setshowimoveisc2ic(value.imoveisc2ic);
    setshowshoppingsc2ic(value.shoppingsc2ic);

    setshowkernelsaltosc(value.kernelsaltosc);
    setshowkerneljacaresc(value.kerneljacaresc);
    setshowponibusmarasc(value.ponibusmarasc);
    setshowlonibusmarasc(value.lonibusmarasc);

    setshowredesdrenalimeiramrv(value.redesdrenalimeiramrv);
    setshowrecargahidricalimeiramrv(value.recargahidricalimeiramrv);
    setshowferroviaslimeiramrv(value.ferroviaslimeiramrv);
    setshowviaslimeiramrv(value.viaslimeiramrv);
    setshowviasligalimeiramrv(value.viasligalimeiramrv);
    setshowrankingterlimeiramrv(value.rankingterlimeiramrv);
    setshowredesdrenajundiaimrv(value.redesdrenajundiaimrv);
    setshowrecargahidricajundiaimrv(value.recargahidricajundiaimrv);
    setshowviasjundiaimrv(value.viasjundiaimrv);
    setshowviasligajundiaimrv(value.viasligajundiaimrv);
    setshowferroviasjundiaimrv(value.ferroviasjundiaimrv);
    setshowequipamentosjundiaimrv(value.equipamentosjundiaimrv);
    setshowrankingterjundiaimrv(value.rankingterjundiaimrv);
    setshowpoluicaosonorajundiaimrv(value.poluicaosonorajundiaimrv);

    setshowacadmelten(value.acadmelten);
    setshowareasafastmelten(value.areasafastmelten);
    setshowareasproxmelten(value.areasproxmelten);
    setshowcomerciosmelten(value.comerciosmelten);
    setshowconcconcmelten(value.concconcmelten);
    setshowconcmelten(value.concmelten);
    setshowcrescurbanomelten(value.crescurbanomelten);
    setshowdomiciliosmelten(value.domiciliosmelten);
    setshowequipublimelten(value.equipublimelten);
    setshowescolasprivadasmelten(value.escolasprivadasmelten);
    setshowhidroviasmelten(value.hidroviasmelten);
    setshowhospitaismelten(value.hospitaismelten);
    setshowimoveismelten(value.imoveismelten);
    setshowinfraviariamelten(value.infraviariamelten);
    setshowmelten(value.melten);
    setshowpalmasmelten(value.palmasmelten);
    setshowpopmelten(value.popmelten);
    setshowrendamelten(value.rendamelten);
    setshowrestaurantesmelten(value.restaurantesmelten);
    setshowtransportemelten(value.transportemelten);
    setshowviasmelten(value.viasmelten);

    setshowgtdipocircu(value.gtdipocircu);
    setshowarearedipocircu(value.arearedipocircu);
    setshowareaspotipocircu(value.areaspotipocircu);
    setshowcoopreciipocircu(value.coopreciipocircu);
    setshowipocircu(value.ipocircu);
    setshowempsipocircu(value.empsipocircu);
    setshowdensempsipocircu(value.densempsipocircu);
    setshowecosipocircu(value.ecosipocircu);

    setshowcorrecondcamacircu(value.correcondcamacircu);
    setshowcorrecondvargcircu(value.correcondvargcircu);
    setshowcorrecondcampcircu(value.correcondcampcircu);
    setshowcorrecondjundcircu(value.correcondjundcircu);
    setshowcorrecondsorocircu(value.correcondsorocircu);

    setshowregionalizacaomelten(value.regionalizacaomelten);

    setshowcrescpecpau(value.crescpecpau);
    setshowdadossociospecpau(value.dadossociospecpau);
    setshowhidropecpau(value.hidropecpau);
    setshowlimitesambpecpau(value.limitesambpecpau);
    setshowpontosfluxopecpau(value.pontosfluxopecpau);
    setshowraios500pecpau(value.raios500pecpau);
    setshowservidaopecpau(value.servidaopecpau);
    setshowterrenospotpecpau(value.terrenospotpecpau);
    setshowterrenosranqpecpau(value.terrenosranqpecpau);
    setshowtopopecpau(value.topopecpau);
    setshowvaziospecpau(value.vaziospecpau);

    setshowplayersagrovh(value.playersagrovh);
    setshowcampinasvh(value.campinasvh);
    setshowparquesteccampvh(value.parquesteccampvh);

    setshowdemandagu(value.demandagu);
    setshowativpesquisagu(value.ativpesquisagu);
    setshowinteressehubgu(value.interessehubgu);
    setshowmodelotrabalhogu(value.modelotrabalhogu);
    setshowcapitalpropriogu(value.capitalpropriogu);
    setshowfundestaduaisgu(value.fundestaduaisgu);
    setshowsebraegu(value.sebraegu);
    setshowbndesgu(value.bndesgu);
    setshowinvestidoranjogu(value.investidoranjogu);
    setshowfundosinvestgu(value.fundosinvestgu);
    setshowaceleradorasgu(value.aceleradorasgu);
    setshowsemrespostagu(value.semrespostagu);

    setshowictsvh(value.ictsvh);
    setshowictsagrovh(value.ictsagrovh);

    setshowareaspreservavespemcp(value.areaspreservavespemcp);
    setshowvegnativavespemcp(value.vegnativavespemcp);
    setshowreservalegalvespemcp(value.reservalegalvespemcp);
    setshownascentesvespemcp(value.nascentesvespemcp);
    setshowcursosaguavespemcp(value.cursosaguavespemcp);
    setshowtopografiavespemcp(value.topografiavespemcp);
    setshowlimitevespemcp(value.limitevespemcp);
    setshowzoneamentovespemcp(value.zoneamentovespemcp);
    setshowusovertvespemcp(value.usovertvespemcp);
    setshowpermissaovespemcp(value.permissaovespemcp);
    setshowlocterrenovespemcp(value.locterrenovespemcp);
    setshowservidaovespemcp(value.servidaovespemcp);
    setshowareaconsovespemcp(value.areaconsovespemcp);
    setshowareascontavespemcp(value.areascontavespemcp);
    setshowdutoviasvespemcp(value.dutoviasvespemcp);
    setshowlinhaovespemcp(value.linhaovespemcp);
    setshownovoscondvespemcp(value.novoscondvespemcp);
    setshowrendavespemcp(value.rendavespemcp);
    setshowpopvespemcp(value.popvespemcp);
    setshowdomicivespemcp(value.domicivespemcp);
    setshowdomicialuvespemcp(value.domicialuvespemcp);
    setshowexpurbanavespemcp(value.expurbanavespemcp);
    setshowveloeprecovespemcp(value.veloeprecovespemcp);
    setshowtaxahabvespemcp(value.taxahabvespemcp);
    setshowconcorrenciavespemcp(value.concorrenciavespemcp);
    setshowareaurbavespemcp(value.areaurbavespemcp);
    setshowareasruraisvespemcp(value.areasruraisvespemcp);
    setshowconsuaguavespemcp(value.consuaguavespemcp);
    setshowdispaguavespemcp(value.dispaguavespemcp);
    setshowcustoprojetovespemcp(value.custoprojetovespemcp);
    setshowterrenosanunvespemcp(value.terrenosanunvespemcp);
    setshowimoveisanunvespemcp(value.imoveisanunvespemcp);
    setshowpontosatravespemcp(value.pontosatravespemcp);
    setshowpontosdetravespemcp(value.pontosdetravespemcp);

    setshowtjchpdtjd(value.tjchpdtjd);
    setshowcompdiretoschpdtjd(value.compdiretoschpdtjd);
    setshowcriancas05chpdtjd(value.criancas05chpdtjd);
    setshowescolasidiomaschpdtjd(value.escolasidiomaschpdtjd);
    setshowescolasinfantischpdtjd(value.escolasinfantischpdtjd);
    setshowescolaschpdtjd(value.escolaschpdtjd);
    setshowimovelindicadochpdtjd(value.imovelindicadochpdtjd);
    setshowpontosfluxochpdtjd(value.pontosfluxochpdtjd);
    setshowprincipaisviaschpdtjd(value.principaisviaschpdtjd);
    setshowraio1kmcompchpdtjd(value.raio1kmcompchpdtjd);
    setshowraio2kmcompchpdtjd(value.raio2kmcompchpdtjd);
    setshowrendafamiliarchpdtjd(value.rendafamiliarchpdtjd);

    setshowareainflurctjd(value.areainflurctjd);
    setshowareasindirctjd(value.areasindirctjd);
    setshowcompetidoresrctjd(value.competidoresrctjd);
    setshowcondominiosrctjd(value.condominiosrctjd);
    setshowimoveisrctjd(value.imoveisrctjd);
    setshowpontosfluxirctjd(value.pontosfluxirctjd);
    setshowcompdiretosvptjd(value.compdiretosvptjd);
    setshowimoveissapopembavptjd(value.imoveissapopembavptjd);
    setshowimoveisvpvptjd(value.imoveisvpvptjd);
    setshowpontosfluxovptjd(value.pontosfluxovptjd);
    setshowrendafamiliarvptjd(value.rendafamiliarvptjd);
    setshowtjschoolvptjd(value.tjschoolvptjd);

    setshowappwbgi(value.appwbgi);
    setshowareacontwbgi(value.areacontwbgi);
    setshowcurvasnivelwbgi(value.curvasnivelwbgi);
    setshowdeclividadewbgi(value.declividadewbgi);
    setshowdistcorposaguawbgi(value.distcorposaguawbgi);
    setshowdistcorposhidricoswbgi(value.distcorposhidricoswbgi);
    setshowhidrografiawbgi(value.hidrografiawbgi);
    setshowlimeirawbgi(value.limeirawbgi);
    setshowreservalegalwbgi(value.reservalegalwbgi);
    setshowvegnativawbgi(value.vegnativawbgi);
    setshowviveiristaswbgi(value.viveiristaswbgi);

    setshowlimiteosascotnd(value.limiteosascotnd)
    setshowareapreserosascotnd(value.areapreserosascotnd)
    setshowvegnaivaosascotnd(value.vegnaivaosascotnd)
    setshowreservaosascotnd(value.reservaosascotnd)
    setshownascentesosascotnd(value.nascentesosascotnd)
    setshowcursososascotnd(value.cursososascotnd)
    setshowcorpososascotnd(value.corpososascotnd)
    setshowtopografiaosascotnd(value.topografiaosascotnd)
    setshowzoneamentoosascotnd(value.zoneamentoosascotnd)
    setshowusovertosascotnd(value.usovertosascotnd)
    setshowimplantacaohishmposascotnd(value.implantacaohishmposascotnd)
    setshowservadmosascotnd(value.servadmosascotnd)
    setshowareaconsoosascotnd(value.areaconsoosascotnd)
    setshowareascontaosascotnd(value.areascontaosascotnd)
    setshowdutoviasosascotnd(value.dutoviasosascotnd)
    setshowlinhaoosascotnd(value.linhaoosascotnd)
    setshowpontosatratosascotnd(value.pontosatratosascotnd)
    setshowpontosdetratosascotnd(value.pontosdetratosascotnd)
    setshowrendaosascotnd(value.rendaosascotnd)
    setshowdomiciliososascotnd(value.domiciliososascotnd)
    setshowdomiciliosaluguelosascotnd(value.domiciliosaluguelosascotnd)
    setshowexpcidadesosascotnd(value.expcidadesosascotnd)
    setshowprecomedioosascotnd(value.precomedioosascotnd)
    setshowtaxahabosascotnd(value.taxahabosascotnd)
    setshowconcoosascotnd(value.concoosascotnd)
    setshowareaurbanizadaosascotnd(value.areaurbanizadaosascotnd)
    setshowareasruraisosascotnd(value.areasruraisosascotnd)
    setshowvaziosurbanososascotnd(value.vaziosurbanososascotnd)
    setshowgrapohabosascotnd(value.grapohabosascotnd)
    setshowterrenososascotnd(value.terrenososascotnd)
    setshowimoveisosascotnd(value.imoveisosascotnd)
    setshowtendenciassetorosascotnd(value.tendenciassetorosascotnd)
    setshowsinaismudancaosascotnd(value.sinaismudancaosascotnd)
    setshowoportunidadeosascotnd(value.oportunidadeosascotnd)
    setshowareausorestosascotnd(value.areausorestosascotnd)

    setshowareapotlimeiramrv(value.areapotlimeiramrv)

    setshowareapreserslgb(value.areapreserslgb)
setshowvegnativaslgb(value.vegnativaslgb)
setshowreservaslgb(value.reservaslgb)
setshownascentesslgb(value.nascentesslgb)
setshowhidrografiaslgb(value.hidrografiaslgb)
setshowtopografiaslgb(value.topografiaslgb)
setshowzoneamentoslgb(value.zoneamentoslgb)
setshowusovertslgb(value.usovertslgb)
setshowusohorslgb(value.usohorslgb)
setshowviasslgb(value.viasslgb)
setshowdutoviasslgb(value.dutoviasslgb)
setshowlinhaoslgb(value.linhaoslgb)
setshowpontosatratosslgb(value.pontosatratosslgb)
setshowpontosdetratslgb(value.pontosdetratslgb)
setshowrendaslgb(value.rendaslgb)
setshowdomiciliosslgb(value.domiciliosslgb)
setshowconcoslgb(value.concoslgb)
setshowareasruraisslgb(value.areasruraisslgb)
setshowterrenosslgb(value.terrenosslgb)
setshowimoveisslgb(value.imoveisslgb)

setshowraiopesquisaslgb(value.raiopesquisaslgb)
setshowterrenosanaslgb(value.terrenosanaslgb)

setshowlimitestatnd(value.limitestatnd)
setshowareapreserstatnd(value.areapreserstatnd)
setshowvegnaivastatnd(value.vegnaivastatnd)
setshowreservastatnd(value.reservastatnd)
setshownascentesstatnd(value.nascentesstatnd)
setshowhidrocarstatnd(value.hidrocarstatnd)
setshowhidrocompstatnd(value.hidrocompstatnd)
setshowtopografiastatnd(value.topografiastatnd)
setshowzoneamentostatnd(value.zoneamentostatnd)
setshowusovertstatnd(value.usovertstatnd)
setshowimplantacaohishmpstatnd(value.implantacaohishmpstatnd)
setshowservadmstatnd(value.servadmstatnd)
setshowareausoreststatnd(value.areausoreststatnd)
setshowareaconsostatnd(value.areaconsostatnd)
setshowareascontastatnd(value.areascontastatnd)
setshowdutoviasstatnd(value.dutoviasstatnd)
setshowlinhaostatnd(value.linhaostatnd)
setshowrededrenagemstatnd(value.rededrenagemstatnd)
setshowpontosatratstatnd(value.pontosatratstatnd)
setshowpontosdetratstatnd(value.pontosdetratstatnd)
setshowdomiciliosstatnd(value.domiciliosstatnd)
setshowrendastatnd(value.rendastatnd)
setshowdomiciliosaluguelstatnd(value.domiciliosaluguelstatnd)
setshowexpcidadesstatnd(value.expcidadesstatnd)
setshowexpverticalstatnd(value.expverticalstatnd)
setshowprecomediostatnd(value.precomediostatnd)
setshowtaxahabstatnd(value.taxahabstatnd)
setshowareaurbanizadastatnd(value.areaurbanizadastatnd)
setshowareasruraisstatnd(value.areasruraisstatnd)
setshowvaziosurbanosstatnd(value.vaziosurbanosstatnd)
setshowgrapohabstatnd(value.grapohabstatnd)
setshowterrenosstatnd(value.terrenosstatnd)
setshowimoveisstatnd(value.imoveisstatnd)

setshowlimiteriva(value.limiteriva)
setshowrecorteriva(value.recorteriva)
setshowareapreserriva(value.areapreserriva)
setshowconservacaoriva(value.conservacaoriva)
setshowcursosriva(value.cursosriva)
setshownascentesriva(value.nascentesriva)
setshowareasriscoriva(value.areasriscoriva)
setshowareasriscocontriva(value.areasriscocontriva)
setshowtopografiariva(value.topografiariva)
setshowzoneamentoriva(value.zoneamentoriva)
setshowusovertriva(value.usovertriva)
setshowaeroportosriva(value.aeroportosriva)
setshowdutoviasriva(value.dutoviasriva)
setshowlinhaoriva(value.linhaoriva)
setshowprotculturalriva(value.protculturalriva)
setshowbemcultriva(value.bemcultriva)
setshowbemcultimovelriva(value.bemcultimovelriva)
setshowbemcultnaturalriva(value.bemcultnaturalriva)
setshowdiretrizriva(value.diretrizriva)
setshowtombamentoriva(value.tombamentoriva)
setshowpontosatratriva(value.pontosatratriva)
setshowpontosdetratriva(value.pontosdetratriva)
setshowocupacaoriva(value.ocupacaoriva)
setshowrendariva(value.rendariva)
setshowpmedioriva(value.pmedioriva)
setshowtaxahabriva(value.taxahabriva)
setshowconcoriva(value.concoriva)
setshowvaziosriva(value.vaziosriva)
setshowimoveisanunriva(value.imoveisanunriva)


setshowlimitemacapaic(value.limitemacapaic)
setshowrecortemacapaic(value.recortemacapaic)
setshowvarejomacapaic(value.varejomacapaic)
setshowconcvarejomacapaic(value.concvarejomacapaic)
setshowconcfluxomacapaic(value.concfluxomacapaic)
setshowshoppingsmacapaic(value.shoppingsmacapaic)
setshowescolasmacapaic(value.escolasmacapaic)
setshowhospitaismacapaic(value.hospitaismacapaic)
setshowestacionamentosmacapaic(value.estacionamentosmacapaic)
setshowconcorrentesmacapaic(value.concorrentesmacapaic)
setshowuniversidadesmacapaic(value.universidadesmacapaic)
setshowassaimacapaic(value.assaimacapaic)
setshowrendamacapaic(value.rendamacapaic)
setshowdomiciliosmacapaic(value.domiciliosmacapaic)
setshowpopmacapaic(value.popmacapaic)
setshowimoveismacapaic(value.imoveismacapaic)

setshowcatimoveisriva(value.catimoveisriva)


setshowdomiciliosaureo(value.domiciliosaureo)
setshowempresasaureo(value.empresasaureo)
setshowlocinteresseaureo(value.locinteresseaureo)
setshowmarcasaureo(value.marcasaureo)
setshowpontosaureo(value.pontosaureo)
setshowraio1kmaureo(value.raio1kmaureo)
setshowraio2kmaureo(value.raio2kmaureo)
setshowrendaaureo(value.rendaaureo)
setshowviasaureo(value.viasaureo)
setshowzoneamentoaureo(value.zoneamentoaureo)
setshowpiscininha(value.piscininha)
setshowpiscinao(value.piscinao)

setshowimoveisvendidosemcp(value.imoveisvendidosemcp)
setshowoutrossetoresemcp(value.outrossetoresemcp)
setshowsetorescomimoveisemcp(value.setorescomimoveisemcp)
setshowvendasemcp(value.vendasemcp)

setshowcomerciosaureopet1(value.comerciosaureopet1)
setshowdomiciliosaureopet1(value.domiciliosaureopet1)
setshowempreendimentoaureopet1(value.empreendimentoaureopet1)
setshowlimiteaureopet1(value.limiteaureopet1)
setshowlocalizacaoaureopet1(value.localizacaoaureopet1)
setshowmarcasvareaureopet1(value.marcasvareaureopet1)
setshowr1kmaureopet1(value.r1kmaureopet1)
setshowr2kmaureopet1(value.r2kmaureopet1)
setshowrendaaureopet1(value.rendaaureopet1)
setshowviasacessoaureopet1(value.viasacessoaureopet1)
setshowcomerciosaureopet2(value.comerciosaureopet2)
setshowdomiciliosaureopet2(value.domiciliosaureopet2)
setshowempreendimentoaureopet2(value.empreendimentoaureopet2)
setshowlocalizacaoaureopet2(value.localizacaoaureopet2)
setshowr1kmaureopet2(value.r1kmaureopet2)
setshowr2kmaureopet2(value.r2kmaureopet2)
setshowrendaaureopet2(value.rendaaureopet2)
setshowviasacessoaureopet2(value.viasacessoaureopet2)
setshowzoneamentoaureopet2(value.zoneamentoaureopet2)
setshowhidrologiaaureopet1(value.hidrologiaaureopet1)
setshowtopografiaaureopet1(value.topografiaaureopet1)
setshowhidrologiaaureopet2(value.hidrologiaaureopet2)
setshowtopografiaaureopet2(value.topografiaaureopet2)  


setshowtopografiaacc3(value.topografiaacc3)
setshowzoneamentoacc3(value.zoneamentoacc3)
setshowareapousoacc3(value.areapousoacc3)
setshowrotaaviaoacc3(value.rotaaviaoacc3)
setshowareascontacc3(value.areascontacc3)
setshowdutoviasacc3(value.dutoviasacc3)
setshowestabelecimentosacc3(value.estabelecimentosacc3)
setshowveiculosacc3(value.veiculosacc3)
setshowfluxoveicacc3(value.fluxoveicacc3)
setshowmovimentacaoacc3(value.movimentacaoacc3)
setshowrendaacc3(value.rendaacc3)
setshowpopulacaoacc3(value.populacaoacc3)
setshowdomiciliosacc3(value.domiciliosacc3)
setshowdomialuguelacc3(value.domialuguelacc3)
setshowareaurbaacc3(value.areaurbaacc3)

setshowspacc3(value.spacc3)
setshowguarulhosacc3(value.guarulhosacc3)
setshowtrechoacc3(value.trechoacc3)

setshowaeror2soroaureo(value.aeror2soroaureo)
setshowshoppingr2soroaureo(value.shoppingr2soroaureo)
setshowcondor2soroaureo(value.condor2soroaureo)
setshowdomicilior3soroaureo(value.domicilior3soroaureo)
setshowpopr3soroaureo(value.popr3soroaureo)
setshowvarejor3soroaureo(value.varejor3soroaureo)
setshowindustriar3soroaureo(value.industriar3soroaureo)
setshowdomicilior4soroaureo(value.domicilior4soroaureo)
setshowpopr4soroaureo(value.popr4soroaureo)
setshowvarejor4soroaureo(value.varejor4soroaureo)
setshowindustriar4soroaureo(value.industriar4soroaureo)
setshowdomicilior5soroaureo(value.domicilior5soroaureo)
setshowpopr5soroaureo(value.popr5soroaureo)
setshowvarejor5soroaureo(value.varejor5soroaureo)
setshowindustriar5soroaureo(value.industriar5soroaureo)
setshowsud1petrop1aureo(value.sud1petrop1aureo)
setshowsud2petrop1aureo(value.sud2petrop1aureo)
setshowsud1petrop2aureo(value.sud1petrop2aureo)
setshowsud2petrop2aureo(value.sud2petrop2aureo)
setshowareascontastacetesbtnd(value.areascontastacetesbtnd)
setshowareascontastasgmptnd(value.areascontastasgmptnd)
setshowlimitebairrosstatnd(value.limitebairrosstatnd)
setshowfichasstatnd(value.fichasstatnd)
setshowzoneamentostaeixotamandtnd(value.zoneamentostaeixotamandtnd)
setshowgalpoesjaguariaivaic(value.galpoesjaguariaivaic)

setshowzoneamentocuritibatrply(value.zoneamentocuritibatrply)
setshowpermissaocuritibatrply(value.permissaocuritibatrply)
setshowterrenoscuritibatrply(value.terrenoscuritibatrply)
setshowtopografiacuritibatrply(value.topografiacuritibatrply)
setshowlimitesambcuritibatrply(value.limitesambcuritibatrply)
setshowhidrografiacuritibatrply(value.hidrografiacuritibatrply)
setshowservidaocuritibatrply(value.servidaocuritibatrply)
setshowterrenospotenciascuritibatrply(value.terrenospotenciascuritibatrply)
setshowcrescimentocuritibatrply(value.crescimentocuritibatrply)
setshowdadossocioscuritibatrply(value.dadossocioscuritibatrply)
setshowpontosdeinteressecuritibatrply(value.pontosdeinteressecuritibatrply)


    let lastChecked = null;

    if (value.terrenoslivre) {
    }

    if (value.limite) {
      setKmlLPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          ".kmz"
      );
      lastChecked = "limite";
    }

    if (value.zoneamento) {
      setKmlPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_1_" +
          "Zoneamento" +
          ".kmz"
      );
      lastChecked = "zoneamento";
    }
    if (value.topografia) {
      setTopografiaPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_5_" +
          "topografia" +
          ".kmz"
      );
      lastChecked = "topografia";
    }
    if (value.hidrografia) {
      setHidrografiaPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_7_" +
          "hidrografia" +
          ".kmz"
      );
      lastChecked = "hidrografia";
      console.log("hidrografia");
    }
    if (value.limitesa) {
      setLimitesaPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_6_" +
          "limites_ambientais" +
          ".kmz"
      );
      lastChecked = "limitesa";
    }
    if (value.permissao) {
      setPermissaoPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_2_" +
          "Permissao" +
          ".kmz"
      );
      lastChecked = "permissao";
    }
    if (value.dados) {
      setDadosPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_11_" +
          "dados_socioeconomicos" +
          ".kmz"
      );
      lastChecked = "dados";
    }
    if (value.fluxo) {
      setFluxoPath(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_14_" +
          "pontos_de_interesse" +
          ".kmz"
      );
      lastChecked = "fluxo";
    }
    if (value.thej) {
      setTheJoyPath("./camadas/SP/thej/TheJoy" + municipio.label + ".kmz");
      lastChecked = "thej";
    }

    if (value.competidorestj) {
      setCompetidores(
        "./camadas/SP/thej/TheJoy" + municipio.label + "_Competidores" + ".kmz"
      );
      lastChecked = "competidorestj";
    }

    if (value.areastj) {
      console.log("oareas");
      setAreas(
        "./camadas/SP/thej/TheJoy" +
          municipio.label +
          "_Area_Indicadas" +
          ".kmz"
      );
      console.log(
        "./camadas/SP/thej/TheJoy" +
          municipio.label +
          "_Area_Indicadas" +
          ".kmz"
      );
      lastChecked = "areastj";
    }

    if (value.condominiostj) {
      console.log("oi");
      setCondominios(
        "./camadas/SP/thej/TheJoy" + municipio.label + "_Condominios" + ".kmz"
      );
      lastChecked = "condominiostj";
    }

    if (value.influenciatj) {
      console.log("oi");
      setInfluencia(
        "./camadas/SP/thej/TheJoy" +
          municipio.label +
          "_Area_Influencia_Competidores" +
          ".kmz"
      );
      lastChecked = "influenciatj";
    }

    if (value.pontosfluxotj) {
      console.log("oi");
      setPontosFluxo(
        "./camadas/SP/thej/TheJoy" + municipio.label + "_Pontos_Fluxo" + ".kmz"
      );
      lastChecked = "pontosfluxotj";
    }

    if (value.imoveis) {
      console.log("oi");
      setImoveis(
        "./camadas/SP/thej/TheJoy" + municipio.label + "_Imoveis" + ".kmz"
      );
      lastChecked = "imoveis";
    }

    //chapadao
    if (value.c1) {
      setC1("./camadas/SP/thej/TheJoyChapadao_Competidores Diretos" + ".kmz");
      lastChecked = "c1";
    }

    if (value.c2) {
      setC2(
        "./camadas/SP/thej/TheJoyChapadao_Competidores_Escolas_Idiomas" + ".kmz"
      );
      lastChecked = "c2";
    }

    if (value.c3) {
      setC3(
        "./camadas/SP/thej/TheJoyChapadao_Competidores_Escolas_Infantis" +
          ".kmz"
      );
      lastChecked = "c3";
    }

    if (value.c4) {
      setC4("./camadas/SP/thej/TheJoyChapadao_Escolas" + ".kmz");
      lastChecked = "c4";
    }

    if (value.c5) {
      setC5("./camadas/SP/thej/TheJoyChapadao_Hospitais" + ".kmz");
      lastChecked = "c5";
    }

    if (value.c6) {
      setC6("./camadas/SP/thej/TheJoyChapadao_Imovel Indicado" + ".kmz");
      lastChecked = "c6";
    }

    if (value.c7) {
      setC7("./camadas/SP/thej/TheJoyChapadao_Mercados" + ".kmz");
      lastChecked = "c7";
    }

    if (value.c8) {
      setC8("./camadas/SP/thej/TheJoyChapadao_Pracas e Parques" + ".kmz");
      lastChecked = "c8";
    }

    if (value.c9) {
      setC9("./camadas/SP/thej/TheJoyChapadao_Principais Vias" + ".kmz");
      lastChecked = "c9";
    }

    if (value.c10) {
      setC10("./camadas/SP/thej/TheJoyChapadao_Raio 1km Competidores" + ".kmz");
      lastChecked = "c10";
    }

    if (value.c11) {
      setC11("./camadas/SP/thej/TheJoyChapadao_Raio 2km" + ".kmz");
      lastChecked = "c11";
    }

    if (value.c12) {
      setC12("./camadas/SP/thej/TheJoyChapadao_Restaurantes" + ".kmz");
      lastChecked = "c12";
    }

    if (value.c13) {
      setC13("./camadas/SP/thej/TheJoyChapadao_Setores Criancas 0-5" + ".kmz");
      lastChecked = "c13";
    }

    if (value.c14) {
      setC14(
        "./camadas/SP/thej/TheJoyChapadao_Setores Renda Familiar" + ".kmz"
      );
      lastChecked = "c14";
    }

    if (value.c15) {
      setC15("./camadas/SP/thej/TheJoyChapadao_Teatros" + ".kmz");

      lastChecked = "c15";
    }

    if (value.c16) {
      setC16("./camadas/SP/thej/TheJoyChapadao_Clubes e Academias" + ".kmz");
      lastChecked = "c16";
    }

    if (value.imoveis200) {
      console.log("imoveis200k");
      setImoveis200lk("./camadas/SP/luka/Imoveis200.kmz");
      lastChecked = "imoveis200";
    }

    if (value.setoreslk) {
      setSetoreslk("./camadas/SP/luka/Setores_Censitarios.kmz");
      lastChecked = "setoreslk";
    }

    if (value.raio1000lk) {
      setraio1000lk("./camadas/SP/luka/Raio1000m.kmz");
      lastChecked = "raio1000lk";
    }

    if (value.raio500lk) {
      setraio500lk("./camadas/SP/luka/Raio500m.kmz");
      lastChecked = "raio500lk";
    }

    if (value.viaslk) {
      setviaslk("./camadas/SP/luka/Vias.kmz");
      lastChecked = "viaslk";
    }

    if (value.competidoreslk) {
      setcompetidoreslk("./camadas/SP/luka/Farmacias_Competidores.kmz");
      lastChecked = "competidoreslk";
    }

    if (value.hospitaislk) {
      sethospitaislk("./camadas/SP/luka/Hospitais.kmz");
      lastChecked = "hospitaislk";
    }

    if (value.pracaslk) {
      setpracaslk("./camadas/SP/luka/Pracas_parques.kmz");
      lastChecked = "pracaslk";
    }

    if (value.mercadoslk) {
      setmercadoslk("./camadas/SP/luka/Mercados.kmz");
      lastChecked = "mercadoslk";
    }

    if (value.restauranteslk) {
      setrestauranteslk("./camadas/SP/luka/Restaurantes.kmz");
      lastChecked = "restauranteslk";
    }

    if (value.condominioslk) {
      setcondominioslk("./camadas/SP/luka/Condominios.kmz");
      lastChecked = "condominioslk";
    }

    if (value.farmacialk) {
      setfarmacialk("./camadas/SP/luka/Farmacia.kmz");
      lastChecked = "farmacialk";
    }

    //griffon

    if (value.areaverdegl) {
      setareaverdegl(
        "./camadas/GEN/" +
          estado.value +
          "/" +
          municipio.label +
          "/AreaVerde.kmz"
      );
      lastChecked = "areaverdegl";
    }

    if (value.municipiogl) {
      setmunicipiogl(
        "./camadas/GEN/" +
          estado.value +
          "/" +
          municipio.label +
          "/Municipio.kmz"
      );
      lastChecked = "municipiogl";
    }

    if (value.condominiogooglegl) {
      setcondominiogooglegl(
        "./camadas/GEN/" +
          estado.value +
          "/" +
          municipio.label +
          "/CondominiosGoogle.kmz"
      );
      lastChecked = "condominiogooglegl";
    }

    if (value.condominiograpgl) {
      setcondominiograpgl(
        "./camadas/GEN/" +
          estado.value +
          "/" +
          municipio.label +
          "/CondominiosGraprohab.kmz"
      );
      lastChecked = "condominiograpgl";
    }

    if (value.rendagl) {
      setrendagl(
        "./camadas/GEN/" + estado.value + "/" + municipio.label + "/Renda.kmz"
      );
      lastChecked = "rendagl";
    }

    // campinas alea

    if (value.servidao) {
      setservidao(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_8_" +
          "Servidao" +
          ".kmz"
      );
      lastChecked = "servidao";
    }

    if (value.crescimento) {
      setcrescimento(
        "./camadas/" +
          estado.value +
          "/" +
          municipio.label +
          "/" +
          municipio.label +
          "_10_" +
          "Crescimento" +
          ".kmz"
      );
      lastChecked = "crescimento";
    }

    // tj paulinia
    if (value.areasinfluenciatjp) {
      setareasinfluenciatjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areas de influencia.kmz"
      );
      lastChecked = "areasinfluenciatjp";
    }

    if (value.areasindicadastjp) {
      setareasindicadastjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areas Indicadas.kmz"
      );
      lastChecked = "areasindicadastjp";
    }

    if (value.compdiretostjp) {
      setcompdiretostjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores diretos.kmz"
      );
      lastChecked = "compdiretostjp";
    }

    if (value.compindiretostjp) {
      setcompindiretostjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores indiretos.kmz"
      );
      lastChecked = "compindiretostjp";
    }

    if (value.condominiostjp) {
      setcondominiostjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Condominios.kmz"
      );
      lastChecked = "condominiostjp";
    }

    if (value.imoveistjp) {
      setimoveistjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Imoveis.kmz"
      );
      lastChecked = "imoveistjp";
    }

    if (value.limitetjp) {
      setlimitetjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Limites do municipio.kmz"
      );
      lastChecked = "limitetjp";
    }

    if (value.pontosfluxotjp) {
      setpontosfluxotjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Pontos de Fluxo.kmz"
      );
      lastChecked = "pontosfluxotjp";
    }

    if (value.escolastjp) {
      setescolastjp(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_The Joy Paulinia.kmz"
      );
      lastChecked = "escolastjp";
    }

    //tj americana

    if (value.areasinfluenciatja) {
      setareasinfluenciatja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Area de influencia The Joy Americana.kmz"
      );
      console.log(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Area de influencia The Joy Americana.kmz"
      );
      console.log(areasinfluenciatja);

      lastChecked = "areasinfluenciatja";
    }

    if (value.areasindicadasinfantiltja) {
      setareasindicadasinfantiltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areas indicadas para educacao infantil.kmz"
      );
      lastChecked = "areasindicadasinfantiltja";
    }

    if (value.areasindicadasfundamentaltja) {
      setareasindicadasfundamentaltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areas indicadas para ensino fundamental.kmz"
      );
      lastChecked = "areasindicadasfundamentaltja";
    }

    if (value.compdiretosinfantiltja) {
      setcompdiretosinfantiltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores diretos Educacao Infantil.kmz"
      );
      lastChecked = "compdiretosinfantiltja";
    }

    if (value.compdiretosfundamentaltja) {
      setcompdiretosfundamentaltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores diretos Ensino Fundamental.kmz"
      );
      lastChecked = "compdiretosfundamentaltja";
    }

    if (value.compindiretosinfantiltja) {
      setcompindiretosinfantiltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores indiretos Educacao Infantil.kmz"
      );
      lastChecked = "compindiretosinfantiltja";
    }

    if (value.compindiretosfundamentaltja) {
      setcompindiretosfundamentaltja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Competidores indiretos Ensino Fundamental.kmz"
      );
      lastChecked = "compindiretosfundamentaltja";
    }

    if (value.concentracao0_5tja) {
      setconcentracao0_5tja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Concentracao de criancas de 0 a 5 anos.kmz"
      );
      lastChecked = "concentracao0_5tja";
    }

    if (value.concentracao6_10tja) {
      setconcentracao6_10tja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Concentracao criancas de 6 a 10 anos.kmz"
      );
      lastChecked = "concentracao6_10tja";
    }

    if (value.escolatja) {
      setescolatja(
        "./camadas/" + estado.value + "/thej/TheJoy" + municipio.label + ".kmz"
      );
      lastChecked = "escolatja";
    }

    if (value.limitetja) {
      setlimitetja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Limite.kmz"
      );
      lastChecked = "limitetja";
    }

    if (value.pontosfluxotja) {
      setpontosfluxotja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Pontos de fluxo.kmz"
      );
      lastChecked = "pontosfluxotja";
    }

    if (value.rendatja) {
      setrendatja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Renda.kmz"
      );
      lastChecked = "rendatja";
    }

    if (value.rendaareainfantiltja) {
      setareainfantilseparadatja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areaseparadafunda.kmz"
      );
      //setrendatja('./camadas/' + estado.value + '/thej/TheJoy' + municipio.label + '_Renda.kmz');
      lastChecked = "rendaareainfantiltja";
    }

    if (value.rendaareafundatja) {
      setareafundaseparadatja(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_Areaseparadainfantil.kmz"
      );

      lastChecked = "rendaareafundatja";
    }

    // HIDS

    if (value.ashidsgu) {
      setashidsgu("./camadas/" + estado.value + "/gurgel/AreaHIDS.kmz");
      lastChecked = "ashidsgu";
    }

    if (value.aspidsgu) {
      setaspidsgu("./camadas/" + estado.value + "/gurgel/AreasPIDS.kmz");
      lastChecked = "aspidsgu";
    }

    if (value.apidsgu) {
      setapidsgu("./camadas/" + estado.value + "/gurgel/AreaPIDS.kmz");
      lastChecked = "apidsgu";
    }

    if (value.bempresasgu) {
      setbempresasgu(
        "./camadas/" + estado.value + "/gurgel/BasedeEmpresas.kmz"
      );
      lastChecked = "bempresasgu";
    }

    if (value.campusdagu) {
      setcampusdagu(
        "./camadas/" + estado.value + "/gurgel/CampusdaUnicamp.kmz"
      );
      lastChecked = "campusdagu";
    }

    if (value.campusgu) {
      setcampusgu("./camadas/" + estado.value + "/gurgel/CampusUnicamp.kmz");
      lastChecked = "campusgu";
    }

    if (value.fzdarggu) {
      setfzdarggu("./camadas/" + estado.value + "/gurgel/FazendaArgentina.kmz");
      lastChecked = "fzdarggu";
    }

    if (value.ferroviasgu) {
      setferroviasgu("./camadas/" + estado.value + "/gurgel/Ferrovias.kmz");
      lastChecked = "ferroviasgu";
    }

    if (value.ictsgu) {
      setictsgu("./camadas/" + estado.value + "/gurgel/ICTs.kmz");
      lastChecked = "ictsgu";
    }

    if (value.parquesgu) {
      setparquesgu(
        "./camadas/" + estado.value + "/gurgel/ParquesTecnologicos.kmz"
      );
      lastChecked = "parquesgu";
    }

    if (value.riosgu) {
      setriosgu("./camadas/" + estado.value + "/gurgel/Rios.kmz");
      lastChecked = "riosgu";
    }

    if (value.viasgu) {
      setviasgu("./camadas/" + estado.value + "/gurgel/Vias.kmz");
      lastChecked = "viasgu";
    }

    //tjg

    if (value.areasindicadasinfantiltjg) {
      setareasindicadastjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_AreasIndicadasEducacaoInfantil.kmz"
      );
      lastChecked = "areasindicadasinfantiltjg";
    }
    if (value.areasindicadasfundamentaltjg) {
      setareasindicadasfundamentaltjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_AreasIndicadasEnsinoFundamental.kmz"
      );
      lastChecked = "areasindicadasfundamentaltjg";
    }
    if (value.compdiretosinfantiltjg) {
      setcompdiretosinfantiltjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_CompetidoresDiretosEducacaoInfantil.kmz"
      );
      lastChecked = "compdiretosinfantiltjg";
    }
    if (value.compdiretosfundamentaltjg) {
      setcompdiretosfundamentaltjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_CompetidoresDiretosEnsinoFundamental.kmz"
      );
      lastChecked = "compdiretosfundamentaltjg";
    }
    if (value.compindiretosinfantiltjg) {
      setcompindiretosinfantiltjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_CompetidoresIndiretosEducacaoInfantil.kmz"
      );
      lastChecked = "compindiretosinfantiltjg";
    }
    if (value.compindiretosfundamentaltjg) {
      setcompindiretosfundamentaltjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_CompetidoresIndiretosEnsinoFundamental.kmz"
      );
      lastChecked = "compindiretosfundamentaltjg";
    }
    if (value.concentracao0_5tjg) {
      setconcentracao0_5tjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_ConcentracaoCriancas0_5anos.kmz"
      );
      lastChecked = "concentracao0_5tjg";
    }
    if (value.concentracao6_10tjg) {
      setconcentracao6_10tjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_ConcentracaoCriancas6_10anos.kmz"
      );
      lastChecked = "concentracao6_10tjg";
    }
    if (value.familiastjg) {
      setfamiliastjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_ConcentracaoFamilias.kmz"
      );
      lastChecked = "familiastjg";
    }
    if (value.rendatjg) {
      setrendatjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_ConcentracaoRenda.kmz"
      );
      lastChecked = "rendatjg";
    }
    if (value.imovelpretendidotjg) {
      setimovelpretendidotjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_ImovelPretendido.kmz"
      );
      lastChecked = "imovelpretendidotjg";
    }
    if (value.limitetjg) {
      setlimitetjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_LimiteUrbanoGuarulhos.kmz"
      );
      lastChecked = "limitetjg";
    }

    if (value.pontosfluxotjg) {
      setpontosfluxotjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_PontosFluxo.kmz"
      );
      lastChecked = "pontosfluxotjg";
    }
    if (value.raioinfluenciatjg) {
      setraioinfluenciatjg(
        "./camadas/" +
          estado.value +
          "/thej/TheJoy" +
          municipio.label +
          "_RaioInfluencia2km.kmz"
      );
      lastChecked = "raioinfluenciatjg";
    }

    if (value.edumap) {
      setEdumap("./camadas/" + estado.value + "/edu/PizzaEduAmato.kmz");
      lastChecked = "edumap";
    }

    //crca

    if (value.bairroscrca) {
      setbairroscrca("./camadas/" + estado.value + "/crca/BairrosCRCA.kmz");
      lastChecked = "bairroscrca";
    }
    if (value.dadossociosfamiliascampinascrca) {
      setdadossociosfamiliascampinascrca(
        "./camadas/" + estado.value + "/crca/CampinasFamilias.kmz"
      );
      lastChecked = "dadossociosfamiliascampinascrca";
    }

    if (value.dadossociosrendacampinascrca) {
      setdadossociosrendacampinascrca(
        "./camadas/" + estado.value + "/crca/CampinasRenda.kmz"
      );
      lastChecked = "dadossociosrendacampinascrca";
    }

    if (value.zoneamentocampinascrca) {
      setzoneamentocampinascrca(
        "./camadas/" + estado.value + "/crca/CampinasZoneamento.kmz"
      );
      lastChecked = "zoneamentocampinascrca";
    }

    if (value.limitecampinascrca) {
      setlimitecampinascrca(
        "./camadas/" + estado.value + "/crca/CampinasLimiteMunicipal.kmz"
      );
      lastChecked = "limitecampinascrca";
    }

    if (value.dadosporbairro) {
      setdadosporbairro(
        "./camadas/" + estado.value + "/crca/DadosporBairro.kmz"
      );
      lastChecked = "dadosporbairro";
    }

    //sjbv
    if (value.sjbv) {
      setsjbv("./camadas/GEN/" + estado.value + "/sjbv/sjbv.kmz");
      lastChecked = "sjbv";
    }

    if (value.sjbv1zoneamento) {
      setsjbv1zoneamento(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv1zoneamento.kmz"
      );
      lastChecked = "sjbv1zoneamento";
    }

    if (value.sjbv2permissao) {
      setsjbv2permissao(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv2permissao.kmz"
      );
      lastChecked = "sjbv2permissao";
    }

    if (value.sjbv3terrenos) {
      setsjbv3terrenos(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv3terrenos.kmz"
      );
      lastChecked = "sjbv3terrenos";
    }

    if (value.sjbv5topografia) {
      setsjbv5topografia(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv5topografia.kmz"
      );
      lastChecked = "sjbv5topografia";
    }

    if (value.sjbv6limitesa) {
      setsjbv6limitesa(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv6limitesa.kmz"
      );
      lastChecked = "sjbv6limitesa";
    }

    if (value.sjbv7hidrografia) {
      setsjbv7hidrografia(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv7hidrografia.kmz"
      );
      lastChecked = "sjbv7hidrografia";
    }

    if (value.sjbv8servidao) {
      setsjbv8servidao(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv8servidao.kmz"
      );
      lastChecked = "sjbv8servidao";
    }

    if (value.sjbv9terrenosp) {
      setsjbv9terrenosp(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv9terrenosp.kmz"
      );
      lastChecked = "sjbv9terrenosp";
    }

    if (value.sjbv10crescimento) {
      setsjbv10crescimento(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv10crescimento.kmz"
      );
      lastChecked = "sjbv10crescimento";
    }

    if (value.sjbv11dadossocios) {
      setsjbv11dadossocios(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv11dadossocios.kmz"
      );
      lastChecked = "sjbv11dadossocios";
    }

    if (value.sjbv12distviario) {
      setsjbv12distviario(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv12distviario.kmz"
      );
      lastChecked = "sjbv12distviario";
    }

    if (value.sjbv13malha) {
      setsjbv13malha("./camadas/GEN/" + estado.value + "/sjbv/sjbv13malha.kmz");
      lastChecked = "sjbv13malha";
    }

    if (value.sjbv14pontos) {
      setsjbv14pontos(
        "./camadas/GEN/" + estado.value + "/sjbv/sjbv14pontos.kmz"
      );
      lastChecked = "sjbv14pontos";
    }

    if (value.rp) {
      setrp("./camadas/SP/miada/rp.kmz");
      lastChecked = "rp";
    }

    if (value.rp1zoneamento) {
      setrp1zoneamento("./camadas/SP/miada/rp1zoneamento.kmz");
      lastChecked = "rp1zoneamento";
    }

    if (value.rp3terrenos) {
      setrp3terrenos("./camadas/SP/miada/rp3terrenos.kmz");
      lastChecked = "rp3terrenos";
    }

    if (value.rp5topografia) {
      setrp5topografia("./camadas/SP/miada/rp5topografia.kmz");
      lastChecked = "rp5topografia";
    }

    if (value.rp6limitesambientais) {
      setrp6limitesambientais("./camadas/SP/miada/rp6limitesambientais.kmz");
      lastChecked = "rp6limitesambientais";
    }

    if (value.rp7hidrografia) {
      setrp7hidrografia("./camadas/SP/miada/rp7hidrografia.kmz");
      lastChecked = "rp7hidrografia";
    }

    if (value.rp8servidao) {
      setrp8servidao("./camadas/SP/miada/rp8servidao.kmz");
      lastChecked = "rp8servidao";
    }

    if (value.rp10crescimento) {
      setrp10crescimento("./camadas/SP/miada/rp10crescimento.kmz");
      lastChecked = "rp10crescimento";
    }

    if (value.rp11dadossocioeconomicosrenda) {
      setrp11dadossocioeconomicosrenda(
        "./camadas/SP/miada/rp11dadossocioeconomicosrenda.kmz"
      );
      lastChecked = "rp11dadossocioeconomicosrenda";
    }

    if (value.rp12dadossocioeconomicosfamilias) {
      setrp12dadossocioeconomicosfamilias(
        "./camadas/SP/miada/rp12dadossocioeconomicosfamilias.kmz"
      );
      lastChecked = "rp12dadossocioeconomicosfamilias";
    }

    if (value.abrangenciacrascrca2) {
      setabrangenciacrascrca2("./camadas/SP/crca2/abrangenciacrascrca2.kmz");
      lastChecked = "abrangenciacrascrca2";
    }

    if (value.assistenciateccrca2) {
      setassistenciateccrca2("./camadas/SP/crca2/assistenciateccrca2.kmz");
      lastChecked = "assistenciateccrca2";
    }

    if (value.bairroscrca2) {
      setbairroscrca2("./camadas/SP/crca2/bairroscrca2.kmz");
      lastChecked = "bairroscrca2";
    }
    if (value.campinasdadossociosrendacrca2) {
      setcampinasdadossociosrendacrca2(
        "./camadas/SP/crca2/campinasdadossociosrendacrca2.kmz"
      );
      lastChecked = "campinasdadossociosrendacrca2";
    }
    if (value.campinasdadossociosfamiliascrca2) {
      setcampinasdadossociosfamiliascrca2(
        "./camadas/SP/crca2/campinasdadossociosfamiliascrca2.kmz"
      );
      lastChecked = "campinasdadossociosfamiliascrca2";
    }
    if (value.campinaslimitecrca2) {
      setcampinaslimitecrca2("./camadas/SP/crca2/campinaslimitecrca2.kmz");
      lastChecked = "campinaslimitecrca2";
    }
    if (value.campinaszoneamentocrca2) {
      setcampinaszoneamentocrca2(
        "./camadas/SP/crca2/campinaszoneamentocrca2.kmz"
      );
      lastChecked = "campinaszoneamentocrca2";
    }
    if (value.comercioalimentoscrca2) {
      setcomercioalimentoscrca2(
        "./camadas/SP/crca2/comercioalimentoscrca2.kmz"
      );
      lastChecked = "comercioalimentoscrca2";
    }
    if (value.dadosporbairrocrca2) {
      setdadosporbairrocrca2("./camadas/SP/crca2/dadosporbairrocrca2.kmz");
      lastChecked = "dadosporbairrocrca2";
    }
    if (value.impressao3dcrca2) {
      setimpressao3dcrca2("./camadas/SP/crca2/impressao3dcrca2.kmz");
      lastChecked = "impressao3dcrca2";
    }
    if (value.lojaderoupascrca2) {
      setlojaderoupascrca2("./camadas/SP/crca2/lojaderoupascrca2.kmz");
      lastChecked = "lojaderoupascrca2";
    }
    if (value.lojasartesanatocrca2) {
      setlojasartesanatocrca2("./camadas/SP/crca2/lojasartesanatocrca2.kmz");
      lastChecked = "lojasartesanatocrca2";
    }
    if (value.oficinasmecanicascrca2) {
      setoficinasmecanicascrca2(
        "./camadas/SP/crca2/oficinasmecanicascrca2.kmz"
      );
      lastChecked = "oficinasmecanicascrca2";
    }
    if (value.orgcivilcrca2) {
      setorgcivilcrca2("./camadas/SP/crca2/orgs2.kml");
      lastChecked = "orgcivilcrca2";
    }
    if (value.padariasconfeitariascrca2) {
      setpadariasconfeitariascrca2(
        "./camadas/SP/crca2/padariasconfeitariascrca2.kmz"
      );
      lastChecked = "padariasconfeitariascrca2";
    }
    if (value.petshopscrca2) {
      setpetshopscrca2("./camadas/SP/crca2/petshopscrca2.kmz");
      lastChecked = "petshopscrca2";
    }
    if (value.regioesvulcrca2) {
      setregioesvulcrca2("./camadas/SP/crca2/regioesvulcrca2.kmz");
      lastChecked = "regioesvulcrca2";
    }

    if (value.salaobelezacrc2) {
      setsalaobelezacrc2("./camadas/SP/crca2/salaobelezacrc2.kmz");
      lastChecked = "salaobelezacrc2";
    }

    if (value.educomp) {
      seteducomp("./camadas/SP/edu/Competidores.kmz");
      lastChecked = "educomp";
    }
    if (value.edufamilia) {
      setedufamilia("./camadas/SP/edu/Familias.kmz");
      lastChecked = "edufamilia";
    }
    if (value.edurenda) {
      setedurenda("./camadas/SP/edu/Renda.kmz");
      lastChecked = "edurenda";
    }
    if (value.eduimoveis) {
      seteduimoveis("./camadas/SP/edu/ImoveisEdu.kmz");
      lastChecked = "eduimoveis";
    }

    if (value.areasindicadasinfantiltjd) {
      setareasindicadasinfantiltjd(
        "./camadas/SP/thej/Dracena/areasindicadasinfantiltjd.kmz"
      );
      lastChecked = "areasindicadasinfantiltjd";
    }

    if (value.areasindicadasfundamentaltjd) {
      setareasindicadasfundamentaltjd(
        "./camadas/SP/thej/Dracena/areasindicadasfundamentaltjd.kmz"
      );
      lastChecked = "areasindicadasfundamentaltjd";
    }

    if (value.compdiretosinfantiltjd) {
      setcompdiretosinfantiltjd(
        "./camadas/SP/thej/Dracena/compdiretosinfantiltjd.kmz"
      );
      lastChecked = "compdiretosinfantiltjd";
    }

    if (value.compdiretosfundamentaltjd) {
      setcompdiretosfundamentaltjd(
        "./camadas/SP/thej/Dracena/compdiretosfundamentaltjd.kmz"
      );
      lastChecked = "compdiretosfundamentaltjd";
    }

    if (value.compindiretosinfantiltjd) {
      setcompindiretosinfantiltjd(
        "./camadas/SP/thej/Dracena/compindiretosinfantiltjd.kmz"
      );
      lastChecked = "compindiretosinfantiltjd";
    }

    if (value.compindiretosfundamentaltjd) {
      setcompindiretosfundamentaltjd(
        "./camadas/SP/thej/Dracena/compindiretosfundamentaltjd.kmz"
      );
      lastChecked = "compindiretosfundamentaltjd";
    }

    if (value.concentracaocriancasinfantiltgd) {
      setconcentracaocriancasinfantiltgd(
        "./camadas/SP/thej/Dracena/concentracaocriancasinfantiltgd.kmz"
      );
      lastChecked = "concentracaocriancasinfantiltgd";
    }

    if (value.concentracaocriancasfundtgd) {
      setconcentracaocriancasfundtgd(
        "./camadas/SP/thej/Dracena/concentracaocriancasfundtgd.kmz"
      );
      lastChecked = "concentracaocriancasfundtgd";
    }

    if (value.familiastjd) {
      setfamiliastjd("./camadas/SP/thej/Dracena/familiastjd.kmz");
      lastChecked = "familiastjd";
    }

    if (value.limitetjd) {
      setlimitetjd("./camadas/SP/thej/Dracena/limitetjd.kmz");
      lastChecked = "limitetjd";
    }

    if (value.rendatjd) {
      setrendatjd("./camadas/SP/thej/Dracena/rendatjd.kmz");
      lastChecked = "rendatjd";
    }

    if (value.pontosfluxotjd) {
      setpontosfluxotjd("./camadas/SP/thej/Dracena/pontosfluxotjd.kmz");
      lastChecked = "pontosfluxotjd";
    }

    if (value.raioinfluenciatjd) {
      setraioinfluenciatjd("./camadas/SP/thej/Dracena/The Joy Dracena.kmz");
      lastChecked = "raioinfluenciatjd";
    }
    if (value.legalizacaolavras) {
      setlegalizacaolavras("./camadas/GEN/MG/legalizacaolavras.kmz");
      lastChecked = "legalizacaolavras";
    }

    if (value.academiasmanapoke) {
      setacademiasmanapoke("./camadas/SC/Academias.kmz");
      lastChecked = "academiasmanapoke";
    }

    if (value.centrosempresariasmanapoke) {
      setcentrosempresariasmanapoke("./camadas/SC/CentrosEmpresariais.kmz");
      lastChecked = "centrosempresariasmanapoke";
    }

    if (value.centrosesportivosmanapoke) {
      setcentrosesportivosmanapoke("./camadas/SC/CentrosEsportivos.kmz");
      lastChecked = "centrosesportivosmanapoke";
    }

    if (value.comerciomanapoke) {
      setcomerciomanapoke("./camadas/SC/Comercio.kmz");
      lastChecked = "comerciomanapoke";
    }

    if (value.compcomidajaponesamanapoke) {
      setcompcomidajaponesamanapoke(
        "./camadas/SC/CompetidoresComidaJaponesa.kmz"
      );
      lastChecked = "compcomidajaponesamanapoke";
    }

    if (value.compcomidasaudavelmanapoke) {
      setcompcomidasaudavelmanapoke(
        "./camadas/SC/CompetidoresComidaSaudavel.kmz"
      );
      lastChecked = "compcomidasaudavelmanapoke";
    }

    if (value.imoveiscomerciaismanapoke) {
      setimoveiscomerciaismanapoke("./camadas/SC/ImoveisComerciais.kmz");
      lastChecked = "imoveiscomerciaismanapoke";
    }

    if (value.pontosculturaismanapoke) {
      setpontosculturaismanapoke("./camadas/SC/PontosCulturais.kmz");
      lastChecked = "pontosculturaismanapoke";
    }

    if (value.pontoseducacaomanapoke) {
      setpontoseducacaomanapoke("./camadas/SC/PontosEducacao.kmz");
      lastChecked = "pontoseducacaomanapoke";
    }

    if (value.rendamanapoke) {
      setrendamanapoke("./camadas/SC/RendaBalnearioCamburiu.kmz");
      lastChecked = "rendamanapoke";
    }

    if (value.zoneamentolavras) {
      setzoneamentolavras("./camadas/GEN/MG/zoneamentolavras.kmz");
      lastChecked = "zoneamentolavras";
    }

    if (value.permissaolavras) {
      setpermissaolavras("./camadas/GEN/MG/permissaolavras.kmz");
      lastChecked = "permissaolavras";
    }

    if (value.terrenoslavras) {
      setterrenoslavras("./camadas/GEN/MG/terrenoslavras.kmz");
      lastChecked = "terrenoslavras";
    }

    if (value.topografialavras) {
      settopografialavras("./camadas/GEN/MG/topografialavras.kmz");
      lastChecked = "topografialavras";
    }

    if (value.applavras) {
      setapplavras("./camadas/GEN/MG/applavras.kmz");
      lastChecked = "applavras";
    }

    if (value.hidrografialavras) {
      sethidrografialavras("./camadas/GEN/MG/hidrografialavras.kmz");
      lastChecked = "hidrografialavras";
    }

    if (value.servidaolavras) {
      setservidaolavras("./camadas/GEN/MG/servidaolavras.kmz");
      lastChecked = "servidaolavras";
    }

    if (value.terrenosinteresselavras) {
      setterrenosinteresselavras(
        "./camadas/GEN/MG/terrenosinteresselavras.kmz"
      );
      lastChecked = "terrenosinteresselavras";
    }

    if (value.crescimentolavras) {
      setcrescimentolavras("./camadas/GEN/MG/crescimentolavras.kmz");
      lastChecked = "crescimentolavras";
    }

    if (value.dadossocioslavras) {
      setdadossocioslavras("./camadas/GEN/MG/dadossocioslavras.kmz");
      lastChecked = "dadossocioslavras";
    }

    if (value.distviariolavras) {
      setdistviariolavras("./camadas/GEN/MG/distviariolavras.kmz");
      lastChecked = "distviariolavras";
    }

    if (value.disturbanalavras) {
      setdisturbanalavras("./camadas/GEN/MG/disturbanalavras.kmz");
      lastChecked = "disturbanalavras";
    }

    if (value.pontoslavras) {
      setpontoslavras("./camadas/GEN/MG/pontoslavras.kmz");
      lastChecked = "pontoslavras";
    }

    if (value.areaspotenciaismanapoke) {
      setareaspotenciaismanapoke("./camadas/SC/areaspotenciaismanapoke.kmz");
      lastChecked = "areaspotenciaismanapoke";
    }

    if (value.clubesbs) {
      setclubesbs("./camadas/PR/buddahspa/clubesbs.kmz");
      lastChecked = "clubesbs";
    }

    if (value.familiasbs) {
      setfamiliasbs("./camadas/PR/buddahspa/familiasbs.kmz");
      lastChecked = "familiasbs";
    }

    if (value.lojasdepartamentobs) {
      setlojasdepartamentobs("./camadas/PR/buddahspa/lojasdepartamentobs.kmz");
      lastChecked = "lojasdepartamentobs";
    }

    if (value.parquesbs) {
      setparquesbs("./camadas/PR/buddahspa/parquesbs.kmz");
      lastChecked = "parquesbs";
    }

    if (value.pontosbuddhaspabs) {
      setpontosbuddhaspabs("./camadas/PR/buddahspa/pontosbuddhapsa.kmz");
      lastChecked = "pontosbuddhaspabs";
    }

    if (value.rendabs) {
      setrendabs("./camadas/PR/buddahspa/rendabs.kmz");
      lastChecked = "rendabs";
    }

    if (value.restaurantesbs) {
      setrestaurantesbs("./camadas/PR/buddahspa/restaurantesbs.kmz");
      lastChecked = "restaurantesbs";
    }

    if (value.shoppingsbs) {
      setshoppingsbs("./camadas/PR/buddahspa/shoppingsbs.kmz");
      lastChecked = "shoppingsbs";
    }

    if (value.supermercadosbs) {
      setsupermercadosbs("./camadas/PR/buddahspa/supermercadosbs.kmz");
      lastChecked = "supermercadosbs";
    }

    if (value.estacionamentosbs) {
      setestacionamentosbs("./camadas/PR/buddahspa/estacionamentosbs.kmz");
      lastChecked = "estacionamentosbs";
    }

    if (value.imoveismenos18bs) {
      setimoveismenos18bs("./camadas/PR/buddahspa/imoveismenos18bs.kmz");
      lastChecked = "imoveismenos18bs";
    }

    if (value.imoveismais18bs) {
      setimoveismais18bs("./camadas/PR/buddahspa/imoveismais18bs.kmz");
      lastChecked = "imoveismais18bs";
    }

    if (value.areainflucompbs) {
      setareainflucompbs("./camadas/PR/buddahspa/areainflucompbs.kmz");
      lastChecked = "areainflucompbs";
    }

    if (value.areapotcomrecorte) {
      setareapotcomrecorte("./camadas/PR/buddahspa/areapotcomrecorte.kmz");
      lastChecked = "areapotcomrecorte";
    }

    if (value.areapotsemrecorte) {
      setareapotsemrecorte("./camadas/PR/buddahspa/areapotsemrecorte.kmz");
      lastChecked = "areapotsemrecorte";
    }

    if (value.competidoresbs) {
      setcompetidoresbs("./camadas/PR/buddahspa/competidoresbs.kmz");
      lastChecked = "competidoresbs";
    }

    if (value.popadultabs) {
      setpopadultabs("./camadas/PR/buddahspa/popadultabs.kmz");
      lastChecked = "popadultabs";
    }

    if (value.areaspotbd) {
      setareaspotbd("./camadas/SP/baldoni/areaspotbd.kmz");
      lastChecked = "areaspotbd";
    }

    if (value.baldonibd) {
      setbaldonibd("./camadas/SP/baldoni/baldonibd.kmz");
      lastChecked = "baldonibd";
    }

    if (value.baldoni2030bd) {
      setbaldoni2030bd("./camadas/SP/baldoni/baldoni2030bd.kmz");
      lastChecked = "baldoni2030bd";
    }

    if (value.lojaspescabd) {
      setlojaspescabd("./camadas/SP/baldoni/lojaspescabd.kmz");
      lastChecked = "lojaspescabd";
    }

    if (value.pesquesbd) {
      setpesquesbd("./camadas/SP/baldoni/pesquesbd.kmz");
      lastChecked = "pesquesbd";
    }

    if (value.petshopsbd) {
      setpetshopsbd("./camadas/SP/baldoni/petshopsbd.kmz");
      lastChecked = "petshopsbd";
    }

    if (value.popadultabd) {
      setpopadultabd("./camadas/SP/baldoni/popadultabd.kmz");
      lastChecked = "popadultabd";
    }

    if (value.rendabd) {
      setrendabd("./camadas/SP/baldoni/rendabd.kmz");
      lastChecked = "rendabd";
    }

    if (value.restaurantesbd) {
      setrestaurantesbd("./camadas/SP/baldoni/restaurantesbd.kmz");
      lastChecked = "restaurantesbd";
    }

    if (value.vetsbd) {
      setvetsbd("./camadas/SP/baldoni/vetsbd.kmz");
      lastChecked = "vetsbd";
    }

    if (value.academiascb) {
      setacademiascb("./camadas/SP/caribu/academiascb.kmz");
      lastChecked = "academiascb";
    }

    if (value.areaspotcb) {
      setareaspotcb("./camadas/SP/caribu/areaspotcb.kmz");
      lastChecked = "areaspotcb";
    }

    if (value.cafescb) {
      setcafescb("./camadas/SP/caribu/cafescb.kmz");
      lastChecked = "cafescb";
    }

    if (value.comerciocb) {
      setcomerciocb("./camadas/SP/caribu/comerciocb.kmz");
      lastChecked = "comerciocb";
    }

    if (value.culturalcb) {
      setculturalcb("./camadas/SP/caribu/culturalcb.kmz");
      lastChecked = "culturalcb";
    }

    if (value.escolascb) {
      setescolascb("./camadas/SP/caribu/escolascb.kmz");
      lastChecked = "escolascb";
    }

    if (value.esportescb) {
      setesportescb("./camadas/SP/caribu/esportescb.kmz");
      lastChecked = "esportescb";
    }

    if (value.faculdadescb) {
      setfaculdadescb("./camadas/SP/caribu/faculdadescb.kmz");
      lastChecked = "faculdadescb";
    }

    if (value.imoveiscb) {
      setimoveiscb("./camadas/SP/caribu/imoveiscb.kmz");
      lastChecked = "imoveiscb";
    }

    if (value.lanchonetescb) {
      setlanchonetescb("./camadas/SP/caribu/lanchonetescb.kmz");
      lastChecked = "lanchonetescb";
    }

    if (value.rendasetorescb) {
      setrendasetorescb("./camadas/SP/caribu/rendasetorescb.kmz");
      lastChecked = "rendasetorescb";
    }

    if (value.comidasaudavelcb) {
      setcomidasaudavelcb("./camadas/SP/caribu/comidasaudavelcb.kmz");
      lastChecked = "comidasaudavelcb";
    }

    if (value.zonasusobs) {
      setzonasusobs("./camadas/PR/buddahspa/zonasusobs.kmz");
      lastChecked = "zonasusobs";
    }

    if (value.Rio_Verde_1_zoneamento) {
      setRio_Verde_1_zoneamento(
        "./camadas/GO/rioverde/Rio_Verde_1_zoneamento.kmz"
      );
      lastChecked = "Rio_Verde_1_zoneamento";
    }

    if (value.Rio_Verde_2_permissao) {
      setRio_Verde_2_permissao(
        "./camadas/GO/rioverde/Rio_Verde_2_permissao.kmz"
      );
      lastChecked = "Rio_Verde_2_permissao";
    }

    if (value.Rio_Verde_3_terrenos) {
      setRio_Verde_3_terrenos("./camadas/GO/rioverde/Rio_Verde_3_terrenos.kmz");
      lastChecked = "Rio_Verde_3_terrenos";
    }

    if (value.Rio_Verde_4_imoveis_disponiveis) {
      setRio_Verde_4_imoveis_disponiveis(
        "./camadas/GO/rioverde/Rio_Verde_4_imoveis_disponiveis.kmz"
      );
      lastChecked = "Rio_Verde_4_imoveis_disponiveis";
    }

    if (value.Rio_Verde_5_topografia) {
      setRio_Verde_5_topografia(
        "./camadas/GO/rioverde/Rio_Verde_5_topografia.kmz"
      );
      lastChecked = "Rio_Verde_5_topografia";
    }

    if (value.Rio_Verde_6_Limites_Ambientais) {
      setRio_Verde_6_Limites_Ambientais(
        "./camadas/GO/rioverde/Rio_Verde_6_Limites_Ambientais.kmz"
      );
      lastChecked = "Rio_Verde_6_Limites_Ambientais";
    }

    if (value.Rio_Verde_7_Hidrografia) {
      setRio_Verde_7_Hidrografia(
        "./camadas/GO/rioverde/Rio_Verde_7_Hidrografia.kmz"
      );
      lastChecked = "Rio_Verde_7_Hidrografia";
    }

    if (value.Rio_Verde_8_Servidao) {
      setRio_Verde_8_Servidao("./camadas/GO/rioverde/Rio_Verde_8_Servidao.kmz");
      lastChecked = "Rio_Verde_8_Servidao";
    }

    if (value.Rio_Verde_9_terrenos_potenciais) {
      setRio_Verde_9_terrenos_potenciais(
        "./camadas/GO/rioverde/Rio_Verde_9_terrenos_potenciais.kmz"
      );
      lastChecked = "Rio_Verde_9_terrenos_potenciais";
    }

    if (value.Rio_Verde_10_crescimento) {
      setRio_Verde_10_crescimento(
        "./camadas/GO/rioverde/Rio_Verde_10_crescimento.kmz"
      );
      lastChecked = "Rio_Verde_10_crescimento";
    }

    if (value.Rio_Verde_11_dados_socioeconomicos) {
      setRio_Verde_11_dados_socioeconomicos(
        "./camadas/GO/rioverde/Rio_Verde_11_dados_socioeconomicos.kmz"
      );
      lastChecked = "Rio_Verde_11_dados_socioeconomicos";
    }

    if (value.Rio_verde_12_dist_viario) {
      setRio_verde_12_dist_viario(
        "./camadas/GO/rioverde/Rio_verde_12_dist_viario.kmz"
      );
      lastChecked = "Rio_verde_12_dist_viario";
    }

    if (value.Rio_Verde_13_Malha_Urbana) {
      setRio_Verde_13_Malha_Urbana(
        "./camadas/GO/rioverde/Rio_Verde_13_Malha_Urbana.kmz"
      );
      lastChecked = "Rio_Verde_13_Malha_Urbana";
    }

    if (value.Rio_Verde_14_Pontos_de_Interesse) {
      setRio_Verde_14_Pontos_de_Interesse(
        "./camadas/GO/rioverde/Rio_Verde_14_Pontos_de_Interesse.kmz"
      );
      lastChecked = "Rio_Verde_14_Pontos_de_Interesse";
    }

    if (value.Rio_Verde) {
      setRio_Verde("./camadas/GO/rioverde/Rio_Verde.kmz");
      lastChecked = "Rio_Verde";
    }

    if (value.Cruzeiro_1_Zoneamento) {
      setCruzeiro_1_Zoneamento("./camadas/MG/tony/Cruzeiro_1_Zoneamento.kmz");
      lastChecked = "Cruzeiro_1_Zoneamento";
    }

    if (value.Guaxupe_1_zoneamento) {
      setGuaxupe_1_zoneamento("./camadas/MG/tony/Guaxupe_1_zoneamento.kmz");
      lastChecked = "Guaxupe_1_zoneamento";
    }

    if (value.Lavras_zoneamento_TESTE) {
      setLavras_zoneamento_TESTE(
        "./camadas/MG/tony/Lavras_zoneamento_TESTE.kmz"
      );
      lastChecked = "Lavras_zoneamento_TESTE";
    }

    if (value.SSParaiso_1_Zoneamento) {
      setSSParaiso_1_Zoneamento("./camadas/MG/tony/SSParaiso_1_Zoneamento.kmz");
      lastChecked = "SSParaiso_1_Zoneamento";
    }

    if (value.angradosreisacc) {
      setangradosreisacc("./camadas/RJ/accenture/municipiosv2.kmz");
      lastChecked = "angradosreisacc";
    }

    if (value.br101km414acc) {
      setbr101km414acc("./camadas/RJ/accenture/Estabelecimentosv2.kmz");
      lastChecked = "br101km414acc";
    }

    if (value.br101km447acc) {
      setbr101km447acc("./camadas/RJ/accenture/trechov2.kmz");
      lastChecked = "br101km447acc";
    }

    if (value.br101km538acc) {
      setbr101km538acc("./camadas/RJ/accenture/areasprotegidasv2.kmz");
      lastChecked = "br101km538acc";
    }

    if (value.itaguaiacc) {
      setitaguaiacc("./camadas/RJ/accenture/Comportamentov2.kmz");
      lastChecked = "itaguaiacc";
    }

    if (value.mangaratibaacc) {
      setmangaratibaacc("./camadas/RJ/accenture/mangaratibaacc.kmz");
      lastChecked = "mangaratibaacc";
    }
    if (value.paratyacc) {
      setparatyacc("./camadas/RJ/accenture/paratyacc.kmz");
      lastChecked = "paratyacc";
    }

    if (value.trechobr101acc) {
      settrechobr101acc("./camadas/RJ/accenture/trechobr101acc.kmz");
      lastChecked = "trechobr101acc";
    }

    if (value.rendaacc) {
      setrendaacc("./camadas/RJ/accenture/rendaacc.kmz");
      lastChecked = "rendaacc";
    }

    if (value.areasconmataatlaacc) {
      setareasconmataatlaacc("./camadas/RJ/accenture/areasconmataatlaacc.kmz");
      lastChecked = "areasconmataatlaacc";
    }

    if (value.appacc) {
      setappacc("./camadas/RJ/accenture/appacc.kmz");
      lastChecked = "appacc";
    }

    if (value.vegetacaonativaacc) {
      setvegetacaonativaacc("./camadas/RJ/accenture/vegetacaonativaacc.kmz");
      lastChecked = "vegetacaonativaacc";
    }

    if (value.uniconsfederaisacc) {
      setuniconsfederaisacc("./camadas/RJ/accenture/uniconsfederaisacc.kmz");
      lastChecked = "uniconsfederaisacc";
    }

    if (value.reservalegalacc) {
      setreservalegalacc("./camadas/RJ/accenture/reservalegalacc.kmz");
      lastChecked = "reservalegalacc";
    }

    if (value.areasprotacc) {
      setareasprotacc("./camadas/RJ/accenture/areasprotacc.kmz");
      lastChecked = "areasprotacc";
    }

    if (value.acaiteriacb) {
      setacaiteriacb("./camadas/SP/caribu/acaiteriacb.kmz");
      lastChecked = "acaiteriacb";
    }

    if (value.sorveteriacb) {
      setsorveteriacb("./camadas/SP/caribu/sorveteriacb.kmz");
      lastChecked = "sorveteriacb";
    }

    if (value.moteisac) {
      setmoteisac("./camadas/RJ/accenture/moteisac.kmz");
      lastChecked = "moteisac";
    }

    if (value.pedagiosac) {
      setpedagiosac("./camadas/RJ/accenture/pedagiosac.kmz");
      lastChecked = "pedagiosac";
    }

    if (value.postosac) {
      setpostosac("./camadas/RJ/accenture/postosac.kmz");
      lastChecked = "postosac";
    }

    if (value.restaurantesac) {
      setrestaurantesac("./camadas/RJ/accenture/restaurantesac.kmz");
      lastChecked = "restaurantesac";
    }

    if (value.hoteisac) {
      sethoteisac("./camadas/RJ/accenture/hoteisac.kmz");
      lastChecked = "hoteisac";
    }

    if (value.pousadasac) {
      setpousadasac("./camadas/RJ/accenture/pousadasac.kmz");
      lastChecked = "pousadasac";
    }

    if (value.areaspotpucrsg) {
      setareaspotpucrsg("./camadas/SP/rsg/areaspotpucrsg.kmz");
      lastChecked = "areaspotpucrsg";
    }

    if (value.areaspotunicampport2rsg) {
      setareaspotunicampport2rsg(
        "./camadas/SP/rsg/areaspotunicampport2rsg.kmz"
      );
      lastChecked = "areaspotunicampport2rsg";
    }

    if (value.areaspotunicampprincrsg) {
      setareaspotunicampprincrsg(
        "./camadas/SP/rsg/areaspotunicampprincrsg.kmz"
      );
      lastChecked = "areaspotunicampprincrsg";
    }

    if (value.portariasrsg) {
      setportariasrsg("./camadas/SP/rsg/portariasrsg.kmz");
      lastChecked = "portariasrsg";
    }

    if (value.raio200rsg) {
      setraio200rsg("./camadas/SP/rsg/raio200rsg.kmz");
      lastChecked = "raio200rsg";
    }

    if (value.terrenosdisprsg) {
      setterrenosdisprsg("./camadas/SP/rsg/terrenosdisprsg.kmz");
      lastChecked = "terrenosdisprsg";
    }

    if (value.perimetrourbanocb) {
      setperimetrourbanocb("./camadas/SP/caribu/perimetrourbanocb.kmz");
      lastChecked = "perimetrourbanocb";
    }

    if (value.fluxokernelcb) {
      setfluxokernelcb("./camadas/SP/caribu/fluxokernelcb.kmz");
      lastChecked = "fluxokernelcb";
    }

    if (value.concentrafluxocb) {
      setconcentrafluxocb("./camadas/SP/caribu/concentrafluxocb.kmz");
      lastChecked = "concentrafluxocb";
    }

    if (value.raiofluxocb) {
      setraiofluxocb("./camadas/SP/caribu/raiofluxocb.kmz");
      lastChecked = "raiofluxocb";
    }

    if (value.fluxoconcocb) {
      setfluxoconcocb("./camadas/SP/caribu/fluxoconcocb.kmz");
      lastChecked = "fluxoconcocb";
    }

    if (value.zoneamentopec) {
      setzoneamentopec("./camadas/SP/peconi/zoneamentopec.kmz");
      lastChecked = "zoneamentopec";
    }

    if (value.pontosintpec) {
      setpontosintpec("./camadas/SP/peconi/pontosintpec.kmz");
      lastChecked = "pontosintpec";
    }

    if (value.permissaopec) {
      setpermissaopec("./camadas/SP/peconi/permissaopec.kmz");
      lastChecked = "permissaopec";
    }

    if (value.topografiapec) {
      settopografiapec("./camadas/SP/peconi/topografiapec.kmz");
      lastChecked = "topografiapec";
    }

    if (value.crescimentoanopec) {
      setcrescimentoanopec(
        "./camadas/SP/peconi/crescimento/crescimentoanopec.kmz"
      );
      lastChecked = "crescimentoanopec";
    }

    if (value.graprohab) {
      setgraprohab("./camadas/SP/peconi/crescimento/graprohabpec.kmz");
      lastChecked = "graprohab";
    }

    if (value.densidadepec) {
      setdensidadepec("./camadas/SP/peconi/dadossocios/densidadepec.kmz");
      lastChecked = "densidadepec";
    }

    if (value.familiaspec) {
      setfamiliaspec("./camadas/SP/peconi/dadossocios/familiaspec.kmz");
      lastChecked = "familiaspec";
    }

    if (value.rendapec) {
      setrendapec("./camadas/SP/peconi/dadossocios/rendapec.kmz");
      lastChecked = "rendapec";
    }

    if (value.corpospec) {
      setcorpospec("./camadas/SP/peconi/hidrografia/corpospec.kmz");
      lastChecked = "corpospec";
    }

    if (value.cursospec) {
      setcursospec("./camadas/SP/peconi/hidrografia/cursospec.kmz");
      lastChecked = "cursospec";
    }

    if (value.nascentespec) {
      setnascentespec("./camadas/SP/peconi/hidrografia/nascentespec.kmz");
      lastChecked = "nascentespec";
    }

    if (value.apppec) {
      setapppec("./camadas/SP/peconi/limitesamb/apppec.kmz");
      lastChecked = "apppec";
    }

    if (value.reservapec) {
      setreservapec("./camadas/SP/peconi/limitesamb/reservapec.kmz");
      lastChecked = "reservapec";
    }

    if (value.vegpec) {
      setvegpec("./camadas/SP/peconi/limitesamb/vegpec.kmz");
      lastChecked = "vegpec";
    }

    if (value.terrenos2k4kpec) {
      setterrenos2k4kpec(
        "./camadas/SP/peconi/terrenosdisp/terrenos2k4kpec.kmz"
      );
      lastChecked = "terrenos2k4kpec";
    }

    if (value.terrenos5kmaispec) {
      setterrenos5kmaispec(
        "./camadas/SP/peconi/terrenosdisp/terrenos5kmaispec.kmz"
      );
      lastChecked = "terrenos5kmaispec";
    }

    if (value.terrenosnaopec) {
      setterrenosnaopec("./camadas/SP/peconi/terrenospot/terrenosnaopec.kmz");
      lastChecked = "terrenosnaopec";
    }

    if (value.terrenospot5kmaispec) {
      setterrenospot5kmaispec(
        "./camadas/SP/peconi/terrenospot/terrenospot5kmaispec.kmz"
      );
      lastChecked = "terrenospot5kmaispec";
    }

    if (value.terrenospot150kmaispec) {
      setterrenospot150kmaispec(
        "./camadas/SP/peconi/terrenospot/terrenospot150kmaispec.kmz"
      );
      lastChecked = "terrenospot150kmaispec";
    }

    if (value.mapaconcentracaopec) {
      setmapaconcentracaopec("./camadas/SP/peconi/mapaconcentracaopec.kmz");
      lastChecked = "mapaconcentracaopec";
    }

    if (value.limitevmap) {
      setlimitevmap("./camadas/SP/votomap/limitevmap.kmz");
      lastChecked = "limitevmap";
    }

    if (value.popfemvmap) {
      setpopfemvmap("./camadas/SP/votomap/popfemvmap.kmz");
      lastChecked = "popfemvmap";
    }

    if (value.popmasvmap) {
      setpopmasvmap("./camadas/SP/votomap/popmasvmap.kmz");
      lastChecked = "popmasvmap";
    }

    if (value.poptotvmap) {
      setpoptotvmap("./camadas/SP/votomap/poptotvmap.kmz");
      lastChecked = "poptotvmap";
    }

    if (value.prefeitovmap) {
      setprefeitovmap("./camadas/SP/votomap/prefeitovmap.kmz");
      lastChecked = "prefeitovmap";
    }

    if (value.prefeitovmap2) {
      setprefeitovmap2("./camadas/SP/votomap/prefeitovmap2.kmz");
      lastChecked = "prefeitovmap2";
    }

    if (value.vereadorvmap) {
      setvereadorvmap("./camadas/SP/votomap/vereadorvmap.kmz");
      lastChecked = "vereadorvmap";
    }

    if (value.vereadorvmap2) {
      setvereadorvmap2("./camadas/SP/votomap/vereadorvmap2.kmz");
      lastChecked = "vereadorvmap2";
    }

    if (value.rendavmap) {
      setrendavmap("./camadas/SP/votomap/rendavmap.kmz");
      lastChecked = "rendavmap";
    }

    if (value.demandaespacopu) {
      setdemandaespacopu("./camadas/SP/unicamp/demandaespacopu.kmz");
      lastChecked = "demandaespacopu";
    }

    if (value.demandaqualidadepu) {
      setdemandaqualidadepu("./camadas/SP/unicamp/demandaqualidadepu.kmz");
      lastChecked = "demandaqualidadepu";
    }

    if (value.faixafaturamentopu) {
      setfaixafaturamentopu("./camadas/SP/unicamp/faixafaturamentopu.kmz");
      lastChecked = "faixafaturamentopu";
    }

    if (value.leispu) {
      setleispu("./camadas/SP/unicamp/leispu.kmz");
      lastChecked = "leispu";
    }

    if (value.mapageralpu) {
      setmapageralpu("./camadas/SP/unicamp/mapageralpu.kmz");
      lastChecked = "mapageralpu";
    }

    if (value.modelopu) {
      setmodelopu("./camadas/SP/unicamp/modelopu.kmz");
      lastChecked = "modelopu";
    }

    if (value.preferenpu) {
      setpreferenpu("./camadas/SP/unicamp/preferenpu.kmz");
      lastChecked = "preferenpu";
    }

    if (value.necessidadepu) {
      setnecessidadepu("./camadas/SP/unicamp/necessidadepu.kmz");
      lastChecked = "necessidadepu";
    }

    if (value.relacaopu) {
      setrelacaopu("./camadas/SP/unicamp/relacaopu.kmz");
      lastChecked = "relacaopu";
    }

    if (value.setorpu) {
      setsetorpu("./camadas/SP/unicamp/setorpu.kmz");
      lastChecked = "setorpu";
    }

    if (value.divisaoregpec) {
      setdivisaoregpec("./camadas/SP/peconi/divisaoregpec.kmz");
      lastChecked = "divisaoregpec";
    }

    if (value.vaziospec) {
      setvaziospec("./camadas/SP/peconi/vaziospec.kmz");
      lastChecked = "vaziospec";
    }

    if (value.usosolopec) {
      setusosolopec("./camadas/SP/peconi/usosolopec.kmz");
      lastChecked = "usosolopec";
    }

    if (value.concenfluxospec) {
      setconcenfluxospec("./camadas/SP/peconi/concenfluxospec.kmz");
      lastChecked = "concenfluxospec";
    }

    if (value.topografiapec2) {
      settopografiapec2("./camadas/SP/peconi/topografia/topografiapec2.kmz");
      lastChecked = "topografiapec2";
    }

    if (value.faixaac) {
      setfaixaac("./camadas/RJ/accenture/faixaac.kmz");
      lastChecked = "faixaac";
    }

    if (value.areaintbb) {
      setareaintbb("./camadas/SP/boibrabo/areaintbb.kmz");
      lastChecked = "areaintbb";
    }

    if (value.boibrabobb) {
      setboibrabobb("./camadas/SP/boibrabo/boibrabobb.kmz");
      lastChecked = "boibrabobb";
    }

    if (value.classesbb) {
      setclassesbb("./camadas/SP/boibrabo/classesbb.kmz");
      lastChecked = "classesbb";
    }

    if (value.compsbb) {
      setcompsbb("./camadas/SP/boibrabo/compsbb.kmz");
      lastChecked = "compsbb";
    }

    if (value.conccompsbb) {
      setconccompsbb("./camadas/SP/boibrabo/conccompsbb.kmz");
      lastChecked = "conccompsbb";
    }

    if (value.concpfbb) {
      setconcpfbb("./camadas/SP/boibrabo/concpfbb.kmz");
      lastChecked = "concpfbb";
    }

    if (value.familiasbb) {
      setfamiliasbb("./camadas/SP/boibrabo/familiasbb.kmz");
      lastChecked = "familiasbb";
    }

    if (value.fluxocompsbb) {
      setfluxocompsbb("./camadas/SP/boibrabo/fluxocompsbb.kmz");
      lastChecked = "fluxocompsbb";
    }

    if (value.imoveisbb) {
      setimoveisbb("./camadas/SP/boibrabo/imoveisbb.kmz");
      lastChecked = "imoveisbb";
    }

    if (value.pontosfluxobb) {
      setpontosfluxobb("./camadas/SP/boibrabo/pontosfluxobb.kmz");
      lastChecked = "pontosfluxobb";
    }

    if (value.rendabb) {
      setrendabb("./camadas/SP/boibrabo/rendabb.kmz");
      lastChecked = "rendabb";
    }

    if (value.metrobb) {
      setmetrobb("./camadas/SP/boibrabo/metrobb.kmz");
      lastChecked = "metrobb";
    }

    if (value.onibusbb) {
      setonibusbb("./camadas/SP/boibrabo/onibusbb.kmz");
      lastChecked = "onibusbb";
    }

    if (value.trembb) {
      settrembb("./camadas/SP/boibrabo/trembb.kmz");
      lastChecked = "trembb";
    }

    if (value.areapotbb) {
      setareapotbb("./camadas/SP/boibrabo/areapotbb.kmz");
      lastChecked = "areapotbb";
    }

    if (value.areapotdp) {
      setareapotdp("./camadas/SP/didio/areapotdp.kmz");
      lastChecked = "areapotdp";
    }

    if (value.compsdp) {
      setcompsdp("./camadas/SP/didio/compsdp.kmz");
      lastChecked = "compsdp";
    }

    if (value.conccompsdp) {
      setconccompsdp("./camadas/SP/didio/conccompsdp.kmz");
      lastChecked = "conccompsdp";
    }

    if (value.concpontofluxodp) {
      setconcpontofluxodp("./camadas/SP/didio/concpontofluxodp.kmz");
      lastChecked = "concpontofluxodp";
    }

    if (value.familiasdp) {
      setfamiliasdp("./camadas/SP/didio/familiasdp.kmz");
      lastChecked = "familiasdp";
    }

    if (value.gastodp) {
      setgastodp("./camadas/SP/didio/gastodp.kmz");
      lastChecked = "gastodp";
    }

    if (value.pontofluxodp) {
      setpontofluxodp("./camadas/SP/didio/pontofluxodp.kmz");
      lastChecked = "pontofluxodp";
    }

    if (value.pop20a69dp) {
      setpop20a69dp("./camadas/SP/didio/pop20a69dp.kmz");
      lastChecked = "pop20a69dp";
    }

    if (value.rendadp) {
      setrendadp("./camadas/SP/didio/rendadp.kmz");
      lastChecked = "rendadp";
    }

    if (value.armazensacc2) {
      setarmazensacc2("./camadas/GEN/acc2/armazensacc2.kmz");
      lastChecked = "armazensacc2";
    }

    if (value.balsasacc2) {
      setbalsasacc2("./camadas/GEN/acc2/balsasacc2.kmz");
      lastChecked = "balsasacc2";
    }

    if (value.concarmazensacc2) {
      setconcarmazensacc2("./camadas/GEN/acc2/concarmazensacc2.kmz");
      lastChecked = "concarmazensacc2";
    }

    if (value.formosoacc2) {
      setformosoacc2("./camadas/GEN/acc2/formosoacc2.kmz");
      lastChecked = "formosoacc2";
    }

    if (value.lagoaacc2) {
      setlagoaacc2("./camadas/GEN/acc2/lagoaacc2.kmz");
      lastChecked = "lagoaacc2";
    }

    if (value.miranorteacc2) {
      setmiranorteacc2("./camadas/GEN/acc2/miranorteacc2.kmz");
      lastChecked = "miranorteacc2";
    }

    if (value.portoacc2) {
      setportoacc2("./camadas/GEN/acc2/portoacc2.kmz");
      lastChecked = "portoacc2";
    }

    if (value.silvanopolisacc2) {
      setsilvanopolisacc2("./camadas/GEN/acc2/silvanopolisacc2.kmz");
      lastChecked = "silvanopolisacc2";
    }

    if (value.tassoacc2) {
      settassoacc2("./camadas/GEN/acc2/tassoacc2.kmz");
      lastChecked = "tassoacc2";
    }

    if (value.usosoloacc2) {
      setusosoloacc2("./camadas/GEN/acc2/usosoloacc2.kmz");
      lastChecked = "usosoloacc2";
    }

    if (value.hidroviasgeralacc2) {
      sethidroviasgeralacc2("./camadas/GEN/acc2/hidroviasgeralacc2.kmz");
      lastChecked = "hidroviasgeralacc2";
    }

    if (value.viasgeralacc2) {
      setviasgeralacc2("./camadas/GEN/acc2/viasgeralacc2.kmz");
      lastChecked = "viasgeralacc2";
    }

    if (value.portsaoluisacc2) {
      setportsaoluisacc2("./camadas/GEN/acc2/portsaoluisacc2.kmz");
      lastChecked = "portsaoluisacc2";
    }

    if (value.portnacionalacc2) {
      setportnacionalacc2("./camadas/GEN/acc2/portnacionalacc2.kmz");
      lastChecked = "portnacionalacc2";
    }

    if (value.intpalemeiranteacc2) {
      setintpalemeiranteacc2("./camadas/GEN/acc2/intpalemeiranteacc2.kmz");
      lastChecked = "intpalemeiranteacc2";
    }

    if (value.rodoviasacc2) {
      setrodoviasacc2("./camadas/GEN/acc2/rodoviasacc2.kmz");
      lastChecked = "rodoviasacc2";
    }

    if (value.instalacoesportacc2) {
      setinstalacoesportacc2("./camadas/GEN/acc2/instalacoesportacc2.kmz");
      lastChecked = "instalacoesportacc2";
    }

    if (value.hidroviasofcacc2) {
      sethidroviasofcacc2("./camadas/GEN/acc2/hidroviasofcacc2.kmz");
      lastChecked = "hidroviasofcacc2";
    }

    if (value.ferroviasacc2) {
      setferroviasacc2("./camadas/GEN/acc2/ferroviasacc2.kmz");
      lastChecked = "ferroviasacc2";
    }

    if (value.usosolomiranorteacc2) {
      setusosolomiranorteacc2("./camadas/GEN/acc2/usosolomiranorteacc2.kmz");
      lastChecked = "usosolomiranorteacc2";
    }

    if (value.usosolosilvanopolisacc2) {
      setusosolosilvanopolisacc2(
        "./camadas/GEN/acc2/usosolosilvanopolisacc2.kmz"
      );
      lastChecked = "usosolosilvanopolisacc2";
    }

    if (value.usosololagoaacc2) {
      setusosololagoaacc2("./camadas/GEN/acc2/usosololagoaacc2.kmz");
      lastChecked = "usosololagoaacc2";
    }

    if (value.usosoloformosoacc2) {
      setusosoloformosoacc2("./camadas/GEN/acc2/usosoloformosoacc2.kmz");
      lastChecked = "usosoloformosoacc2";
    }

    if (value.usosoloportoacc2) {
      setusosoloportoacc2("./camadas/GEN/acc2/usosoloportoacc2.kmz");
      lastChecked = "usosoloportoacc2";
    }

    if (value.usosolobalsasacc2) {
      setusosolobalsasacc2("./camadas/GEN/acc2/usosolobalsasacc2.kmz");
      lastChecked = "usosolobalsasacc2";
    }

    if (value.usosolotassoacc2) {
      setusosolotassoacc2("./camadas/GEN/acc2/usosolotassoacc2.kmz");
      lastChecked = "usosolotassoacc2";
    }

    if (value.lmsaltosc) {
      setlmsaltosc("./camadas/SP/sucaosalto/lmsaltosc.kmz");
      lastChecked = "lmsaltosc";
    }

    if (value.compsaltosc) {
      setcompsaltosc("./camadas/SP/sucaosalto/compsaltosc.kmz");
      lastChecked = "compsaltosc";
    }

    if (value.r1saltosc) {
      setr1saltosc("./camadas/SP/sucaosalto/r1saltosc.kmz");
      lastChecked = "r1saltosc";
    }

    if (value.r2saltosc) {
      setr2saltosc("./camadas/SP/sucaosalto/r2saltosc.kmz");
      lastChecked = "r2saltosc";
    }

    if (value.pfsaltosc) {
      setpfsaltosc("./camadas/SP/sucaosalto/pfsaltosc.kmz");
      lastChecked = "pfsaltosc";
    }

    if (value.scsaltosc) {
      setscsaltosc("./camadas/SP/sucaosalto/scsaltosc.kmz");
      lastChecked = "scsaltosc";
    }

    if (value.lmmarasc) {
      setlmmarasc("./camadas/SP/sucaocampogrande/lmmarasc.kmz");
      lastChecked = "lmmarasc";
    }

    if (value.compmarasc) {
      setcompmarasc("./camadas/SP/sucaocampogrande/compmarasc.kmz");
      lastChecked = "compmarasc";
    }

    if (value.buffermarasc) {
      setbuffermarasc("./camadas/SP/sucaocampogrande/buffermarasc.kmz");
      lastChecked = "buffermarasc";
    }

    if (value.pfmarasc) {
      setpfmarasc("./camadas/SP/sucaocampogrande/pfmarasc.kmz");
      lastChecked = "pfmarasc";
    }

    if (value.scmarasc) {
      setscmarasc("./camadas/SP/sucaocampogrande/scmarasc.kmz");
      lastChecked = "scmarasc";
    }

    if (value.lmjacaresc) {
      setlmjacaresc("./camadas/SP/sucaojacarepagua/lmjacaresc.kmz");
      lastChecked = "lmjacaresc";
    }

    if (value.compjacaresc) {
      setcompjacaresc("./camadas/SP/sucaojacarepagua/compjacaresc.kmz");
      lastChecked = "compjacaresc";
    }

    if (value.r1jacaresc) {
      setr1jacaresc("./camadas/SP/sucaojacarepagua/r1jacaresc.kmz");
      lastChecked = "r1jacaresc";
    }

    if (value.pfjacaresc) {
      setpfjacaresc("./camadas/SP/sucaojacarepagua/pfjacaresc.kmz");
      lastChecked = "pfjacaresc";
    }

    if (value.scjacaresc) {
      setscjacaresc("./camadas/SP/sucaojacarepagua/scjacaresc.kmz");
      lastChecked = "scjacaresc";
    }

    if (value.terrenospec) {
      setterrenospec("./camadas/SP/peconi/terrenospec.kmz");
      lastChecked = "terrenospec";
    }

    if (value.terrenospecpau) {
      setterrenospecpau("./camadas/SP/peconipaulinia/terrenospecpau.kmz");
      lastChecked = "terrenospecpau";
    }

    if (value.amoarmazensacc2) {
      setamoarmazensacc2("./camadas/GEN/acc2/amoarmazensacc2.kmz");
      lastChecked = "amoarmazensacc2";
    }

    if (value.prestaacc2) {
      setprestaacc2("./camadas/GEN/acc2/prestaacc2.kmz");
      lastChecked = "prestaacc2";
    }

    if (value.habacc2) {
      sethabacc2("./camadas/GEN/acc2/habacc2.kmz");
      lastChecked = "habacc2";
    }

    if (value.capacc2) {
      setcapacc2("./camadas/GEN/acc2/capacc2.kmz");
      lastChecked = "capacc2";
    }

    if (value.tipoacc2) {
      settipoacc2("./camadas/GEN/acc2/tipoacc2.kmz");
      lastChecked = "tipoacc2";
    }

    if (value.porteacc2) {
      setporteacc2("./camadas/GEN/acc2/porteacc2.kmz");
      lastChecked = "porteacc2";
    }

    if (value.formacc2) {
      setformacc2("./camadas/GEN/acc2/formacc2.kmz");
      lastChecked = "formacc2";
    }

    if (value.distferroacc2) {
      setdistferroacc2("./camadas/GEN/acc2/distferroacc2.kmz");
      lastChecked = "distferroacc2";
    }

    if (value.distermacc2) {
      setdistermacc2("./camadas/GEN/acc2/distermacc2.kmz");
      lastChecked = "distermacc2";
    }

    if (value.estosoacc2) {
      setestosoacc2("./camadas/GEN/acc2/estosoacc2.kmz");
      lastChecked = "estosoacc2";
    }

    if (value.precosoacc2) {
      setprecosoacc2("./camadas/GEN/acc2/precosoacc2.kmz");
      lastChecked = "precosoacc2";
    }

    if (value.popacc2) {
      setpopacc2("./camadas/GEN/acc2/popacc2.kmz");
      lastChecked = "popacc2";
    }

    if (value.rendaacc2) {
      setrendaacc2("./camadas/GEN/acc2/rendaacc2.kmz");
      lastChecked = "rendaacc2";
    }
    if (value.aeroacc2) {
      setaeroacc2("./camadas/GEN/acc2/aeroacc2.kmz");
      lastChecked = "aeroacc2";
    }

    if (value.declacc2) {
      setdeclacc2("./camadas/GEN/acc2/declacc2.kmz");
      lastChecked = "declacc2";
    }

    if (value.dadossociosaltossc) {
      setdadossociosaltossc("./camadas/SP/sucaosalto/dadossociosaltossc.kmz");
      lastChecked = "dadossociosaltossc";
    }

    if (value.fluxo1marajasc) {
      setfluxo1marajasc("./camadas/SP/sucaocampogrande/fluxo1marajasc.kmz");
      lastChecked = "fluxo1marajasc";
    }

    if (value.kernelfluxo1marajasc) {
      setkernelfluxo1marajasc(
        "./camadas/SP/sucaocampogrande/kernelfluxo1marajasc.kmz"
      );
      lastChecked = "kernelfluxo1marajasc";
    }

    if (value.familiasmarajassc) {
      setfamiliasmarajassc(
        "./camadas/SP/sucaocampogrande/familiasmarajassc.kmz"
      );
      lastChecked = "familiasmarajassc";
    }

    if (value.rendamarajassc) {
      setrendamarajassc("./camadas/SP/sucaocampogrande/rendamarajassc.kmz");
      lastChecked = "rendamarajassc";
    }

    if (value.dadossociojacaresc) {
      setdadossociojacaresc(
        "./camadas/SP/sucaojacarepagua/dadossociojacaresc.kmz"
      );
      lastChecked = "dadossociojacaresc";
    }

    if (value.crescimentobrz) {
      setcrescimentobrz("./camadas/MG/brz/crescimentobrz.kmz");
      lastChecked = "crescimentobrz";
    }

    if (value.domiciliosaluguelbrz) {
      setdomiciliosaluguelbrz("./camadas/MG/brz/domiciliosaluguelbrz.kmz");
      lastChecked = "domiciliosaluguelbrz";
    }

    if (value.domicioliosbrz) {
      setdomicioliosbrz("./camadas/MG/brz/domicioliosbrz.kmz");
      lastChecked = "domicioliosbrz";
    }

    if (value.empreendimentosbrz) {
      setempreendimentosbrz("./camadas/MG/brz/empreendimentosbrz.kmz");
      lastChecked = "empreendimentosbrz";
    }

    if (value.lavrasbrz) {
      setlavrasbrz("./camadas/MG/brz/lavrasbrz.kmz");
      lastChecked = "lavrasbrz";
    }

    if (value.popbrz) {
      setpopbrz("./camadas/MG/brz/popbrz.kmz");
      lastChecked = "popbrz";
    }

    if (value.raio15brz) {
      setraio15brz("./camadas/MG/brz/raio15brz.kmz");
      lastChecked = "raio15brz";
    }

    if (value.rendabrz) {
      setrendabrz("./camadas/MG/brz/rendabrz.kmz");
      lastChecked = "rendabrz";
    }

    if (value.vetorbrz) {
      setvetorbrz("./camadas/MG/brz/vetorbrz.kmz");
      lastChecked = "vetorbrz";
    }

    if (value.prop1toacc2) {
      setprop1toacc2("./camadas/GEN/acc2/prop1toacc2.kmz");
      lastChecked = "prop1toacc2";
    }

    if (value.prop2toacc2) {
      setprop2toacc2("./camadas/GEN/acc2/prop2toacc2.kmz");
      lastChecked = "prop2toacc2";
    }

    if (value.prop1maacc2) {
      setprop1maacc2("./camadas/GEN/acc2/prop1maacc2.kmz");
      lastChecked = "prop1maacc2";
    }

    if (value.prop2maacc2) {
      setprop2maacc2("./camadas/GEN/acc2/prop2maacc2.kmz");
      lastChecked = "prop2maacc2";
    }

    if (value.pontointbrz) {
      setpontointbrz("./camadas/MG/brz/pontointbrz.kmz");
      lastChecked = "pontointbrz";
    }

    if (value.crescimento1brz) {
      setcrescimento1brz("./camadas/MG/brz/crescimento1brz.kmz");
      lastChecked = "crescimento1brz";
    }

    if (value.deficitbrz) {
      console.log("a");
      setdeficitbrz("./camadas/MG/brz/deficitbrz.kmz");
      lastChecked = "deficitbrz";
    }

    if (value.taxahabbrz) {
      settaxahabbrz("./camadas/MG/brz/taxahabbrz.kmz");
      lastChecked = "taxahabbrz";
    }

    if (value.gtdcamacircu) {
      setgtdcamacircu("./camadas/BA/circulagem/gtdcamacircu.kmz");
      lastChecked = "gtdcamacircu";
    }

    if (value.distcamacircu) {
      setdistcamacircu("./camadas/BA/circulagem/distcamacircu.kmz");
      lastChecked = "distcamacircu";
    }

    if (value.arearedcamacircu) {
      setarearedcamacircu("./camadas/BA/circulagem/arearedcamacircu.kmz");
      lastChecked = "arearedcamacircu";
    }

    if (value.areaspotcamacircu) {
      setareaspotcamacircu("./camadas/BA/circulagem/areaspotcamacircu.kmz");
      lastChecked = "areaspotcamacircu";
    }

    if (value.assocamacircu) {
      setassocamacircu("./camadas/BA/circulagem/assocamacircu.kmz");
      lastChecked = "assocamacircu";
    }

    if (value.camacircu) {
      setcamacircu("./camadas/BA/circulagem/camacircu.kmz");
      lastChecked = "camacircu";
    }

    if (value.empscamacircu) {
      setempscamacircu("./camadas/BA/circulagem/empscamacircu.kmz");
      lastChecked = "empscamacircu";
    }

    if (value.concempcamacircu) {
      setconcempcamacircu("./camadas/BA/circulagem/concempcamacircu.kmz");
      lastChecked = "concempcamacircu";
    }

    if (value.areaspotvargcircu) {
      setareaspotvargcircu("./camadas/MG/circulagem/areaspotvargcircu.kmz");
      lastChecked = "areaspotvargcircu";
    }

    if (value.assovargcircu) {
      setassovargcircu("./camadas/MG/circulagem/assovargcircu.kmz");
      lastChecked = "assovargcircu";
    }

    if (value.vargcircu) {
      setvargcircu("./camadas/MG/circulagem/vargcircu.kmz");
      lastChecked = "vargcircu";
    }

    if (value.empsvargcircu) {
      setempsvargcircu("./camadas/MG/circulagem/empsvargcircu.kmz");
      lastChecked = "empsvargcircu";
    }

    if (value.gtdcampcircu) {
      setgtdcampcircu("./camadas/SP/circulagem/gtdcampcircu.kmz");
      lastChecked = "gtdcampcircu";
    }

    if (value.distcampcircu) {
      setdistcampcircu("./camadas/SP/circulagem/distcampcircu.kmz");
      lastChecked = "distcampcircu";
    }

    if (value.distecocampcircu) {
      setdistecocampcircu("./camadas/SP/circulagem/distecocampcircu.kmz");
      lastChecked = "distecocampcircu";
    }

    if (value.arearedcampcircu) {
      setarearedcampcircu("./camadas/SP/circulagem/arearedcampcircu.kmz");
      lastChecked = "arearedcampcircu";
    }

    if (value.areaspotcampcircu) {
      setareaspotcampcircu("./camadas/SP/circulagem/areaspotcampcircu.kmz");
      lastChecked = "areaspotcampcircu";
    }

    if (value.assocampcircu) {
      setassocampcircu("./camadas/SP/circulagem/assocampcircu.kmz");
      lastChecked = "assocampcircu";
    }

    if (value.campcircu) {
      setcampcircu("./camadas/SP/circulagem/campcircu.kmz");
      lastChecked = "campcircu";
    }

    if (value.empscampcircu) {
      setempscampcircu("./camadas/SP/circulagem/empscampcircu.kmz");
      lastChecked = "empscampcircu";
    }

    if (value.densempscampcircu) {
      setdensempscampcircu("./camadas/SP/circulagem/densempscampcircu.kmz");
      lastChecked = "densempscampcircu";
    }

    if (value.ecoscampcircu) {
      setecoscampcircu("./camadas/SP/circulagem/ecoscampcircu.kmz");
      lastChecked = "ecoscampcircu";
    }

    if (value.gtdjundcircu) {
      setgtdjundcircu("./camadas/SP/circulagem/gtdjundcircu.kmz");
      lastChecked = "gtdjundcircu";
    }

    if (value.distjundcircu) {
      setdistjundcircu("./camadas/SP/circulagem/distjundcircu.kmz");
      lastChecked = "distjundcircu";
    }

    if (value.distecojundcircu) {
      setdistecojundcircu("./camadas/SP/circulagem/distecojundcircu.kmz");
      lastChecked = "distecojundcircu";
    }

    if (value.arearedjundcircu) {
      setarearedjundcircu("./camadas/SP/circulagem/arearedjundcircu.kmz");
      lastChecked = "arearedjundcircu";
    }

    if (value.areaspotjundcircu) {
      setareaspotjundcircu("./camadas/SP/circulagem/areaspotjundcircu.kmz");
      lastChecked = "areaspotjundcircu";
    }

    if (value.assojundcircu) {
      setassojundcircu("./camadas/SP/circulagem/assojundcircu.kmz");
      lastChecked = "assojundcircu";
    }

    if (value.jundcircu) {
      setjundcircu("./camadas/SP/circulagem/jundcircu.kmz");
      lastChecked = "jundcircu";
    }

    if (value.empsjundcircu) {
      setempsjundcircu("./camadas/SP/circulagem/empsjundcircu.kmz");
      lastChecked = "empsjundcircu";
    }

    if (value.densempsjundcircu) {
      setdensempsjundcircu("./camadas/SP/circulagem/densempsjundcircu.kmz");
      lastChecked = "densempsjundcircu";
    }

    if (value.ecosjundcircu) {
      setecosjundcircu("./camadas/SP/circulagem/ecosjundcircu.kmz");
      lastChecked = "ecosjundcircu";
    }

    if (value.gtdsorocircu) {
      setgtdsorocircu("./camadas/SP/circulagem/gtdsorocircu.kmz");
      lastChecked = "gtdsorocircu";
    }

    if (value.distsorocircu) {
      setdistsorocircu("./camadas/SP/circulagem/distsorocircu.kmz");
      lastChecked = "distsorocircu";
    }

    if (value.distecosorocircu) {
      setdistecosorocircu("./camadas/SP/circulagem/distecosorocircu.kmz");
      lastChecked = "distecosorocircu";
    }

    if (value.arearedsorocircu) {
      setarearedsorocircu("./camadas/SP/circulagem/arearedsorocircu.kmz");
      lastChecked = "arearedsorocircu";
    }

    if (value.areaspotsorocircu) {
      setareaspotsorocircu("./camadas/SP/circulagem/areaspotsorocircu.kmz");
      lastChecked = "areaspotsorocircu";
    }

    if (value.assosorocircu) {
      setassosorocircu("./camadas/SP/circulagem/assosorocircu.kmz");
      lastChecked = "assosorocircu";
    }

    if (value.sorocircu) {
      setsorocircu("./camadas/SP/circulagem/sorocircu.kmz");
      lastChecked = "sorocircu";
    }

    if (value.empssorocircu) {
      setempssorocircu("./camadas/SP/circulagem/empssorocircu.kmz");
      lastChecked = "empssorocircu";
    }

    if (value.densempssorocircu) {
      setdensempssorocircu("./camadas/SP/circulagem/densempssorocircu.kmz");
      lastChecked = "densempssorocircu";
    }

    if (value.ecossorocircu) {
      setecossorocircu("./camadas/SP/circulagem/ecossorocircu.kmz");
      lastChecked = "ecossorocircu";
    }

    if (value.areapotafasdp) {
      setareapotafasdp("./camadas/SP/didio/areapotafasdp.kmz");
      lastChecked = "areapotafasdp";
    }

    if (value.imoveisdp) {
      setimoveisdp("./camadas/SP/didio/imoveisdp.kmz");
      lastChecked = "imoveisdp";
    }

    if (value.jundiaimrv) {
      setjundiaimrv("./camadas/SP/mrv/jundiaimrv.kmz");
      lastChecked = "jundiaimrv";
    }

    if (value.appjundiaimrv) {
      setappjundiaimrv("./camadas/SP/mrv/appjundiaimrv.kmz");
      lastChecked = "appjundiaimrv";
    }

    if (value.vegjundiaimrv) {
      setvegjundiaimrv("./camadas/SP/mrv/vegjundiaimrv.kmz");
      lastChecked = "vegjundiaimrv";
    }

    if (value.resjundiaimrv) {
      setresjundiaimrv("./camadas/SP/mrv/resjundiaimrv.kmz");
      lastChecked = "resjundiaimrv";
    }

    if (value.areaprojundiaimrv) {
      setareaprojundiaimrv("./camadas/SP/mrv/areaprojundiaimrv.kmz");
      lastChecked = "areaprojundiaimrv";
    }

    if (value.nascjundiaimrv) {
      setnascjundiaimrv("./camadas/SP/mrv/nascjundiaimrv.kmz");
      lastChecked = "nascjundiaimrv";
    }

    if (value.cursjundiaimrv) {
      setcursjundiaimrv("./camadas/SP/mrv/cursjundiaimrv.kmz");
      lastChecked = "cursjundiaimrv";
    }

    if (value.corposjundiaimrv) {
      setcorposjundiaimrv("./camadas/SP/mrv/corposjundiaimrv.kmz");
      lastChecked = "corposjundiaimrv";
    }

    if (value.topojundiaimrv) {
      settopojundiaimrv("./camadas/SP/mrv/topojundiaimrv.kmz");
      lastChecked = "topojundiaimrv";
    }
    if (value.urbjundiaimrv) {
      seturbjundiaimrv("./camadas/SP/mrv/urbjundiaimrv.kmz");
      lastChecked = "urbjundiaimrv";
    }

    if (value.areapoujundiaimrv) {
      setareapoujundiaimrv("./camadas/SP/mrv/areapoujundiaimrv.kmz");
      lastChecked = "areapoujundiaimrv";
    }

    if (value.servjundiaimrv) {
      setservjundiaimrv("./camadas/SP/mrv/servjundiaimrv.kmz");
      lastChecked = "servjundiaimrv";
    }

    if (value.usorestjundiaimrv) {
      setusorestjundiaimrv("./camadas/SP/mrv/usorestjundiaimrv.kmz");
      lastChecked = "usorestjundiaimrv";
    }

    if (value.areaconsjundiaimrv) {
      setareaconsjundiaimrv("./camadas/SP/mrv/areaconsjundiaimrv.kmz");
      lastChecked = "areaconsjundiaimrv";
    }

    if (value.areacontjundiaimrv) {
      setareacontjundiaimrv("./camadas/SP/mrv/areacontjundiaimrv.kmz");
      lastChecked = "areacontjundiaimrv";
    }

    if (value.dutojundiaimrv) {
      setdutojundiaimrv("./camadas/SP/mrv/dutojundiaimrv.kmz");
      lastChecked = "dutojundiaimrv";
    }

    if (value.linhaojundiaimrv) {
      setlinhaojundiaimrv("./camadas/SP/mrv/linhaojundiaimrv.kmz");
      lastChecked = "linhaojundiaimrv";
    }

    if (value.zoneajundiaimrv) {
      setzoneajundiaimrv("./camadas/SP/mrv/zoneajundiaimrv.kmz");
      lastChecked = "zoneajundiaimrv";
    }

    if (value.legavertjundiaimrv) {
      setlegavertjundiaimrv("./camadas/SP/mrv/legavertjundiaimrv.kmz");
      lastChecked = "legavertjundiaimrv";
    }

    if (value.legahorjundiaimrv) {
      setlegahorjundiaimrv("./camadas/SP/mrv/legahorjundiaimrv.kmz");
      lastChecked = "legahorjundiaimrv";
    }

    if (value.rendapopjundiaimrv) {
      setrendapopjundiaimrv("./camadas/SP/mrv/rendapopjundiaimrv.kmz");
      lastChecked = "rendapopjundiaimrv";
    }

    if (value.rendajundiaimrv) {
      setrendajundiaimrv("./camadas/SP/mrv/rendajundiaimrv.kmz");
      lastChecked = "rendajundiaimrv";
    }

    if (value.popjundiaimrv) {
      setpopjundiaimrv("./camadas/SP/mrv/popjundiaimrv.kmz");
      lastChecked = "popjundiaimrv";
    }

    if (value.domicjundiaimrv) {
      setdomicjundiaimrv("./camadas/SP/mrv/domicjundiaimrv.kmz");
      lastChecked = "domicjundiaimrv";
    }

    if (value.domicalujundiaimrv) {
      setdomicalujundiaimrv("./camadas/SP/mrv/domicalujundiaimrv.kmz");
      lastChecked = "domicalujundiaimrv";
    }

    if (value.expurbjundiaimrv) {
      setexpurbjundiaimrv("./camadas/SP/mrv/expurbjundiaimrv.kmz");
      lastChecked = "expurbjundiaimrv";
    }

    if (value.taxahabjundiaimrv) {
      settaxahabjundiaimrv("./camadas/SP/mrv/taxahabjundiaimrv.kmz");
      lastChecked = "taxahabjundiaimrv";
    }

    if (value.graprohabjundiaimrv) {
      setgraprohabjundiaimrv("./camadas/SP/mrv/graprohabjundiaimrv.kmz");
      lastChecked = "graprohabjundiaimrv";
    }

    if (value.areaurbjundiaimrv) {
      setareaurbjundiaimrv("./camadas/SP/mrv/areaurbjundiaimrv.kmz");
      lastChecked = "areaurbjundiaimrv";
    }

    if (value.arearujundiaimrv) {
      setarearujundiaimrv("./camadas/SP/mrv/arearujundiaimrv.kmz");
      lastChecked = "arearujundiaimrv";
    }

    if (value.vaziosjundiaimrv) {
      setvaziosjundiaimrv("./camadas/SP/mrv/vaziosjundiaimrv.kmz");
      lastChecked = "vaziosjundiaimrv";
    }

    if (value.consumoaguajundiaimrv) {
      setconsumoaguajundiaimrv("./camadas/SP/mrv/consumoaguajundiaimrv.kmz");
      lastChecked = "consumoaguajundiaimrv";
    }

    if (value.dispaguajundiaimrv) {
      setdispaguajundiaimrv("./camadas/SP/mrv/dispaguajundiaimrv.kmz");
      lastChecked = "dispaguajundiaimrv";
    }

    if (value.custojundiaimrv) {
      setcustojundiaimrv("./camadas/SP/mrv/custojundiaimrv.kmz");
      lastChecked = "custojundiaimrv";
    }

    if (value.terrenosjundiaimrv) {
      setterrenosjundiaimrv("./camadas/SP/mrv/terrenosjundiaimrv.kml");
      lastChecked = "terrenosjundiaimrv";
    }

    if (value.pontoajundiaimrv) {
      setpontoajundiaimrv("./camadas/SP/mrv/pontoajundiaimrv.kmz");
      lastChecked = "pontoajundiaimrv";
    }

    if (value.pontodjundiaimrv) {
      setpontodjundiaimrv("./camadas/SP/mrv/pontodjundiaimrv.kmz");
      lastChecked = "pontodjundiaimrv";
    }

    if (value.limeiramrv) {
      setlimeiramrv("./camadas/SP/mrv/limeiramrv.kmz");
      lastChecked = "limeiramrv";
    }

    if (value.applimeiramrv) {
      setapplimeiramrv("./camadas/SP/mrv/applimeiramrv.kmz");
      lastChecked = "applimeiramrv";
    }

    if (value.veglimeiramrv) {
      setveglimeiramrv("./camadas/SP/mrv/veglimeiramrv.kmz");
      lastChecked = "veglimeiramrv";
    }

    if (value.reslimeiramrv) {
      setreslimeiramrv("./camadas/SP/mrv/reslimeiramrv.kmz");
      lastChecked = "reslimeiramrv";
    }

    if (value.areaprolimeiramrv) {
      setareaprolimeiramrv("./camadas/SP/mrv/areaprolimeiramrv.kmz");
      lastChecked = "areaprolimeiramrv";
    }

    if (value.nasclimeiramrv) {
      setnasclimeiramrv("./camadas/SP/mrv/nasclimeiramrv.kmz");
      lastChecked = "nasclimeiramrv";
    }

    if (value.curslimeiramrv) {
      setcurslimeiramrv("./camadas/SP/mrv/curslimeiramrv.kmz");
      lastChecked = "curslimeiramrv";
    }

    if (value.corposlimeiramrv) {
      setcorposlimeiramrv("./camadas/SP/mrv/corposlimeiramrv.kmz");
      lastChecked = "corposlimeiramrv";
    }

    if (value.topolimeiramrv) {
      settopolimeiramrv("./camadas/SP/mrv/topolimeiramrv.kmz");
      lastChecked = "topolimeiramrv";
    }
    if (value.urblimeiramrv) {
      seturblimeiramrv("./camadas/SP/mrv/urblimeiramrv.kmz");
      lastChecked = "urblimeiramrv";
    }

    if (value.areapoulimeiramrv) {
      setareapoulimeiramrv("./camadas/SP/mrv/areapoulimeiramrv.kmz");
      lastChecked = "areapoulimeiramrv";
    }

    if (value.servlimeiramrv) {
      setservlimeiramrv("./camadas/SP/mrv/servlimeiramrv.kmz");
      lastChecked = "servlimeiramrv";
    }

    if (value.usorestlimeiramrv) {
      setusorestlimeiramrv("./camadas/SP/mrv/usorestlimeiramrv.kmz");
      lastChecked = "usorestlimeiramrv";
    }

    if (value.areaconslimeiramrv) {
      setareaconslimeiramrv("./camadas/SP/mrv/areaconslimeiramrv.kmz");
      lastChecked = "areaconslimeiramrv";
    }

    if (value.areacontlimeiramrv) {
      setareacontlimeiramrv("./camadas/SP/mrv/areacontlimeiramrv.kmz");
      lastChecked = "areacontlimeiramrv";
    }

    if (value.dutolimeiramrv) {
      setdutolimeiramrv("./camadas/SP/mrv/dutolimeiramrv.kmz");
      lastChecked = "dutolimeiramrv";
    }

    if (value.linhaolimeiramrv) {
      setlinhaolimeiramrv("./camadas/SP/mrv/linhaolimeiramrv.kmz");
      lastChecked = "linhaolimeiramrv";
    }

    if (value.zonealimeiramrv) {
      setzonealimeiramrv("./camadas/SP/mrv/zonealimeiramrv.kmz");
      lastChecked = "zonealimeiramrv";
    }

    if (value.legavertlimeiramrv) {
      setlegavertlimeiramrv("./camadas/SP/mrv/legavertlimeiramrv.kmz");
      lastChecked = "legavertlimeiramrv";
    }

    if (value.legahorlimeiramrv) {
      setlegahorlimeiramrv("./camadas/SP/mrv/legahorlimeiramrv.kmz");
      lastChecked = "legahorlimeiramrv";
    }

    if (value.rendapoplimeiramrv) {
      setrendapoplimeiramrv("./camadas/SP/mrv/rendapoplimeiramrv.kmz");
      lastChecked = "rendapoplimeiramrv";
    }

    if (value.rendalimeiramrv) {
      setrendalimeiramrv("./camadas/SP/mrv/rendalimeiramrv.kmz");
      lastChecked = "rendalimeiramrv";
    }

    if (value.poplimeiramrv) {
      setpoplimeiramrv("./camadas/SP/mrv/poplimeiramrv.kmz");
      lastChecked = "poplimeiramrv";
    }

    if (value.domiclimeiramrv) {
      setdomiclimeiramrv("./camadas/SP/mrv/domiclimeiramrv.kmz");
      lastChecked = "domiclimeiramrv";
    }

    if (value.domicalulimeiramrv) {
      setdomicalulimeiramrv("./camadas/SP/mrv/domicalulimeiramrv.kmz");
      lastChecked = "domicalulimeiramrv";
    }

    if (value.expurblimeiramrv) {
      setexpurblimeiramrv("./camadas/SP/mrv/expurblimeiramrv.kmz");
      lastChecked = "expurblimeiramrv";
    }

    if (value.taxahablimeiramrv) {
      settaxahablimeiramrv("./camadas/SP/mrv/taxahablimeiramrv.kmz");
      lastChecked = "taxahablimeiramrv";
    }

    if (value.graprohablimeiramrv) {
      setgraprohablimeiramrv("./camadas/SP/mrv/graprohablimeiramrv.kmz");
      lastChecked = "graprohablimeiramrv";
    }

    if (value.areaurblimeiramrv) {
      setareaurblimeiramrv("./camadas/SP/mrv/areaurblimeiramrv.kmz");
      lastChecked = "areaurblimeiramrv";
    }

    if (value.arearulimeiramrv) {
      setarearulimeiramrv("./camadas/SP/mrv/arearulimeiramrv.kmz");
      lastChecked = "arearulimeiramrv";
    }

    if (value.vazioslimeiramrv) {
      setvazioslimeiramrv("./camadas/SP/mrv/vazioslimeiramrv.kmz");
      lastChecked = "vazioslimeiramrv";
    }

    if (value.consumoagualimeiramrv) {
      setconsumoagualimeiramrv("./camadas/SP/mrv/consumoagualimeiramrv.kmz");
      lastChecked = "consumoagualimeiramrv";
    }

    if (value.dispagualimeiramrv) {
      setdispagualimeiramrv("./camadas/SP/mrv/dispagualimeiramrv.kmz");
      lastChecked = "dispagualimeiramrv";
    }

    if (value.custolimeiramrv) {
      setcustolimeiramrv("./camadas/SP/mrv/custolimeiramrv.kmz");
      lastChecked = "custolimeiramrv";
    }

    if (value.terrenoslimeiramrv) {
      setterrenoslimeiramrv("./camadas/SP/mrv/terrenoslimeiramrv.kml");
      lastChecked = "terrenoslimeiramrv";
    }

    if (value.pontoalimeiramrv) {
      setpontoalimeiramrv("./camadas/SP/mrv/pontoalimeiramrv.kmz");
      lastChecked = "pontoalimeiramrv";
    }

    if (value.pontodlimeiramrv) {
      setpontodlimeiramrv("./camadas/SP/mrv/pontodlimeiramrv.kmz");
      lastChecked = "pontodlimeiramrv";
    }

    if (value.veloprecojundiaimrv) {
      setveloprecojundiaimrv("./camadas/SP/mrv/veloprecojundiaimrv.kmz");
      lastChecked = "veloprecojundiaimrv";
    }

    if (value.veloprecolimeiramrv) {
      setveloprecolimeiramrv("./camadas/SP/mrv/veloprecolimeiramrv.kmz");
      lastChecked = "veloprecolimeiramrv";
    }

    if (value.bdgu) {
      setbdgu("./camadas/SP/gurgel/BaseDadosGeolocalizada.kmz");
      lastChecked = "bdgu";
    }

    if (value.boticampcircu) {
      setboticampcircu("./camadas/SP/circulagem/boticampcircu.kmz");
      lastChecked = "boticampcircu";
    }

    if (value.botijundcircu) {
      setbotijundcircu("./camadas/SP/circulagem/botijundcircu.kmz");
      lastChecked = "botijundcircu";
    }

    if (value.botisorocircu) {
      setbotisorocircu("./camadas/SP/circulagem/botisorocircu.kmz");
      lastChecked = "botisorocircu";
    }

    if (value.botigrandespcircu) {
      setbotigrandespcircu("./camadas/SP/circulagem/botigrandespcircu.kmz");
      lastChecked = "botigrandespcircu";
    }
    if (value.grandespcircu) {
      setgrandespcircu("./camadas/SP/circulagem/grandespcircu.kmz");
      lastChecked = "grandespcircu";
    }

    if (value.grandespcircu) {
      setgrandespcircu("./camadas/SP/circulagem/grandespcircu.kmz");
      lastChecked = "grandespcircu";
    }

    if (value.imoveisc1ic) {
      setimoveisc1ic("./camadas/SP/investcorp/imoveisc1ic.kmz");
      lastChecked = "imoveisc1ic";
    }

    if (value.raioc1ic) {
      setraioc1ic("./camadas/SP/investcorp/raioc1ic.kmz");
      lastChecked = "raioc1ic";
    }

    if (value.areaestudoc2ic) {
      setareaestudoc2ic("./camadas/SP/investcorp/areaestudoc2ic.kmz");
      lastChecked = "areaestudoc2ic";
    }

    if (value.rendac2ic) {
      setrendac2ic("./camadas/SP/investcorp/rendac2ic.kmz");
      lastChecked = "rendac2ic";
    }

    if (value.recortec2ic) {
      setrecortec2ic("./camadas/SP/investcorp/recortec2ic.kmz");
      lastChecked = "recortec2ic";
    }

    if (value.pontosfluxc2ic) {
      setpontosfluxc2ic("./camadas/SP/investcorp/pontosfluxc2ic.kmz");
      lastChecked = "pontosfluxc2ic";
    }

    if (value.concfluxoc2ic) {
      setconcfluxoc2ic("./camadas/SP/investcorp/concfluxoc2ic.kmz");
      lastChecked = "concfluxoc2ic";
    }

    if (value.oticasc2ic) {
      setoticasc2ic("./camadas/SP/investcorp/oticasc2ic.kmz");
      lastChecked = "oticasc2ic";
    }

    if (value.concfluxooticasc2ic) {
      setconcfluxooticasc2ic("./camadas/SP/investcorp/concfluxooticasc2ic.kmz");
      lastChecked = "concfluxooticasc2ic";
    }

    if (value.imoveisc2ic) {
      setimoveisc2ic("./camadas/SP/investcorp/imoveisc2ic.kmz");
      lastChecked = "imoveisc2ic";
    }

    if (value.shoppingsc2ic) {
      setshoppingsc2ic("./camadas/SP/investcorp/shoppingsc2ic.kmz");
      lastChecked = "shoppingsc2ic";
    }

    if (value.kernelsaltosc) {
      setkernelsaltosc("./camadas/SP/sucaosalto/kernelsaltosc.kmz");
      lastChecked = "kernelsaltosc";
    }

    if (value.kerneljacaresc) {
      setkerneljacaresc("./camadas/SP/sucaojacarepagua/kerneljacaresc.kmz");
      lastChecked = "kerneljacaresc";
    }

    if (value.ponibusmarasc) {
      setponibusmarasc("./camadas/SP/sucaocampogrande/ponibusmarasc.kmz");
      lastChecked = "ponibusmarasc";
    }

    if (value.lonibusmarasc) {
      setlonibusmarasc("./camadas/SP/sucaocampogrande/lonibusmarasc.kmz");
      lastChecked = "lonibusmarasc";
    }

    if (value.redesdrenalimeiramrv) {
      setredesdrenalimeiramrv("./camadas/SP/mrv/redesdrenalimeiramrv.kmz");
      lastChecked = "redesdrenalimeiramrv";
    }

    if (value.recargahidricalimeiramrv) {
      setrecargahidricalimeiramrv(
        "./camadas/SP/mrv/recargahidricalimeiramrv.kmz"
      );
      lastChecked = "recargahidricalimeiramrv";
    }

    if (value.ferroviaslimeiramrv) {
      setferroviaslimeiramrv("./camadas/SP/mrv/ferroviaslimeiramrv.kmz");
      lastChecked = "ferroviaslimeiramrv";
    }

    if (value.viaslimeiramrv) {
      setviaslimeiramrv("./camadas/SP/mrv/viaslimeiramrv.kmz");
      lastChecked = "viaslimeiramrv";
    }

    if (value.viasligalimeiramrv) {
      setviasligalimeiramrv("./camadas/SP/mrv/viasligalimeiramrv.kmz");
      lastChecked = "viasligalimeiramrv";
    }

    if (value.rankingterlimeiramrv) {
      setrankingterlimeiramrv("./camadas/SP/mrv/rankingterlimeiramrv.kml");
      lastChecked = "rankingterlimeiramrv";
    }

    if (value.redesdrenajundiaimrv) {
      setredesdrenajundiaimrv("./camadas/SP/mrv/redesdrenajundiaimrv.kmz");
      lastChecked = "redesdrenajundiaimrv";
    }

    if (value.recargahidricajundiaimrv) {
      setrecargahidricajundiaimrv(
        "./camadas/SP/mrv/recargahidricajundiaimrv.kmz"
      );
      lastChecked = "recargahidricajundiaimrv";
    }

    if (value.viasjundiaimrv) {
      setviasjundiaimrv("./camadas/SP/mrv/viasjundiaimrv.kmz");
      lastChecked = "viasjundiaimrv";
    }

    if (value.viasligajundiaimrv) {
      setviasligajundiaimrv("./camadas/SP/mrv/viasligajundiaimrv.kmz");
      lastChecked = "viasligajundiaimrv";
    }

    if (value.ferroviasjundiaimrv) {
      setferroviasjundiaimrv("./camadas/SP/mrv/ferroviasjundiaimrv.kmz");
      lastChecked = "ferroviasjundiaimrv";
    }

    if (value.equipamentosjundiaimrv) {
      setequipamentosjundiaimrv("./camadas/SP/mrv/equipamentosjundiaimrv.kmz");
      lastChecked = "equipamentosjundiaimrv";
    }

    if (value.rankingterjundiaimrv) {
      setrankingterjundiaimrv("./camadas/SP/mrv/rankingterjundiaimrv.kml");
      lastChecked = "rankingterjundiaimrv";
    }

    if (value.poluicaosonorajundiaimrv) {
      setpoluicaosonorajundiaimrv(
        "./camadas/SP/mrv/poluicaosonorajundiaimrv.kmz"
      );
      lastChecked = "poluicaosonorajundiaimrv";
    }

    if (value.acadmelten) {
      setacadmelten("./camadas/TO/melten/acadmelten.kmz");
      lastChecked = "acadmelten";
    }

    if (value.areasafastmelten) {
      setareasafastmelten("./camadas/TO/melten/areasafastmelten.kmz");
      lastChecked = "areasafastmelten";
    }

    if (value.areasproxmelten) {
      setareasproxmelten("./camadas/TO/melten/areasproxmelten.kmz");
      lastChecked = "areasproxmelten";
    }

    if (value.comerciosmelten) {
      setcomerciosmelten("./camadas/TO/melten/comerciosmelten.kmz");
      lastChecked = "comerciosmelten";
    }

    if (value.concconcmelten) {
      setconcconcmelten("./camadas/TO/melten/concconcmelten.kmz");
      lastChecked = "concconcmelten";
    }

    if (value.concmelten) {
      setconcmelten("./camadas/TO/melten/concmelten.kmz");
      lastChecked = "concmelten";
    }

    if (value.crescurbanomelten) {
      setcrescurbanomelten("./camadas/TO/melten/crescurbanomelten.kmz");
      lastChecked = "crescurbanomelten";
    }

    if (value.domiciliosmelten) {
      setdomiciliosmelten("./camadas/TO/melten/domiciliosmelten.kmz");
      lastChecked = "domiciliosmelten";
    }

    if (value.equipublimelten) {
      setequipublimelten("./camadas/TO/melten/equipublimelten.kmz");
      lastChecked = "equipublimelten";
    }

    if (value.escolasprivadasmelten) {
      setescolasprivadasmelten("./camadas/TO/melten/escolasprivadasmelten.kmz");
      lastChecked = "escolasprivadasmelten";
    }

    if (value.hidroviasmelten) {
      sethidroviasmelten("./camadas/TO/melten/hidroviasmelten.kmz");
      lastChecked = "hidroviasmelten";
    }

    if (value.hospitaismelten) {
      sethospitaismelten("./camadas/TO/melten/hospitaismelten.kmz");
      lastChecked = "hospitaismelten";
    }

    if (value.imoveismelten) {
      setimoveismelten("./camadas/TO/melten/imoveismelten.kmz");
      lastChecked = "imoveismelten";
    }

    if (value.infraviariamelten) {
      setinfraviariamelten("./camadas/TO/melten/infraviariamelten.kmz");
      lastChecked = "infraviariamelten";
    }

    if (value.melten) {
      setmelten("./camadas/TO/melten/melten.kmz");
      lastChecked = "melten";
    }

    if (value.palmasmelten) {
      setpalmasmelten("./camadas/TO/melten/palmasmelten.kmz");
      lastChecked = "palmasmelten";
    }

    if (value.popmelten) {
      setpopmelten("./camadas/TO/melten/popmelten.kmz");
      lastChecked = "popmelten";
    }

    if (value.rendamelten) {
      setrendamelten("./camadas/TO/melten/rendamelten.kmz");
      lastChecked = "rendamelten";
    }

    if (value.restaurantesmelten) {
      setrestaurantesmelten("./camadas/TO/melten/restaurantesmelten.kmz");
      lastChecked = "restaurantesmelten";
    }

    if (value.transportemelten) {
      settransportemelten("./camadas/TO/melten/transportemelten.kmz");
      lastChecked = "transportemelten";
    }

    if (value.viasmelten) {
      setviasmelten("./camadas/TO/melten/viasmelten.kmz");
      lastChecked = "viasmelten";
    }

    if (value.gtdipocircu) {
      setgtdipocircu("./camadas/PE/circulagem/gtdipocircu.kmz");
      lastChecked = "gtdipocircu";
    }

    if (value.arearedipocircu) {
      setarearedipocircu("./camadas/PE/circulagem/arearedipocircu.kmz");
      lastChecked = "arearedipocircu";
    }

    if (value.areaspotipocircu) {
      setareaspotipocircu("./camadas/PE/circulagem/areaspotipocircu.kmz");
      lastChecked = "areaspotipocircu";
    }

    if (value.coopreciipocircu) {
      setcoopreciipocircu("./camadas/PE/circulagem/coopreciipocircu.kmz");
      lastChecked = "coopreciipocircu";
    }

    if (value.ipocircu) {
      setipocircu("./camadas/PE/circulagem/ipocircu.kmz");
      lastChecked = "ipocircu";
    }

    if (value.empsipocircu) {
      setempsipocircu("./camadas/PE/circulagem/empsipocircu.kmz");
      lastChecked = "empsipocircu";
    }

    if (value.densempsipocircu) {
      setdensempsipocircu("./camadas/PE/circulagem/densempsipocircu.kmz");
      lastChecked = "densempsipocircu";
    }

    if (value.ecosipocircu) {
      setecosipocircu("./camadas/PE/circulagem/ecosipocircu.kmz");
      lastChecked = "ecosipocircu";
    }

    if (value.correcondcamacircu) {
      setcorrecondcamacircu("./camadas/BA/circulagem/correcondcamacircu.kmz");
      lastChecked = "correcondcamacircu";
    }

    if (value.correcondvargcircu) {
      setcorrecondvargcircu("./camadas/SP/circulagem/correcondvargcircu.kmz");
      lastChecked = "correcondvargcircu";
    }

    if (value.correcondcampcircu) {
      setcorrecondcampcircu("./camadas/SP/circulagem/correcondcampcircu.kmz");
      lastChecked = "correcondcampcircu";
    }

    if (value.correcondjundcircu) {
      setcorrecondjundcircu("./camadas/SP/circulagem/correcondjundcircu.kmz");
      lastChecked = "correcondjundcircu";
    }

    if (value.correcondsorocircu) {
      setcorrecondsorocircu("./camadas/SP/circulagem/correcondsorocircu.kmz");
      lastChecked = "correcondsorocircu";
    }

    if (value.regionalizacaomelten) {
      setregionalizacaomelten("./camadas/TO/melten/regionalizacaomelten.kmz");
      lastChecked = "regionalizacaomelten";
    }

    if (value.crescpecpau) {
      setcrescpecpau("./camadas/SP/peconipaulinia/crescpecpau.kmz");
      lastChecked = "crescpecpau";
    }

    if (value.dadossociospecpau) {
      setdadossociospecpau("./camadas/SP/peconipaulinia/dadossociospecpau.kmz");
      lastChecked = "dadossociospecpau";
    }

    if (value.hidropecpau) {
      sethidropecpau("./camadas/SP/peconipaulinia/hidropecpau.kmz");
      lastChecked = "hidropecpau";
    }

    if (value.limitesambpecpau) {
      setlimitesambpecpau("./camadas/SP/peconipaulinia/limitesambpecpau.kmz");
      lastChecked = "limitesambpecpau";
    }

    if (value.pontosfluxopecpau) {
      setpontosfluxopecpau("./camadas/SP/peconipaulinia/pontosfluxopecpau.kmz");
      lastChecked = "pontosfluxopecpau";
    }

    if (value.raios500pecpau) {
      setraios500pecpau("./camadas/SP/peconipaulinia/raios500pecpau.kmz");
      lastChecked = "raios500pecpau";
    }

    if (value.servidaopecpau) {
      setservidaopecpau("./camadas/SP/peconipaulinia/servidaopecpau.kmz");
      lastChecked = "servidaopecpau";
    }

    if (value.terrenospotpecpau) {
      setterrenospotpecpau("./camadas/SP/peconipaulinia/terrenospotpecpau.kmz");
      lastChecked = "terrenospotpecpau";
    }

    if (value.terrenosranqpecpau) {
      setterrenosranqpecpau(
        "./camadas/SP/peconipaulinia/terrenosranqpecpau.kmz"
      );
      lastChecked = "terrenosranqpecpau";
    }

    if (value.topopecpau) {
      settopopecpau("./camadas/SP/peconipaulinia/topopecpau.kmz");
      lastChecked = "topopecpau";
    }

    if (value.vaziospecpau) {
      setvaziospecpau("./camadas/SP/peconipaulinia/vaziospecpau.kmz");
      lastChecked = "vaziospecpau";
    }

    if (value.playersagrovh) {
      setplayersagrovh("./camadas/SP/venturehub/playersagrovh.kmz");
      lastChecked = "playersagrovh";
    }

    if (value.campinasvh) {
      setcampinasvh("./camadas/SP/venturehub/campinasvh.kmz");
      lastChecked = "campinasvh";
    }

    if (value.parquesteccampvh) {
      setparquesteccampvh("./camadas/SP/venturehub/parquesteccampvh.kmz");
      lastChecked = "parquesteccampvh";
    }

    if (value.demandagu) {
      setdemandagu("./camadas/" + estado.value + "/gurgel/demandagu.kmz");
      lastChecked = "demandagu";
    }

    if (value.ativpesquisagu) {
      setativpesquisagu(
        "./camadas/" + estado.value + "/gurgel/ativpesquisagu.kmz"
      );
      lastChecked = "ativpesquisagu";
    }

    if (value.interessehubgu) {
      setinteressehubgu(
        "./camadas/" + estado.value + "/gurgel/interessehubgu.kmz"
      );
      lastChecked = "interessehubgu";
    }

    if (value.modelotrabalhogu) {
      setmodelotrabalhogu(
        "./camadas/" + estado.value + "/gurgel/modelotrabalhogu.kmz"
      );
      lastChecked = "modelotrabalhogu";
    }

    if (value.capitalpropriogu) {
      setcapitalpropriogu(
        "./camadas/" + estado.value + "/gurgel/capitalpropriogu.kmz"
      );
      lastChecked = "capitalpropriogu";
    }

    if (value.fundestaduaisgu) {
      setfundestaduaisgu(
        "./camadas/" + estado.value + "/gurgel/fundestaduaisgu.kmz"
      );
      lastChecked = "fundestaduaisgu";
    }

    if (value.sebraegu) {
      setsebraegu("./camadas/" + estado.value + "/gurgel/sebraegu.kmz");
      lastChecked = "sebraegu";
    }

    if (value.bndesgu) {
      setbndesgu("./camadas/" + estado.value + "/gurgel/bndesgu.kmz");
      lastChecked = "bndesgu";
    }

    if (value.investidoranjogu) {
      setinvestidoranjogu(
        "./camadas/" + estado.value + "/gurgel/investidoranjogu.kmz"
      );
      lastChecked = "investidoranjogu";
    }

    if (value.fundosinvestgu) {
      setfundosinvestgu(
        "./camadas/" + estado.value + "/gurgel/fundosinvestgu.kmz"
      );
      lastChecked = "fundosinvestgu";
    }

    if (value.aceleradorasgu) {
      setaceleradorasgu(
        "./camadas/" + estado.value + "/gurgel/aceleradorasgu.kmz"
      );
      lastChecked = "aceleradorasgu";
    }

    if (value.semrespostagu) {
      setsemrespostagu(
        "./camadas/" + estado.value + "/gurgel/semrespostagu.kmz"
      );
      lastChecked = "semrespostagu";
    }

    if (value.ictsvh) {
      setictsvh("./camadas/SP/venturehub/ictsvh.kmz");
      lastChecked = "ictsvh";
    }

    if (value.ictsagrovh) {
      setictsagrovh("./camadas/SP/venturehub/ictsagrovh.kmz");
      lastChecked = "ictsagrovh";
    }

    if (value.areaspreservavespemcp) {
      setareaspreservavespemcp(
        "./camadas/MG/emccamp/areaspreservavespemcp.kmz"
      );
      lastChecked = "areaspreservavespemcp";
    }

    if (value.vegnativavespemcp) {
      setvegnativavespemcp("./camadas/MG/emccamp/vegnativavespemcp.kmz");
      lastChecked = "vegnativavespemcp";
    }

    if (value.reservalegalvespemcp) {
      setreservalegalvespemcp("./camadas/MG/emccamp/reservalegalvespemcp.kmz");
      lastChecked = "reservalegalvespemcp";
    }

    if (value.nascentesvespemcp) {
      setnascentesvespemcp("./camadas/MG/emccamp/nascentesvespemcp.kmz");
      lastChecked = "nascentesvespemcp";
    }

    if (value.cursosaguavespemcp) {
      setcursosaguavespemcp("./camadas/MG/emccamp/cursosaguavespemcp.kmz");
      lastChecked = "cursosaguavespemcp";
    }
    if (value.topografiavespemcp) {
      settopografiavespemcp("./camadas/MG/emccamp/topografiavespemcp.kmz");
      lastChecked = "topografiavespemcp";
    }

    if (value.limitevespemcp) {
      setlimitevespemcp("./camadas/MG/emccamp/limitevespemcp.kmz");
      lastChecked = "limitevespemcp";
    }

    if (value.zoneamentovespemcp) {
      setzoneamentovespemcp("./camadas/MG/emccamp/zoneamentovespemcp.kmz");
      lastChecked = "zoneamentovespemcp";
    }

    if (value.usovertvespemcp) {
      setusovertvespemcp("./camadas/MG/emccamp/usovertvespemcp.kmz");
      lastChecked = "usovertvespemcp";
    }

    if (value.permissaovespemcp) {
      setpermissaovespemcp("./camadas/MG/emccamp/permissaovespemcp.kmz");
      lastChecked = "permissaovespemcp";
    }
    if (value.locterrenovespemcp) {
      setlocterrenovespemcp("./camadas/MG/emccamp/locterrenovespemcp.kmz");
      lastChecked = "locterrenovespemcp";
    }

    if (value.servidaovespemcp) {
      setservidaovespemcp("./camadas/MG/emccamp/servidaovespemcp.kmz");
      lastChecked = "servidaovespemcp";
    }

    if (value.areaconsovespemcp) {
      setareaconsovespemcp("./camadas/MG/emccamp/areaconsovespemcp.kmz");
      lastChecked = "areaconsovespemcp";
    }

    if (value.areascontavespemcp) {
      setareascontavespemcp("./camadas/MG/emccamp/areascontavespemcp.kmz");
      lastChecked = "areascontavespemcp";
    }

    if (value.dutoviasvespemcp) {
      setdutoviasvespemcp("./camadas/MG/emccamp/dutoviasvespemcp.kmz");
      lastChecked = "dutoviasvespemcp";
    }
    if (value.linhaovespemcp) {
      setlinhaovespemcp("./camadas/MG/emccamp/linhaovespemcp.kmz");
      lastChecked = "linhaovespemcp";
    }

    if (value.novoscondvespemcp) {
      setnovoscondvespemcp("./camadas/MG/emccamp/novoscondvespemcp.kmz");
      lastChecked = "novoscondvespemcp";
    }

    if (value.rendavespemcp) {
      setrendavespemcp("./camadas/MG/emccamp/rendavespemcp.kmz");
      lastChecked = "rendavespemcp";
    }

    if (value.popvespemcp) {
      setpopvespemcp("./camadas/MG/emccamp/popvespemcp.kmz");
      lastChecked = "popvespemcp";
    }

    if (value.domicivespemcp) {
      setdomicivespemcp("./camadas/MG/emccamp/domicivespemcp.kmz");
      lastChecked = "domicivespemcp";
    }
    if (value.domicialuvespemcp) {
      setdomicialuvespemcp("./camadas/MG/emccamp/domicialuvespemcp.kmz");
      lastChecked = "domicialuvespemcp";
    }

    if (value.expurbanavespemcp) {
      setexpurbanavespemcp("./camadas/MG/emccamp/expurbanavespemcp.kmz");
      lastChecked = "expurbanavespemcp";
    }

    if (value.veloeprecovespemcp) {
      setveloeprecovespemcp("./camadas/MG/emccamp/veloeprecovespemcp.kmz");
      lastChecked = "veloeprecovespemcp";
    }

    if (value.taxahabvespemcp) {
      settaxahabvespemcp("./camadas/MG/emccamp/taxahabvespemcp.kmz");
      lastChecked = "taxahabvespemcp";
    }

    if (value.concorrenciavespemcp) {
      setconcorrenciavespemcp("./camadas/MG/emccamp/concorrenciavespemcp.kmz");
      lastChecked = "concorrenciavespemcp";
    }
    if (value.areaurbavespemcp) {
      setareaurbavespemcp("./camadas/MG/emccamp/areaurbavespemcp.kmz");
      lastChecked = "areaurbavespemcp";
    }

    if (value.areasruraisvespemcp) {
      setareasruraisvespemcp("./camadas/MG/emccamp/areasruraisvespemcp.kmz");
      lastChecked = "areasruraisvespemcp";
    }

    if (value.consuaguavespemcp) {
      setconsuaguavespemcp("./camadas/MG/emccamp/consuaguavespemcp.kmz");
      lastChecked = "consuaguavespemcp";
    }

    if (value.dispaguavespemcp) {
      setdispaguavespemcp("./camadas/MG/emccamp/dispaguavespemcp.kmz");
      lastChecked = "dispaguavespemcp";
    }

    if (value.custoprojetovespemcp) {
      setcustoprojetovespemcp("./camadas/MG/emccamp/custoprojetovespemcp.kmz");
      lastChecked = "custoprojetovespemcp";
    }
    if (value.terrenosanunvespemcp) {
      setterrenosanunvespemcp("./camadas/MG/emccamp/terrenosanunvespemcp.kmz");
      lastChecked = "terrenosanunvespemcp";
    }

    if (value.imoveisanunvespemcp) {
      setimoveisanunvespemcp("./camadas/MG/emccamp/imoveisanunvespemcp.kmz");
      lastChecked = "imoveisanunvespemcp";
    }

    if (value.pontosatravespemcp) {
      setpontosatravespemcp("./camadas/MG/emccamp/pontosatravespemcp.kmz");
      lastChecked = "pontosatravespemcp";
    }

    if (value.pontosdetravespemcp) {
      setpontosdetravespemcp("./camadas/MG/emccamp/pontosdetravespemcp.kmz");
      lastChecked = "pontosdetravespemcp";
    }

    if (value.tjchpdtjd) {
      settjchpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/tjchpdtjd.kmz"
      );
      lastChecked = "tjchpdtjd";
    }

    if (value.compdiretoschpdtjd) {
      setcompdiretoschpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/compdiretoschpdtjd.kmz"
      );
      lastChecked = "compdiretoschpdtjd";
    }

    if (value.criancas05chpdtjd) {
      setcriancas05chpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/criancas05chpdtjd.kmz"
      );
      lastChecked = "criancas05chpdtjd";
    }

    if (value.escolasidiomaschpdtjd) {
      setescolasidiomaschpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/escolasidiomaschpdtjd.kmz"
      );
      lastChecked = "escolasidiomaschpdtjd";
    }

    if (value.escolasinfantischpdtjd) {
      setescolasinfantischpdtjd(
        "./camadas/" +
          estado.value +
          "/thej/Chapadao/escolasinfantischpdtjd.kmz"
      );
      lastChecked = "escolasinfantischpdtjd";
    }

    if (value.escolaschpdtjd) {
      setescolaschpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/escolaschpdtjd.kmz"
      );
      lastChecked = "escolaschpdtjd";
    }

    if (value.imovelindicadochpdtjd) {
      setimovelindicadochpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/imovelindicadochpdtjd.kmz"
      );
      lastChecked = "imovelindicadochpdtjd";
    }

    if (value.pontosfluxochpdtjd) {
      setpontosfluxochpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/pontosfluxochpdtjd.kmz"
      );
      lastChecked = "pontosfluxochpdtjd";
    }

    if (value.principaisviaschpdtjd) {
      setprincipaisviaschpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/principaisviaschpdtjd.kmz"
      );
      lastChecked = "principaisviaschpdtjd";
    }

    if (value.raio1kmcompchpdtjd) {
      setraio1kmcompchpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/raio1kmcompchpdtjd.kmz"
      );
      lastChecked = "raio1kmcompchpdtjd";
    }

    if (value.raio2kmcompchpdtjd) {
      setraio2kmcompchpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/raio2kmcompchpdtjd.kmz"
      );
      lastChecked = "raio2kmcompchpdtjd";
    }

    if (value.rendafamiliarchpdtjd) {
      setrendafamiliarchpdtjd(
        "./camadas/" + estado.value + "/thej/Chapadao/rendafamiliarchpdtjd.kmz"
      );
      lastChecked = "rendafamiliarchpdtjd";
    }

    if (value.areainflurctjd) {
      setareainflurctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/areainflurctjd.kmz"
      );
      lastChecked = "areainflurctjd";
    }

    if (value.areasindirctjd) {
      setareasindirctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/areasindirctjd.kmz"
      );
      lastChecked = "areasindirctjd";
    }

    if (value.competidoresrctjd) {
      setcompetidoresrctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/competidoresrctjd.kmz"
      );
      lastChecked = "competidoresrctjd";
    }

    if (value.condominiosrctjd) {
      setcondominiosrctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/condominiosrctjd.kmz"
      );
      lastChecked = "condominiosrctjd";
    }

    if (value.imoveisrctjd) {
      setimoveisrctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/imoveisrctjd.kmz"
      );
      lastChecked = "imoveisrctjd";
    }

    if (value.pontosfluxirctjd) {
      setpontosfluxirctjd(
        "./camadas/" + estado.value + "/thej/RioClaro/pontosfluxirctjd.kmz"
      );
      lastChecked = "pontosfluxirctjd";
    }

    if (value.compdiretosvptjd) {
      setcompdiretosvptjd(
        "./camadas/" + estado.value + "/thej/VilaPrudente/compdiretosvptjd.kmz"
      );
      lastChecked = "compdiretosvptjd";
    }

    if (value.imoveissapopembavptjd) {
      setimoveissapopembavptjd(
        "./camadas/" +
          estado.value +
          "/thej/VilaPrudente/imoveissapopembavptjd.kmz"
      );
      lastChecked = "imoveissapopembavptjd";
    }

    if (value.imoveisvpvptjd) {
      setimoveisvpvptjd(
        "./camadas/" + estado.value + "/thej/VilaPrudente/imoveisvpvptjd.kmz"
      );
      lastChecked = "imoveisvpvptjd";
    }

    if (value.pontosfluxovptjd) {
      setpontosfluxovptjd(
        "./camadas/" + estado.value + "/thej/VilaPrudente/pontosfluxovptjd.kmz"
      );
      lastChecked = "pontosfluxovptjd";
    }

    if (value.rendafamiliarvptjd) {
      setrendafamiliarvptjd(
        "./camadas/" +
          estado.value +
          "/thej/VilaPrudente/rendafamiliarvptjd.kmz"
      );
      lastChecked = "rendafamiliarvptjd";
    }

    if (value.tjschoolvptjd) {
      settjschoolvptjd(
        "./camadas/" + estado.value + "/thej/VilaPrudente/tjschoolvptjd.kmz"
      );
      lastChecked = "tjschoolvptjd";
    }

    if (value.appwbgi) {
      setappwbgi("./camadas/" + estado.value + "/wbgi/appwbgi.kmz");
      lastChecked = "appwbgi";
    }

    if (value.areacontwbgi) {
      setareacontwbgi("./camadas/" + estado.value + "/wbgi/areacontwbgi.kmz");
      lastChecked = "areacontwbgi";
    }

    if (value.curvasnivelwbgi) {
      setcurvasnivelwbgi(
        "./camadas/" + estado.value + "/wbgi/curvasnivelwbgi.kmz"
      );
      lastChecked = "curvasnivelwbgi";
    }

    if (value.declividadewbgi) {
      setdeclividadewbgi(
        "./camadas/" + estado.value + "/wbgi/declividadewbgi.kmz"
      );
      lastChecked = "declividadewbgi";
    }

    if (value.distcorposaguawbgi) {
      setdistcorposaguawbgi(
        "./camadas/" + estado.value + "/wbgi/distcorposaguawbgi.kmz"
      );
      lastChecked = "distcorposaguawbgi";
    }

    if (value.distcorposhidricoswbgi) {
      setdistcorposhidricoswbgi(
        "./camadas/" + estado.value + "/wbgi/distcorposhidricoswbgi.kmz"
      );
      lastChecked = "distcorposhidricoswbgi";
    }

    if (value.hidrografiawbgi) {
      sethidrografiawbgi(
        "./camadas/" + estado.value + "/wbgi/hidrografiawbgi.kmz"
      );
      lastChecked = "hidrografiawbgi";
    }

    if (value.limeirawbgi) {
      setlimeirawbgi("./camadas/" + estado.value + "/wbgi/limeirawbgi.kmz");
      lastChecked = "limeirawbgi";
    }

    if (value.reservalegalwbgi) {
      setreservalegalwbgi(
        "./camadas/" + estado.value + "/wbgi/reservalegalwbgi.kmz"
      );
      lastChecked = "reservalegalwbgi";
    }

    if (value.vegnativawbgi) {
      setvegnativawbgi("./camadas/" + estado.value + "/wbgi/vegnativawbgi.kmz");
      lastChecked = "vegnativawbgi";
    }

    if (value.viveiristaswbgi) {
      setviveiristaswbgi(
        "./camadas/" + estado.value + "/wbgi/viveiristaswbgi.kmz"
      );
      lastChecked = "viveiristaswbgi";
    }

    if (value.limiteosascotnd) {
      setlimiteosascotnd(
        "./camadas/" + estado.value + "/tenda/limiteosascotnd.kmz"
      );
      lastChecked = "limiteosascotnd";
    }

    if (value.areapreserosascotnd) {
      setareapreserosascotnd(
        "./camadas/" + estado.value + "/tenda/areapreserosascotnd.kmz"
      );
      lastChecked = "areapreserosascotnd";
    }

    if (value.vegnaivaosascotnd) {
      setvegnaivaosascotnd(
        "./camadas/" + estado.value + "/tenda/vegnaivaosascotnd.kmz"
      );
      lastChecked = "vegnaivaosascotnd";
    }

    if (value.reservaosascotnd) {
      setreservaosascotnd(
        "./camadas/" + estado.value + "/tenda/reservaosascotnd.kmz"
      );
      lastChecked = "reservaosascotnd";
    }

    if (value.nascentesosascotnd) {
      setnascentesosascotnd(
        "./camadas/" + estado.value + "/tenda/nascentesosascotnd.kmz"
      );
      lastChecked = "nascentesosascotnd";
    }

    if (value.cursososascotnd) {
      setcursososascotnd(
        "./camadas/" + estado.value + "/tenda/cursososascotnd.kmz"
      );
      lastChecked = "cursososascotnd";
    }

    if (value.corpososascotnd) {
      setcorpososascotnd(
        "./camadas/" + estado.value + "/tenda/corpososascotnd.kmz"
      );
      lastChecked = "corpososascotnd";
    }

    if (value.topografiaosascotnd) {
      settopografiaosascotnd(
        "./camadas/" + estado.value + "/tenda/topografiaosascotnd.kmz"
      );
      lastChecked = "topografiaosascotnd";
    }

    if (value.zoneamentoosascotnd) {
      setzoneamentoosascotnd(
        "./camadas/" + estado.value + "/tenda/zoneamentoosascotnd.kmz"
      );
      lastChecked = "zoneamentoosascotnd";
    }

    if (value.usovertosascotnd) {
      setusovertosascotnd(
        "./camadas/" + estado.value + "/tenda/usovertosascotnd.kmz"
      );
      lastChecked = "usovertosascotnd";
    }

    if (value.implantacaohishmposascotnd) {
      setimplantacaohishmposascotnd(
        "./camadas/" + estado.value + "/tenda/implantacaohishmposascotnd.kmz"
      );
      lastChecked = "implantacaohishmposascotnd";
    }

    if (value.servadmosascotnd) {
      setservadmosascotnd(
        "./camadas/" + estado.value + "/tenda/servadmosascotnd.kmz"
      );
      lastChecked = "servadmosascotnd";
    }

    if (value.areaconsoosascotnd) {
      setareaconsoosascotnd(
        "./camadas/" + estado.value + "/tenda/areaconsoosascotnd.kmz"
      );
      lastChecked = "areaconsoosascotnd";
    }

    if (value.areascontaosascotnd) {
      setareascontaosascotnd(
        "./camadas/" + estado.value + "/tenda/areascontaosascotnd.kmz"
      );
      lastChecked = "areascontaosascotnd";
    }

    if (value.dutoviasosascotnd) {
      setdutoviasosascotnd(
        "./camadas/" + estado.value + "/tenda/dutoviasosascotnd.kmz"
      );
      lastChecked = "dutoviasosascotnd";
    }

    if (value.linhaoosascotnd) {
      setlinhaoosascotnd(
        "./camadas/" + estado.value + "/tenda/linhaoosascotnd.kmz"
      );
      lastChecked = "linhaoosascotnd";
    }

    if (value.pontosatratosascotnd) {
      setpontosatratosascotnd(
        "./camadas/" + estado.value + "/tenda/pontosatratosascotnd.kmz"
      );
      lastChecked = "pontosatratosascotnd";
    }

    if (value.pontosdetratosascotnd) {
      setpontosdetratosascotnd(
        "./camadas/" + estado.value + "/tenda/pontosdetratosascotnd.kmz"
      );
      lastChecked = "pontosdetratosascotnd";
    }

    if (value.rendaosascotnd) {
      setrendaosascotnd(
        "./camadas/" + estado.value + "/tenda/rendaosascotnd.kmz"
      );
      lastChecked = "rendaosascotnd";
    }

    if (value.domiciliososascotnd) {
      setdomiciliososascotnd(
        "./camadas/" + estado.value + "/tenda/domiciliososascotnd.kmz"
      );
      lastChecked = "domiciliososascotnd";
    }

    if (value.domiciliosaluguelosascotnd) {
      setdomiciliosaluguelosascotnd(
        "./camadas/" + estado.value + "/tenda/domiciliosaluguelosascotnd.kmz"
      );
      lastChecked = "domiciliosaluguelosascotnd";
    }

    if (value.expcidadesosascotnd) {
      setexpcidadesosascotnd(
        "./camadas/" + estado.value + "/tenda/expcidadesosascotnd.kmz"
      );
      lastChecked = "expcidadesosascotnd";
    }

    if (value.precomedioosascotnd) {
      setprecomedioosascotnd(
        "./camadas/" + estado.value + "/tenda/precomedioosascotnd.kmz"
      );
      lastChecked = "precomedioosascotnd";
    }

    if (value.taxahabosascotnd) {
      settaxahabosascotnd(
        "./camadas/" + estado.value + "/tenda/taxahabosascotnd.kmz"
      );
      lastChecked = "taxahabosascotnd";
    }

    if (value.concoosascotnd) {
      setconcoosascotnd(
        "./camadas/" + estado.value + "/tenda/concoosascotnd.kmz"
      );
      lastChecked = "concoosascotnd";
    }

    if (value.areaurbanizadaosascotnd) {
      setareaurbanizadaosascotnd(
        "./camadas/" + estado.value + "/tenda/areaurbanizadaosascotnd.kmz"
      );
      lastChecked = "areaurbanizadaosascotnd";
    }

    if (value.areasruraisosascotnd) {
      setareasruraisosascotnd(
        "./camadas/" + estado.value + "/tenda/areasruraisosascotnd.kmz"
      );
      lastChecked = "areasruraisosascotnd";
    }

    if (value.vaziosurbanososascotnd) {
      setvaziosurbanososascotnd(
        "./camadas/" + estado.value + "/tenda/vaziosurbanososascotnd.kmz"
      );
      lastChecked = "vaziosurbanososascotnd";
    }

    if (value.grapohabosascotnd) {
      setgrapohabosascotnd(
        "./camadas/" + estado.value + "/tenda/grapohabosascotnd.kmz"
      );
      lastChecked = "grapohabosascotnd";
    }

    if (value.terrenososascotnd) {
      setterrenososascotnd(
        "./camadas/" + estado.value + "/tenda/terrenososascotnd.kmz"
      );
      lastChecked = "terrenososascotnd";
    }

    if (value.imoveisosascotnd) {
      setimoveisosascotnd(
        "./camadas/" + estado.value + "/tenda/imoveisosascotnd.kmz"
      );
      lastChecked = "imoveisosascotnd";
    }

    if (value.tendenciassetorosascotnd) {
      settendenciassetorosascotnd(
        "./camadas/" + estado.value + "/tenda/tendenciassetorosascotnd.kmz"
      );
      lastChecked = "tendenciassetorosascotnd";
    }

    if (value.sinaismudancaosascotnd) {
      setsinaismudancaosascotnd(
        "./camadas/" + estado.value + "/tenda/sinaismudancaosascotnd.kmz"
      );
      lastChecked = "sinaismudancaosascotnd";
    }

    if (value.oportunidadeosascotnd) {
      setoportunidadeosascotnd(
        "./camadas/" + estado.value + "/tenda/oportunidadeosascotnd.kmz"
      );
      lastChecked = "oportunidadeosascotnd";
    }

    if (value.areausorestosascotnd) {
      setareausorestosascotnd(
        "./camadas/" + estado.value + "/tenda/areausorestosascotnd.kmz"
      );
      lastChecked = "areausorestosascotnd";
    }

    if (value.areapotlimeiramrv) {
      setareapotlimeiramrv(
        "./camadas/" + estado.value + "/mrv/areapotlimeiramrv.kmz"
      );
      lastChecked = "areapotlimeiramrv";
    }


    if (value.areapreserslgb) {
      setareapreserslgb(
        "./camadas/" + estado.value + "/gb/areapreserslgb.kmz"
      );
      lastChecked = "areapreserslgb";
    }

    if (value.vegnativaslgb) {
      setvegnativaslgb(
        "./camadas/" + estado.value + "/gb/vegnativaslgb.kmz"
      );
      lastChecked = "vegnativaslgb";
    }

    if (value.reservaslgb) {
      setreservaslgb(
        "./camadas/" + estado.value + "/gb/reservaslgb.kmz"
      );
      lastChecked = "reservaslgb";
    }

    if (value.nascentesslgb) {
      setnascentesslgb(
        "./camadas/" + estado.value + "/gb/nascentesslgb.kmz"
      );
      lastChecked = "nascentesslgb";
    }

    if (value.hidrografiaslgb) {
      sethidrografiaslgb(
        "./camadas/" + estado.value + "/gb/hidrografiaslgb.kmz"
      );
      lastChecked = "hidrografiaslgb";
    }

    if (value.topografiaslgb) {
      settopografiaslgb(
        "./camadas/" + estado.value + "/gb/topografiaslgb.kmz"
      );
      lastChecked = "topografiaslgb";
    }

    if (value.zoneamentoslgb) {
      setzoneamentoslgb(
        "./camadas/" + estado.value + "/gb/zoneamentoslgb.kmz"
      );
      lastChecked = "zoneamentoslgb";
    }

    if (value.usovertslgb) {
      setusovertslgb(
        "./camadas/" + estado.value + "/gb/usovertslgb.kmz"
      );
      lastChecked = "usovertslgb";
    }

    if (value.usohorslgb) {
      setusohorslgb(
        "./camadas/" + estado.value + "/gb/usohorslgb.kmz"
      );
      lastChecked = "usohorslgb";
    }

    if (value.viasslgb) {
      setviasslgb(
        "./camadas/" + estado.value + "/gb/viasslgb.kmz"
      );
      lastChecked = "viasslgb";
    }
    if (value.dutoviasslgb) {
      setdutoviasslgb(
        "./camadas/" + estado.value + "/gb/dutoviasslgb.kmz"
      );
      lastChecked = "dutoviasslgb";
    }

    if (value.linhaoslgb) {
      setlinhaoslgb(
        "./camadas/" + estado.value + "/gb/linhaoslgb.kmz"
      );
      lastChecked = "linhaoslgb";
    }

    if (value.pontosatratosslgb) {
      setpontosatratosslgb(
        "./camadas/" + estado.value + "/gb/pontosatratosslgb.kmz"
      );
      lastChecked = "pontosatratosslgb";
    }

    if (value.pontosdetratslgb) {
      setpontosdetratslgb(
        "./camadas/" + estado.value + "/gb/pontosdetratslgb.kmz"
      );
      lastChecked = "pontosdetratslgb";
    }

    if (value.rendaslgb) {
      setrendaslgb(
        "./camadas/" + estado.value + "/gb/rendaslgb.kmz"
      );
      lastChecked = "rendaslgb";
    }

    if (value.domiciliosslgb) {
      setdomiciliosslgb(
        "./camadas/" + estado.value + "/gb/domiciliosslgb.kmz"
      );
      lastChecked = "domiciliosslgb";
    }

    if (value.concoslgb) {
      setconcoslgb(
        "./camadas/" + estado.value + "/gb/concoslgb.kmz"
      );
      lastChecked = "concoslgb";
    }

    if (value.areasruraisslgb) {
      setareasruraisslgb(
        "./camadas/" + estado.value + "/gb/areasruraisslgb.kmz"
      );
      lastChecked = "areasruraisslgb";
    }

    if (value.terrenosslgb) {
      setterrenosslgb(
        "./camadas/" + estado.value + "/gb/terrenosslgb.kmz"
      );
      lastChecked = "terrenosslgb";
    }

    if (value.imoveisslgb) {
      setimoveisslgb(
        "./camadas/" + estado.value + "/gb/imoveisslgb.kmz"
      );
      lastChecked = "imoveisslgb";
    }

    if (value.raiopesquisaslgb) {
      setraiopesquisaslgb(
        "./camadas/" + estado.value + "/gb/raiopesquisaslgb.kmz"
      );
      lastChecked = "raiopesquisaslgb";
    }

    if (value.terrenosanaslgb) {
      setterrenosanaslgb(
        "./camadas/" + estado.value + "/gb/terrenosanaslgb.kmz"
      );
      lastChecked = "terrenosanaslgb";
    }


    if (value.limitestatnd) {
      setlimitestatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/limitestatnd.kmz"
      );
      lastChecked = "limitestatnd";
    }

    if (value.areapreserstatnd) {
      setareapreserstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areapreserstatnd.kmz"
      );
      lastChecked = "areapreserstatnd";
    }

    if (value.vegnaivastatnd) {
      setvegnaivastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/vegnaivastatnd.kmz"
      );
      lastChecked = "vegnaivastatnd";
    }

    if (value.reservastatnd) {
      setreservastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/reservastatnd.kmz"
      );
      lastChecked = "reservastatnd";
    }

    if (value.nascentesstatnd) {
      setnascentesstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/nascentesstatnd.kmz"
      );
      lastChecked = "nascentesstatnd";
    }

    if (value.hidrocarstatnd) {
      sethidrocarstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/hidrocarstatnd.kmz"
      );
      lastChecked = "hidrocarstatnd";
    }

    if (value.hidrocompstatnd) {
      sethidrocompstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/hidrocompstatnd.kmz"
      );
      lastChecked = "hidrocompstatnd";
    }

    if (value.topografiastatnd) {
      settopografiastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/topografiastatnd.kmz"
      );
      lastChecked = "topografiastatnd";
    }

    if (value.zoneamentostatnd) {
      setzoneamentostatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/zoneamentostatnd.kmz"
      );
      lastChecked = "zoneamentostatnd";
    }

    if (value.usovertstatnd) {
      setusovertstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/usovertstatnd.kmz"
      );
      lastChecked = "usovertstatnd";
    }

    if (value.implantacaohishmpstatnd) {
      setimplantacaohishmpstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/implantacaohishmpstatnd.kmz"
      );
      lastChecked = "implantacaohishmpstatnd";
    }

    if (value.servadmstatnd) {
      setservadmstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/servadmstatnd.kmz"
      );
      lastChecked = "servadmstatnd";
    }

    if (value.areausoreststatnd) {
      setareausoreststatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areausoreststatnd.kmz"
      );
      lastChecked = "areausoreststatnd";
    }

    if (value.areaconsostatnd) {
      setareaconsostatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areaconsostatnd.kmz"
      );
      lastChecked = "areaconsostatnd";
    }

    if (value.areascontastatnd) {
      setareascontastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areascontastatnd.kmz"
      );
      lastChecked = "areascontastatnd";
    }

    if (value.dutoviasstatnd) {
      setdutoviasstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/dutoviasstatnd.kmz"
      );
      lastChecked = "dutoviasstatnd";
    }

    if (value.linhaostatnd) {
      setlinhaostatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/linhaostatnd.kmz"
      );
      lastChecked = "linhaostatnd";
    }

    if (value.rededrenagemstatnd) {
      setrededrenagemstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/rededrenagemstatnd.kmz"
      );
      lastChecked = "rededrenagemstatnd";
    }

    if (value.pontosatratstatnd) {
      setpontosatratstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/pontosatratstatnd.kmz"
      );
      lastChecked = "pontosatratstatnd";
    }

    if (value.pontosdetratstatnd) {
      setpontosdetratstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/pontosdetratstatnd.kmz"
      );
      lastChecked = "pontosdetratstatnd";
    }

    if (value.domiciliosstatnd) {
      setdomiciliosstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/domiciliosstatnd.kmz"
      );
      lastChecked = "domiciliosstatnd";
    }

    if (value.rendastatnd) {
      setrendastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/rendastatnd.kmz"
      );
      lastChecked = "rendastatnd";
    }

    if (value.domiciliosaluguelstatnd) {
      setdomiciliosaluguelstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/domiciliosaluguelstatnd.kmz"
      );
      lastChecked = "domiciliosaluguelstatnd";
    }

    if (value.expcidadesstatnd) {
      setexpcidadesstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/expcidadesstatnd.kmz"
      );
      lastChecked = "expcidadesstatnd";
    }

    if (value.expverticalstatnd) {
      setexpverticalstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/expverticalstatnd.kmz"
      );
      lastChecked = "expverticalstatnd";
    }

    if (value.precomediostatnd) {
      setprecomediostatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/precomediostatnd.kmz"
      );
      lastChecked = "precomediostatnd";
    }

    if (value.taxahabstatnd) {
      settaxahabstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/taxahabstatnd.kmz"
      );
      lastChecked = "taxahabstatnd";
    }

    if (value.areaurbanizadastatnd) {
      setareaurbanizadastatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areaurbanizadastatnd.kmz"
      );
      lastChecked = "areaurbanizadastatnd";
    }

    if (value.areasruraisstatnd) {
      setareasruraisstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/areasruraisstatnd.kmz"
      );
      lastChecked = "areasruraisstatnd";
    }

    if (value.vaziosurbanosstatnd) {
      setvaziosurbanosstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/vaziosurbanosstatnd.kmz"
      );
      lastChecked = "vaziosurbanosstatnd";
    }

    if (value.grapohabstatnd) {
      setgrapohabstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/grapohabstatnd.kmz"
      );
      lastChecked = "grapohabstatnd";
    }

    if (value.terrenosstatnd) {
      setterrenosstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/terrenosstatnd.kmz"
      );
      lastChecked = "terrenosstatnd";
    }

    if (value.imoveisstatnd) {
      setimoveisstatnd(
        "./camadas/" + estado.value + "/tenda/santoandre/imoveisstatnd.kmz"
      );
      lastChecked = "imoveisstatnd";
    }

    if (value.limiteriva) {
      setlimiteriva(
        "./camadas/" + estado.value + "/riva/limiteriva.kmz"
      );
      lastChecked = "limiteriva";
    }

    if (value.recorteriva) {
      setrecorteriva(
        "./camadas/" + estado.value + "/riva/recorteriva.kmz"
      );
      lastChecked = "recorteriva";
    }

    if (value.areapreserriva) {
      setareapreserriva(
        "./camadas/" + estado.value + "/riva/areapreserriva.kmz"
      );
      lastChecked = "areapreserriva";
    }

    if (value.conservacaoriva) {
      setconservacaoriva(
        "./camadas/" + estado.value + "/riva/conservacaoriva.kmz"
      );
      lastChecked = "conservacaoriva";
    }

    if (value.cursosriva) {
      setcursosriva(
        "./camadas/" + estado.value + "/riva/cursosriva.kmz"
      );
      lastChecked = "cursosriva";
    }

    if (value.nascentesriva) {
      setnascentesriva(
        "./camadas/" + estado.value + "/riva/nascentesriva.kmz"
      );
      lastChecked = "nascentesriva";
    }

    if (value.areasriscoriva) {
      setareasriscoriva(
        "./camadas/" + estado.value + "/riva/areasriscoriva.kmz"
      );
      lastChecked = "areasriscoriva";
    }

    if (value.areasriscocontriva) {
      setareasriscocontriva(
        "./camadas/" + estado.value + "/riva/areasriscocontriva.kmz"
      );
      lastChecked = "areasriscocontriva";
    }

    if (value.topografiariva) {
      settopografiariva(
        "./camadas/" + estado.value + "/riva/topografiariva.kmz"
      );
      lastChecked = "topografiariva";
    }

    if (value.zoneamentoriva) {
      setzoneamentoriva(
        "./camadas/" + estado.value + "/riva/zoneamentoriva.kmz"
      );
      lastChecked = "zoneamentoriva";
    }

    if (value.usovertriva) {
      setusovertriva(
        "./camadas/" + estado.value + "/riva/usovertriva.kmz"
      );
      lastChecked = "usovertriva";
    }

    if (value.aeroportosriva) {
      setaeroportosriva(
        "./camadas/" + estado.value + "/riva/aeroportosriva.kmz"
      );
      lastChecked = "aeroportosriva";
    }

    if (value.dutoviasriva) {
      setdutoviasriva(
        "./camadas/" + estado.value + "/riva/dutoviasriva.kmz"
      );
      lastChecked = "dutoviasriva";
    }

    if (value.linhaoriva) {
      setlinhaoriva(
        "./camadas/" + estado.value + "/riva/linhaoriva.kmz"
      );
      lastChecked = "linhaoriva";
    }

    if (value.protculturalriva) {
      setprotculturalriva(
        "./camadas/" + estado.value + "/riva/protculturalriva.kmz"
      );
      lastChecked = "protculturalriva";
    }

    if (value.bemcultriva) {
      setbemcultriva(
        "./camadas/" + estado.value + "/riva/bemcultriva.kmz"
      );
      lastChecked = "bemcultriva";
    }

    if (value.bemcultimovelriva) {
      setbemcultimovelriva(
        "./camadas/" + estado.value + "/riva/bemcultimovelriva.kmz"
      );
      lastChecked = "bemcultimovelriva";
    }

    if (value.bemcultnaturalriva) {
      setbemcultnaturalriva(
        "./camadas/" + estado.value + "/riva/bemcultnaturalriva.kmz"
      );
      lastChecked = "bemcultnaturalriva";
    }

    if (value.diretrizriva) {
      setdiretrizriva(
        "./camadas/" + estado.value + "/riva/diretrizriva.kmz"
      );
      lastChecked = "diretrizriva";
    }

    if (value.tombamentoriva) {
      settombamentoriva(
        "./camadas/" + estado.value + "/riva/tombamentoriva.kmz"
      );
      lastChecked = "tombamentoriva";
    }

    if (value.pontosatratriva) {
      setpontosatratriva(
        "./camadas/" + estado.value + "/riva/pontosatratriva.kmz"
      );
      lastChecked = "pontosatratriva";
    }

    if (value.pontosdetratriva) {
      setpontosdetratriva(
        "./camadas/" + estado.value + "/riva/pontosdetratriva.kmz"
      );
      lastChecked = "pontosdetratriva";
    }

    if (value.ocupacaoriva) {
      setocupacaoriva(
        "./camadas/" + estado.value + "/riva/ocupacaoriva.kmz"
      );
      lastChecked = "ocupacaoriva";
    }

    if (value.rendariva) {
      setrendariva(
        "./camadas/" + estado.value + "/riva/rendariva.kmz"
      );
      lastChecked = "rendariva";
    }

    if (value.pmedioriva) {
      setpmedioriva(
        "./camadas/" + estado.value + "/riva/pmedioriva.kmz"
      );
      lastChecked = "pmedioriva";
    }

    if (value.taxahabriva) {
      settaxahabriva(
        "./camadas/" + estado.value + "/riva/taxahabriva.kmz"
      );
      lastChecked = "taxahabriva";
    }

    if (value.concoriva) {
      setconcoriva(
        "./camadas/" + estado.value + "/riva/concoriva.kmz"
      );
      lastChecked = "concoriva";
    }

    if (value.vaziosriva) {
      setvaziosriva(
        "./camadas/" + estado.value + "/riva/vaziosriva.kmz"
      );
      lastChecked = "vaziosriva";
    }

    if (value.imoveisanunriva) {
      setimoveisanunriva(
        "./camadas/" + estado.value + "/riva/imoveisanunriva.kmz"
      );
      lastChecked = "imoveisanunriva";
    }

    if (value.limitemacapaic) {
      setlimitemacapaic(
        "./camadas/" + estado.value + "/investcorp/limitemacapaic.kmz"
      );
      lastChecked = "limitemacapaic";
    }

    if (value.recortemacapaic) {
      setrecortemacapaic(
        "./camadas/" + estado.value + "/investcorp/recortemacapaic.kmz"
      );
      lastChecked = "recortemacapaic";
    }

    if (value.varejomacapaic) {
      setvarejomacapaic(
        "./camadas/" + estado.value + "/investcorp/varejomacapaic.kmz"
      );
      lastChecked = "varejomacapaic";
    }

    if (value.concvarejomacapaic) {
      setconcvarejomacapaic(
        "./camadas/" + estado.value + "/investcorp/concvarejomacapaic.kmz"
      );
      lastChecked = "concvarejomacapaic";
    }

    if (value.concfluxomacapaic) {
      setconcfluxomacapaic(
        "./camadas/" + estado.value + "/investcorp/concfluxomacapaic.kmz"
      );
      lastChecked = "concfluxomacapaic";
    }

    if (value.shoppingsmacapaic) {
      setshoppingsmacapaic(
        "./camadas/" + estado.value + "/investcorp/shoppingsmacapaic.kmz"
      );
      lastChecked = "shoppingsmacapaic";
    }

    if (value.escolasmacapaic) {
      setescolasmacapaic(
        "./camadas/" + estado.value + "/investcorp/escolasmacapaic.kmz"
      );
      lastChecked = "escolasmacapaic";
    }

    if (value.hospitaismacapaic) {
      sethospitaismacapaic(
        "./camadas/" + estado.value + "/investcorp/hospitaismacapaic.kmz"
      );
      lastChecked = "hospitaismacapaic";
    }

    if (value.estacionamentosmacapaic) {
      setestacionamentosmacapaic(
        "./camadas/" + estado.value + "/investcorp/estacionamentosmacapaic.kmz"
      );
      lastChecked = "estacionamentosmacapaic";
    }

    if (value.concorrentesmacapaic) {
      setconcorrentesmacapaic(
        "./camadas/" + estado.value + "/investcorp/concorrentesmacapaic.kmz"
      );
      lastChecked = "concorrentesmacapaic";
    }

    if (value.universidadesmacapaic) {
      setuniversidadesmacapaic(
        "./camadas/" + estado.value + "/investcorp/universidadesmacapaic.kmz"
      );
      lastChecked = "universidadesmacapaic";
    }

    if (value.assaimacapaic) {
      setassaimacapaic(
        "./camadas/" + estado.value + "/investcorp/assaimacapaic.kmz"
      );
      lastChecked = "assaimacapaic";
    }

    if (value.rendamacapaic) {
      setrendamacapaic(
        "./camadas/" + estado.value + "/investcorp/rendamacapaic.kmz"
      );
      lastChecked = "rendamacapaic";
    }

    if (value.domiciliosmacapaic) {
      setdomiciliosmacapaic(
        "./camadas/" + estado.value + "/investcorp/domiciliosmacapaic.kmz"
      );
      lastChecked = "domiciliosmacapaic";
    }

    if (value.popmacapaic) {
      setpopmacapaic(
        "./camadas/" + estado.value + "/investcorp/popmacapaic.kmz"
      );
      lastChecked = "popmacapaic";
    }

    if (value.imoveismacapaic) {
      setimoveismacapaic(
        "./camadas/" + estado.value + "/investcorp/imoveismacapaic.kmz"
      );
      lastChecked = "imoveismacapaic";
    }

    if (value.catimoveisriva) {
      setcatimoveisriva(
        "./camadas/" + estado.value + "/riva/catimoveisriva.kmz"
      );
      lastChecked = "catimoveisriva";
    }

    if (value.domiciliosaureo) {
      setdomiciliosaureo(
        "./camadas/" + estado.value + "/aureo/domiciliosaureo.kmz"
      );
      lastChecked = "domiciliosaureo";
    }

    if (value.empresasaureo) {
      setempresasaureo(
        "./camadas/" + estado.value + "/aureo/empresasaureo.kmz"
      );
      lastChecked = "empresasaureo";
    }

    if (value.locinteresseaureo) {
      setlocinteresseaureo(
        "./camadas/" + estado.value + "/aureo/locinteresseaureo.kmz"
      );
      lastChecked = "locinteresseaureo";
    }

    if (value.marcasaureo) {
      setmarcasaureo(
        "./camadas/" + estado.value + "/aureo/marcasaureo.kmz"
      );
      lastChecked = "marcasaureo";
    }

    if (value.pontosaureo) {
      setpontosaureo(
        "./camadas/" + estado.value + "/aureo/pontosaureo.kmz"
      );
      lastChecked = "pontosaureo";
    }

    if (value.raio1kmaureo) {
      setraio1kmaureo(
        "./camadas/" + estado.value + "/aureo/raio1kmaureo.kmz"
      );
      lastChecked = "raio1kmaureo";
    }

    if (value.raio2kmaureo) {
      setraio2kmaureo(
        "./camadas/" + estado.value + "/aureo/raio2kmaureo.kmz"
      );
      lastChecked = "raio2kmaureo";
    }

    if (value.rendaaureo) {
      setrendaaureo(
        "./camadas/" + estado.value + "/aureo/rendaaureo.kmz"
      );
      lastChecked = "rendaaureo";
    }

    if (value.viasaureo) {
      setviasaureo(
        "./camadas/" + estado.value + "/aureo/viasaureo.kmz"
      );
      lastChecked = "viasaureo";
    }

    if (value.zoneamentoaureo) {
      setzoneamentoaureo(
        "./camadas/" + estado.value + "/aureo/zoneamentoaureo.kmz"
      );
      lastChecked = "zoneamentoaureo";
    }

    if (value.piscininha) {
      setpiscininha(
        "./camadas/" + estado.value + "/tenda/santoandre/piscininha.kmz"
      );
      lastChecked = "piscininha";
    }

    if (value.piscinao) {
      setpiscinao(
        "./camadas/" + estado.value + "/tenda/santoandre/piscinao.kmz"
      );
      lastChecked = "piscinao";
    }
    
    if (value.imoveisvendidosemcp) {
      setimoveisvendidosemcp(
        "./camadas/MG/emccamp/imoveisvendidosemcp.kmz"
      );
      lastChecked = "imoveisvendidosemcp";
    }

    if (value.outrossetoresemcp) {
      setoutrossetoresemcp(
        "./camadas/MG/emccamp/outrossetoresemcp.kmz"
      );
      lastChecked = "outrossetoresemcp";
    }

    if (value.setorescomimoveisemcp) {
      setsetorescomimoveisemcp(
        "./camadas/MG/emccamp/setorescomimoveisemcp.kmz"
      );
      lastChecked = "setorescomimoveisemcp";
    }

    if (value.vendasemcp) {
      setvendasemcp(
        "./camadas/MG/emccamp/vendasemcp.kmz"
      );
      lastChecked = "vendasemcp";
    }


    if (value.comerciosaureopet1) {
      setcomerciosaureopet1(
        "./camadas/SP/aureo/comerciosaureopet1.kmz"
      );
      lastChecked = "comerciosaureopet1";
    }

     if (value.domiciliosaureopet1) {
      setdomiciliosaureopet1(
        "./camadas/SP/aureo/domiciliosaureopet1.kmz"
      );
      lastChecked = "domiciliosaureopet1";
    }
 if (value.empreendimentoaureopet1) {
      setempreendimentoaureopet1(
        "./camadas/SP/aureo/empreendimentoaureopet1.kmz"
      );
      lastChecked = "empreendimentoaureopet1";
    }
 if (value.limiteaureopet1) {
      setlimiteaureopet1(
        "./camadas/SP/aureo/limiteaureopet1.kmz"
      );
      lastChecked = "limiteaureopet1";
    }
 if (value.localizacaoaureopet1) {
      setlocalizacaoaureopet1(
        "./camadas/SP/aureo/localizacaoaureopet1.kmz"
      );
      lastChecked = "localizacaoaureopet1";
    }
 if (value.marcasvareaureopet1) {
      setmarcasvareaureopet1(
        "./camadas/SP/aureo/marcasvareaureopet1.kmz"
      );
      lastChecked = "marcasvareaureopet1";
    }
 if (value.r1kmaureopet1) {
      setr1kmaureopet1(
        "./camadas/SP/aureo/r1kmaureopet1.kmz"
      );
      lastChecked = "r1kmaureopet1";
    }
 if (value.r2kmaureopet1) {
      setr2kmaureopet1(
        "./camadas/SP/aureo/r2kmaureopet1.kmz"
      );
      lastChecked = "r2kmaureopet1";
    }
 if (value.rendaaureopet1) {
      setrendaaureopet1(
        "./camadas/SP/aureo/rendaaureopet1.kmz"
      );
      lastChecked = "rendaaureopet1";
    }
 if (value.viasacessoaureopet1) {
      setviasacessoaureopet1(
        "./camadas/SP/aureo/viasacessoaureopet1.kmz"
      );
      lastChecked = "viasacessoaureopet1";
    }
 if (value.comerciosaureopet2) {
      setcomerciosaureopet2(
        "./camadas/SP/aureo/comerciosaureopet2.kmz"
      );
      lastChecked = "comerciosaureopet2";
    }
 if (value.domiciliosaureopet2) {
      setdomiciliosaureopet2(
        "./camadas/SP/aureo/domiciliosaureopet2.kmz"
      );
      lastChecked = "domiciliosaureopet2";
    }
 if (value.empreendimentoaureopet2) {
      setempreendimentoaureopet2(
        "./camadas/SP/aureo/empreendimentoaureopet2.kmz"
      );
      lastChecked = "empreendimentoaureopet2";
    }
 if (value.localizacaoaureopet2) {
      setlocalizacaoaureopet2(
        "./camadas/SP/aureo/localizacaoaureopet2.kmz"
      );
      lastChecked = "localizacaoaureopet2";
    }
 if (value.r1kmaureopet2) {
      setr1kmaureopet2(
        "./camadas/SP/aureo/r1kmaureopet2.kmz"
      );
      lastChecked = "r1kmaureopet2";
    }
 if (value.r2kmaureopet2) {
      setr2kmaureopet2(
        "./camadas/SP/aureo/r2kmaureopet2.kmz"
      );
      lastChecked = "r2kmaureopet2";
    }
 if (value.rendaaureopet2) {
      setrendaaureopet2(
        "./camadas/SP/aureo/rendaaureopet2.kmz"
      );
      lastChecked = "rendaaureopet2";
    }
 if (value.viasacessoaureopet2) {
      setviasacessoaureopet2(
        "./camadas/SP/aureo/viasacessoaureopet2.kmz"
      );
      lastChecked = "viasacessoaureopet2";
    }
     if (value.zoneamentoaureopet2) {
      setzoneamentoaureopet2(
        "./camadas/SP/aureo/zoneamentoaureopet2.kmz"
      );
      lastChecked = "zoneamentoaureopet2";
    }

    if (value.hidrologiaaureopet1) {
      sethidrologiaaureopet1(
        "./camadas/SP/aureo/hidrologiaaureopet1.kmz"
      );
      lastChecked = "hidrologiaaureopet1";
    }
    if (value.topografiaaureopet1) {
      settopografiaaureopet1(
        "./camadas/SP/aureo/topografiaaureopet1.kmz"
      );
      lastChecked = "topografiaaureopet1";
    }
    if (value.hidrologiaaureopet2) {
      sethidrologiaaureopet2(
        "./camadas/SP/aureo/hidrologiaaureopet2.kmz"
      );
      lastChecked = "hidrologiaaureopet2";
    }
    if (value.topografiaaureopet2) {
      settopografiaaureopet2(
        "./camadas/SP/aureo/topografiaaureopet2.kmz"
      );
      lastChecked = "topografiaaureopet2";
    }


    if (value.topografiaacc3) {
      settopografiaacc3("./camadas/SP/acc3/topografiaacc3.kmz");
      lastChecked = "topografiaacc3";
    }

    if (value.zoneamentoacc3) {
      setzoneamentoacc3("./camadas/SP/acc3/zoneamentoacc3.kmz");
      lastChecked = "zoneamentoacc3";
    }
    
    if (value.areapousoacc3) {
      setareapousoacc3("./camadas/SP/acc3/areapousoacc3.kmz");
      lastChecked = "areapousoacc3";
    }
    if (value.rotaaviaoacc3) {
      setrotaaviaoacc3("./camadas/SP/acc3/rotaaviaoacc3.kmz");
      lastChecked = "rotaaviaoacc3";
    }
    if (value.areascontacc3) {
      setareascontacc3("./camadas/SP/acc3/areascontacc3.kmz");
      lastChecked = "areascontacc3";
    }
    if (value.dutoviasacc3) {
      setdutoviasacc3("./camadas/SP/acc3/dutoviasacc3.kmz");
      lastChecked = "dutoviasacc3";
    }
    if (value.estabelecimentosacc3) {
      setestabelecimentosacc3("./camadas/SP/acc3/estabelecimentosacc3.kml");
      lastChecked = "estabelecimentosacc3";
    }
    if (value.veiculosacc3) {
      setveiculosacc3("./camadas/SP/acc3/veiculosacc3.kmz");
      lastChecked = "veiculosacc3";
    }

    if (value.fluxoveicacc3) {
      setfluxoveicacc3("./camadas/SP/acc3/fluxoveicacc3.kmz");
      lastChecked = "fluxoveicacc3";
    }
    
    if (value.movimentacaoacc3) {
      setmovimentacaoacc3("./camadas/SP/acc3/movimentacaoacc3.kmz");
      lastChecked = "movimentacaoacc3";
    }
    if (value.rendaacc3) {
      setrendaacc3("./camadas/SP/acc3/rendaacc3.kmz");
      lastChecked = "rendaacc3";
    }
    if (value.populacaoacc3) {
      setpopulacaoacc3("./camadas/SP/acc3/populacaoacc3.kmz");
      lastChecked = "populacaoacc3";
    }
    if (value.domiciliosacc3) {
      setdomiciliosacc3("./camadas/SP/acc3/domiciliosacc3.kmz");
      lastChecked = "domiciliosacc3";
    }
    if (value.domialuguelacc3) {
      setdomialuguelacc3("./camadas/SP/acc3/domialuguelacc3.kmz");
      lastChecked = "domialuguelacc3";
    }
    if (value.areaurbaacc3) {
      setareaurbaacc3("./camadas/SP/acc3/areaurbaacc3.kmz");
      lastChecked = "areaurbaacc3";
    }

    if (value.spacc3) {
      setspacc3("./camadas/SP/acc3/spacc3.kmz");
      lastChecked = "spacc3";
    }

    if (value.guarulhosacc3) {
      setguarulhosacc3("./camadas/SP/acc3/guarulhosacc3.kmz");
      lastChecked = "guarulhosacc3";
    }

    if (value.trechoacc3) {
      settrechoacc3("./camadas/SP/acc3/trechoacc3.kmz");
      lastChecked = "trechoacc3";
    }

    if (value.aeror2soroaureo) {
      setaeror2soroaureo("./camadas/SP/aureo/aeror2soroaureo.kmz");
      lastChecked = "aeror2soroaureo";
    }
    if (value.shoppingr2soroaureo) {
      setshoppingr2soroaureo("./camadas/SP/aureo/shoppingr2soroaureo.kmz");
      lastChecked = "shoppingr2soroaureo";
    }
    if (value.condor2soroaureo) {
      setcondor2soroaureo("./camadas/SP/aureo/condor2soroaureo.kmz");
      lastChecked = "condor2soroaureo";
    }
    if (value.domicilior3soroaureo) {
      setdomicilior3soroaureo("./camadas/SP/aureo/domicilior3soroaureo.kmz");
      lastChecked = "domicilior3soroaureo";
    }
    if (value.popr3soroaureo) {
      setpopr3soroaureo("./camadas/SP/aureo/popr3soroaureo.kmz");
      lastChecked = "popr3soroaureo";
    }
    if (value.varejor3soroaureo) {
      setvarejor3soroaureo("./camadas/SP/aureo/varejor3soroaureo.kmz");
      lastChecked = "varejor3soroaureo";
    }
    if (value.industriar3soroaureo) {
      setindustriar3soroaureo("./camadas/SP/aureo/industriar3soroaureo.kmz");
      lastChecked = "industriar3soroaureo";
    }
    if (value.domicilior4soroaureo) {
      setdomicilior4soroaureo("./camadas/SP/aureo/domicilior4soroaureo.kmz");
      lastChecked = "domicilior4soroaureo";
    }
    if (value.popr4soroaureo) {
      setpopr4soroaureo("./camadas/SP/aureo/popr4soroaureo.kmz");
      lastChecked = "popr4soroaureo";
    }
    if (value.varejor4soroaureo) {
      setvarejor4soroaureo("./camadas/SP/aureo/varejor4soroaureo.kmz");
      lastChecked = "varejor4soroaureo";
    }
    if (value.industriar4soroaureo) {
      setindustriar4soroaureo("./camadas/SP/aureo/industriar4soroaureo.kmz");
      lastChecked = "industriar4soroaureo";
    }
    if (value.domicilior5soroaureo) {
      setdomicilior5soroaureo("./camadas/SP/aureo/domicilior5soroaureo.kmz");
      lastChecked = "domicilior5soroaureo";
    }
    if (value.popr5soroaureo) {
      setpopr5soroaureo("./camadas/SP/aureo/popr5soroaureo.kmz");
      lastChecked = "popr5soroaureo";
    }
    if (value.varejor5soroaureo) {
      setvarejor5soroaureo("./camadas/SP/aureo/varejor5soroaureo.kmz");
      lastChecked = "varejor5soroaureo";
    }
    if (value.industriar5soroaureo) {
      setindustriar5soroaureo("./camadas/SP/aureo/industriar5soroaureo.kmz");
      lastChecked = "industriar5soroaureo";
    }
    if (value.sud1petrop1aureo) {
      setsud1petrop1aureo("./camadas/SP/aureo/sud1petrop1aureo.kmz");
      lastChecked = "sud1petrop1aureo";
    }
    if (value.sud2petrop1aureo) {
      setsud2petrop1aureo("./camadas/SP/aureo/sud2petrop1aureo.kmz");
      lastChecked = "sud2petrop1aureo";
    }

    if (value.sud1petrop2aureo) {
      setsud1petrop2aureo("./camadas/SP/aureo/sud1petrop2aureo.kmz");
      lastChecked = "sud1petrop2aureo";
    }

    if (value.sud2petrop2aureo) {
      setsud2petrop2aureo("./camadas/SP/aureo/sud2petrop2aureo.kmz");
      lastChecked = "sud2petrop2aureo";
    }

    if (value.areascontastacetesbtnd) {
      setareascontastacetesbtnd("./camadas/SP/tenda/santoandre/areascontastacetesbtnd.kmz");
      lastChecked = "areascontastacetesbtnd";
    }

    if (value.areascontastasgmptnd) {
      setareascontastasgmptnd("./camadas/SP/tenda/santoandre/areascontastasgmptnd.kmz");
      lastChecked = "areascontastasgmptnd";
    }

    if (value.limitebairrosstatnd) {
      setlimitebairrosstatnd("./camadas/SP/tenda/santoandre/limitebairrosstatnd.kmz");
      lastChecked = "limitebairrosstatnd";
    }

    if (value.fichasstatnd) {
      setfichasstatnd("./camadas/SP/tenda/santoandre/fichasstatnd.kmz");
      lastChecked = "fichasstatnd";
    }

    if (value.zoneamentostaeixotamandtnd) {
      setzoneamentostaeixotamandtnd("./camadas/SP/tenda/santoandre/zoneamentostaeixotamandtnd.kmz");
      lastChecked = "zoneamentostaeixotamandtnd";
    }

    if (value.galpoesjaguariaivaic) {
      setgalpoesjaguariaivaic("./camadas/PR/investcorp/jaguariaiva/galpoesjaguariaivaic.kmz");
      lastChecked = "galpoesjaguariaivaic";
    }

    if (value.zoneamentocuritibatrply) {
      setzoneamentocuritibatrply("./camadas/PR/tenda_rply/zoneamentocuritibatrply.kmz");
      lastChecked = "zoneamentocuritibatrply";
    }

    if (value.permissaocuritibatrply) {
      setpermissaocuritibatrply("./camadas/PR/tenda_rply/permissaocuritibatrply.kmz");
      lastChecked = "permissaocuritibatrply";
    }

    if (value.terrenoscuritibatrply) {
      setterrenoscuritibatrply("./camadas/PR/tenda_rply/terrenoscuritibatrply.kmz");
      lastChecked = "terrenoscuritibatrply";
    }

    if (value.topografiacuritibatrply) {
      settopografiacuritibatrply("./camadas/PR/tenda_rply/topografiacuritibatrply.kmz");
      lastChecked = "topografiacuritibatrply";
    }

    if (value.limitesambcuritibatrply) {
      setlimitesambcuritibatrply("./camadas/PR/tenda_rply/limitesambcuritibatrply.kmz");
      lastChecked = "limitesambcuritibatrply";
    }

    if (value.hidrografiacuritibatrply) {
      sethidrografiacuritibatrply("./camadas/PR/tenda_rply/hidrografiacuritibatrply.kmz");
      lastChecked = "hidrografiacuritibatrply";
    }

    if (value.servidaocuritibatrply) {
      setservidaocuritibatrply("./camadas/PR/tenda_rply/servidaocuritibatrply.kmz");
      lastChecked = "servidaocuritibatrply";
    }

    if (value.terrenospotenciascuritibatrply) {
      setterrenospotenciascuritibatrply("./camadas/PR/tenda_rply/terrenospotenciascuritibatrply.kmz");
      lastChecked = "terrenospotenciascuritibatrply";
    }

    if (value.crescimentocuritibatrply) {
      setcrescimentocuritibatrply("./camadas/PR/tenda_rply/crescimentocuritibatrply.kmz");
      lastChecked = "crescimentocuritibatrply";
    }

    if (value.dadossocioscuritibatrply) {
      setdadossocioscuritibatrply("./camadas/PR/tenda_rply/dadossocioscuritibatrply.kmz");
      lastChecked = "dadossocioscuritibatrply";
    }

    if (value.pontosdeinteressecuritibatrply) {
      setpontosdeinteressecuritibatrply("./camadas/PR/tenda_rply/pontosdeinteressecuritibatrply.kmz");
      lastChecked = "pontosdeinteressecuritibatrply";
    }


    

    var kmlDataSource = null;
    if (lastChecked === "limite") {
      kmlDataSource = viewer.dataSources.get(0); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "zoneamento") {
      kmlDataSource = viewer.dataSources.get(1); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "topografia") {
      kmlDataSource = viewer.dataSources.get(2); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "hidrografia") {
      kmlDataSource = viewer.dataSources.get(3); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "limitesa") {
      kmlDataSource = viewer.dataSources.get(4); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "permissao") {
      kmlDataSource = viewer.dataSources.get(5); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "dados") {
      kmlDataSource = viewer.dataSources.get(6); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "fluxo") {
      kmlDataSource = viewer.dataSources.get(7); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "thej") {
      kmlDataSource = viewer.dataSources.get(8); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "competidorestj") {
      kmlDataSource = viewer.dataSources.get(9); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areastj") {
      kmlDataSource = viewer.dataSources.get(10); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "condominiostj") {
      kmlDataSource = viewer.dataSources.get(11); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "influenciatj") {
      kmlDataSource = viewer.dataSources.get(12); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosfluxotj") {
      kmlDataSource = viewer.dataSources.get(13); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "imoveis") {
      kmlDataSource = viewer.dataSources.get(14); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "setoreslk") {
      kmlDataSource = viewer.dataSources.get(15); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "raio1000lk") {
      kmlDataSource = viewer.dataSources.get(16); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "raio500lk") {
      kmlDataSource = viewer.dataSources.get(17); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "viaslk") {
      kmlDataSource = viewer.dataSources.get(18); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "competidoreslk") {
      kmlDataSource = viewer.dataSources.get(19); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "hospitaislk") {
      kmlDataSource = viewer.dataSources.get(20); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pracaslk") {
      kmlDataSource = viewer.dataSources.get(21); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "mercadoslk") {
      kmlDataSource = viewer.dataSources.get(22); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "restauranteslk") {
      kmlDataSource = viewer.dataSources.get(23); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "condominioslk") {
      kmlDataSource = viewer.dataSources.get(24); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "farmacialk") {
      kmlDataSource = viewer.dataSources.get(25); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c1") {
      kmlDataSource = viewer.dataSources.get(26); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c2") {
      kmlDataSource = viewer.dataSources.get(27); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c3") {
      kmlDataSource = viewer.dataSources.get(28); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c4") {
      kmlDataSource = viewer.dataSources.get(29); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c5") {
      kmlDataSource = viewer.dataSources.get(30); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c6") {
      kmlDataSource = viewer.dataSources.get(31); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c7") {
      kmlDataSource = viewer.dataSources.get(32); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c8") {
      kmlDataSource = viewer.dataSources.get(33); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c9") {
      kmlDataSource = viewer.dataSources.get(34); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c10") {
      kmlDataSource = viewer.dataSources.get(35); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c11") {
      kmlDataSource = viewer.dataSources.get(36); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c12") {
      kmlDataSource = viewer.dataSources.get(37); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c13") {
      kmlDataSource = viewer.dataSources.get(38); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c14") {
      kmlDataSource = viewer.dataSources.get(39); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c15") {
      kmlDataSource = viewer.dataSources.get(40); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "c16") {
      kmlDataSource = viewer.dataSources.get(41); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "imoveis200") {
      kmlDataSource = viewer.dataSources.get(42); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    //griffon
    // "Griffon Living - Campinas",
    //             "Griffon Living - Jundiai"

    if (lastChecked === "areaverdegl") {
      kmlDataSource = viewer.dataSources.get(43); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "municipiogl") {
      kmlDataSource = viewer.dataSources.get(44); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "condominiogooglegl") {
      kmlDataSource = viewer.dataSources.get(45); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "condominiograpgl") {
      kmlDataSource = viewer.dataSources.get(46); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "rendagl") {
      kmlDataSource = viewer.dataSources.get(47); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "servidao") {
      kmlDataSource = viewer.dataSources.get(48); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "crescimento") {
      kmlDataSource = viewer.dataSources.get(49); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasinfluenciatjp") {
      kmlDataSource = viewer.dataSources.get(50); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasindicadastjp") {
      kmlDataSource = viewer.dataSources.get(51); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compdiretostjp") {
      kmlDataSource = viewer.dataSources.get(52); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compindiretostjp") {
      kmlDataSource = viewer.dataSources.get(53); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "condominiostjp") {
      kmlDataSource = viewer.dataSources.get(54); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "imoveistjp") {
      kmlDataSource = viewer.dataSources.get(55); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "limitetjp") {
      kmlDataSource = viewer.dataSources.get(56); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosfluxotjp") {
      kmlDataSource = viewer.dataSources.get(57); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "escolastjp") {
      kmlDataSource = viewer.dataSources.get(58); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    //tj americana

    if (lastChecked === "areasinfluenciatja") {
      kmlDataSource = viewer.dataSources.get(59); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasindicadasinfantiltja") {
      kmlDataSource = viewer.dataSources.get(60); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasindicadasfundamentaltja") {
      kmlDataSource = viewer.dataSources.get(61); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compdiretosinfantiltja") {
      kmlDataSource = viewer.dataSources.get(62); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compdiretosfundamentaltja") {
      kmlDataSource = viewer.dataSources.get(63); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compindiretosinfantiltja") {
      kmlDataSource = viewer.dataSources.get(64); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compindiretosfundamentaltja") {
      kmlDataSource = viewer.dataSources.get(65); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "concentracao0_5tja") {
      kmlDataSource = viewer.dataSources.get(66); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "concentracao6_10tja") {
      kmlDataSource = viewer.dataSources.get(67); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "escolatja") {
      kmlDataSource = viewer.dataSources.get(68); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "limitetja") {
      kmlDataSource = viewer.dataSources.get(69); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosfluxotja") {
      kmlDataSource = viewer.dataSources.get(70); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendatja") {
      kmlDataSource = viewer.dataSources.get(71); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendaareainfantiltja") {
      kmlDataSource = viewer.dataSources.get(72); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendaareafundatja") {
      kmlDataSource = viewer.dataSources.get(73); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "ashidsgu") {
      kmlDataSource = viewer.dataSources.get(74); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "aspidsgu") {
      kmlDataSource = viewer.dataSources.get(75); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "apidsgu") {
      kmlDataSource = viewer.dataSources.get(76); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "bempresasgu") {
      kmlDataSource = viewer.dataSources.get(77); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "campusdagu") {
      kmlDataSource = viewer.dataSources.get(78); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "campusgu") {
      kmlDataSource = viewer.dataSources.get(79); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "fzdarggu") {
      kmlDataSource = viewer.dataSources.get(80); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "ferroviasgu") {
      kmlDataSource = viewer.dataSources.get(81); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "ictsgu") {
      kmlDataSource = viewer.dataSources.get(82); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "parquesgu") {
      kmlDataSource = viewer.dataSources.get(83); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "riosgu") {
      console.log("riosgu");
      kmlDataSource = viewer.dataSources.get(84); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "viasgu") {
      kmlDataSource = viewer.dataSources.get(85); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasindicadasinfantiltjg") {
      kmlDataSource = viewer.dataSources.get(84); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areasindicadasfundamentaltjg") {
      kmlDataSource = viewer.dataSources.get(85); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compdiretosinfantiltjg") {
      kmlDataSource = viewer.dataSources.get(86); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compdiretosfundamentaltjg") {
      kmlDataSource = viewer.dataSources.get(87); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compindiretosinfantiltjg") {
      kmlDataSource = viewer.dataSources.get(88); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compindiretosfundamentaltjg") {
      kmlDataSource = viewer.dataSources.get(89); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "concentracao0_5tjg") {
      kmlDataSource = viewer.dataSources.get(90); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "concentracao6_10tjg") {
      kmlDataSource = viewer.dataSources.get(91); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "familiastjg") {
      kmlDataSource = viewer.dataSources.get(92); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendatjg") {
      kmlDataSource = viewer.dataSources.get(93); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "imovelpretendidotjg") {
      kmlDataSource = viewer.dataSources.get(94); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "limitetjg") {
      kmlDataSource = viewer.dataSources.get(95); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosfluxotjg") {
      kmlDataSource = viewer.dataSources.get(96); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "raioinfluenciatjg") {
      kmlDataSource = viewer.dataSources.get(97); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    //edu
    if (lastChecked === "edumap") {
      kmlDataSource = viewer.dataSources.get(98); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    //crca
    if (lastChecked === "bairroscrca") {
      kmlDataSource = viewer.dataSources.get(99); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "dadossociosfamiliascampinascrca") {
      kmlDataSource = viewer.dataSources.get(100); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "zoneamentocampinascrca") {
      kmlDataSource = viewer.dataSources.get(101); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "limitecampinascrca") {
      kmlDataSource = viewer.dataSources.get(102); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    //sjbv
    if (lastChecked === "sjbv") {
      kmlDataSource = viewer.dataSources.get(103); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv1zoneamento") {
      kmlDataSource = viewer.dataSources.get(104); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv2permissao") {
      kmlDataSource = viewer.dataSources.get(105); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv3terrenos") {
      kmlDataSource = viewer.dataSources.get(106); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv5topografia") {
      kmlDataSource = viewer.dataSources.get(107); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv6limitesa") {
      kmlDataSource = viewer.dataSources.get(108); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv7hidrografia") {
      kmlDataSource = viewer.dataSources.get(109); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv8servidao") {
      kmlDataSource = viewer.dataSources.get(110); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv9terrenosp") {
      kmlDataSource = viewer.dataSources.get(111); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv10crescimento") {
      kmlDataSource = viewer.dataSources.get(112); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv11dadossocios") {
      kmlDataSource = viewer.dataSources.get(113); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv12distviario") {
      kmlDataSource = viewer.dataSources.get(114); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv13malha") {
      kmlDataSource = viewer.dataSources.get(115); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "sjbv14pontos") {
      kmlDataSource = viewer.dataSources.get(116); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    //crca
    if (lastChecked === "dadosporbairro") {
      kmlDataSource = viewer.dataSources.get(117); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "dadossociosrendacampinascrca") {
      kmlDataSource = viewer.dataSources.get(118); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp") {
      kmlDataSource = viewer.dataSources.get(119); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp1zoneamento") {
      kmlDataSource = viewer.dataSources.get(120); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp3terrenos") {
      kmlDataSource = viewer.dataSources.get(121); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp5topografia") {
      kmlDataSource = viewer.dataSources.get(122); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp6limitesambientais") {
      kmlDataSource = viewer.dataSources.get(123); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp7hidrografia") {
      kmlDataSource = viewer.dataSources.get(124); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp8servidao") {
      kmlDataSource = viewer.dataSources.get(125); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp10crescimento") {
      kmlDataSource = viewer.dataSources.get(126); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp11dadossocioeconomicosrenda") {
      kmlDataSource = viewer.dataSources.get(127); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rp12dadossocioeconomicosfamilias") {
      kmlDataSource = viewer.dataSources.get(128); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "abrangenciacrascrca2") {
      kmlDataSource = viewer.dataSources.get(129); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "assistenciateccrca2") {
      kmlDataSource = viewer.dataSources.get(130); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "bairroscrca2") {
      kmlDataSource = viewer.dataSources.get(131); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "campinasdadossociosrendacrca2") {
      kmlDataSource = viewer.dataSources.get(132); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "campinasdadossociosfamiliascrca2") {
      kmlDataSource = viewer.dataSources.get(133); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "campinaslimitecrca2") {
      kmlDataSource = viewer.dataSources.get(134); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "campinaszoneamentocrca2") {
      kmlDataSource = viewer.dataSources.get(135); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "comercioalimentoscrca2") {
      kmlDataSource = viewer.dataSources.get(136); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "dadosporbairrocrca2") {
      kmlDataSource = viewer.dataSources.get(137); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "impressao3dcrca2") {
      kmlDataSource = viewer.dataSources.get(138); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "lojaderoupascrca2") {
      kmlDataSource = viewer.dataSources.get(139); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "lojasartesanatocrca2") {
      kmlDataSource = viewer.dataSources.get(140); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "oficinasmecanicascrca2") {
      kmlDataSource = viewer.dataSources.get(141); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "orgcivilcrca2") {
      kmlDataSource = viewer.dataSources.get(142); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "padariasconfeitariascrca2") {
      kmlDataSource = viewer.dataSources.get(143); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "petshopscrca2") {
      kmlDataSource = viewer.dataSources.get(144); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "regioesvulcrca2") {
      kmlDataSource = viewer.dataSources.get(145); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "salaobelezacrc2") {
      kmlDataSource = viewer.dataSources.get(146); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "educomp") {
      kmlDataSource = viewer.dataSources.get(147); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "edufamilia") {
      kmlDataSource = viewer.dataSources.get(148); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "edurenda") {
      kmlDataSource = viewer.dataSources.get(149); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "eduimoveis") {
      kmlDataSource = viewer.dataSources.get(150); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "areasindicadasinfantiltjd") {
      kmlDataSource = viewer.dataSources.get(151); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "areasindicadasfundamentaltjd") {
      kmlDataSource = viewer.dataSources.get(152); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "compdiretosinfantiltjd") {
      kmlDataSource = viewer.dataSources.get(153); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "compdiretosfundamentaltjd") {
      kmlDataSource = viewer.dataSources.get(154); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "compindiretosinfantiltjd") {
      kmlDataSource = viewer.dataSources.get(155); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "compindiretosfundamentaltjd") {
      kmlDataSource = viewer.dataSources.get(156); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "concentracaocriancasinfantiltgd") {
      kmlDataSource = viewer.dataSources.get(157); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "concentracaocriancasfundtgd") {
      kmlDataSource = viewer.dataSources.get(158); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "familiastjd") {
      kmlDataSource = viewer.dataSources.get(159); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "limitetjd") {
      kmlDataSource = viewer.dataSources.get(160); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }
    if (lastChecked === "rendatjd") {
      kmlDataSource = viewer.dataSources.get(161); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosfluxotjd") {
      kmlDataSource = viewer.dataSources.get(162); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "raioinfluenciatjd") {
      kmlDataSource = viewer.dataSources.get(163); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "legalizacaolavras") {
      kmlDataSource = viewer.dataSources.get(164); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "academiasmanapoke") {
      kmlDataSource = viewer.dataSources.get(165); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "centrosempresariasmanapoke") {
      kmlDataSource = viewer.dataSources.get(166); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "centrosesportivosmanapoke") {
      kmlDataSource = viewer.dataSources.get(167); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "comerciomanapoke") {
      kmlDataSource = viewer.dataSources.get(168); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compcomidajaponesamanapoke") {
      kmlDataSource = viewer.dataSources.get(169); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "compcomidasaudavelmanapoke") {
      kmlDataSource = viewer.dataSources.get(170); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "imoveiscomerciaismanapoke") {
      kmlDataSource = viewer.dataSources.get(171); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosculturaismanapoke") {
      kmlDataSource = viewer.dataSources.get(172); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontoseducacaomanapoke") {
      kmlDataSource = viewer.dataSources.get(173); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendamanapoke") {
      kmlDataSource = viewer.dataSources.get(174); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "zoneamentolavras") {
      kmlDataSource = viewer.dataSources.get(175); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "permissaolavras") {
      kmlDataSource = viewer.dataSources.get(176); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "terrenoslavras") {
      kmlDataSource = viewer.dataSources.get(177); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "topografialavras") {
      kmlDataSource = viewer.dataSources.get(178); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "applavras") {
      kmlDataSource = viewer.dataSources.get(179); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "hidrografialavras") {
      kmlDataSource = viewer.dataSources.get(180); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "servidaolavras") {
      kmlDataSource = viewer.dataSources.get(181); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "terrenosinteresselavras") {
      kmlDataSource = viewer.dataSources.get(182); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "crescimentolavras") {
      kmlDataSource = viewer.dataSources.get(183); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "dadossocioslavras") {
      kmlDataSource = viewer.dataSources.get(184); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "distviariolavras") {
      kmlDataSource = viewer.dataSources.get(185); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "disturbanalavras") {
      kmlDataSource = viewer.dataSources.get(186); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontoslavras") {
      kmlDataSource = viewer.dataSources.get(187); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "areaspotenciaismanapoke") {
      kmlDataSource = viewer.dataSources.get(188); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "clubesbs") {
      kmlDataSource = viewer.dataSources.get(189); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "familiasbs") {
      kmlDataSource = viewer.dataSources.get(190); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "lojasdepartamentobs") {
      kmlDataSource = viewer.dataSources.get(191); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "parquesbs") {
      kmlDataSource = viewer.dataSources.get(192); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "rendabs") {
      kmlDataSource = viewer.dataSources.get(193); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "pontosbuddhaspabs") {
      kmlDataSource = viewer.dataSources.get(194); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "restaurantesbs") {
      kmlDataSource = viewer.dataSources.get(195); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "shoppingsbs") {
      kmlDataSource = viewer.dataSources.get(196); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "supermercadosbs") {
      kmlDataSource = viewer.dataSources.get(197); // Obtém o primeiro KmlDataSource (assumindo que é o seu arquivo KML)
    }

    if (lastChecked === "estacionamentosbs") {
      kmlDataSource = viewer.dataSources.get(198);
    }

    if (lastChecked === "imoveismenos18bs") {
      kmlDataSource = viewer.dataSources.get(199);
    }

    if (lastChecked === "imoveismais18bs") {
      kmlDataSource = viewer.dataSources.get(200);
    }

    if (lastChecked === "areainflucompbs") {
      kmlDataSource = viewer.dataSources.get(201);
    }

    if (lastChecked === "areapotcomrecorte") {
      kmlDataSource = viewer.dataSources.get(202);
    }

    if (lastChecked === "areapotsemrecorte") {
      kmlDataSource = viewer.dataSources.get(203);
    }

    if (lastChecked === "competidoresbs") {
      kmlDataSource = viewer.dataSources.get(204);
    }

    if (lastChecked === "popadultabs") {
      kmlDataSource = viewer.dataSources.get(205);
    }

    if (lastChecked === "areaspotbd") {
      kmlDataSource = viewer.dataSources.get(206);
    }

    if (lastChecked === "baldonibd") {
      kmlDataSource = viewer.dataSources.get(207);
    }

    if (lastChecked === "baldoni2030bd") {
      kmlDataSource = viewer.dataSources.get(208);
    }

    if (lastChecked === "lojaspescabd") {
      kmlDataSource = viewer.dataSources.get(209);
    }

    if (lastChecked === "pesquesbd") {
      kmlDataSource = viewer.dataSources.get(210);
    }

    if (lastChecked === "petshopsbd") {
      kmlDataSource = viewer.dataSources.get(211);
    }

    if (lastChecked === "popadultabd") {
      kmlDataSource = viewer.dataSources.get(212);
    }

    if (lastChecked === "rendabd") {
      kmlDataSource = viewer.dataSources.get(213);
    }

    if (lastChecked === "restaurantesbd") {
      kmlDataSource = viewer.dataSources.get(214);
    }

    if (lastChecked === "vetsbd") {
      kmlDataSource = viewer.dataSources.get(215);
    }

    if (lastChecked === "academiascb") {
      kmlDataSource = viewer.dataSources.get(216);
    }

    if (lastChecked === "areaspotcb") {
      kmlDataSource = viewer.dataSources.get(217);
    }

    if (lastChecked === "cafescb") {
      kmlDataSource = viewer.dataSources.get(218);
    }

    if (lastChecked === "comerciocb") {
      kmlDataSource = viewer.dataSources.get(219);
    }

    if (lastChecked === "culturalcb") {
      kmlDataSource = viewer.dataSources.get(220);
    }

    if (lastChecked === "escolascb") {
      kmlDataSource = viewer.dataSources.get(221);
    }

    if (lastChecked === "esportescb") {
      kmlDataSource = viewer.dataSources.get(222);
    }

    if (lastChecked === "faculdadescb") {
      kmlDataSource = viewer.dataSources.get(223);
    }

    if (lastChecked === "imoveiscb") {
      kmlDataSource = viewer.dataSources.get(224);
    }

    if (lastChecked === "lanchonetescb") {
      kmlDataSource = viewer.dataSources.get(225);
    }
    if (lastChecked === "rendasetorescb") {
      kmlDataSource = viewer.dataSources.get(226);
    }

    if (lastChecked === "comidasaudavelcb") {
      kmlDataSource = viewer.dataSources.get(227);
    }

    if (lastChecked === "zonasusobs") {
      kmlDataSource = viewer.dataSources.get(228);
    }

    if (lastChecked === "Rio_Verde_1_zoneamento") {
      kmlDataSource = viewer.dataSources.get(229);
    }

    if (lastChecked === "Rio_Verde_2_permissao") {
      kmlDataSource = viewer.dataSources.get(230);
    }

    if (lastChecked === "Rio_Verde_3_terrenos") {
      kmlDataSource = viewer.dataSources.get(231);
    }

    if (lastChecked === "Rio_Verde_4_imoveis_disponiveis") {
      kmlDataSource = viewer.dataSources.get(232);
    }

    if (lastChecked === "Rio_Verde_5_topografia") {
      kmlDataSource = viewer.dataSources.get(233);
    }

    if (lastChecked === "Rio_Verde_6_Limites_Ambientais") {
      kmlDataSource = viewer.dataSources.get(234);
    }

    if (lastChecked === "Rio_Verde_7_Hidrografia") {
      kmlDataSource = viewer.dataSources.get(235);
    }

    if (lastChecked === "Rio_Verde_8_Servidao") {
      kmlDataSource = viewer.dataSources.get(236);
    }

    if (lastChecked === "Rio_Verde_9_terrenos_potenciais") {
      kmlDataSource = viewer.dataSources.get(237);
    }

    if (lastChecked === "Rio_Verde_10_crescimento") {
      kmlDataSource = viewer.dataSources.get(238);
    }

    if (lastChecked === "Rio_Verde_11_dados_socioeconomicos") {
      kmlDataSource = viewer.dataSources.get(239);
    }

    if (lastChecked === "Rio_verde_12_dist_viario") {
      kmlDataSource = viewer.dataSources.get(240);
    }

    if (lastChecked === "Rio_Verde_13_Malha_Urbana") {
      kmlDataSource = viewer.dataSources.get(241);
    }

    if (lastChecked === "Rio_Verde_14_Pontos_de_Interesse") {
      kmlDataSource = viewer.dataSources.get(242);
    }

    if (lastChecked === "Rio_Verde") {
      kmlDataSource = viewer.dataSources.get(243);
    }

    if (lastChecked === "Cruzeiro_1_Zoneamento") {
      kmlDataSource = viewer.dataSources.get(244);
    }

    if (lastChecked === "Guaxupe_1_zoneamento") {
      kmlDataSource = viewer.dataSources.get(245);
    }

    if (lastChecked === "Lavras_zoneamento_TESTE") {
      kmlDataSource = viewer.dataSources.get(246);
    }

    if (lastChecked === "SSParaiso_1_Zoneamento") {
      kmlDataSource = viewer.dataSources.get(247);
    }

    if (lastChecked === "angradosreisacc") {
      kmlDataSource = viewer.dataSources.get(248);
    }

    if (lastChecked === "br101km414acc") {
      kmlDataSource = viewer.dataSources.get(249);
    }

    if (lastChecked === "br101km447acc") {
      kmlDataSource = viewer.dataSources.get(250);
    }

    if (lastChecked === "br101km538acc") {
      kmlDataSource = viewer.dataSources.get(251);
    }

    if (lastChecked === "itaguaiacc") {
      kmlDataSource = viewer.dataSources.get(252);
    }

    if (lastChecked === "mangaratibaacc") {
      kmlDataSource = viewer.dataSources.get(253);
    }

    if (lastChecked === "paratyacc") {
      kmlDataSource = viewer.dataSources.get(254);
    }

    if (lastChecked === "trechobr101acc") {
      kmlDataSource = viewer.dataSources.get(255);
    }

    if (lastChecked === "rendaacc") {
      kmlDataSource = viewer.dataSources.get(256);
    }

    if (lastChecked === "areasconmataatlaacc") {
      kmlDataSource = viewer.dataSources.get(257);
    }

    if (lastChecked === "appacc") {
      kmlDataSource = viewer.dataSources.get(258);
    }

    if (lastChecked === "vegetacaonativaacc") {
      kmlDataSource = viewer.dataSources.get(259);
    }

    if (lastChecked === "uniconsfederaisacc") {
      kmlDataSource = viewer.dataSources.get(260);
    }

    if (lastChecked === "reservalegalacc") {
      kmlDataSource = viewer.dataSources.get(261);
    }

    if (lastChecked === "areasprotacc") {
      kmlDataSource = viewer.dataSources.get(262);
    }

    if (lastChecked === "acaiteriacb") {
      kmlDataSource = viewer.dataSources.get(263);
    }

    if (lastChecked === "sorveteriacb") {
      kmlDataSource = viewer.dataSources.get(264);
    }

    if (lastChecked === "moteisac") {
      kmlDataSource = viewer.dataSources.get(265);
    }

    if (lastChecked === "pedagiosac") {
      kmlDataSource = viewer.dataSources.get(266);
    }

    if (lastChecked === "postosac") {
      kmlDataSource = viewer.dataSources.get(267);
    }

    if (lastChecked === "restaurantesac") {
      kmlDataSource = viewer.dataSources.get(268);
    }

    if (lastChecked === "hoteisac") {
      kmlDataSource = viewer.dataSources.get(269);
    }

    if (lastChecked === "pousadasac") {
      kmlDataSource = viewer.dataSources.get(270);
    }

    if (lastChecked === "areaspotpucrsg") {
      kmlDataSource = viewer.dataSources.get(271);
    }

    if (lastChecked === "areaspotunicampport2rsg") {
      kmlDataSource = viewer.dataSources.get(272);
    }

    if (lastChecked === "areaspotunicampprincrsg") {
      kmlDataSource = viewer.dataSources.get(273);
    }

    if (lastChecked === "portariasrsg") {
      kmlDataSource = viewer.dataSources.get(274);
    }

    if (lastChecked === "raio200rsg") {
      kmlDataSource = viewer.dataSources.get(275);
    }

    if (lastChecked === "terrenosdisprsg") {
      kmlDataSource = viewer.dataSources.get(276);
    }

    if (lastChecked === "perimetrourbanocb") {
      kmlDataSource = viewer.dataSources.get(277);
    }

    if (lastChecked === "fluxokernelcb") {
      kmlDataSource = viewer.dataSources.get(278);
    }

    if (lastChecked === "concentrafluxocb") {
      kmlDataSource = viewer.dataSources.get(279);
    }

    if (lastChecked === "raiofluxocb") {
      kmlDataSource = viewer.dataSources.get(280);
    }

    if (lastChecked === "fluxoconcocb") {
      kmlDataSource = viewer.dataSources.get(281);
    }

    if (lastChecked === "zoneamentopec") {
      kmlDataSource = viewer.dataSources.get(282);
    }

    if (lastChecked === "pontosintpec") {
      kmlDataSource = viewer.dataSources.get(283);
    }

    if (lastChecked === "permissaopec") {
      kmlDataSource = viewer.dataSources.get(284);
    }

    if (lastChecked === "topografiapec") {
      kmlDataSource = viewer.dataSources.get(285);
    }

    if (lastChecked === "crescimentoanopec") {
      kmlDataSource = viewer.dataSources.get(286);
    }

    if (lastChecked === "graprohab") {
      kmlDataSource = viewer.dataSources.get(287);
    }

    if (lastChecked === "densidadepec") {
      kmlDataSource = viewer.dataSources.get(288);
    }

    if (lastChecked === "familiaspec") {
      kmlDataSource = viewer.dataSources.get(289);
    }

    if (lastChecked === "rendapec") {
      kmlDataSource = viewer.dataSources.get(290);
    }

    if (lastChecked === "corpospec") {
      kmlDataSource = viewer.dataSources.get(291);
    }

    if (lastChecked === "cursospec") {
      kmlDataSource = viewer.dataSources.get(292);
    }

    if (lastChecked === "nascentespec") {
      kmlDataSource = viewer.dataSources.get(293);
    }

    if (lastChecked === "apppec") {
      kmlDataSource = viewer.dataSources.get(294);
    }

    if (lastChecked === "reservapec") {
      kmlDataSource = viewer.dataSources.get(295);
    }

    if (lastChecked === "vegpec") {
      kmlDataSource = viewer.dataSources.get(296);
    }

    if (lastChecked === "terrenos2k4kpec") {
      kmlDataSource = viewer.dataSources.get(297);
    }

    if (lastChecked === "terrenos5kmaispec") {
      kmlDataSource = viewer.dataSources.get(298);
    }

    if (lastChecked === "terrenosnaopec") {
      kmlDataSource = viewer.dataSources.get(299);
    }

    if (lastChecked === "terrenospot5kmaispec") {
      kmlDataSource = viewer.dataSources.get(300);
    }

    if (lastChecked === "terrenospot150kmaispec") {
      kmlDataSource = viewer.dataSources.get(301);
    }

    if (lastChecked === "mapaconcentracaopec") {
      kmlDataSource = viewer.dataSources.get(302);
    }

    if (lastChecked === "limitevmap") {
      kmlDataSource = viewer.dataSources.get(303);
    }

    if (lastChecked === "popfemvmap") {
      kmlDataSource = viewer.dataSources.get(304);
    }

    if (lastChecked === "popmasvmap") {
      kmlDataSource = viewer.dataSources.get(305);
    }

    if (lastChecked === "poptotvmap") {
      kmlDataSource = viewer.dataSources.get(306);
    }

    if (lastChecked === "prefeitovmap") {
      kmlDataSource = viewer.dataSources.get(307);
    }

    if (lastChecked === "vereadorvmap") {
      kmlDataSource = viewer.dataSources.get(308);
    }

    if (lastChecked === "rendavmap") {
      kmlDataSource = viewer.dataSources.get(309);
    }

    if (lastChecked === "vereadorvmap2") {
      kmlDataSource = viewer.dataSources.get(310);
    }

    if (lastChecked === "prefeitovmap2") {
      kmlDataSource = viewer.dataSources.get(311);
    }

    if (lastChecked === "demandaespacopu") {
      kmlDataSource = viewer.dataSources.get(312);
    }

    if (lastChecked === "demandaqualidadepu") {
      kmlDataSource = viewer.dataSources.get(313);
    }

    if (lastChecked === "faixafaturamentopu") {
      kmlDataSource = viewer.dataSources.get(314);
    }

    if (lastChecked === "leispu") {
      kmlDataSource = viewer.dataSources.get(315);
    }

    if (lastChecked === "mapageralpu") {
      kmlDataSource = viewer.dataSources.get(316);
    }

    if (lastChecked === "modelopu") {
      kmlDataSource = viewer.dataSources.get(317);
    }

    if (lastChecked === "preferenpu") {
      kmlDataSource = viewer.dataSources.get(318);
    }

    if (lastChecked === "necessidadepu") {
      kmlDataSource = viewer.dataSources.get(319);
    }

    if (lastChecked === "relacaopu") {
      kmlDataSource = viewer.dataSources.get(320);
    }

    if (lastChecked === "setorpu") {
      kmlDataSource = viewer.dataSources.get(321);
    }

    if (lastChecked === "divisaoregpec") {
      kmlDataSource = viewer.dataSources.get(322);
    }

    if (lastChecked === "vaziospec") {
      kmlDataSource = viewer.dataSources.get(323);
    }

    if (lastChecked === "usosolopec") {
      kmlDataSource = viewer.dataSources.get(324);
    }

    if (lastChecked === "concenfluxospec") {
      kmlDataSource = viewer.dataSources.get(325);
    }

    if (lastChecked === "topografiapec2") {
      kmlDataSource = viewer.dataSources.get(326);
    }

    if (lastChecked === "faixaac") {
      kmlDataSource = viewer.dataSources.get(327);
    }

    if (lastChecked === "areaintbb") {
      kmlDataSource = viewer.dataSources.get(328);
    }

    if (lastChecked === "boibrabobb") {
      kmlDataSource = viewer.dataSources.get(329);
    }

    if (lastChecked === "classesbb") {
      kmlDataSource = viewer.dataSources.get(330);
    }

    if (lastChecked === "compsbb") {
      kmlDataSource = viewer.dataSources.get(331);
    }

    if (lastChecked === "conccompsbb") {
      kmlDataSource = viewer.dataSources.get(332);
    }

    if (lastChecked === "concpfbb") {
      kmlDataSource = viewer.dataSources.get(333);
    }

    if (lastChecked === "familiasbb") {
      kmlDataSource = viewer.dataSources.get(334);
    }

    if (lastChecked === "fluxocompsbb") {
      kmlDataSource = viewer.dataSources.get(335);
    }

    if (lastChecked === "imoveisbb") {
      kmlDataSource = viewer.dataSources.get(336);
    }

    if (lastChecked === "pontosfluxobb") {
      kmlDataSource = viewer.dataSources.get(337);
    }

    if (lastChecked === "rendabb") {
      kmlDataSource = viewer.dataSources.get(338);
    }

    if (lastChecked === "metrobb") {
      kmlDataSource = viewer.dataSources.get(339);
    }

    if (lastChecked === "onibusbb") {
      kmlDataSource = viewer.dataSources.get(340);
    }

    if (lastChecked === "trembb") {
      kmlDataSource = viewer.dataSources.get(341);
    }

    if (lastChecked === "areapotbb") {
      kmlDataSource = viewer.dataSources.get(342);
    }

    if (lastChecked === "areapotdp") {
      kmlDataSource = viewer.dataSources.get(343);
    }

    if (lastChecked === "compsdp") {
      kmlDataSource = viewer.dataSources.get(344);
    }

    if (lastChecked === "conccompsdp") {
      kmlDataSource = viewer.dataSources.get(345);
    }

    if (lastChecked === "concpontofluxodp") {
      kmlDataSource = viewer.dataSources.get(346);
    }

    if (lastChecked === "familiasdp") {
      kmlDataSource = viewer.dataSources.get(347);
    }

    if (lastChecked === "gastodp") {
      kmlDataSource = viewer.dataSources.get(348);
    }

    if (lastChecked === "pontofluxodp") {
      kmlDataSource = viewer.dataSources.get(349);
    }

    if (lastChecked === "pop20a69dp") {
      kmlDataSource = viewer.dataSources.get(350);
    }

    if (lastChecked === "rendadp") {
      kmlDataSource = viewer.dataSources.get(351);
    }

    if (lastChecked === "armazensacc2") {
      kmlDataSource = viewer.dataSources.get(352);
    }

    if (lastChecked === "balsasacc2") {
      kmlDataSource = viewer.dataSources.get(353);
    }

    if (lastChecked === "concarmazensacc2") {
      kmlDataSource = viewer.dataSources.get(354);
    }

    if (lastChecked === "formosoacc2") {
      kmlDataSource = viewer.dataSources.get(355);
    }

    if (lastChecked === "lagoaacc2") {
      kmlDataSource = viewer.dataSources.get(356);
    }

    if (lastChecked === "miranorteacc2") {
      kmlDataSource = viewer.dataSources.get(357);
    }

    if (lastChecked === "portoacc2") {
      kmlDataSource = viewer.dataSources.get(358);
    }

    if (lastChecked === "silvanopolisacc2") {
      kmlDataSource = viewer.dataSources.get(359);
    }

    if (lastChecked === "tassoacc2") {
      kmlDataSource = viewer.dataSources.get(360);
    }

    if (lastChecked === "usosoloacc2") {
      kmlDataSource = viewer.dataSources.get(361);
    }

    if (lastChecked === "hidroviasgeralacc2") {
      kmlDataSource = viewer.dataSources.get(362);
    }

    if (lastChecked === "viasgeralacc2") {
      kmlDataSource = viewer.dataSources.get(363);
    }

    if (lastChecked === "portsaoluisacc2") {
      kmlDataSource = viewer.dataSources.get(364);
    }

    if (lastChecked === "portnacionalacc2") {
      kmlDataSource = viewer.dataSources.get(365);
    }

    if (lastChecked === "intpalemeiranteacc2") {
      kmlDataSource = viewer.dataSources.get(366);
    }

    if (lastChecked === "rodoviasacc2") {
      kmlDataSource = viewer.dataSources.get(367);
    }

    if (lastChecked === "instalacoesportacc2") {
      kmlDataSource = viewer.dataSources.get(368);
    }

    if (lastChecked === "hidroviasofcacc2") {
      kmlDataSource = viewer.dataSources.get(369);
    }

    if (lastChecked === "ferroviasacc2") {
      kmlDataSource = viewer.dataSources.get(370);
    }

    if (lastChecked === "usosolomiranorteacc2") {
      kmlDataSource = viewer.dataSources.get(371);
    }

    if (lastChecked === "usosolosilvanopolisacc2") {
      kmlDataSource = viewer.dataSources.get(372);
    }

    if (lastChecked === "usosololagoaacc2") {
      kmlDataSource = viewer.dataSources.get(373);
    }

    if (lastChecked === "usosoloformosoacc2") {
      kmlDataSource = viewer.dataSources.get(374);
    }

    if (lastChecked === "usosoloportoacc2") {
      kmlDataSource = viewer.dataSources.get(375);
    }

    if (lastChecked === "usosolobalsasacc2") {
      kmlDataSource = viewer.dataSources.get(376);
    }

    if (lastChecked === "usosolotassoacc2") {
      kmlDataSource = viewer.dataSources.get(377);
    }

    if (lastChecked === "lmsaltosc") {
      kmlDataSource = viewer.dataSources.get(378);
    }

    if (lastChecked === "compsaltosc") {
      kmlDataSource = viewer.dataSources.get(379);
    }

    if (lastChecked === "r1saltosc") {
      kmlDataSource = viewer.dataSources.get(380);
    }

    if (lastChecked === "r2saltosc") {
      kmlDataSource = viewer.dataSources.get(381);
    }

    if (lastChecked === "pfsaltosc") {
      kmlDataSource = viewer.dataSources.get(382);
    }

    if (lastChecked === "scsaltosc") {
      kmlDataSource = viewer.dataSources.get(383);
    }

    if (lastChecked === "lmmarasc") {
      kmlDataSource = viewer.dataSources.get(384);
    }

    if (lastChecked === "compmarasc") {
      kmlDataSource = viewer.dataSources.get(385);
    }

    if (lastChecked === "buffermarasc") {
      kmlDataSource = viewer.dataSources.get(386);
    }

    if (lastChecked === "pfmarasc") {
      kmlDataSource = viewer.dataSources.get(387);
    }

    if (lastChecked === "scmarasc") {
      kmlDataSource = viewer.dataSources.get(388);
    }

    if (lastChecked === "lmjacaresc") {
      kmlDataSource = viewer.dataSources.get(389);
    }

    if (lastChecked === "compjacaresc") {
      kmlDataSource = viewer.dataSources.get(390);
    }

    if (lastChecked === "r1jacaresc") {
      kmlDataSource = viewer.dataSources.get(391);
    }

    if (lastChecked === "pfjacaresc") {
      kmlDataSource = viewer.dataSources.get(392);
    }

    if (lastChecked === "scjacaresc") {
      kmlDataSource = viewer.dataSources.get(393);
    }

    if (lastChecked === "terrenospec") {
      kmlDataSource = viewer.dataSources.get(394);
    }

    if (lastChecked === "terrenospecpau") {
      kmlDataSource = viewer.dataSources.get(395);
    }

    if (lastChecked === "amoarmazensacc2") {
      kmlDataSource = viewer.dataSources.get(396);
    }

    if (lastChecked === "prestaacc2") {
      kmlDataSource = viewer.dataSources.get(397);
    }

    if (lastChecked === "habacc2") {
      kmlDataSource = viewer.dataSources.get(398);
    }

    if (lastChecked === "capacc2") {
      kmlDataSource = viewer.dataSources.get(399);
    }
    if (lastChecked === "tipoacc2") {
      kmlDataSource = viewer.dataSources.get(400);
    }

    if (lastChecked === "porteacc2") {
      kmlDataSource = viewer.dataSources.get(401);
    }

    if (lastChecked === "formacc2") {
      kmlDataSource = viewer.dataSources.get(402);
    }
    if (lastChecked === "distferroacc2") {
      kmlDataSource = viewer.dataSources.get(403);
    }

    if (lastChecked === "distermacc2") {
      kmlDataSource = viewer.dataSources.get(404);
    }

    if (lastChecked === "estosoacc2") {
      kmlDataSource = viewer.dataSources.get(405);
    }

    if (lastChecked === "precosoacc2") {
      kmlDataSource = viewer.dataSources.get(406);
    }
    if (lastChecked === "popacc2") {
      kmlDataSource = viewer.dataSources.get(407);
    }

    if (lastChecked === "rendaacc2") {
      kmlDataSource = viewer.dataSources.get(408);
    }

    if (lastChecked === "aeroacc2") {
      kmlDataSource = viewer.dataSources.get(409);
    }

    if (lastChecked === "declacc2") {
      kmlDataSource = viewer.dataSources.get(410);
    }

    if (lastChecked === "dadossociosaltossc") {
      kmlDataSource = viewer.dataSources.get(411);
    }

    if (lastChecked === "fluxo1marajasc") {
      kmlDataSource = viewer.dataSources.get(412);
    }

    if (lastChecked === "kernelfluxo1marajasc") {
      kmlDataSource = viewer.dataSources.get(413);
    }

    if (lastChecked === "familiasmarajassc") {
      kmlDataSource = viewer.dataSources.get(414);
    }

    if (lastChecked === "rendamarajassc") {
      kmlDataSource = viewer.dataSources.get(415);
    }

    if (lastChecked === "dadossociojacaresc") {
      kmlDataSource = viewer.dataSources.get(416);
    }

    if (lastChecked === "crescimentobrz") {
      kmlDataSource = viewer.dataSources.get(417);
    }

    if (lastChecked === "domiciliosaluguelbrz") {
      kmlDataSource = viewer.dataSources.get(418);
    }

    if (lastChecked === "domicioliosbrz") {
      kmlDataSource = viewer.dataSources.get(419);
    }

    if (lastChecked === "empreendimentosbrz") {
      kmlDataSource = viewer.dataSources.get(420);
    }

    if (lastChecked === "lavrasbrz") {
      kmlDataSource = viewer.dataSources.get(421);
    }

    if (lastChecked === "popbrz") {
      kmlDataSource = viewer.dataSources.get(422);
    }

    if (lastChecked === "raio15brz") {
      kmlDataSource = viewer.dataSources.get(423);
    }

    if (lastChecked === "rendabrz") {
      kmlDataSource = viewer.dataSources.get(424);
    }

    if (lastChecked === "vetorbrz") {
      kmlDataSource = viewer.dataSources.get(425);
    }

    if (lastChecked === "prop1toacc2") {
      kmlDataSource = viewer.dataSources.get(426);
    }

    if (lastChecked === "prop2toacc2") {
      kmlDataSource = viewer.dataSources.get(427);
    }

    if (lastChecked === "prop1maacc2") {
      kmlDataSource = viewer.dataSources.get(428);
    }

    if (lastChecked === "prop2maacc2") {
      kmlDataSource = viewer.dataSources.get(429);
    }

    if (lastChecked === "pontointbrz") {
      kmlDataSource = viewer.dataSources.get(430);
    }

    if (lastChecked === "crescimento1brz") {
      kmlDataSource = viewer.dataSources.get(431);
    }

    if (lastChecked === "deficitbrz") {
      kmlDataSource = viewer.dataSources.get(432);
    }

    if (lastChecked === "taxahabbrz") {
      kmlDataSource = viewer.dataSources.get(433);
    }

    if (lastChecked === "gtdcamacircu") {
      kmlDataSource = viewer.dataSources.get(434);
    }

    if (lastChecked === "distcamacircu") {
      kmlDataSource = viewer.dataSources.get(435);
    }

    if (lastChecked === "arearedcamacircu") {
      kmlDataSource = viewer.dataSources.get(436);
    }

    if (lastChecked === "areaspotcamacircu") {
      kmlDataSource = viewer.dataSources.get(437);
    }

    if (lastChecked === "assocamacircu") {
      kmlDataSource = viewer.dataSources.get(438);
    }

    if (lastChecked === "camacircu") {
      kmlDataSource = viewer.dataSources.get(439);
    }

    if (lastChecked === "empscamacircu") {
      kmlDataSource = viewer.dataSources.get(440);
    }

    if (lastChecked === "concempcamacircu") {
      kmlDataSource = viewer.dataSources.get(441);
    }

    if (lastChecked === "areaspotvargcircu") {
      kmlDataSource = viewer.dataSources.get(442);
    }

    if (lastChecked === "assovargcircu") {
      kmlDataSource = viewer.dataSources.get(443);
    }

    if (lastChecked === "vargcircu") {
      kmlDataSource = viewer.dataSources.get(444);
    }

    if (lastChecked === "empsvargcircu") {
      kmlDataSource = viewer.dataSources.get(445);
    }

    if (lastChecked === "gtdcampcircu") {
      kmlDataSource = viewer.dataSources.get(446);
    }

    if (lastChecked === "distcampcircu") {
      kmlDataSource = viewer.dataSources.get(447);
    }

    if (lastChecked === "distecocampcircu") {
      kmlDataSource = viewer.dataSources.get(448);
    }

    if (lastChecked === "arearedcampcircu") {
      kmlDataSource = viewer.dataSources.get(449);
    }

    if (lastChecked === "areaspotcampcircu") {
      kmlDataSource = viewer.dataSources.get(450);
    }

    if (lastChecked === "assocampcircu") {
      kmlDataSource = viewer.dataSources.get(451);
    }

    if (lastChecked === "campcircu") {
      kmlDataSource = viewer.dataSources.get(452);
    }

    if (lastChecked === "empscampcircu") {
      kmlDataSource = viewer.dataSources.get(453);
    }

    if (lastChecked === "densempscampcircu") {
      kmlDataSource = viewer.dataSources.get(454);
    }

    if (lastChecked === "ecoscampcircu") {
      kmlDataSource = viewer.dataSources.get(455);
    }

    if (lastChecked === "gtdjundcircu") {
      kmlDataSource = viewer.dataSources.get(456);
    }

    if (lastChecked === "distjundcircu") {
      kmlDataSource = viewer.dataSources.get(457);
    }

    if (lastChecked === "distecojundcircu") {
      kmlDataSource = viewer.dataSources.get(458);
    }

    if (lastChecked === "arearedjundcircu") {
      kmlDataSource = viewer.dataSources.get(459);
    }

    if (lastChecked === "areaspotjundcircu") {
      kmlDataSource = viewer.dataSources.get(460);
    }

    if (lastChecked === "assojundcircu") {
      kmlDataSource = viewer.dataSources.get(461);
    }

    if (lastChecked === "jundcircu") {
      kmlDataSource = viewer.dataSources.get(462);
    }

    if (lastChecked === "empsjundcircu") {
      kmlDataSource = viewer.dataSources.get(463);
    }

    if (lastChecked === "densempsjundcircu") {
      kmlDataSource = viewer.dataSources.get(464);
    }

    if (lastChecked === "ecosjundcircu") {
      kmlDataSource = viewer.dataSources.get(465);
    }

    if (lastChecked === "gtdsorocircu") {
      kmlDataSource = viewer.dataSources.get(466);
    }

    if (lastChecked === "distsorocircu") {
      kmlDataSource = viewer.dataSources.get(467);
    }

    if (lastChecked === "distecosorocircu") {
      kmlDataSource = viewer.dataSources.get(468);
    }

    if (lastChecked === "arearedsorocircu") {
      kmlDataSource = viewer.dataSources.get(469);
    }

    if (lastChecked === "areaspotsorocircu") {
      kmlDataSource = viewer.dataSources.get(470);
    }

    if (lastChecked === "assosorocircu") {
      kmlDataSource = viewer.dataSources.get(471);
    }

    if (lastChecked === "sorocircu") {
      kmlDataSource = viewer.dataSources.get(472);
    }

    if (lastChecked === "empssorocircu") {
      kmlDataSource = viewer.dataSources.get(473);
    }

    if (lastChecked === "densempssorocircu") {
      kmlDataSource = viewer.dataSources.get(474);
    }

    if (lastChecked === "ecossorocircu") {
      kmlDataSource = viewer.dataSources.get(475);
    }

    if (lastChecked === "areapotafasdp") {
      kmlDataSource = viewer.dataSources.get(476);
    }

    if (lastChecked === "imoveisdp") {
      kmlDataSource = viewer.dataSources.get(477);
    }

    if (lastChecked === "jundiaimrv") {
      console.log("aqui");
      kmlDataSource = viewer.dataSources.get(478);
    }

    if (lastChecked === "appjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(479);
    }

    if (lastChecked === "vegjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(480);
    }

    if (lastChecked === "resjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(481);
    }

    if (lastChecked === "areaprojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(482);
    }

    if (lastChecked === "nascjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(483);
    }

    if (lastChecked === "cursjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(484);
    }

    if (lastChecked === "corposjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(485);
    }

    if (lastChecked === "topojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(486);
    }

    if (lastChecked === "urbjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(487);
    }

    if (lastChecked === "areapoujundiaimrv") {
      kmlDataSource = viewer.dataSources.get(488);
    }

    if (lastChecked === "servjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(489);
    }

    if (lastChecked === "usorestjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(490);
    }

    if (lastChecked === "areaconsjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(491);
    }

    if (lastChecked === "areacontjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(492);
    }

    if (lastChecked === "dutojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(493);
    }

    if (lastChecked === "linhaojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(494);
    }

    if (lastChecked === "zoneajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(495);
    }

    if (lastChecked === "legavertjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(496);
    }

    if (lastChecked === "legahorjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(497);
    }

    if (lastChecked === "rendapopjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(498);
    }

    if (lastChecked === "rendajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(499);
    }

    if (lastChecked === "popjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(500);
    }

    if (lastChecked === "domicjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(501);
    }

    if (lastChecked === "domicalujundiaimrv") {
      kmlDataSource = viewer.dataSources.get(502);
    }

    if (lastChecked === "expurbjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(503);
    }

    if (lastChecked === "taxahabjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(504);
    }

    if (lastChecked === "graprohabjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(505);
    }

    if (lastChecked === "areaurbjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(506);
    }

    if (lastChecked === "arearujundiaimrv") {
      kmlDataSource = viewer.dataSources.get(507);
    }

    if (lastChecked === "vaziosjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(508);
    }

    if (lastChecked === "consumoaguajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(509);
    }

    if (lastChecked === "dispaguajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(510);
    }

    if (lastChecked === "custojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(511);
    }

    if (lastChecked === "terrenosjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(512);
    }

    if (lastChecked === "pontoajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(513);
    }

    if (lastChecked === "pontodjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(514);
    }

    if (lastChecked === "limeiramrv") {
      kmlDataSource = viewer.dataSources.get(515);
    }

    if (lastChecked === "applimeiramrv") {
      kmlDataSource = viewer.dataSources.get(516);
    }

    if (lastChecked === "veglimeiramrv") {
      kmlDataSource = viewer.dataSources.get(517);
    }

    if (lastChecked === "reslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(518);
    }

    if (lastChecked === "areaprolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(519);
    }

    if (lastChecked === "nasclimeiramrv") {
      kmlDataSource = viewer.dataSources.get(520);
    }

    if (lastChecked === "curslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(521);
    }

    if (lastChecked === "corposlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(522);
    }

    if (lastChecked === "topolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(523);
    }

    if (lastChecked === "urblimeiramrv") {
      kmlDataSource = viewer.dataSources.get(524);
    }

    if (lastChecked === "areapoulimeiramrv") {
      kmlDataSource = viewer.dataSources.get(525);
    }

    if (lastChecked === "servlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(526);
    }

    if (lastChecked === "usorestlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(527);
    }

    if (lastChecked === "areaconslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(528);
    }

    if (lastChecked === "areacontlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(529);
    }

    if (lastChecked === "dutolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(530);
    }

    if (lastChecked === "linhaolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(531);
    }

    if (lastChecked === "zonealimeiramrv") {
      kmlDataSource = viewer.dataSources.get(532);
    }

    if (lastChecked === "legavertlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(533);
    }

    if (lastChecked === "legahorlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(534);
    }

    if (lastChecked === "rendapoplimeiramrv") {
      kmlDataSource = viewer.dataSources.get(535);
    }

    if (lastChecked === "rendalimeiramrv") {
      kmlDataSource = viewer.dataSources.get(536);
    }

    if (lastChecked === "poplimeiramrv") {
      kmlDataSource = viewer.dataSources.get(537);
    }

    if (lastChecked === "domiclimeiramrv") {
      kmlDataSource = viewer.dataSources.get(538);
    }

    if (lastChecked === "domicalulimeiramrv") {
      kmlDataSource = viewer.dataSources.get(539);
    }

    if (lastChecked === "expurblimeiramrv") {
      kmlDataSource = viewer.dataSources.get(540);
    }

    if (lastChecked === "taxahablimeiramrv") {
      kmlDataSource = viewer.dataSources.get(541);
    }

    if (lastChecked === "graprohablimeiramrv") {
      kmlDataSource = viewer.dataSources.get(542);
    }

    if (lastChecked === "areaurblimeiramrv") {
      kmlDataSource = viewer.dataSources.get(543);
    }

    if (lastChecked === "arearulimeiramrv") {
      kmlDataSource = viewer.dataSources.get(544);
    }

    if (lastChecked === "vazioslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(545);
    }

    if (lastChecked === "consumoagualimeiramrv") {
      kmlDataSource = viewer.dataSources.get(546);
    }

    if (lastChecked === "dispagualimeiramrv") {
      kmlDataSource = viewer.dataSources.get(547);
    }

    if (lastChecked === "custolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(548);
    }

    if (lastChecked === "terrenoslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(549);
    }

    if (lastChecked === "pontoalimeiramrv") {
      kmlDataSource = viewer.dataSources.get(550);
    }

    if (lastChecked === "pontodlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(551);
    }

    if (lastChecked === "veloprecojundiaimrv") {
      kmlDataSource = viewer.dataSources.get(552);
    }

    if (lastChecked === "veloprecolimeiramrv") {
      kmlDataSource = viewer.dataSources.get(553);
    }

    if (lastChecked === "bdgu") {
      kmlDataSource = viewer.dataSources.get(554);
    }

    if (lastChecked === "boticampcircu") {
      kmlDataSource = viewer.dataSources.get(555);
    }

    if (lastChecked === "botijundcircu") {
      kmlDataSource = viewer.dataSources.get(556);
    }

    if (lastChecked === "botisorocircu") {
      kmlDataSource = viewer.dataSources.get(557);
    }

    if (lastChecked === "botigrandespcircu") {
      kmlDataSource = viewer.dataSources.get(558);
    }

    if (lastChecked === "grandespcircu") {
      kmlDataSource = viewer.dataSources.get(559);
    }

    if (lastChecked === "imoveisc1ic") {
      kmlDataSource = viewer.dataSources.get(560);
    }

    if (lastChecked === "raioc1ic") {
      kmlDataSource = viewer.dataSources.get(561);
    }

    if (lastChecked === "areaestudoc2ic") {
      kmlDataSource = viewer.dataSources.get(562);
    }

    if (lastChecked === "rendac2ic") {
      kmlDataSource = viewer.dataSources.get(563);
    }

    if (lastChecked === "recortec2ic") {
      kmlDataSource = viewer.dataSources.get(564);
    }

    if (lastChecked === "pontosfluxc2ic") {
      kmlDataSource = viewer.dataSources.get(565);
    }

    if (lastChecked === "concfluxoc2ic") {
      kmlDataSource = viewer.dataSources.get(566);
    }

    if (lastChecked === "oticasc2ic") {
      kmlDataSource = viewer.dataSources.get(567);
    }

    if (lastChecked === "concfluxooticasc2ic") {
      kmlDataSource = viewer.dataSources.get(568);
    }

    if (lastChecked === "imoveisc2ic") {
      kmlDataSource = viewer.dataSources.get(569);
    }

    if (lastChecked === "shoppingsc2ic") {
      kmlDataSource = viewer.dataSources.get(570);
    }

    if (lastChecked === "kernelsaltosc") {
      kmlDataSource = viewer.dataSources.get(571);
    }

    if (lastChecked === "kerneljacaresc") {
      kmlDataSource = viewer.dataSources.get(572);
    }

    if (lastChecked === "ponibusmarasc") {
      kmlDataSource = viewer.dataSources.get(573);
    }

    if (lastChecked === "lonibusmarasc") {
      kmlDataSource = viewer.dataSources.get(574);
    }

    if (lastChecked === "redesdrenalimeiramrv") {
      kmlDataSource = viewer.dataSources.get(575);
    }

    if (lastChecked === "recargahidricalimeiramrv") {
      kmlDataSource = viewer.dataSources.get(576);
    }

    if (lastChecked === "ferroviaslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(577);
    }

    if (lastChecked === "viaslimeiramrv") {
      kmlDataSource = viewer.dataSources.get(578);
    }

    if (lastChecked === "viasligalimeiramrv") {
      kmlDataSource = viewer.dataSources.get(579);
    }

    if (lastChecked === "rankingterlimeiramrv") {
      kmlDataSource = viewer.dataSources.get(580);
    }

    if (lastChecked === "redesdrenajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(581);
    }

    if (lastChecked === "recargahidricajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(582);
    }

    if (lastChecked === "viasjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(583);
    }

    if (lastChecked === "viasligajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(584);
    }

    if (lastChecked === "ferroviasjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(585);
    }

    if (lastChecked === "equipamentosjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(586);
    }

    if (lastChecked === "rankingterjundiaimrv") {
      kmlDataSource = viewer.dataSources.get(587);
    }

    if (lastChecked === "poluicaosonorajundiaimrv") {
      kmlDataSource = viewer.dataSources.get(588);
    }

    if (lastChecked === "acadmelten") {
      kmlDataSource = viewer.dataSources.get(589);
    }

    if (lastChecked === "areasafastmelten") {
      kmlDataSource = viewer.dataSources.get(590);
    }

    if (lastChecked === "areasproxmelten") {
      kmlDataSource = viewer.dataSources.get(591);
    }

    if (lastChecked === "comerciosmelten") {
      kmlDataSource = viewer.dataSources.get(592);
    }

    if (lastChecked === "concconcmelten") {
      kmlDataSource = viewer.dataSources.get(593);
    }

    if (lastChecked === "concmelten") {
      kmlDataSource = viewer.dataSources.get(594);
    }

    if (lastChecked === "crescurbanomelten") {
      kmlDataSource = viewer.dataSources.get(595);
    }

    if (lastChecked === "domiciliosmelten") {
      kmlDataSource = viewer.dataSources.get(596);
    }

    if (lastChecked === "equipublimelten") {
      kmlDataSource = viewer.dataSources.get(597);
    }

    if (lastChecked === "escolasprivadasmelten") {
      kmlDataSource = viewer.dataSources.get(598);
    }

    if (lastChecked === "hidroviasmelten") {
      kmlDataSource = viewer.dataSources.get(599);
    }

    if (lastChecked === "hospitaismelten") {
      kmlDataSource = viewer.dataSources.get(600);
    }

    if (lastChecked === "imoveismelten") {
      kmlDataSource = viewer.dataSources.get(601);
    }

    if (lastChecked === "infraviariamelten") {
      kmlDataSource = viewer.dataSources.get(602);
    }

    if (lastChecked === "melten") {
      kmlDataSource = viewer.dataSources.get(603);
    }

    if (lastChecked === "palmasmelten") {
      kmlDataSource = viewer.dataSources.get(604);
    }

    if (lastChecked === "popmelten") {
      kmlDataSource = viewer.dataSources.get(605);
    }

    if (lastChecked === "rendamelten") {
      kmlDataSource = viewer.dataSources.get(606);
    }

    if (lastChecked === "restaurantesmelten") {
      kmlDataSource = viewer.dataSources.get(607);
    }

    if (lastChecked === "transportemelten") {
      kmlDataSource = viewer.dataSources.get(608);
    }

    if (lastChecked === "viasmelten") {
      kmlDataSource = viewer.dataSources.get(609);
    }

    if (lastChecked === "gtdipocircu") {
      kmlDataSource = viewer.dataSources.get(610);
    }

    if (lastChecked === "arearedipocircu") {
      kmlDataSource = viewer.dataSources.get(611);
    }

    if (lastChecked === "areaspotipocircu") {
      kmlDataSource = viewer.dataSources.get(612);
    }

    if (lastChecked === "coopreciipocircu") {
      kmlDataSource = viewer.dataSources.get(613);
    }

    if (lastChecked === "ipocircu") {
      kmlDataSource = viewer.dataSources.get(614);
    }

    if (lastChecked === "empsipocircu") {
      kmlDataSource = viewer.dataSources.get(615);
    }

    if (lastChecked === "densempsipocircu") {
      kmlDataSource = viewer.dataSources.get(616);
    }

    if (lastChecked === "ecosipocircu") {
      kmlDataSource = viewer.dataSources.get(617);
    }

    if (lastChecked === "correcondcamacircu") {
      kmlDataSource = viewer.dataSources.get(618);
    }

    if (lastChecked === "correcondvargcircu") {
      kmlDataSource = viewer.dataSources.get(619);
    }

    if (lastChecked === "correcondcampcircu") {
      kmlDataSource = viewer.dataSources.get(620);
    }

    if (lastChecked === "correcondjundcircu") {
      kmlDataSource = viewer.dataSources.get(621);
    }

    if (lastChecked === "correcondsorocircu") {
      kmlDataSource = viewer.dataSources.get(622);
    }

    if (lastChecked === "regionalizacaomelten") {
      kmlDataSource = viewer.dataSources.get(623);
    }

    if (lastChecked === "crescpecpau") {
      kmlDataSource = viewer.dataSources.get(624);
    }

    if (lastChecked === "dadossociospecpau") {
      kmlDataSource = viewer.dataSources.get(625);
    }

    if (lastChecked === "hidropecpau") {
      kmlDataSource = viewer.dataSources.get(626);
    }

    if (lastChecked === "limitesambpecpau") {
      kmlDataSource = viewer.dataSources.get(627);
    }

    if (lastChecked === "pontosfluxopecpau") {
      kmlDataSource = viewer.dataSources.get(628);
    }

    if (lastChecked === "raios500pecpau") {
      kmlDataSource = viewer.dataSources.get(629);
    }

    if (lastChecked === "servidaopecpau") {
      kmlDataSource = viewer.dataSources.get(630);
    }

    if (lastChecked === "terrenospotpecpau") {
      kmlDataSource = viewer.dataSources.get(631);
    }

    if (lastChecked === "terrenosranqpecpau") {
      kmlDataSource = viewer.dataSources.get(632);
    }

    if (lastChecked === "topopecpau") {
      kmlDataSource = viewer.dataSources.get(633);
    }

    if (lastChecked === "vaziospecpau") {
      kmlDataSource = viewer.dataSources.get(634);
    }

    if (lastChecked === "playersagrovh") {
      kmlDataSource = viewer.dataSources.get(635);
    }

    if (lastChecked === "campinasvh") {
      kmlDataSource = viewer.dataSources.get(636);
    }

    if (lastChecked === "parquesteccampvh") {
      kmlDataSource = viewer.dataSources.get(637);
    }

    if (lastChecked === "demandagu") {
      kmlDataSource = viewer.dataSources.get(638);
    }

    if (lastChecked === "ativpesquisagu") {
      kmlDataSource = viewer.dataSources.get(639);
    }

    if (lastChecked === "interessehubgu") {
      kmlDataSource = viewer.dataSources.get(640);
    }

    if (lastChecked === "modelotrabalhogu") {
      kmlDataSource = viewer.dataSources.get(641);
    }

    if (lastChecked === "capitalpropriogu") {
      kmlDataSource = viewer.dataSources.get(642);
    }

    if (lastChecked === "fundestaduaisgu") {
      kmlDataSource = viewer.dataSources.get(643);
    }

    if (lastChecked === "sebraegu") {
      kmlDataSource = viewer.dataSources.get(644);
    }

    if (lastChecked === "bndesgu") {
      kmlDataSource = viewer.dataSources.get(645);
    }

    if (lastChecked === "investidoranjogu") {
      kmlDataSource = viewer.dataSources.get(646);
    }

    if (lastChecked === "fundosinvestgu") {
      kmlDataSource = viewer.dataSources.get(647);
    }

    if (lastChecked === "aceleradorasgu") {
      kmlDataSource = viewer.dataSources.get(648);
    }

    if (lastChecked === "semrespostagu") {
      kmlDataSource = viewer.dataSources.get(649);
    }

    if (lastChecked === "ictsvh") {
      kmlDataSource = viewer.dataSources.get(650);
    }

    if (lastChecked === "ictsagrovh") {
      kmlDataSource = viewer.dataSources.get(651);
    }

    if (lastChecked === "areaspreservavespemcp") {
      kmlDataSource = viewer.dataSources.get(652);
    }

    if (lastChecked === "vegnativavespemcp") {
      kmlDataSource = viewer.dataSources.get(653);
    }

    if (lastChecked === "reservalegalvespemcp") {
      kmlDataSource = viewer.dataSources.get(654);
    }

    if (lastChecked === "nascentesvespemcp") {
      kmlDataSource = viewer.dataSources.get(655);
    }

    if (lastChecked === "cursosaguavespemcp") {
      kmlDataSource = viewer.dataSources.get(656);
    }

    if (lastChecked === "topografiavespemcp") {
      kmlDataSource = viewer.dataSources.get(657);
    }

    if (lastChecked === "limitevespemcp") {
      kmlDataSource = viewer.dataSources.get(658);
    }

    if (lastChecked === "zoneamentovespemcp") {
      kmlDataSource = viewer.dataSources.get(659);
    }

    if (lastChecked === "usovertvespemcp") {
      kmlDataSource = viewer.dataSources.get(660);
    }

    if (lastChecked === "permissaovespemcp") {
      kmlDataSource = viewer.dataSources.get(661);
    }

    if (lastChecked === "locterrenovespemcp") {
      kmlDataSource = viewer.dataSources.get(662);
    }

    if (lastChecked === "servidaovespemcp") {
      kmlDataSource = viewer.dataSources.get(663);
    }

    if (lastChecked === "areaconsovespemcp") {
      kmlDataSource = viewer.dataSources.get(664);
    }

    if (lastChecked === "areascontavespemcp") {
      kmlDataSource = viewer.dataSources.get(665);
    }

    if (lastChecked === "dutoviasvespemcp") {
      kmlDataSource = viewer.dataSources.get(666);
    }

    if (lastChecked === "linhaovespemcp") {
      kmlDataSource = viewer.dataSources.get(667);
    }

    if (lastChecked === "novoscondvespemcp") {
      kmlDataSource = viewer.dataSources.get(668);
    }

    if (lastChecked === "rendavespemcp") {
      kmlDataSource = viewer.dataSources.get(669);
    }

    if (lastChecked === "popvespemcp") {
      kmlDataSource = viewer.dataSources.get(670);
    }

    if (lastChecked === "domicivespemcp") {
      kmlDataSource = viewer.dataSources.get(671);
    }

    if (lastChecked === "domicialuvespemcp") {
      kmlDataSource = viewer.dataSources.get(672);
    }

    if (lastChecked === "expurbanavespemcp") {
      kmlDataSource = viewer.dataSources.get(673);
    }

    if (lastChecked === "veloeprecovespemcp") {
      kmlDataSource = viewer.dataSources.get(674);
    }

    if (lastChecked === "taxahabvespemcp") {
      kmlDataSource = viewer.dataSources.get(675);
    }

    if (lastChecked === "concorrenciavespemcp") {
      kmlDataSource = viewer.dataSources.get(676);
    }

    if (lastChecked === "areaurbavespemcp") {
      kmlDataSource = viewer.dataSources.get(677);
    }

    if (lastChecked === "areasruraisvespemcp") {
      kmlDataSource = viewer.dataSources.get(678);
    }

    if (lastChecked === "consuaguavespemcp") {
      kmlDataSource = viewer.dataSources.get(679);
    }

    if (lastChecked === "dispaguavespemcp") {
      kmlDataSource = viewer.dataSources.get(680);
    }

    if (lastChecked === "custoprojetovespemcp") {
      kmlDataSource = viewer.dataSources.get(681);
    }

    if (lastChecked === "terrenosanunvespemcp") {
      kmlDataSource = viewer.dataSources.get(682);
    }

    if (lastChecked === "imoveisanunvespemcp") {
      kmlDataSource = viewer.dataSources.get(683);
    }

    if (lastChecked === "pontosatravespemcp") {
      kmlDataSource = viewer.dataSources.get(684);
    }

    if (lastChecked === "pontosdetravespemcp") {
      kmlDataSource = viewer.dataSources.get(685);
    }

    if (lastChecked === "tjchpdtjd") {
      kmlDataSource = viewer.dataSources.get(686);
    }

    if (lastChecked === "compdiretoschpdtjd") {
      kmlDataSource = viewer.dataSources.get(687);
    }

    if (lastChecked === "criancas05chpdtjd") {
      kmlDataSource = viewer.dataSources.get(688);
    }

    if (lastChecked === "escolasidiomaschpdtjd") {
      kmlDataSource = viewer.dataSources.get(689);
    }

    if (lastChecked === "escolasinfantischpdtjd") {
      kmlDataSource = viewer.dataSources.get(690);
    }

    if (lastChecked === "escolaschpdtjd") {
      kmlDataSource = viewer.dataSources.get(691);
    }

    if (lastChecked === "imovelindicadochpdtjd") {
      kmlDataSource = viewer.dataSources.get(692);
    }

    if (lastChecked === "pontosfluxochpdtjd") {
      kmlDataSource = viewer.dataSources.get(693);
    }

    if (lastChecked === "principaisviaschpdtjd") {
      kmlDataSource = viewer.dataSources.get(694);
    }

    if (lastChecked === "raio1kmcompchpdtjd") {
      kmlDataSource = viewer.dataSources.get(695);
    }

    if (lastChecked === "raio2kmcompchpdtjd") {
      kmlDataSource = viewer.dataSources.get(696);
    }

    if (lastChecked === "rendafamiliarchpdtjd") {
      kmlDataSource = viewer.dataSources.get(697);
    }

    if (lastChecked === "areainflurctjd") {
      kmlDataSource = viewer.dataSources.get(698);
    }

    if (lastChecked === "areasindirctjd") {
      kmlDataSource = viewer.dataSources.get(699);
    }

    if (lastChecked === "competidoresrctjd") {
      kmlDataSource = viewer.dataSources.get(700);
    }

    if (lastChecked === "condominiosrctjd") {
      kmlDataSource = viewer.dataSources.get(701);
    }

    if (lastChecked === "imoveisrctjd") {
      kmlDataSource = viewer.dataSources.get(702);
    }

    if (lastChecked === "pontosfluxirctjd") {
      kmlDataSource = viewer.dataSources.get(703);
    }

    if (lastChecked === "compdiretosvptjd") {
      kmlDataSource = viewer.dataSources.get(704);
    }

    if (lastChecked === "imoveissapopembavptjd") {
      kmlDataSource = viewer.dataSources.get(705);
    }

    if (lastChecked === "imoveisvpvptjd") {
      kmlDataSource = viewer.dataSources.get(706);
    }

    if (lastChecked === "pontosfluxovptjd") {
      kmlDataSource = viewer.dataSources.get(707);
    }

    if (lastChecked === "rendafamiliarvptjd") {
      kmlDataSource = viewer.dataSources.get(708);
    }

    if (lastChecked === "tjschoolvptjd") {
      kmlDataSource = viewer.dataSources.get(709);
    }

    if (lastChecked === "appwbgi") {
      kmlDataSource = viewer.dataSources.get(710);
    }

    if (lastChecked === "areacontwbgi") {
      kmlDataSource = viewer.dataSources.get(711);
    }

    if (lastChecked === "curvasnivelwbgi") {
      kmlDataSource = viewer.dataSources.get(712);
    }

    if (lastChecked === "declividadewbgi") {
      kmlDataSource = viewer.dataSources.get(713);
    }

    if (lastChecked === "distcorposaguawbgi") {
      kmlDataSource = viewer.dataSources.get(714);
    }

    if (lastChecked === "distcorposhidricoswbgi") {
      kmlDataSource = viewer.dataSources.get(715);
    }

    if (lastChecked === "hidrografiawbgi") {
      kmlDataSource = viewer.dataSources.get(716);
    }

    if (lastChecked === "limeirawbgi") {
      kmlDataSource = viewer.dataSources.get(717);
    }

    if (lastChecked === "reservalegalwbgi") {
      kmlDataSource = viewer.dataSources.get(718);
    }

    if (lastChecked === "vegnativawbgi") {
      kmlDataSource = viewer.dataSources.get(719);
    }

    if (lastChecked === "viveiristaswbgi") {
      kmlDataSource = viewer.dataSources.get(720);
    }

    if (lastChecked === "limiteosascotnd") {
      kmlDataSource = viewer.dataSources.get(721);
  }
  if (lastChecked === "areapreserosascotnd") {
      kmlDataSource = viewer.dataSources.get(722);
  }
  if (lastChecked === "vegnaivaosascotnd") {
      kmlDataSource = viewer.dataSources.get(723);
  }
  if (lastChecked === "reservaosascotnd") {
      kmlDataSource = viewer.dataSources.get(724);
  }
  if (lastChecked === "nascentesosascotnd") {
      kmlDataSource = viewer.dataSources.get(725);
  }
  if (lastChecked === "cursososascotnd") {
      kmlDataSource = viewer.dataSources.get(726);
  }
  if (lastChecked === "corpososascotnd") {
      kmlDataSource = viewer.dataSources.get(727);
  }
  if (lastChecked === "topografiaosascotnd") {
      kmlDataSource = viewer.dataSources.get(728);
  }
  if (lastChecked === "zoneamentoosascotnd") {
      kmlDataSource = viewer.dataSources.get(729);
  }
  if (lastChecked === "usovertosascotnd") {
      kmlDataSource = viewer.dataSources.get(730);
  }
  if (lastChecked === "implantacaohishmposascotnd") {
      kmlDataSource = viewer.dataSources.get(731);
  }
  if (lastChecked === "servadmosascotnd") {
      kmlDataSource = viewer.dataSources.get(732);
  }
  if (lastChecked === "areaconsoosascotnd") {
      kmlDataSource = viewer.dataSources.get(733);
  }
  if (lastChecked === "areascontaosascotnd") {
      kmlDataSource = viewer.dataSources.get(734);
  }
  if (lastChecked === "dutoviasosascotnd") {
      kmlDataSource = viewer.dataSources.get(735);
  }
  if (lastChecked === "linhaoosascotnd") {
      kmlDataSource = viewer.dataSources.get(736);
  }
  if (lastChecked === "pontosatratosascotnd") {
      kmlDataSource = viewer.dataSources.get(737);
  }
  if (lastChecked === "pontosdetratosascotnd") {
      kmlDataSource = viewer.dataSources.get(738);
  }
  if (lastChecked === "rendaosascotnd") {
      kmlDataSource = viewer.dataSources.get(739);
  }
  if (lastChecked === "domiciliososascotnd") {
      kmlDataSource = viewer.dataSources.get(740);
  }
  if (lastChecked === "domiciliosaluguelosascotnd") {
      kmlDataSource = viewer.dataSources.get(741);
  }
  if (lastChecked === "expcidadesosascotnd") {
      kmlDataSource = viewer.dataSources.get(742);
  }
  if (lastChecked === "precomedioosascotnd") {
      kmlDataSource = viewer.dataSources.get(743);
  }
  if (lastChecked === "taxahabosascotnd") {
      kmlDataSource = viewer.dataSources.get(744);
  }
  if (lastChecked === "concoosascotnd") {
      kmlDataSource = viewer.dataSources.get(745);
  }
  if (lastChecked === "areaurbanizadaosascotnd") {
      kmlDataSource = viewer.dataSources.get(746);
  }
  if (lastChecked === "areasruraisosascotnd") {
      kmlDataSource = viewer.dataSources.get(747);
  }
  if (lastChecked === "vaziosurbanososascotnd") {
      kmlDataSource = viewer.dataSources.get(748);
  }
  if (lastChecked === "grapohabosascotnd") {
      kmlDataSource = viewer.dataSources.get(749);
  }
  if (lastChecked === "terrenososascotnd") {
      kmlDataSource = viewer.dataSources.get(750);
  }
  if (lastChecked === "imoveisosascotnd") {
      kmlDataSource = viewer.dataSources.get(751);
  }
  if (lastChecked === "tendenciassetorosascotnd") {
      kmlDataSource = viewer.dataSources.get(752);
  }
  if (lastChecked === "sinaismudancaosascotnd") {
      kmlDataSource = viewer.dataSources.get(753);
  }
  if (lastChecked === "oportunidadeosascotnd") {
      kmlDataSource = viewer.dataSources.get(754);
  }

  if (lastChecked === "areausorestosascotnd") {
    kmlDataSource = viewer.dataSources.get(755);
  }

  if (lastChecked === "areapotlimeiramrv") {
    kmlDataSource = viewer.dataSources.get(756);
  }
  
  if (lastChecked === "areapreserslgb") {
    kmlDataSource = viewer.dataSources.get(757);
  }

  if (lastChecked === "vegnativaslgb") {
    kmlDataSource = viewer.dataSources.get(758);
  }

  if (lastChecked === "reservaslgb") {
    kmlDataSource = viewer.dataSources.get(759);
  }

  if (lastChecked === "nascentesslgb") {
    kmlDataSource = viewer.dataSources.get(760);
  }

  if (lastChecked === "hidrografiaslgb") {
    kmlDataSource = viewer.dataSources.get(761);
  }

  if (lastChecked === "topografiaslgb") {
    kmlDataSource = viewer.dataSources.get(762);
  }

  if (lastChecked === "zoneamentoslgb") {
    kmlDataSource = viewer.dataSources.get(763);
  }

  if (lastChecked === "usovertslgb") {
    kmlDataSource = viewer.dataSources.get(764);
  }

  if (lastChecked === "usohorslgb") {
    kmlDataSource = viewer.dataSources.get(765);
  }

  if (lastChecked === "viasslgb") {
    kmlDataSource = viewer.dataSources.get(766);
  }

  if (lastChecked === "dutoviasslgb") {
    kmlDataSource = viewer.dataSources.get(767);
  }

  if (lastChecked === "linhaoslgb") {
    kmlDataSource = viewer.dataSources.get(768);
  }

  if (lastChecked === "pontosatratosslgb") {
    kmlDataSource = viewer.dataSources.get(769);
  }

  if (lastChecked === "pontosdetratslgb") {
    kmlDataSource = viewer.dataSources.get(770);
  }

  if (lastChecked === "rendaslgb") {
    kmlDataSource = viewer.dataSources.get(771);
  }

  if (lastChecked === "domiciliosslgb") {
    kmlDataSource = viewer.dataSources.get(772);
  }

  if (lastChecked === "concoslgb") {
    kmlDataSource = viewer.dataSources.get(773);
  }

  if (lastChecked === "areasruraisslgb") {
    kmlDataSource = viewer.dataSources.get(774);
  }

  if (lastChecked === "terrenosslgb") {
    kmlDataSource = viewer.dataSources.get(775);
  }

  if (lastChecked === "imoveisslgb") {
    kmlDataSource = viewer.dataSources.get(776);
  }

  if (lastChecked === "raiopesquisaslgb") {
    kmlDataSource = viewer.dataSources.get(777);
  }


  if (lastChecked === "terrenosanaslgb") {
    kmlDataSource = viewer.dataSources.get(778);
  }

  if (lastChecked === "limitestatnd") {
    kmlDataSource = viewer.dataSources.get(779);
  }

  if (lastChecked === "areapreserstatnd") {
    kmlDataSource = viewer.dataSources.get(780);
  }

  if (lastChecked === "vegnaivastatnd") {
    kmlDataSource = viewer.dataSources.get(781);
  }

  if (lastChecked === "reservastatnd") {
    kmlDataSource = viewer.dataSources.get(782);
  }

  if (lastChecked === "nascentesstatnd") {
    kmlDataSource = viewer.dataSources.get(783);
  }

  if (lastChecked === "hidrocarstatnd") {
    kmlDataSource = viewer.dataSources.get(784);
  }

  if (lastChecked === "hidrocompstatnd") {
    kmlDataSource = viewer.dataSources.get(785);
  }

  if (lastChecked === "topografiastatnd") {
    kmlDataSource = viewer.dataSources.get(786);
  }

  if (lastChecked === "zoneamentostatnd") {
    kmlDataSource = viewer.dataSources.get(787);
  }

  if (lastChecked === "usovertstatnd") {
    kmlDataSource = viewer.dataSources.get(788);
  }

  if (lastChecked === "implantacaohishmpstatnd") {
    kmlDataSource = viewer.dataSources.get(789);
  }

  if (lastChecked === "servadmstatnd") {
    kmlDataSource = viewer.dataSources.get(790);
  }

  if (lastChecked === "areausoreststatnd") {
    kmlDataSource = viewer.dataSources.get(791);
  }

  if (lastChecked === "areaconsostatnd") {
    kmlDataSource = viewer.dataSources.get(792);
  }

  if (lastChecked === "areascontastatnd") {
    kmlDataSource = viewer.dataSources.get(793);
  }

  if (lastChecked === "dutoviasstatnd") {
    kmlDataSource = viewer.dataSources.get(794);
  }

  if (lastChecked === "linhaostatnd") {
    kmlDataSource = viewer.dataSources.get(795);
  }

  if (lastChecked === "rededrenagemstatnd") {
    kmlDataSource = viewer.dataSources.get(796);
  }

  if (lastChecked === "pontosatratstatnd") {
    kmlDataSource = viewer.dataSources.get(797);
  }

  if (lastChecked === "pontosdetratstatnd") {
    kmlDataSource = viewer.dataSources.get(798);
  }

  if (lastChecked === "domiciliosstatnd") {
    kmlDataSource = viewer.dataSources.get(799);
  }

  if (lastChecked === "rendastatnd") {
    kmlDataSource = viewer.dataSources.get(800);
  }

  if (lastChecked === "domiciliosaluguelstatnd") {
    kmlDataSource = viewer.dataSources.get(801);
  }

  if (lastChecked === "expcidadesstatnd") {
    kmlDataSource = viewer.dataSources.get(802);
  }

  if (lastChecked === "expverticalstatnd") {
    kmlDataSource = viewer.dataSources.get(803);
  }

  if (lastChecked === "precomediostatnd") {
    kmlDataSource = viewer.dataSources.get(804);
  }

  if (lastChecked === "taxahabstatnd") {
    kmlDataSource = viewer.dataSources.get(805);
  }

  if (lastChecked === "areaurbanizadastatnd") {
    kmlDataSource = viewer.dataSources.get(806);
  }

  if (lastChecked === "areasruraisstatnd") {
    kmlDataSource = viewer.dataSources.get(807);
  }

  if (lastChecked === "vaziosurbanosstatnd") {
    kmlDataSource = viewer.dataSources.get(808);
  }

  if (lastChecked === "grapohabstatnd") {
    kmlDataSource = viewer.dataSources.get(809);
  }

  if (lastChecked === "terrenosstatnd") {
    kmlDataSource = viewer.dataSources.get(810);
  }

  if (lastChecked === "imoveisstatnd") {
    kmlDataSource = viewer.dataSources.get(811);
  }

  if (lastChecked === "limiteriva") {
    kmlDataSource = viewer.dataSources.get(812);
  }

  if (lastChecked === "recorteriva") {
    kmlDataSource = viewer.dataSources.get(813);
  }

  if (lastChecked === "areapreserriva") {
    kmlDataSource = viewer.dataSources.get(814);
  }

  if (lastChecked === "conservacaoriva") {
    kmlDataSource = viewer.dataSources.get(815);
  }

  if (lastChecked === "cursosriva") {
    kmlDataSource = viewer.dataSources.get(816);
  }

  if (lastChecked === "nascentesriva") {
    kmlDataSource = viewer.dataSources.get(817);
  }

  if (lastChecked === "areasriscoriva") {
    kmlDataSource = viewer.dataSources.get(818);
  }

  if (lastChecked === "areasriscocontriva") {
    kmlDataSource = viewer.dataSources.get(819);
  }

  if (lastChecked === "topografiariva") {
    kmlDataSource = viewer.dataSources.get(820);
  }

  if (lastChecked === "zoneamentoriva") {
    kmlDataSource = viewer.dataSources.get(821);
  }

  if (lastChecked === "usovertriva") {
    kmlDataSource = viewer.dataSources.get(822);
  }

  if (lastChecked === "aeroportosriva") {
    kmlDataSource = viewer.dataSources.get(823);
  }

  if (lastChecked === "dutoviasriva") {
    kmlDataSource = viewer.dataSources.get(824);
  }

  if (lastChecked === "linhaoriva") {
    kmlDataSource = viewer.dataSources.get(825);
  }

  if (lastChecked === "protculturalriva") {
    kmlDataSource = viewer.dataSources.get(826);
  }

  if (lastChecked === "bemcultriva") {
    kmlDataSource = viewer.dataSources.get(827);
  }

  if (lastChecked === "bemcultimovelriva") {
    kmlDataSource = viewer.dataSources.get(828);
  }

  if (lastChecked === "bemcultnaturalriva") {
    kmlDataSource = viewer.dataSources.get(829);
  }

  if (lastChecked === "diretrizriva") {
    kmlDataSource = viewer.dataSources.get(830);
  }

  if (lastChecked === "tombamentoriva") {
    kmlDataSource = viewer.dataSources.get(831);
  }

  if (lastChecked === "pontosatratriva") {
    kmlDataSource = viewer.dataSources.get(832);
  }

  if (lastChecked === "pontosdetratriva") {
    kmlDataSource = viewer.dataSources.get(833);
  }

  if (lastChecked === "ocupacaoriva") {
    kmlDataSource = viewer.dataSources.get(834);
  }

  if (lastChecked === "rendariva") {
    kmlDataSource = viewer.dataSources.get(835);
  }

  if (lastChecked === "pmedioriva") {
    kmlDataSource = viewer.dataSources.get(836);
  }

  if (lastChecked === "taxahabriva") {
    kmlDataSource = viewer.dataSources.get(837);
  }

  if (lastChecked === "concoriva") {
    kmlDataSource = viewer.dataSources.get(838);
  }

  if (lastChecked === "vaziosriva") {
    kmlDataSource = viewer.dataSources.get(839);
  }

  if (lastChecked === "imoveisanunriva") {
    kmlDataSource = viewer.dataSources.get(840);
  }

  if (lastChecked === "limitemacapaic") {
    kmlDataSource = viewer.dataSources.get(841);
  }

  if (lastChecked === "recortemacapaic") {
    kmlDataSource = viewer.dataSources.get(842);
  }

  if (lastChecked === "varejomacapaic") {
    kmlDataSource = viewer.dataSources.get(843);
  }

  if (lastChecked === "concvarejomacapaic") {
    kmlDataSource = viewer.dataSources.get(844);
  }

  if (lastChecked === "concfluxomacapaic") {
    kmlDataSource = viewer.dataSources.get(845);
  }

  if (lastChecked === "shoppingsmacapaic") {
    kmlDataSource = viewer.dataSources.get(846);
  }

  if (lastChecked === "escolasmacapaic") {
    kmlDataSource = viewer.dataSources.get(847);
  }

  if (lastChecked === "hospitaismacapaic") {
    kmlDataSource = viewer.dataSources.get(848);
  }

  if (lastChecked === "estacionamentosmacapaic") {
    kmlDataSource = viewer.dataSources.get(849);
  }

  if (lastChecked === "concorrentesmacapaic") {
    kmlDataSource = viewer.dataSources.get(850);
  }

  if (lastChecked === "universidadesmacapaic") {
    kmlDataSource = viewer.dataSources.get(851);
  }

  if (lastChecked === "assaimacapaic") {
    kmlDataSource = viewer.dataSources.get(852);
  }

  if (lastChecked === "rendamacapaic") {
    kmlDataSource = viewer.dataSources.get(853);
  }

  if (lastChecked === "domiciliosmacapaic") {
    kmlDataSource = viewer.dataSources.get(854);
  }

  if (lastChecked === "popmacapaic") {
    kmlDataSource = viewer.dataSources.get(855);
  }

  if (lastChecked === "imoveismacapaic") {
    kmlDataSource = viewer.dataSources.get(856);
  }

  if (lastChecked === "catimoveisriva") {
    kmlDataSource = viewer.dataSources.get(857);
  }

  if (lastChecked === "domiciliosaureo") {
    kmlDataSource = viewer.dataSources.get(858);
  }

  if (lastChecked === "empresasaureo") {
    kmlDataSource = viewer.dataSources.get(859);
  }

  if (lastChecked === "locinteresseaureo") {
    kmlDataSource = viewer.dataSources.get(860);
  }

  if (lastChecked === "marcasaureo") {
    kmlDataSource = viewer.dataSources.get(861);
  }

  if (lastChecked === "pontosaureo") {
    kmlDataSource = viewer.dataSources.get(862);
  }

  if (lastChecked === "raio1kmaureo") {
    kmlDataSource = viewer.dataSources.get(863);
  }

  if (lastChecked === "raio2kmaureo") {
    kmlDataSource = viewer.dataSources.get(864);
  }

  if (lastChecked === "rendaaureo") {
    kmlDataSource = viewer.dataSources.get(865);
  }

  if (lastChecked === "viasaureo") {
    kmlDataSource = viewer.dataSources.get(866);
  }

  if (lastChecked === "zoneamentoaureo") {
    kmlDataSource = viewer.dataSources.get(867);
  }

  if (lastChecked === "piscininha") {
    kmlDataSource = viewer.dataSources.get(868);
  }

  if (lastChecked === "piscinao") {
    kmlDataSource = viewer.dataSources.get(869);
  }

  if (lastChecked === "imoveisvendidosemcp") {
    kmlDataSource = viewer.dataSources.get(870);
  }

  if (lastChecked === "outrossetoresemcp") {
    kmlDataSource = viewer.dataSources.get(871);
  }

  if (lastChecked === "setorescomimoveisemcp") {
    kmlDataSource = viewer.dataSources.get(872);
  }

  if (lastChecked === "vendasemcp") {
    kmlDataSource = viewer.dataSources.get(873);
  }


  if (lastChecked === "comerciosaureopet1") {
    kmlDataSource = viewer.dataSources.get(874);
  }

if (lastChecked === "domiciliosaureopet1") {
    kmlDataSource = viewer.dataSources.get(875);
  }
if (lastChecked === "empreendimentoaureopet1") {
    kmlDataSource = viewer.dataSources.get(876);
  }
if (lastChecked === "limiteaureopet1") {
    kmlDataSource = viewer.dataSources.get(877);
  }
if (lastChecked === "localizacaoaureopet1") {
    kmlDataSource = viewer.dataSources.get(878);
  }
if (lastChecked === "marcasvareaureopet1") {
    kmlDataSource = viewer.dataSources.get(879);
  }
if (lastChecked === "r1kmaureopet1") {
    kmlDataSource = viewer.dataSources.get(880);
  }
if (lastChecked === "r2kmaureopet1") {
    kmlDataSource = viewer.dataSources.get(881);
  }
if (lastChecked === "rendaaureopet1") {
    kmlDataSource = viewer.dataSources.get(882);
  }
if (lastChecked === "viasacessoaureopet1") {
    kmlDataSource = viewer.dataSources.get(883);
  }
if (lastChecked === "comerciosaureopet2") {
    kmlDataSource = viewer.dataSources.get(884);
  }
if (lastChecked === "domiciliosaureopet2") {
    kmlDataSource = viewer.dataSources.get(885);
  }
if (lastChecked === "empreendimentoaureopet2") {
    kmlDataSource = viewer.dataSources.get(886);
  }
if (lastChecked === "localizacaoaureopet2") {
    kmlDataSource = viewer.dataSources.get(887);
  }
if (lastChecked === "r1kmaureopet2") {
    kmlDataSource = viewer.dataSources.get(888);
  }
if (lastChecked === "r2kmaureopet2") {
    kmlDataSource = viewer.dataSources.get(889);
  }
if (lastChecked === "rendaaureopet2") {
    kmlDataSource = viewer.dataSources.get(890);
  }
if (lastChecked === "viasacessoaureopet2") {
    kmlDataSource = viewer.dataSources.get(891);
  }
if (lastChecked === "zoneamentoaureopet2") {
    kmlDataSource = viewer.dataSources.get(892);
  }

  if (lastChecked === "hidrologiaaureopet1") {
    kmlDataSource = viewer.dataSources.get(893);
  }
  if (lastChecked === "topografiaaureopet1") {
    kmlDataSource = viewer.dataSources.get(894);
  }
  if (lastChecked === "hidrologiaaureopet2") {
    kmlDataSource = viewer.dataSources.get(895);
  }
  if (lastChecked === "topografiaaureopet2") {
    kmlDataSource = viewer.dataSources.get(896);
  }

  if (lastChecked === "topografiaacc3") {
    kmlDataSource = viewer.dataSources.get(897);
  }

  if (lastChecked === "zoneamentoacc3") {
    kmlDataSource = viewer.dataSources.get(898);
  }

  if (lastChecked === "areapousoacc3") {
    kmlDataSource = viewer.dataSources.get(899);
  }

  if (lastChecked === "rotaaviaoacc3") {
    kmlDataSource = viewer.dataSources.get(900);
  }

  if (lastChecked === "areascontacc3") {
    kmlDataSource = viewer.dataSources.get(901);
  }

  if (lastChecked === "dutoviasacc3") {
    kmlDataSource = viewer.dataSources.get(902);
  }

  if (lastChecked === "estabelecimentosacc3") {
    kmlDataSource = viewer.dataSources.get(903);
  }

  if (lastChecked === "veiculosacc3") {
    kmlDataSource = viewer.dataSources.get(904);
  }

  if (lastChecked === "fluxoveicacc3") {
    kmlDataSource = viewer.dataSources.get(905);
  }

  if (lastChecked === "movimentacaoacc3") {
    kmlDataSource = viewer.dataSources.get(906);
  }

  if (lastChecked === "rendaacc3") {
    kmlDataSource = viewer.dataSources.get(907);
  }

  if (lastChecked === "populacaoacc3") {
    kmlDataSource = viewer.dataSources.get(908);
  }

  if (lastChecked === "domiciliosacc3") {
    kmlDataSource = viewer.dataSources.get(909);
  }

  if (lastChecked === "domialuguelacc3") {
    kmlDataSource = viewer.dataSources.get(910);
  }

  if (lastChecked === "areaurbaacc3") {
    kmlDataSource = viewer.dataSources.get(911);
  }

  if (lastChecked === "spacc3") {
    kmlDataSource = viewer.dataSources.get(912);
  }

  if (lastChecked === "guarulhosacc3") {
    kmlDataSource = viewer.dataSources.get(913);
  }

  if (lastChecked === "trechoacc3") {
    kmlDataSource = viewer.dataSources.get(914);
  }

  if (lastChecked === "aeror2soroaureo") {
    kmlDataSource = viewer.dataSources.get(915);
  }

  if (lastChecked === "shoppingr2soroaureo") {
    kmlDataSource = viewer.dataSources.get(916);
  }

  if (lastChecked === "condor2soroaureo") {
    kmlDataSource = viewer.dataSources.get(917);
  }

  if (lastChecked === "domicilior3soroaureo") {
    kmlDataSource = viewer.dataSources.get(918);
  }

  if (lastChecked === "popr3soroaureo") {
    kmlDataSource = viewer.dataSources.get(919);
  }

  if (lastChecked === "varejor3soroaureo") {
    kmlDataSource = viewer.dataSources.get(920);
  }

  if (lastChecked === "industriar3soroaureo") {
    kmlDataSource = viewer.dataSources.get(921);
  }

  if (lastChecked === "domicilior4soroaureo") {
    kmlDataSource = viewer.dataSources.get(922);
  }

  if (lastChecked === "popr4soroaureo") {
    kmlDataSource = viewer.dataSources.get(923);
  }

  if (lastChecked === "varejor4soroaureo") {
    kmlDataSource = viewer.dataSources.get(924);
  }

  if (lastChecked === "industriar4soroaureo") {
    kmlDataSource = viewer.dataSources.get(925);
  }

  if (lastChecked === "domicilior5soroaureo") {
    kmlDataSource = viewer.dataSources.get(926);
  }

  if (lastChecked === "popr5soroaureo") {
    kmlDataSource = viewer.dataSources.get(927);
  }

  if (lastChecked === "varejor5soroaureo") {
    kmlDataSource = viewer.dataSources.get(928);
  }

  if (lastChecked === "industriar5soroaureo") {
    kmlDataSource = viewer.dataSources.get(929);
  }

  if (lastChecked === "sud1petrop1aureo") {
    kmlDataSource = viewer.dataSources.get(930);
  }

  if (lastChecked === "sud2petrop1aureo") {
    kmlDataSource = viewer.dataSources.get(931);
  }

  if (lastChecked === "sud1petrop2aureo") {
    kmlDataSource = viewer.dataSources.get(932);
  }

  if (lastChecked === "sud2petrop2aureo") {
    kmlDataSource = viewer.dataSources.get(933);
  }

  if (lastChecked === "areascontastacetesbtnd") {
    kmlDataSource = viewer.dataSources.get(934);
  }

  if (lastChecked === "areascontastasgmptnd") {
    kmlDataSource = viewer.dataSources.get(935);
  }

  if (lastChecked === "limitebairrosstatnd") {
    kmlDataSource = viewer.dataSources.get(936);
  }

  if (lastChecked === "fichasstatnd") {
    kmlDataSource = viewer.dataSources.get(937);
  }

  if (lastChecked === "zoneamentostaeixotamandtnd") {
    kmlDataSource = viewer.dataSources.get(938);
  }

  if (lastChecked === "galpoesjaguariaivaic") {
    kmlDataSource = viewer.dataSources.get(939);
  }

  if (lastChecked === "zoneamentocuritibatrply") {
    kmlDataSource = viewer.dataSources.get(940);
  }

  if (lastChecked === "permissaocuritibatrply") {
    kmlDataSource = viewer.dataSources.get(941);
  }

  if (lastChecked === "terrenoscuritibatrply") {
    kmlDataSource = viewer.dataSources.get(942);
  }

  if (lastChecked === "topografiacuritibatrply") {
    kmlDataSource = viewer.dataSources.get(943);
  }

  if (lastChecked === "limitesambcuritibatrply") {
    kmlDataSource = viewer.dataSources.get(944);
  }

  if (lastChecked === "hidrografiacuritibatrply") {
    kmlDataSource = viewer.dataSources.get(945);
  }

  if (lastChecked === "servidaocuritibatrply") {
    kmlDataSource = viewer.dataSources.get(946);
  }

  if (lastChecked === "terrenospotenciascuritibatrply") {
    kmlDataSource = viewer.dataSources.get(947);
  }

  if (lastChecked === "crescimentocuritibatrply") {
    kmlDataSource = viewer.dataSources.get(948);
  }

  if (lastChecked === "dadossocioscuritibatrply") {
    kmlDataSource = viewer.dataSources.get(949);
  }

  if (lastChecked === "pontosdeinteressecuritibatrply") {
    kmlDataSource = viewer.dataSources.get(950);
  }


    if (lastChecked) {
      if (gurgLogged) {
        const targetPosition = Cartesian3.fromDegrees(
          -47.05555286,
          -22.8863397,
          8000000.0
        );
        viewer.camera.flyTo({
          destination: targetPosition,
          orientation: {
            heading: Math.toRadians(0),
            pitch: Math.toRadians(-90),
            roll: 0,
          },
          duration: 2,
        });
      }

      if (kmlDataSource && !gurgLogged) {
        viewer.flyTo(kmlDataSource, {
          duration: 2, // Duração da animação de voo em segundos
        });
      }
    }
    loadingViewRef.current = false;
  };

  useEffect(() => {
    // console.log('ativou')
    // const viewer = viewerRef.current.cesiumElement; // Obtém a instância do Cesium Viewer

    // var kmlDataSourceLabels = null;

    // kmlDataSourceLabels = viewer.dataSources._dataSources;
    // kmlDataSourceLabels.forEach((kml)=>{
    //   let entarray = kml._entityCollection._entities._array;
    //   entarray.forEach((ent)=>{
    //     if(ent._label){
    //       ent._label = false;
    //     }
    //   })
    // })
    if (loadingViewRef.current === false) {
      console.log(false);
    }

    if (loadingViewRef.current === true) {
      console.log(true);
    }
  }, [loadingViewRef.current]);

  const viewerRef = useRef();
  const handleKmlLoad = (kmlDataSource) => {
    const entities = kmlDataSource.entities.values;
    // console.log(entities)

    // entities.forEach((entity) => {
    //     if(entity.polygon){

    //       entity.polygon._outlineColor._value.red = 1;
    //       entity.polygon._outlineWidth._value = outlineWidth;

    //     }
    // });

    // console.log(entities)
    // setOutlineWidth(outlineWidth);

    entities.forEach((entity) => {
      if (entity.polygon) {
        entity.polygon._material._color._value.alpha = 0.6;
      }
    });
    console.log(kmlDataSource);
  };

  const handleZoom = (kmlDataSource) => {
    console.log(kmlDataSource);
  };

  const aumentarOpacidade = (kmlDataSource) => {
    const entities = kmlDataSource.entities.values;

    entities.forEach((entity) => {
      if (entity.polygon) {
        entity.polygon._material._color._value.alpha = 0.7;
      }
    });
  };

  // useEffect(() => {
  //   if (kmlDataSourceRef.current) {
  //     let kmlDataSource = kmlDataSourceRef.current;
  //     let entities = kmlDataSource.cesiumElement.entities.values
  //     console.log('useefe')
  //     entities.forEach((entity) => {

  //       if (entity) {
  //         entity.label = false;
  //       }

  //     });
  //     console.log(entities);
  //   }
  // }, [c1]);

  const handleLabelChange = (value) => {
    const viewer = viewerRef.current.cesiumElement; // Obtém a instância do Cesium Viewer
    var kmlDataSource = null;
    kmlDataSource = viewer.dataSources.get(97);
    console.log(viewer.dataSources._dataSources);
    console.log("---------");
    console.log(kmlDataSource);
    console.log("---------");

    var kmlDataSourceLabels = null;

    kmlDataSourceLabels = viewer.dataSources._dataSources;
    kmlDataSourceLabels.forEach((kml, index) => {
      if (index === 72) {
        console.log(kml);
      }
      let entarray = kml._entityCollection._entities._array;
      entarray.forEach((ent) => {
        if (ent._label) {
          ent._label = false;
        }
      });
    });
  };

  return (
    !loading && (
      <div>
        <Header
          estadoChange={handleEstadoChange}
          municipioChange={handleMunicipioChange}
          camadasChange={handleCamadasChange}
          labelsVisibleChange={handleLabelChange}
          imprimir={handleImprimir}
        ></Header>
        <div className="content">
          <Viewer
            className="v1"
            resolutionScale={true}
            ref={viewerRef}
            onClick={(e, target) => {
              
              if (target?.id && target?.primitive instanceof PointPrimitive) {
                if (target.id?._id) {
                  setselectedTLId(target.id._id);
                  handleTLPopupChange();
                }
              }
            }}
          >
            {/* <ScreenSpaceCameraController
              maximumZoomDistance={6378137 * 2}
              minimumZoomDistance={1000}
            /> */}
            <KmlDataSource data={kmlLPath} show={showKmlL} />
            <KmlDataSource data={kmlPath} show={showKml} />
            <KmlDataSource data={topografiaPath} show={showTopografia} />
            <KmlDataSource data={hidrografiaPath} show={showHidrografia} />
            <KmlDataSource data={limitesaPath} show={showLimitesa} />
            <KmlDataSource data={permissaoPath} show={showPermissao} />
            <KmlDataSource data={dadosPath} show={showDados} />
            <KmlDataSource data={fluxoPath} show={showFluxo} />

            <KmlDataSource data={thejoyPath} show={showTheJ} />
            <KmlDataSource data={competidores} show={showCompetidores} />
            <KmlDataSource data={areas} show={showAreas} />
            <KmlDataSource data={condominios} show={showCondominios} />
            <KmlDataSource data={influencia} show={showInfluencia} />
            <KmlDataSource data={pontosFluxo} show={showPontosFluxo} />
            <KmlDataSource data={imoveis} show={showImoveis} />

            <KmlDataSource data={setoreslk} show={showsetoreslk} />
            <KmlDataSource data={raio1000lk} show={showraio1000lk} />
            <KmlDataSource data={raio500lk} show={showraio500lk} />
            <KmlDataSource data={viaslk} show={showviaslk} />
            <KmlDataSource data={competidoreslk} show={showcompetidoreslk} />
            <KmlDataSource data={hospitaislk} show={showhospitaislk} />
            <KmlDataSource data={pracaslk} show={showpracaslk} />
            <KmlDataSource data={mercadoslk} show={showmercadoslk} />
            <KmlDataSource data={restauranteslk} show={showrestauranteslk} />
            <KmlDataSource data={condominioslk} show={showcondominioslk} />
            <KmlDataSource data={farmacialk} show={showfarmacialk} />

            <KmlDataSource data={c1} show={showC1} />
            <KmlDataSource data={c2} show={showC2} />
            <KmlDataSource data={c3} show={showC3} />
            <KmlDataSource data={c4} show={showC4} />
            <KmlDataSource data={c5} show={showC5} />
            <KmlDataSource data={c6} show={showC6} />
            <KmlDataSource data={c7} show={showC7} />
            <KmlDataSource data={c8} show={showC8} />
            <KmlDataSource data={c9} show={showC9} />
            <KmlDataSource data={c10} show={showC10} />
            <KmlDataSource data={c11} show={showC11} />
            <KmlDataSource data={c12} show={showC12} />
            <KmlDataSource data={c13} show={showC13} />
            <KmlDataSource data={c14} show={showC14} />
            <KmlDataSource data={c15} show={showC15} />
            <KmlDataSource data={c16} show={showC16} />

            {/*luka*/}
            <KmlDataSource data={imoveis200lk} show={showimoveis200lk} />

            {/*gen*/}
            <KmlDataSource data={areaverdegl} show={showareaverdegl} />
            <KmlDataSource data={municipiogl} show={showmunicipiogl} />
            <KmlDataSource
              data={condominiogooglegl}
              show={showcondominiogooglegl}
            />
            <KmlDataSource
              data={condominiograpgl}
              show={showcondominiograpgl}
            />
            <KmlDataSource data={rendagl} show={showrendagl} />

            {/*campinas alea*/}
            <KmlDataSource data={servidao} show={showservidao} />
            <KmlDataSource data={crescimento} show={showcrescimento} />

            {/*tj paulinia*/}
            <KmlDataSource
              data={areasinfluenciatjp}
              show={showareasinfluenciatjp}
            />
            <KmlDataSource
              data={areasindicadastjp}
              show={showareasindicadastjp}
            />
            <KmlDataSource data={compdiretostjp} show={showcompdiretostjp} />
            <KmlDataSource
              data={compindiretostjp}
              show={showcompindiretostjp}
            />
            <KmlDataSource data={condominiostjp} show={showcondominiostjp} />
            <KmlDataSource data={imoveistjp} show={showimoveistjp} />
            <KmlDataSource data={limitetjp} show={showlimitetjp} />
            <KmlDataSource data={pontosfluxotjp} show={showpontosfluxotjp} />
            <KmlDataSource data={escolastjp} show={showescolastjp} />

            {/*tj paulinia*/}
            <KmlDataSource
              data={areasinfluenciatja}
              show={showareasinfluenciatja}
            />
            <KmlDataSource
              data={areasindicadasinfantiltja}
              show={showareasindicadasinfantiltja}
            />
            <KmlDataSource
              data={areasindicadasfundamentaltja}
              show={showareasindicadasfundamentaltja}
            />
            <KmlDataSource
              data={compdiretosinfantiltja}
              show={showcompdiretosinfantiltja}
            />
            <KmlDataSource
              data={compdiretosfundamentaltja}
              show={showcompdiretosfundamentaltja}
            />
            <KmlDataSource
              data={compindiretosinfantiltja}
              show={showcompindiretosinfantiltja}
            />
            <KmlDataSource
              data={compindiretosfundamentaltja}
              show={showcompindiretosfundamentaltja}
            />
            <KmlDataSource
              data={concentracao0_5tja}
              show={showconcentracao0_5tja}
            />
            <KmlDataSource
              data={concentracao6_10tja}
              show={showconcentracao6_10tja}
            />
            <KmlDataSource data={escolatja} show={showescolatja} />
            <KmlDataSource data={limitetja} show={showlimitetja} />
            <KmlDataSource data={pontosfluxotja} show={showpontosfluxotja} />
            <KmlDataSource
              data={rendatja}
              show={showrendatja}
              onLoad={handleKmlLoad}
            />

            {/* hids */}
            <KmlDataSource
              data={ashidsgu}
              show={showashidsgu}
              onLoad={handleZoom}
            />
            <KmlDataSource data={aspidsgu} show={showaspidsgu} />
            <KmlDataSource data={apidsgu} show={showapidsgu} />
            <KmlDataSource data={bempresasgu} show={showbempresasgu} />
            <KmlDataSource data={campusdagu} show={showcampusdagu} />
            <KmlDataSource data={campusgu} show={showcampusgu} />
            <KmlDataSource data={fzdarggu} show={showfzdarggu} />
            <KmlDataSource data={ferroviasgu} show={showferroviasgu} />
            <KmlDataSource data={ictsgu} show={showictsgu} />
            <KmlDataSource data={parquesgu} show={showparquesgu} />
            <KmlDataSource data={riosgu} show={showriosgu} />
            <KmlDataSource data={viasgu} show={showviasgu} />

            {/* tjg */}
            <KmlDataSource
              data={areasindicadasinfantiltjg}
              show={showareasindicadasinfantiltjg}
            />
            <KmlDataSource
              data={areasindicadasfundamentaltjg}
              show={showareasindicadasfundamentaltjg}
            />
            <KmlDataSource
              data={compdiretosinfantiltjg}
              show={showcompdiretosinfantiltjg}
            />
            <KmlDataSource
              data={compdiretosfundamentaltjg}
              show={showcompdiretosfundamentaltjg}
            />
            <KmlDataSource
              data={compindiretosinfantiltjg}
              show={showcompindiretosinfantiltjg}
            />
            <KmlDataSource
              data={compindiretosfundamentaltjg}
              show={showcompindiretosfundamentaltjg}
            />
            <KmlDataSource
              data={concentracao0_5tjg}
              show={showconcentracao0_5tjg}
            />
            <KmlDataSource
              data={concentracao6_10tjg}
              show={showconcentracao6_10tjg}
            />
            <KmlDataSource data={familiastjg} show={showfamiliastjg} />
            <KmlDataSource data={rendatjg} show={showrendatjg} />
            <KmlDataSource
              data={imovelpretendidotjg}
              show={showimovelpretendidotjg}
            />
            <KmlDataSource data={limitetjg} show={showlimitetjg} />
            <KmlDataSource data={pontosfluxotjg} show={showpontosfluxotjg} />
            <KmlDataSource
              data={raioinfluenciatjg}
              show={showraioinfluenciatjg}
            />

            <KmlDataSource data={edumap} show={showedumap} />

            <KmlDataSource data={bairroscrca} show={showbairroscrca} />
            <KmlDataSource
              data={dadossociosfamiliascampinascrca}
              show={showdadossociosfamiliascampinascrca}
            />
            <KmlDataSource
              data={zoneamentocampinascrca}
              show={showzoneamentocampinascrca}
            />
            <KmlDataSource
              data={limitecampinascrca}
              show={showlimitecampinascrca}
            />

            {/* sjbv */}
            <KmlDataSource data={sjbv} show={showsjbv} />
            <KmlDataSource data={sjbv1zoneamento} show={showsjbv1zoneamento} />
            <KmlDataSource data={sjbv2permissao} show={showsjbv2permissao} />
            <KmlDataSource data={sjbv3terrenos} show={showsjbv3terrenos} />
            <KmlDataSource data={sjbv5topografia} show={showsjbv5topografia} />
            <KmlDataSource data={sjbv6limitesa} show={showsjbv6limitesa} />
            <KmlDataSource
              data={sjbv7hidrografia}
              show={showsjbv7hidrografia}
            />
            <KmlDataSource data={sjbv8servidao} show={showsjbv8servidao} />
            <KmlDataSource data={sjbv9terrenosp} show={showsjbv9terrenosp} />
            <KmlDataSource
              data={sjbv10crescimento}
              show={showsjbv10crescimento}
            />
            <KmlDataSource
              data={sjbv11dadossocios}
              show={showsjbv11dadossocios}
            />
            <KmlDataSource
              data={sjbv12distviario}
              show={showsjbv12distviario}
            />
            <KmlDataSource data={sjbv13malha} show={showsjbv13malha} />
            <KmlDataSource data={sjbv14pontos} show={showsjbv14pontos} />

            <KmlDataSource data={dadosporbairro} show={showdadosporbairro} />
            <KmlDataSource
              data={dadossociosrendacampinascrca}
              show={showdadossociosrendacampinascrca}
            />

            {/* rp */}
            <KmlDataSource data={rp} show={showrp} />
            <KmlDataSource data={rp1zoneamento} show={showrp1zoneamento} />

            <KmlDataSource data={rp3terrenos} show={showrp3terrenos} />
            <KmlDataSource data={rp5topografia} show={showrp5topografia} />
            <KmlDataSource
              data={rp6limitesambientais}
              show={showrp6limitesambientais}
            />
            <KmlDataSource data={rp7hidrografia} show={showrp7hidrografia} />
            <KmlDataSource data={rp8servidao} show={showrp8servidao} />
            <KmlDataSource data={rp10crescimento} show={showrp10crescimento} />
            <KmlDataSource
              data={rp11dadossocioeconomicosrenda}
              show={showrp11dadossocioeconomicosrenda}
            />
            <KmlDataSource
              data={rp12dadossocioeconomicosfamilias}
              show={showrp12dadossocioeconomicosfamilias}
            />

            {/* crca2 */}
            <KmlDataSource
              data={abrangenciacrascrca2}
              show={showabrangenciacrascrca2}
            />
            <KmlDataSource
              data={assistenciateccrca2}
              show={showassistenciateccrca2}
            />
            <KmlDataSource data={bairroscrca2} show={showbairroscrca2} />
            <KmlDataSource
              data={campinasdadossociosrendacrca2}
              show={showcampinasdadossociosrendacrca2}
            />
            <KmlDataSource
              data={campinasdadossociosfamiliascrca2}
              show={showcampinasdadossociosfamiliascrca2}
            />
            <KmlDataSource
              data={campinaslimitecrca2}
              show={showcampinaslimitecrca2}
            />
            <KmlDataSource
              data={campinaszoneamentocrca2}
              show={showcampinaszoneamentocrca2}
            />
            <KmlDataSource
              data={comercioalimentoscrca2}
              show={showcomercioalimentoscrca2}
            />
            <KmlDataSource
              data={dadosporbairrocrca2}
              show={showdadosporbairrocrca2}
            />
            <KmlDataSource
              data={impressao3dcrca2}
              show={showimpressao3dcrca2}
            />
            <KmlDataSource
              data={lojaderoupascrca2}
              show={showlojaderoupascrca2}
            />
            <KmlDataSource
              data={lojasartesanatocrca2}
              show={showlojasartesanatocrca2}
            />
            <KmlDataSource
              data={oficinasmecanicascrca2}
              show={showoficinasmecanicascrca2}
            />
            <KmlDataSource data={orgcivilcrca2} show={showorgcivilcrca2} />
            <KmlDataSource
              data={padariasconfeitariascrca2}
              show={showpadariasconfeitariascrca2}
            />
            <KmlDataSource data={petshopscrca2} show={showpetshopscrca2} />
            <KmlDataSource data={regioesvulcrca2} show={showregioesvulcrca2} />
            <KmlDataSource data={salaobelezacrc2} show={showsalaobelezacrc2} />

            <KmlDataSource data={educomp} show={showeducomp} />
            <KmlDataSource data={edufamilia} show={showedufamilia} />
            <KmlDataSource data={edurenda} show={showedurenda} />
            <KmlDataSource data={eduimoveis} show={showeduimoveis} />

            <KmlDataSource
              show={showareasindicadasinfantiltjd}
              data={areasindicadasinfantiltjd}
            />
            <KmlDataSource
              show={showareasindicadasfundamentaltjd}
              data={areasindicadasfundamentaltjd}
            />
            <KmlDataSource
              show={showcompdiretosinfantiltjd}
              data={compdiretosinfantiltjd}
            />
            <KmlDataSource
              show={showcompdiretosfundamentaltjd}
              data={compdiretosfundamentaltjd}
            />
            <KmlDataSource
              show={showcompindiretosinfantiltjd}
              data={compindiretosinfantiltjd}
            />
            <KmlDataSource
              show={showcompindiretosfundamentaltjd}
              data={compindiretosfundamentaltjd}
            />
            <KmlDataSource
              show={showconcentracaocriancasinfantiltgd}
              data={concentracaocriancasinfantiltgd}
            />
            <KmlDataSource
              show={showconcentracaocriancasfundtgd}
              data={concentracaocriancasfundtgd}
            />
            <KmlDataSource show={showfamiliastjd} data={familiastjd} />
            <KmlDataSource show={showlimitetjd} data={limitetjd} />
            <KmlDataSource show={showrendatjd} data={rendatjd} />
            <KmlDataSource show={showpontosfluxotjd} data={pontosfluxotjd} />
            <KmlDataSource
              show={showraioinfluenciatjd}
              data={raioinfluenciatjd}
            />
            {/* lavras */}
            <KmlDataSource
              show={showlegalizacaolavras}
              data={legalizacaolavras}
            />

            {/* manapoke */}
            <KmlDataSource
              show={showacademiasmanapoke}
              data={academiasmanapoke}
            />
            <KmlDataSource
              show={showcentrosempresariasmanapoke}
              data={centrosempresariasmanapoke}
            />
            <KmlDataSource
              show={showcentrosesportivosmanapoke}
              data={centrosesportivosmanapoke}
            />
            <KmlDataSource
              show={showcomerciomanapoke}
              data={comerciomanapoke}
            />
            <KmlDataSource
              show={showcompcomidajaponesamanapoke}
              data={compcomidajaponesamanapoke}
            />
            <KmlDataSource
              show={showcompcomidasaudavelmanapoke}
              data={compcomidasaudavelmanapoke}
            />
            <KmlDataSource
              show={showimoveiscomerciaismanapoke}
              data={imoveiscomerciaismanapoke}
            />
            <KmlDataSource
              show={showpontosculturaismanapoke}
              data={pontosculturaismanapoke}
            />
            <KmlDataSource
              show={showpontoseducacaomanapoke}
              data={pontoseducacaomanapoke}
            />
            <KmlDataSource show={showrendamanapoke} data={rendamanapoke} />

            {/* lavras */}
            <KmlDataSource
              show={showzoneamentolavras}
              data={zoneamentolavras}
            />
            <KmlDataSource show={showpermissaolavras} data={permissaolavras} />
            <KmlDataSource show={showterrenoslavras} data={terrenoslavras} />
            <KmlDataSource
              show={showtopografialavras}
              data={topografialavras}
            />
            <KmlDataSource show={showapplavras} data={applavras} />
            <KmlDataSource
              show={showhidrografialavras}
              data={hidrografialavras}
            />
            <KmlDataSource show={showservidaolavras} data={servidaolavras} />
            <KmlDataSource
              show={showterrenosinteresselavras}
              data={terrenosinteresselavras}
            />
            <KmlDataSource
              show={showcrescimentolavras}
              data={crescimentolavras}
            />
            <KmlDataSource
              show={showdadossocioslavras}
              data={dadossocioslavras}
            />
            <KmlDataSource
              show={showdistviariolavras}
              data={distviariolavras}
            />
            <KmlDataSource
              show={showdisturbanalavras}
              data={disturbanalavras}
            />
            <KmlDataSource show={showpontoslavras} data={pontoslavras} />

            {/* manapoke */}
            <KmlDataSource
              show={showareaspotenciaismanapoke}
              data={areaspotenciaismanapoke}
            />

            {/* buddahspa */}
            <KmlDataSource show={showclubesbs} data={clubesbs} />
            <KmlDataSource show={showfamiliasbs} data={familiasbs} />
            <KmlDataSource
              show={showlojasdepartamentobs}
              data={lojasdepartamentobs}
            />
            <KmlDataSource show={showparquesbs} data={parquesbs} />
            <KmlDataSource show={showrendabs} data={rendabs} />
            <KmlDataSource
              show={showpontosbuddhaspabs}
              data={pontosbuddhaspabs}
            />
            <KmlDataSource show={showrestaurantesbs} data={restaurantesbs} />
            <KmlDataSource show={showshoppingsbs} data={shoppingsbs} />
            <KmlDataSource show={showsupermercadosbs} data={supermercadosbs} />
            <KmlDataSource
              show={showestacionamentosbs}
              data={estacionamentosbs}
            />
            <KmlDataSource
              show={showimoveismenos18bs}
              data={imoveismenos18bs}
            />
            <KmlDataSource show={showimoveismais18bs} data={imoveismais18bs} />

            <KmlDataSource show={showareainflucompbs} data={areainflucompbs} />
            <KmlDataSource
              show={showareapotcomrecorte}
              data={areapotcomrecorte}
            />
            <KmlDataSource
              show={showareapotsemrecorte}
              data={areapotsemrecorte}
            />
            <KmlDataSource show={showcompetidoresbs} data={competidoresbs} />
            <KmlDataSource show={showpopadultabs} data={popadultabs} />

            <KmlDataSource show={showareaspotbd} data={areaspotbd} />
            <KmlDataSource show={showbaldonibd} data={baldonibd} />
            <KmlDataSource show={showbaldoni2030bd} data={baldoni2030bd} />
            <KmlDataSource show={showlojaspescabd} data={lojaspescabd} />
            <KmlDataSource show={showpesquesbd} data={pesquesbd} />
            <KmlDataSource show={showpetshopsbd} data={petshopsbd} />
            <KmlDataSource show={showpopadultabd} data={popadultabd} />
            <KmlDataSource show={showrendabd} data={rendabd} />
            <KmlDataSource show={showrestaurantesbd} data={restaurantesbd} />
            <KmlDataSource show={showvetsbd} data={vetsbd} />

            <KmlDataSource show={showacademiascb} data={academiascb} />
            <KmlDataSource show={showareaspotcb} data={areaspotcb} />
            <KmlDataSource show={showcafescb} data={cafescb} />
            <KmlDataSource show={showcomerciocb} data={comerciocb} />
            <KmlDataSource show={showculturalcb} data={culturalcb} />
            <KmlDataSource show={showescolascb} data={escolascb} />
            <KmlDataSource show={showesportescb} data={esportescb} />
            <KmlDataSource show={showfaculdadescb} data={faculdadescb} />
            <KmlDataSource show={showimoveiscb} data={imoveiscb} />
            <KmlDataSource show={showlanchonetescb} data={lanchonetescb} />
            <KmlDataSource show={showrendasetorescb} data={rendasetorescb} />
            <KmlDataSource
              show={showcomidasaudavelcb}
              data={comidasaudavelcb}
            />

            <KmlDataSource show={showzonasusobs} data={zonasusobs} />

            {/* rioverde */}
            <KmlDataSource
              show={showRio_Verde_1_zoneamento}
              data={Rio_Verde_1_zoneamento}
            />
            <KmlDataSource
              show={showRio_Verde_2_permissao}
              data={Rio_Verde_2_permissao}
            />
            <KmlDataSource
              show={showRio_Verde_3_terrenos}
              data={Rio_Verde_3_terrenos}
            />
            <KmlDataSource
              show={showRio_Verde_4_imoveis_disponiveis}
              data={Rio_Verde_4_imoveis_disponiveis}
            />
            <KmlDataSource
              show={showRio_Verde_5_topografia}
              data={Rio_Verde_5_topografia}
            />
            <KmlDataSource
              show={showRio_Verde_6_Limites_Ambientais}
              data={Rio_Verde_6_Limites_Ambientais}
            />
            <KmlDataSource
              show={showRio_Verde_7_Hidrografia}
              data={Rio_Verde_7_Hidrografia}
            />
            <KmlDataSource
              show={showRio_Verde_8_Servidao}
              data={Rio_Verde_8_Servidao}
            />
            <KmlDataSource
              show={showRio_Verde_9_terrenos_potenciais}
              data={Rio_Verde_9_terrenos_potenciais}
            />
            <KmlDataSource
              show={showRio_Verde_10_crescimento}
              data={Rio_Verde_10_crescimento}
            />
            <KmlDataSource
              show={showRio_Verde_11_dados_socioeconomicos}
              data={Rio_Verde_11_dados_socioeconomicos}
            />
            <KmlDataSource
              show={showRio_verde_12_dist_viario}
              data={Rio_verde_12_dist_viario}
            />
            <KmlDataSource
              show={showRio_Verde_13_Malha_Urbana}
              data={Rio_Verde_13_Malha_Urbana}
            />
            <KmlDataSource
              show={showRio_Verde_14_Pontos_de_Interesse}
              data={Rio_Verde_14_Pontos_de_Interesse}
            />
            <KmlDataSource show={showRio_Verde} data={Rio_Verde} />

            {/* tony */}
            <KmlDataSource
              show={showCruzeiro_1_Zoneamento}
              data={Cruzeiro_1_Zoneamento}
            />
            <KmlDataSource
              show={showGuaxupe_1_zoneamento}
              data={Guaxupe_1_zoneamento}
            />
            <KmlDataSource
              show={showLavras_zoneamento_TESTE}
              data={Lavras_zoneamento_TESTE}
            />
            <KmlDataSource
              show={showSSParaiso_1_Zoneamento}
              data={SSParaiso_1_Zoneamento}
            />

            {/* accenture */}
            <KmlDataSource show={showangradosreisacc} data={angradosreisacc} />
            <KmlDataSource show={showbr101km414acc} data={br101km414acc} />
            <KmlDataSource show={showbr101km447acc} data={br101km447acc} />
            <KmlDataSource show={showbr101km538acc} data={br101km538acc} />
            <KmlDataSource show={showitaguaiacc} data={itaguaiacc} />
            <KmlDataSource show={showmangaratibaacc} data={mangaratibaacc} />
            <KmlDataSource show={showparatyacc} data={paratyacc} />
            <KmlDataSource show={showtrechobr101acc} data={trechobr101acc} />

            <KmlDataSource show={showrendaacc} data={rendaacc} />
            <KmlDataSource
              show={showareasconmataatlaacc}
              data={areasconmataatlaacc}
            />
            <KmlDataSource show={showappacc} data={appacc} />
            <KmlDataSource
              show={showvegetacaonativaacc}
              data={vegetacaonativaacc}
            />
            <KmlDataSource
              show={showuniconsfederaisacc}
              data={uniconsfederaisacc}
            />
            <KmlDataSource show={showreservalegalacc} data={reservalegalacc} />
            <KmlDataSource show={showareasprotacc} data={areasprotacc} />

            <KmlDataSource show={showacaiteriacb} data={acaiteriacb} />
            <KmlDataSource show={showsorveteriacb} data={sorveteriacb} />

            <KmlDataSource show={showmoteisac} data={moteisac} />
            <KmlDataSource show={showpedagiosac} data={pedagiosac} />
            <KmlDataSource show={showpostosac} data={postosac} />
            <KmlDataSource show={showrestaurantesac} data={restaurantesac} />
            <KmlDataSource show={showhoteisac} data={hoteisac} />
            <KmlDataSource show={showpousadasac} data={pousadasac} />

            <KmlDataSource show={showareaspotpucrsg} data={areaspotpucrsg} />
            <KmlDataSource
              show={showareaspotunicampport2rsg}
              data={areaspotunicampport2rsg}
            />
            <KmlDataSource
              show={showareaspotunicampprincrsg}
              data={areaspotunicampprincrsg}
            />
            <KmlDataSource show={showportariasrsg} data={portariasrsg} />
            <KmlDataSource show={showraio200rsg} data={raio200rsg} />
            <KmlDataSource show={showterrenosdisprsg} data={terrenosdisprsg} />

            <KmlDataSource
              show={showperimetrourbanocb}
              data={perimetrourbanocb}
            />
            <KmlDataSource show={showfluxokernelcb} data={fluxokernelcb} />

            <KmlDataSource
              show={showconcentrafluxocb}
              data={concentrafluxocb}
            />
            <KmlDataSource show={showraiofluxocb} data={raiofluxocb} />
            <KmlDataSource show={showfluxoconcocb} data={fluxoconcocb} />

            <KmlDataSource show={showzoneamentopec} data={zoneamentopec} />
            <KmlDataSource show={showpontosintpec} data={pontosintpec} />
            <KmlDataSource show={showpermissaopec} data={permissaopec} />
            <KmlDataSource show={showtopografiapec} data={topografiapec} />
            <KmlDataSource
              show={showcrescimentoanopec}
              data={crescimentoanopec}
            />
            <KmlDataSource show={showgraprohab} data={graprohab} />
            <KmlDataSource show={showdensidadepec} data={densidadepec} />
            <KmlDataSource show={showfamiliaspec} data={familiaspec} />
            <KmlDataSource show={showrendapec} data={rendapec} />
            <KmlDataSource show={showcorpospec} data={corpospec} />
            <KmlDataSource show={showcursospec} data={cursospec} />
            <KmlDataSource show={shownascentespec} data={nascentespec} />
            <KmlDataSource show={showapppec} data={apppec} />
            <KmlDataSource show={showreservapec} data={reservapec} />
            <KmlDataSource show={showvegpec} data={vegpec} />
            <KmlDataSource show={showterrenos2k4kpec} data={terrenos2k4kpec} />
            <KmlDataSource
              show={showterrenos5kmaispec}
              data={terrenos5kmaispec}
            />
            <KmlDataSource show={showterrenosnaopec} data={terrenosnaopec} />
            <KmlDataSource
              show={showterrenospot5kmaispec}
              data={terrenospot5kmaispec}
            />
            <KmlDataSource
              show={showterrenospot150kmaispec}
              data={terrenospot150kmaispec}
            />
            <KmlDataSource
              show={showmapaconcentracaopec}
              data={mapaconcentracaopec}
            />
            <KmlDataSource show={showlimitevmap} data={limitevmap} />
            <KmlDataSource show={showpopfemvmap} data={popfemvmap} />
            <KmlDataSource show={showpopmasvmap} data={popmasvmap} />
            <KmlDataSource show={showpoptotvmap} data={poptotvmap} />
            <KmlDataSource show={showprefeitovmap} data={prefeitovmap} />
            <KmlDataSource show={showvereadorvmap} data={vereadorvmap} />
            <KmlDataSource show={showrendavmap} data={rendavmap} />
            <KmlDataSource show={showvereadorvmap2} data={vereadorvmap2} />
            <KmlDataSource show={showprefeitovmap2} data={prefeitovmap2} />

            <KmlDataSource show={showdemandaespacopu} data={demandaespacopu} />
            <KmlDataSource
              show={showdemandaqualidadepu}
              data={demandaqualidadepu}
            />
            <KmlDataSource
              show={showfaixafaturamentopu}
              data={faixafaturamentopu}
            />
            <KmlDataSource show={showleispu} data={leispu} />
            <KmlDataSource show={showmapageralpu} data={mapageralpu} />
            <KmlDataSource show={showmodelopu} data={modelopu} />
            <KmlDataSource show={showpreferenpu} data={preferenpu} />
            <KmlDataSource show={shownecessidadepu} data={necessidadepu} />
            <KmlDataSource show={showrelacaopu} data={relacaopu} />
            <KmlDataSource show={showsetorpu} data={setorpu} />

            <KmlDataSource show={showdivisaoregpec} data={divisaoregpec} />
            <KmlDataSource show={showvaziospec} data={vaziospec} />
            <KmlDataSource show={showusosolopec} data={usosolopec} />
            <KmlDataSource show={showconcenfluxospec} data={concenfluxospec} />
            <KmlDataSource show={showtopografiapec2} data={topografiapec2} />
            <KmlDataSource show={showfaixaac} data={faixaac} />

            <KmlDataSource show={showareaintbb} data={areaintbb} />
            <KmlDataSource show={showboibrabobb} data={boibrabobb} />
            <KmlDataSource show={showclassesbb} data={classesbb} />
            <KmlDataSource show={showcompsbb} data={compsbb} />
            <KmlDataSource show={showconccompsbb} data={conccompsbb} />
            <KmlDataSource show={showconcpfbb} data={concpfbb} />
            <KmlDataSource show={showfamiliasbb} data={familiasbb} />
            <KmlDataSource show={showfluxocompsbb} data={fluxocompsbb} />
            <KmlDataSource show={showimoveisbb} data={imoveisbb} />
            <KmlDataSource show={showpontosfluxobb} data={pontosfluxobb} />
            <KmlDataSource show={showrendabb} data={rendabb} />
            <KmlDataSource show={showmetrobb} data={metrobb} />
            <KmlDataSource show={showonibusbb} data={onibusbb} />
            <KmlDataSource show={showtrembb} data={trembb} />
            <KmlDataSource show={showareapotbb} data={areapotbb} />

            <KmlDataSource show={showareapotdp} data={areapotdp} />
            <KmlDataSource show={showcompsdp} data={compsdp} />
            <KmlDataSource show={showconccompsdp} data={conccompsdp} />
            <KmlDataSource
              show={showconcpontofluxodp}
              data={concpontofluxodp}
            />
            <KmlDataSource show={showfamiliasdp} data={familiasdp} />
            <KmlDataSource show={showgastodp} data={gastodp} />
            <KmlDataSource show={showpontofluxodp} data={pontofluxodp} />
            <KmlDataSource show={showpop20a69dp} data={pop20a69dp} />
            <KmlDataSource show={showrendadp} data={rendadp} />

            <KmlDataSource show={showarmazensacc2} data={armazensacc2} />
            <KmlDataSource show={showbalsasacc2} data={balsasacc2} />
            <KmlDataSource
              show={showconcarmazensacc2}
              data={concarmazensacc2}
            />
            <KmlDataSource show={showformosoacc2} data={formosoacc2} />
            <KmlDataSource show={showlagoaacc2} data={lagoaacc2} />
            <KmlDataSource show={showmiranorteacc2} data={miranorteacc2} />
            <KmlDataSource show={showportoacc2} data={portoacc2} />
            <KmlDataSource
              show={showsilvanopolisacc2}
              data={silvanopolisacc2}
            />
            <KmlDataSource show={showtassoacc2} data={tassoacc2} />
            <KmlDataSource show={showusosoloacc2} data={usosoloacc2} />
            <KmlDataSource
              show={showhidroviasgeralacc2}
              data={hidroviasgeralacc2}
            />
            <KmlDataSource show={showviasgeralacc2} data={viasgeralacc2} />
            <KmlDataSource show={showportsaoluisacc2} data={portsaoluisacc2} />
            <KmlDataSource
              show={showportnacionalacc2}
              data={portnacionalacc2}
            />
            <KmlDataSource
              show={showintpalemeiranteacc2}
              data={intpalemeiranteacc2}
            />
            <KmlDataSource show={showrodoviasacc2} data={rodoviasacc2} />
            <KmlDataSource
              show={showinstalacoesportacc2}
              data={instalacoesportacc2}
            />
            <KmlDataSource
              show={showhidroviasofcacc2}
              data={hidroviasofcacc2}
            />
            <KmlDataSource show={showferroviasacc2} data={ferroviasacc2} />

            <KmlDataSource
              show={showusosolomiranorteacc2}
              data={usosolomiranorteacc2}
            />
            <KmlDataSource
              show={showusosolosilvanopolisacc2}
              data={usosolosilvanopolisacc2}
            />
            <KmlDataSource
              show={showusosololagoaacc2}
              data={usosololagoaacc2}
            />
            <KmlDataSource
              show={showusosoloformosoacc2}
              data={usosoloformosoacc2}
            />
            <KmlDataSource
              show={showusosoloportoacc2}
              data={usosoloportoacc2}
            />
            <KmlDataSource
              show={showusosolobalsasacc2}
              data={usosolobalsasacc2}
            />
            <KmlDataSource
              show={showusosolotassoacc2}
              data={usosolotassoacc2}
            />

            <KmlDataSource show={showlmsaltosc} data={lmsaltosc} />

            <KmlDataSource show={showcompsaltosc} data={compsaltosc} />

            <KmlDataSource show={showr1saltosc} data={r1saltosc} />

            <KmlDataSource show={showr2saltosc} data={r2saltosc} />

            <KmlDataSource show={showpfsaltosc} data={pfsaltosc} />

            <KmlDataSource show={showscsaltosc} data={scsaltosc} />

            <KmlDataSource show={showlmmarasc} data={lmmarasc} />

            <KmlDataSource show={showcompmarasc} data={compmarasc} />

            <KmlDataSource show={showbuffermarasc} data={buffermarasc} />

            <KmlDataSource show={showpfmarasc} data={pfmarasc} />

            <KmlDataSource show={showscmarasc} data={scmarasc} />

            <KmlDataSource show={showlmjacaresc} data={lmjacaresc} />

            <KmlDataSource show={showcompjacaresc} data={compjacaresc} />

            <KmlDataSource show={showr1jacaresc} data={r1jacaresc} />

            <KmlDataSource show={showpfjacaresc} data={pfjacaresc} />

            <KmlDataSource show={showscjacaresc} data={scjacaresc} />
            <KmlDataSource show={showterrenospec} data={terrenospec} />
            <KmlDataSource show={showterrenospecpau} data={terrenospecpau} />

            <KmlDataSource show={showamoarmazensacc2} data={amoarmazensacc2} />
            <KmlDataSource show={showprestaacc2} data={prestaacc2} />
            <KmlDataSource show={showhabacc2} data={habacc2} />
            <KmlDataSource show={showcapacc2} data={capacc2} />
            <KmlDataSource show={showtipoacc2} data={tipoacc2} />
            <KmlDataSource show={showporteacc2} data={porteacc2} />
            <KmlDataSource show={showformacc2} data={formacc2} />
            <KmlDataSource show={showdistferroacc2} data={distferroacc2} />
            <KmlDataSource show={showdistermacc2} data={distermacc2} />
            <KmlDataSource show={showestosoacc2} data={estosoacc2} />
            <KmlDataSource show={showprecosoacc2} data={precosoacc2} />
            <KmlDataSource show={showpopacc2} data={popacc2} />
            <KmlDataSource show={showrendaacc2} data={rendaacc2} />
            <KmlDataSource show={showaeroacc2} data={aeroacc2} />
            <KmlDataSource show={showdeclacc2} data={declacc2} />

            <KmlDataSource
              show={showdadossociosaltossc}
              data={dadossociosaltossc}
            />
            <KmlDataSource show={showfluxo1marajasc} data={fluxo1marajasc} />
            <KmlDataSource
              show={showkernelfluxo1marajasc}
              data={kernelfluxo1marajasc}
            />
            <KmlDataSource
              show={showfamiliasmarajassc}
              data={familiasmarajassc}
            />
            <KmlDataSource show={showrendamarajassc} data={rendamarajassc} />
            <KmlDataSource
              show={showdadossociojacaresc}
              data={dadossociojacaresc}
            />

            <KmlDataSource show={showcrescimentobrz} data={crescimentobrz} />
            <KmlDataSource
              show={showdomiciliosaluguelbrz}
              data={domiciliosaluguelbrz}
            />
            <KmlDataSource show={showdomicioliosbrz} data={domicioliosbrz} />
            <KmlDataSource
              show={showempreendimentosbrz}
              data={empreendimentosbrz}
            />
            <KmlDataSource show={showlavrasbrz} data={lavrasbrz} />
            <KmlDataSource show={showpopbrz} data={popbrz} />
            <KmlDataSource show={showraio15brz} data={raio15brz} />
            <KmlDataSource show={showrendabrz} data={rendabrz} />
            <KmlDataSource show={showvetorbrz} data={vetorbrz} />

            <KmlDataSource show={showprop1toacc2} data={prop1toacc2} />
            <KmlDataSource show={showprop2toacc2} data={prop2toacc2} />
            <KmlDataSource show={showprop1maacc2} data={prop1maacc2} />
            <KmlDataSource show={showprop2maacc2} data={prop2maacc2} />

            <KmlDataSource show={showpontointbrz} data={pontointbrz} />
            <KmlDataSource show={showcrescimento1brz} data={crescimento1brz} />
            <KmlDataSource show={showdeficitbrz} data={deficitbrz} />
            <KmlDataSource show={showtaxahabbrz} data={taxahabbrz} />

            <KmlDataSource show={showgtdcamacircu} data={gtdcamacircu} />
            <KmlDataSource show={showdistcamacircu} data={distcamacircu} />
            <KmlDataSource
              show={showarearedcamacircu}
              data={arearedcamacircu}
            />
            <KmlDataSource
              show={showareaspotcamacircu}
              data={areaspotcamacircu}
            />
            <KmlDataSource show={showassocamacircu} data={assocamacircu} />
            <KmlDataSource show={showcamacircu} data={camacircu} />
            <KmlDataSource show={showempscamacircu} data={empscamacircu} />
            <KmlDataSource
              show={showconcempcamacircu}
              data={concempcamacircu}
            />
            <KmlDataSource
              show={showareaspotvargcircu}
              data={areaspotvargcircu}
            />
            <KmlDataSource show={showassovargcircu} data={assovargcircu} />
            <KmlDataSource show={showvargcircu} data={vargcircu} />
            <KmlDataSource show={showempsvargcircu} data={empsvargcircu} />
            <KmlDataSource show={showgtdcampcircu} data={gtdcampcircu} />
            <KmlDataSource show={showdistcampcircu} data={distcampcircu} />
            <KmlDataSource
              show={showdistecocampcircu}
              data={distecocampcircu}
            />
            <KmlDataSource
              show={showarearedcampcircu}
              data={arearedcampcircu}
            />
            <KmlDataSource
              show={showareaspotcampcircu}
              data={areaspotcampcircu}
            />
            <KmlDataSource show={showassocampcircu} data={assocampcircu} />
            <KmlDataSource show={showcampcircu} data={campcircu} />
            <KmlDataSource show={showempscampcircu} data={empscampcircu} />
            <KmlDataSource
              show={showdensempscampcircu}
              data={densempscampcircu}
            />
            <KmlDataSource show={showecoscampcircu} data={ecoscampcircu} />
            <KmlDataSource show={showgtdjundcircu} data={gtdjundcircu} />
            <KmlDataSource show={showdistjundcircu} data={distjundcircu} />
            <KmlDataSource
              show={showdistecojundcircu}
              data={distecojundcircu}
            />
            <KmlDataSource
              show={showarearedjundcircu}
              data={arearedjundcircu}
            />
            <KmlDataSource
              show={showareaspotjundcircu}
              data={areaspotjundcircu}
            />
            <KmlDataSource show={showassojundcircu} data={assojundcircu} />
            <KmlDataSource show={showjundcircu} data={jundcircu} />
            <KmlDataSource show={showempsjundcircu} data={empsjundcircu} />
            <KmlDataSource
              show={showdensempsjundcircu}
              data={densempsjundcircu}
            />
            <KmlDataSource show={showecosjundcircu} data={ecosjundcircu} />
            <KmlDataSource show={showgtdsorocircu} data={gtdsorocircu} />
            <KmlDataSource show={showdistsorocircu} data={distsorocircu} />
            <KmlDataSource
              show={showdistecosorocircu}
              data={distecosorocircu}
            />
            <KmlDataSource
              show={showarearedsorocircu}
              data={arearedsorocircu}
            />
            <KmlDataSource
              show={showareaspotsorocircu}
              data={areaspotsorocircu}
            />
            <KmlDataSource show={showassosorocircu} data={assosorocircu} />
            <KmlDataSource show={showsorocircu} data={sorocircu} />
            <KmlDataSource show={showempssorocircu} data={empssorocircu} />
            <KmlDataSource
              show={showdensempssorocircu}
              data={densempssorocircu}
            />
            <KmlDataSource show={showecossorocircu} data={ecossorocircu} />

            <KmlDataSource show={showareapotafasdp} data={areapotafasdp} />
            <KmlDataSource show={showimoveisdp} data={imoveisdp} />

            <KmlDataSource show={showjundiaimrv} data={jundiaimrv} />
            <KmlDataSource show={showappjundiaimrv} data={appjundiaimrv} />
            <KmlDataSource show={showvegjundiaimrv} data={vegjundiaimrv} />
            <KmlDataSource show={showresjundiaimrv} data={resjundiaimrv} />
            <KmlDataSource
              show={showareaprojundiaimrv}
              data={areaprojundiaimrv}
            />
            <KmlDataSource show={shownascjundiaimrv} data={nascjundiaimrv} />
            <KmlDataSource show={showcursjundiaimrv} data={cursjundiaimrv} />
            <KmlDataSource
              show={showcorposjundiaimrv}
              data={corposjundiaimrv}
            />
            <KmlDataSource show={showtopojundiaimrv} data={topojundiaimrv} />
            <KmlDataSource show={showurbjundiaimrv} data={urbjundiaimrv} />
            <KmlDataSource
              show={showareapoujundiaimrv}
              data={areapoujundiaimrv}
            />
            <KmlDataSource show={showservjundiaimrv} data={servjundiaimrv} />
            <KmlDataSource
              show={showusorestjundiaimrv}
              data={usorestjundiaimrv}
            />
            <KmlDataSource
              show={showareaconsjundiaimrv}
              data={areaconsjundiaimrv}
            />
            <KmlDataSource
              show={showareacontjundiaimrv}
              data={areacontjundiaimrv}
            />
            <KmlDataSource show={showdutojundiaimrv} data={dutojundiaimrv} />
            <KmlDataSource
              show={showlinhaojundiaimrv}
              data={linhaojundiaimrv}
            />
            <KmlDataSource show={showzoneajundiaimrv} data={zoneajundiaimrv} />
            <KmlDataSource
              show={showlegavertjundiaimrv}
              data={legavertjundiaimrv}
            />
            <KmlDataSource
              show={showlegahorjundiaimrv}
              data={legahorjundiaimrv}
            />
            <KmlDataSource
              show={showrendapopjundiaimrv}
              data={rendapopjundiaimrv}
            />
            <KmlDataSource show={showrendajundiaimrv} data={rendajundiaimrv} />
            <KmlDataSource show={showpopjundiaimrv} data={popjundiaimrv} />
            <KmlDataSource show={showdomicjundiaimrv} data={domicjundiaimrv} />
            <KmlDataSource
              show={showdomicalujundiaimrv}
              data={domicalujundiaimrv}
            />
            <KmlDataSource
              show={showexpurbjundiaimrv}
              data={expurbjundiaimrv}
            />
            <KmlDataSource
              show={showtaxahabjundiaimrv}
              data={taxahabjundiaimrv}
            />
            <KmlDataSource
              show={showgraprohabjundiaimrv}
              data={graprohabjundiaimrv}
            />
            <KmlDataSource
              show={showareaurbjundiaimrv}
              data={areaurbjundiaimrv}
            />
            <KmlDataSource
              show={showarearujundiaimrv}
              data={arearujundiaimrv}
            />
            <KmlDataSource
              show={showvaziosjundiaimrv}
              data={vaziosjundiaimrv}
            />
            <KmlDataSource
              show={showconsumoaguajundiaimrv}
              data={consumoaguajundiaimrv}
            />
            <KmlDataSource
              show={showdispaguajundiaimrv}
              data={dispaguajundiaimrv}
            />
            <KmlDataSource show={showcustojundiaimrv} data={custojundiaimrv} />
            <KmlDataSource
              show={showterrenosjundiaimrv}
              data={terrenosjundiaimrv}
            />
            <KmlDataSource
              show={showpontoajundiaimrv}
              data={pontoajundiaimrv}
            />
            <KmlDataSource
              show={showpontodjundiaimrv}
              data={pontodjundiaimrv}
            />

            <KmlDataSource show={showlimeiramrv} data={limeiramrv} />

            <KmlDataSource show={showapplimeiramrv} data={applimeiramrv} />

            <KmlDataSource show={showveglimeiramrv} data={veglimeiramrv} />

            <KmlDataSource show={showreslimeiramrv} data={reslimeiramrv} />

            <KmlDataSource
              show={showareaprolimeiramrv}
              data={areaprolimeiramrv}
            />

            <KmlDataSource show={shownasclimeiramrv} data={nasclimeiramrv} />

            <KmlDataSource show={showcurslimeiramrv} data={curslimeiramrv} />
            <KmlDataSource
              show={showcorposlimeiramrv}
              data={corposlimeiramrv}
            />
            <KmlDataSource show={showtopolimeiramrv} data={topolimeiramrv} />

            <KmlDataSource show={showurblimeiramrv} data={urblimeiramrv} />

            <KmlDataSource
              show={showareapoulimeiramrv}
              data={areapoulimeiramrv}
            />

            <KmlDataSource show={showservlimeiramrv} data={servlimeiramrv} />

            <KmlDataSource
              show={showusorestlimeiramrv}
              data={usorestlimeiramrv}
            />

            <KmlDataSource
              show={showareaconslimeiramrv}
              data={areaconslimeiramrv}
            />

            <KmlDataSource
              show={showareacontlimeiramrv}
              data={areacontlimeiramrv}
            />

            <KmlDataSource show={showdutolimeiramrv} data={dutolimeiramrv} />

            <KmlDataSource
              show={showlinhaolimeiramrv}
              data={linhaolimeiramrv}
            />
            <KmlDataSource show={showzonealimeiramrv} data={zonealimeiramrv} />
            <KmlDataSource
              show={showlegavertlimeiramrv}
              data={legavertlimeiramrv}
            />
            <KmlDataSource
              show={showlegahorlimeiramrv}
              data={legahorlimeiramrv}
            />
            <KmlDataSource
              show={showrendapoplimeiramrv}
              data={rendapoplimeiramrv}
            />
            <KmlDataSource show={showrendalimeiramrv} data={rendalimeiramrv} />
            <KmlDataSource show={showpoplimeiramrv} data={poplimeiramrv} />
            <KmlDataSource show={showdomiclimeiramrv} data={domiclimeiramrv} />
            <KmlDataSource
              show={showdomicalulimeiramrv}
              data={domicalulimeiramrv}
            />
            <KmlDataSource
              show={showexpurblimeiramrv}
              data={expurblimeiramrv}
            />
            <KmlDataSource
              show={showtaxahablimeiramrv}
              data={taxahablimeiramrv}
            />
            <KmlDataSource
              show={showgraprohablimeiramrv}
              data={graprohablimeiramrv}
            />
            <KmlDataSource
              show={showareaurblimeiramrv}
              data={areaurblimeiramrv}
            />
            <KmlDataSource
              show={showarearulimeiramrv}
              data={arearulimeiramrv}
            />
            <KmlDataSource
              show={showvazioslimeiramrv}
              data={vazioslimeiramrv}
            />
            <KmlDataSource
              show={showconsumoagualimeiramrv}
              data={consumoagualimeiramrv}
            />
            <KmlDataSource
              show={showdispagualimeiramrv}
              data={dispagualimeiramrv}
            />
            <KmlDataSource show={showcustolimeiramrv} data={custolimeiramrv} />
            <KmlDataSource
              show={showterrenoslimeiramrv}
              data={terrenoslimeiramrv}
            />
            <KmlDataSource
              show={showpontoalimeiramrv}
              data={pontoalimeiramrv}
            />
            <KmlDataSource
              show={showpontodlimeiramrv}
              data={pontodlimeiramrv}
            />

            <KmlDataSource
              show={showveloprecojundiaimrv}
              data={veloprecojundiaimrv}
            />

            <KmlDataSource
              show={showveloprecolimeiramrv}
              data={veloprecolimeiramrv}
            />

            <KmlDataSource show={showbdgu} data={bdgu} />

            <KmlDataSource show={showboticampcircu} data={boticampcircu} />
            <KmlDataSource show={showbotijundcircu} data={botijundcircu} />
            <KmlDataSource show={showbotisorocircu} data={botisorocircu} />
            <KmlDataSource
              show={showbotigrandespcircu}
              data={botigrandespcircu}
            />
            <KmlDataSource show={showgrandespcircu} data={grandespcircu} />

            <KmlDataSource show={showimoveisc1ic} data={imoveisc1ic} />
            <KmlDataSource show={showraioc1ic} data={raioc1ic} />
            <KmlDataSource show={showareaestudoc2ic} data={areaestudoc2ic} />
            <KmlDataSource show={showrendac2ic} data={rendac2ic} />
            <KmlDataSource show={showrecortec2ic} data={recortec2ic} />
            <KmlDataSource show={showpontosfluxc2ic} data={pontosfluxc2ic} />
            <KmlDataSource show={showconcfluxoc2ic} data={concfluxoc2ic} />
            <KmlDataSource show={showoticasc2ic} data={oticasc2ic} />
            <KmlDataSource
              show={showconcfluxooticasc2ic}
              data={concfluxooticasc2ic}
            />
            <KmlDataSource show={showimoveisc2ic} data={imoveisc2ic} />
            <KmlDataSource show={showshoppingsc2ic} data={shoppingsc2ic} />

            <KmlDataSource show={showkernelsaltosc} data={kernelsaltosc} />
            <KmlDataSource show={showkerneljacaresc} data={kerneljacaresc} />
            <KmlDataSource show={showponibusmarasc} data={ponibusmarasc} />
            <KmlDataSource show={showlonibusmarasc} data={lonibusmarasc} />

            <KmlDataSource
              show={showredesdrenalimeiramrv}
              data={redesdrenalimeiramrv}
            />
            <KmlDataSource
              show={showrecargahidricalimeiramrv}
              data={recargahidricalimeiramrv}
            />
            <KmlDataSource
              show={showferroviaslimeiramrv}
              data={ferroviaslimeiramrv}
            />
            <KmlDataSource show={showviaslimeiramrv} data={viaslimeiramrv} />
            <KmlDataSource
              show={showviasligalimeiramrv}
              data={viasligalimeiramrv}
            />
            <KmlDataSource
              show={showrankingterlimeiramrv}
              data={rankingterlimeiramrv}
            />
            <KmlDataSource
              show={showredesdrenajundiaimrv}
              data={redesdrenajundiaimrv}
            />
            <KmlDataSource
              show={showrecargahidricajundiaimrv}
              data={recargahidricajundiaimrv}
            />
            <KmlDataSource show={showviasjundiaimrv} data={viasjundiaimrv} />
            <KmlDataSource
              show={showviasligajundiaimrv}
              data={viasligajundiaimrv}
            />
            <KmlDataSource
              show={showferroviasjundiaimrv}
              data={ferroviasjundiaimrv}
            />
            <KmlDataSource
              show={showequipamentosjundiaimrv}
              data={equipamentosjundiaimrv}
            />
            <KmlDataSource
              show={showrankingterjundiaimrv}
              data={rankingterjundiaimrv}
            />
            <KmlDataSource
              show={showpoluicaosonorajundiaimrv}
              data={poluicaosonorajundiaimrv}
            />

            <KmlDataSource show={showacadmelten} data={acadmelten} />
            <KmlDataSource
              show={showareasafastmelten}
              data={areasafastmelten}
            />
            <KmlDataSource show={showareasproxmelten} data={areasproxmelten} />
            <KmlDataSource show={showcomerciosmelten} data={comerciosmelten} />
            <KmlDataSource show={showconcconcmelten} data={concconcmelten} />
            <KmlDataSource show={showconcmelten} data={concmelten} />
            <KmlDataSource
              show={showcrescurbanomelten}
              data={crescurbanomelten}
            />
            <KmlDataSource
              show={showdomiciliosmelten}
              data={domiciliosmelten}
            />
            <KmlDataSource show={showequipublimelten} data={equipublimelten} />
            <KmlDataSource
              show={showescolasprivadasmelten}
              data={escolasprivadasmelten}
            />
            <KmlDataSource show={showhidroviasmelten} data={hidroviasmelten} />
            <KmlDataSource show={showhospitaismelten} data={hospitaismelten} />
            <KmlDataSource show={showimoveismelten} data={imoveismelten} />
            <KmlDataSource
              show={showinfraviariamelten}
              data={infraviariamelten}
            />
            <KmlDataSource show={showmelten} data={melten} />
            <KmlDataSource show={showpalmasmelten} data={palmasmelten} />
            <KmlDataSource show={showpopmelten} data={popmelten} />
            <KmlDataSource show={showrendamelten} data={rendamelten} />
            <KmlDataSource
              show={showrestaurantesmelten}
              data={restaurantesmelten}
            />
            <KmlDataSource
              show={showtransportemelten}
              data={transportemelten}
            />
            <KmlDataSource show={showviasmelten} data={viasmelten} />

            <KmlDataSource show={showgtdipocircu} data={gtdipocircu} />
            <KmlDataSource show={showarearedipocircu} data={arearedipocircu} />
            <KmlDataSource
              show={showareaspotipocircu}
              data={areaspotipocircu}
            />
            <KmlDataSource
              show={showcoopreciipocircu}
              data={coopreciipocircu}
            />
            <KmlDataSource show={showipocircu} data={ipocircu} />
            <KmlDataSource show={showempsipocircu} data={empsipocircu} />
            <KmlDataSource
              show={showdensempsipocircu}
              data={densempsipocircu}
            />
            <KmlDataSource show={showecosipocircu} data={ecosipocircu} />

            <KmlDataSource
              show={showcorrecondcamacircu}
              data={correcondcamacircu}
            />
            <KmlDataSource
              show={showcorrecondvargcircu}
              data={correcondvargcircu}
            />
            <KmlDataSource
              show={showcorrecondcampcircu}
              data={correcondcampcircu}
            />
            <KmlDataSource
              show={showcorrecondjundcircu}
              data={correcondjundcircu}
            />
            <KmlDataSource
              show={showcorrecondsorocircu}
              data={correcondsorocircu}
            />

            <KmlDataSource
              show={showregionalizacaomelten}
              data={regionalizacaomelten}
            />

            <KmlDataSource show={showcrescpecpau} data={crescpecpau} />
            <KmlDataSource
              show={showdadossociospecpau}
              data={dadossociospecpau}
            />
            <KmlDataSource show={showhidropecpau} data={hidropecpau} />
            <KmlDataSource
              show={showlimitesambpecpau}
              data={limitesambpecpau}
            />
            <KmlDataSource
              show={showpontosfluxopecpau}
              data={pontosfluxopecpau}
            />
            <KmlDataSource show={showraios500pecpau} data={raios500pecpau} />
            <KmlDataSource show={showservidaopecpau} data={servidaopecpau} />
            <KmlDataSource
              show={showterrenospotpecpau}
              data={terrenospotpecpau}
            />
            <KmlDataSource
              show={showterrenosranqpecpau}
              data={terrenosranqpecpau}
            />
            <KmlDataSource show={showtopopecpau} data={topopecpau} />
            <KmlDataSource show={showvaziospecpau} data={vaziospecpau} />

            <KmlDataSource show={showplayersagrovh} data={playersagrovh} />
            <KmlDataSource show={showcampinasvh} data={campinasvh} />
            <KmlDataSource
              show={showparquesteccampvh}
              data={parquesteccampvh}
            />

            <KmlDataSource show={showdemandagu} data={demandagu} />
            <KmlDataSource show={showativpesquisagu} data={ativpesquisagu} />
            <KmlDataSource show={showinteressehubgu} data={interessehubgu} />
            <KmlDataSource
              show={showmodelotrabalhogu}
              data={modelotrabalhogu}
            />
            <KmlDataSource
              show={showcapitalpropriogu}
              data={capitalpropriogu}
            />
            <KmlDataSource show={showfundestaduaisgu} data={fundestaduaisgu} />
            <KmlDataSource show={showsebraegu} data={sebraegu} />
            <KmlDataSource show={showbndesgu} data={bndesgu} />
            <KmlDataSource
              show={showinvestidoranjogu}
              data={investidoranjogu}
            />
            <KmlDataSource show={showfundosinvestgu} data={fundosinvestgu} />
            <KmlDataSource show={showaceleradorasgu} data={aceleradorasgu} />
            <KmlDataSource show={showsemrespostagu} data={semrespostagu} />

            <KmlDataSource show={showictsvh} data={ictsvh} />
            <KmlDataSource show={showictsagrovh} data={ictsagrovh} />

            <KmlDataSource
              show={showareaspreservavespemcp}
              data={areaspreservavespemcp}
            />
            <KmlDataSource
              show={showvegnativavespemcp}
              data={vegnativavespemcp}
            />
            <KmlDataSource
              show={showreservalegalvespemcp}
              data={reservalegalvespemcp}
            />
            <KmlDataSource
              show={shownascentesvespemcp}
              data={nascentesvespemcp}
            />
            <KmlDataSource
              show={showcursosaguavespemcp}
              data={cursosaguavespemcp}
            />
            <KmlDataSource
              show={showtopografiavespemcp}
              data={topografiavespemcp}
            />
            <KmlDataSource show={showlimitevespemcp} data={limitevespemcp} />
            <KmlDataSource
              show={showzoneamentovespemcp}
              data={zoneamentovespemcp}
            />
            <KmlDataSource show={showusovertvespemcp} data={usovertvespemcp} />
            <KmlDataSource
              show={showpermissaovespemcp}
              data={permissaovespemcp}
            />
            <KmlDataSource
              show={showlocterrenovespemcp}
              data={locterrenovespemcp}
            />
            <KmlDataSource
              show={showservidaovespemcp}
              data={servidaovespemcp}
            />
            <KmlDataSource
              show={showareaconsovespemcp}
              data={areaconsovespemcp}
            />
            <KmlDataSource
              show={showareascontavespemcp}
              data={areascontavespemcp}
            />
            <KmlDataSource
              show={showdutoviasvespemcp}
              data={dutoviasvespemcp}
            />
            <KmlDataSource show={showlinhaovespemcp} data={linhaovespemcp} />
            <KmlDataSource
              show={shownovoscondvespemcp}
              data={novoscondvespemcp}
            />
            <KmlDataSource show={showrendavespemcp} data={rendavespemcp} />
            <KmlDataSource show={showpopvespemcp} data={popvespemcp} />
            <KmlDataSource show={showdomicivespemcp} data={domicivespemcp} />
            <KmlDataSource
              show={showdomicialuvespemcp}
              data={domicialuvespemcp}
            />
            <KmlDataSource
              show={showexpurbanavespemcp}
              data={expurbanavespemcp}
            />
            <KmlDataSource
              show={showveloeprecovespemcp}
              data={veloeprecovespemcp}
            />
            <KmlDataSource show={showtaxahabvespemcp} data={taxahabvespemcp} />
            <KmlDataSource
              show={showconcorrenciavespemcp}
              data={concorrenciavespemcp}
            />
            <KmlDataSource
              show={showareaurbavespemcp}
              data={areaurbavespemcp}
            />
            <KmlDataSource
              show={showareasruraisvespemcp}
              data={areasruraisvespemcp}
            />
            <KmlDataSource
              show={showconsuaguavespemcp}
              data={consuaguavespemcp}
            />
            <KmlDataSource
              show={showdispaguavespemcp}
              data={dispaguavespemcp}
            />
            <KmlDataSource
              show={showcustoprojetovespemcp}
              data={custoprojetovespemcp}
            />
            <KmlDataSource
              show={showterrenosanunvespemcp}
              data={terrenosanunvespemcp}
            />
            <KmlDataSource
              show={showimoveisanunvespemcp}
              data={imoveisanunvespemcp}
            />
            <KmlDataSource
              show={showpontosatravespemcp}
              data={pontosatravespemcp}
            />
            <KmlDataSource
              show={showpontosdetravespemcp}
              data={pontosdetravespemcp}
            />

            <KmlDataSource show={showtjchpdtjd} data={tjchpdtjd} />
            <KmlDataSource
              show={showcompdiretoschpdtjd}
              data={compdiretoschpdtjd}
            />
            <KmlDataSource
              show={showcriancas05chpdtjd}
              data={criancas05chpdtjd}
            />
            <KmlDataSource
              show={showescolasidiomaschpdtjd}
              data={escolasidiomaschpdtjd}
            />
            <KmlDataSource
              show={showescolasinfantischpdtjd}
              data={escolasinfantischpdtjd}
            />
            <KmlDataSource show={showescolaschpdtjd} data={escolaschpdtjd} />
            <KmlDataSource
              show={showimovelindicadochpdtjd}
              data={imovelindicadochpdtjd}
            />
            <KmlDataSource
              show={showpontosfluxochpdtjd}
              data={pontosfluxochpdtjd}
            />
            <KmlDataSource
              show={showprincipaisviaschpdtjd}
              data={principaisviaschpdtjd}
            />
            <KmlDataSource
              show={showraio1kmcompchpdtjd}
              data={raio1kmcompchpdtjd}
            />
            <KmlDataSource
              show={showraio2kmcompchpdtjd}
              data={raio2kmcompchpdtjd}
            />
            <KmlDataSource
              show={showrendafamiliarchpdtjd}
              data={rendafamiliarchpdtjd}
            />

            <KmlDataSource show={showareainflurctjd} data={areainflurctjd} />
            <KmlDataSource show={showareasindirctjd} data={areasindirctjd} />
            <KmlDataSource
              show={showcompetidoresrctjd}
              data={competidoresrctjd}
            />
            <KmlDataSource
              show={showcondominiosrctjd}
              data={condominiosrctjd}
            />
            <KmlDataSource show={showimoveisrctjd} data={imoveisrctjd} />
            <KmlDataSource
              show={showpontosfluxirctjd}
              data={pontosfluxirctjd}
            />
            <KmlDataSource
              show={showcompdiretosvptjd}
              data={compdiretosvptjd}
            />
            <KmlDataSource
              show={showimoveissapopembavptjd}
              data={imoveissapopembavptjd}
            />
            <KmlDataSource show={showimoveisvpvptjd} data={imoveisvpvptjd} />
            <KmlDataSource
              show={showpontosfluxovptjd}
              data={pontosfluxovptjd}
            />
            <KmlDataSource
              show={showrendafamiliarvptjd}
              data={rendafamiliarvptjd}
            />
            <KmlDataSource show={showtjschoolvptjd} data={tjschoolvptjd} />

            <KmlDataSource show={showappwbgi} data={appwbgi} />
            <KmlDataSource show={showareacontwbgi} data={areacontwbgi} />
            <KmlDataSource show={showcurvasnivelwbgi} data={curvasnivelwbgi} />
            <KmlDataSource show={showdeclividadewbgi} data={declividadewbgi} />
            <KmlDataSource
              show={showdistcorposaguawbgi}
              data={distcorposaguawbgi}
            />
            <KmlDataSource
              show={showdistcorposhidricoswbgi}
              data={distcorposhidricoswbgi}
            />
            <KmlDataSource show={showhidrografiawbgi} data={hidrografiawbgi} />
            <KmlDataSource show={showlimeirawbgi} data={limeirawbgi} />
            <KmlDataSource
              show={showreservalegalwbgi}
              data={reservalegalwbgi}
            />
            <KmlDataSource show={showvegnativawbgi} data={vegnativawbgi} />
            <KmlDataSource show={showviveiristaswbgi} data={viveiristaswbgi} />
            
            <KmlDataSource show={showlimiteosascotnd} data={limiteosascotnd} />
            <KmlDataSource show={showareapreserosascotnd} data={areapreserosascotnd} />
            <KmlDataSource show={showvegnaivaosascotnd} data={vegnaivaosascotnd} />
            <KmlDataSource show={showreservaosascotnd} data={reservaosascotnd} />
            <KmlDataSource show={shownascentesosascotnd} data={nascentesosascotnd} />
            <KmlDataSource show={showcursososascotnd} data={cursososascotnd} />
            <KmlDataSource show={showcorpososascotnd} data={corpososascotnd} />
            <KmlDataSource show={showtopografiaosascotnd} data={topografiaosascotnd} />
            <KmlDataSource show={showzoneamentoosascotnd} data={zoneamentoosascotnd} />
            <KmlDataSource show={showusovertosascotnd} data={usovertosascotnd} />
            <KmlDataSource show={showimplantacaohishmposascotnd} data={implantacaohishmposascotnd} />
            <KmlDataSource show={showservadmosascotnd} data={servadmosascotnd} />
            <KmlDataSource show={showareaconsoosascotnd} data={areaconsoosascotnd} />
            <KmlDataSource show={showareascontaosascotnd} data={areascontaosascotnd} />
            <KmlDataSource show={showdutoviasosascotnd} data={dutoviasosascotnd} />
            <KmlDataSource show={showlinhaoosascotnd} data={linhaoosascotnd} />
            <KmlDataSource show={showpontosatratosascotnd} data={pontosatratosascotnd} />
            <KmlDataSource show={showpontosdetratosascotnd} data={pontosdetratosascotnd} />
            <KmlDataSource show={showrendaosascotnd} data={rendaosascotnd} />
            <KmlDataSource show={showdomiciliososascotnd} data={domiciliososascotnd} />
            <KmlDataSource show={showdomiciliosaluguelosascotnd} data={domiciliosaluguelosascotnd} />
            <KmlDataSource show={showexpcidadesosascotnd} data={expcidadesosascotnd} />
            <KmlDataSource show={showprecomedioosascotnd} data={precomedioosascotnd} />
            <KmlDataSource show={showtaxahabosascotnd} data={taxahabosascotnd} />
            <KmlDataSource show={showconcoosascotnd} data={concoosascotnd} />
            <KmlDataSource show={showareaurbanizadaosascotnd} data={areaurbanizadaosascotnd} />
            <KmlDataSource show={showareasruraisosascotnd} data={areasruraisosascotnd} />
            <KmlDataSource show={showvaziosurbanososascotnd} data={vaziosurbanososascotnd} />
            <KmlDataSource show={showgrapohabosascotnd} data={grapohabosascotnd} />
            <KmlDataSource show={showterrenososascotnd} data={terrenososascotnd} />
            <KmlDataSource show={showimoveisosascotnd} data={imoveisosascotnd} />
            <KmlDataSource show={showtendenciassetorosascotnd} data={tendenciassetorosascotnd} />
            <KmlDataSource show={showsinaismudancaosascotnd} data={sinaismudancaosascotnd} />
            <KmlDataSource show={showoportunidadeosascotnd} data={oportunidadeosascotnd} />
            <KmlDataSource show={showareausorestosascotnd} data={areausorestosascotnd} />
            <KmlDataSource show={showareapotlimeiramrv} data={areapotlimeiramrv} />
            
            <KmlDataSource show={showareapreserslgb} data={areapreserslgb} />
            <KmlDataSource show={showvegnativaslgb} data={vegnativaslgb} />
            <KmlDataSource show={showreservaslgb} data={reservaslgb} />
            <KmlDataSource show={shownascentesslgb} data={nascentesslgb} />
            <KmlDataSource show={showhidrografiaslgb} data={hidrografiaslgb} />
            <KmlDataSource show={showtopografiaslgb} data={topografiaslgb} />
            <KmlDataSource show={showzoneamentoslgb} data={zoneamentoslgb} />
            <KmlDataSource show={showusovertslgb} data={usovertslgb} />
            <KmlDataSource show={showusohorslgb} data={usohorslgb} />
            <KmlDataSource show={showviasslgb} data={viasslgb} />
            <KmlDataSource show={showdutoviasslgb} data={dutoviasslgb} />
            <KmlDataSource show={showlinhaoslgb} data={linhaoslgb} />
            <KmlDataSource show={showpontosatratosslgb} data={pontosatratosslgb} />
            <KmlDataSource show={showpontosdetratslgb} data={pontosdetratslgb} />
            <KmlDataSource show={showrendaslgb} data={rendaslgb} />
            <KmlDataSource show={showdomiciliosslgb} data={domiciliosslgb} />
            <KmlDataSource show={showconcoslgb} data={concoslgb} />
            <KmlDataSource show={showareasruraisslgb} data={areasruraisslgb} />
            <KmlDataSource show={showterrenosslgb} data={terrenosslgb} />
            <KmlDataSource show={showimoveisslgb} data={imoveisslgb} />
            <KmlDataSource show={showraiopesquisaslgb} data={raiopesquisaslgb} />
            <KmlDataSource show={showterrenosanaslgb} data={terrenosanaslgb} />
            
            <KmlDataSource show={showlimitestatnd} data={limitestatnd} />
            <KmlDataSource show={showareapreserstatnd} data={areapreserstatnd} />
            <KmlDataSource show={showvegnaivastatnd} data={vegnaivastatnd} />
            <KmlDataSource show={showreservastatnd} data={reservastatnd} />
            <KmlDataSource show={shownascentesstatnd} data={nascentesstatnd} />
            <KmlDataSource show={showhidrocarstatnd} data={hidrocarstatnd} />
            <KmlDataSource show={showhidrocompstatnd} data={hidrocompstatnd} />
            <KmlDataSource show={showtopografiastatnd} data={topografiastatnd} />
            <KmlDataSource show={showzoneamentostatnd} data={zoneamentostatnd} />
            <KmlDataSource show={showusovertstatnd} data={usovertstatnd} />
            <KmlDataSource show={showimplantacaohishmpstatnd} data={implantacaohishmpstatnd} />
            <KmlDataSource show={showservadmstatnd} data={servadmstatnd} />
            <KmlDataSource show={showareausoreststatnd} data={areausoreststatnd} />
            <KmlDataSource show={showareaconsostatnd} data={areaconsostatnd} />
            <KmlDataSource show={showareascontastatnd} data={areascontastatnd} />
            <KmlDataSource show={showdutoviasstatnd} data={dutoviasstatnd} />
            <KmlDataSource show={showlinhaostatnd} data={linhaostatnd} />
            <KmlDataSource show={showrededrenagemstatnd} data={rededrenagemstatnd} />
            <KmlDataSource show={showpontosatratstatnd} data={pontosatratstatnd} />
            <KmlDataSource show={showpontosdetratstatnd} data={pontosdetratstatnd} />
            <KmlDataSource show={showdomiciliosstatnd} data={domiciliosstatnd} />
            <KmlDataSource show={showrendastatnd} data={rendastatnd} />
            <KmlDataSource show={showdomiciliosaluguelstatnd} data={domiciliosaluguelstatnd} />
            <KmlDataSource show={showexpcidadesstatnd} data={expcidadesstatnd} />
            <KmlDataSource show={showexpverticalstatnd} data={expverticalstatnd} />
            <KmlDataSource show={showprecomediostatnd} data={precomediostatnd} />
            <KmlDataSource show={showtaxahabstatnd} data={taxahabstatnd} />
            <KmlDataSource show={showareaurbanizadastatnd} data={areaurbanizadastatnd} />
            <KmlDataSource show={showareasruraisstatnd} data={areasruraisstatnd} />
            <KmlDataSource show={showvaziosurbanosstatnd} data={vaziosurbanosstatnd} />
            <KmlDataSource show={showgrapohabstatnd} data={grapohabstatnd} />
            <KmlDataSource show={showterrenosstatnd} data={terrenosstatnd} />
            <KmlDataSource show={showimoveisstatnd} data={imoveisstatnd} />
            
            <KmlDataSource show={showlimiteriva} data={limiteriva} />
            <KmlDataSource show={showrecorteriva} data={recorteriva} />
            <KmlDataSource show={showareapreserriva} data={areapreserriva} />
            <KmlDataSource show={showconservacaoriva} data={conservacaoriva} />
            <KmlDataSource show={showcursosriva} data={cursosriva} />
            <KmlDataSource show={shownascentesriva} data={nascentesriva} />
            <KmlDataSource show={showareasriscoriva} data={areasriscoriva} />
            <KmlDataSource show={showareasriscocontriva} data={areasriscocontriva} />
            <KmlDataSource show={showtopografiariva} data={topografiariva} />
            <KmlDataSource show={showzoneamentoriva} data={zoneamentoriva} />
            <KmlDataSource show={showusovertriva} data={usovertriva} />
            <KmlDataSource show={showaeroportosriva} data={aeroportosriva} />
            <KmlDataSource show={showdutoviasriva} data={dutoviasriva} />
            <KmlDataSource show={showlinhaoriva} data={linhaoriva} />
            <KmlDataSource show={showprotculturalriva} data={protculturalriva} />
            <KmlDataSource show={showbemcultriva} data={bemcultriva} />
            <KmlDataSource show={showbemcultimovelriva} data={bemcultimovelriva} />
            <KmlDataSource show={showbemcultnaturalriva} data={bemcultnaturalriva} />
            <KmlDataSource show={showdiretrizriva} data={diretrizriva} />
            <KmlDataSource show={showtombamentoriva} data={tombamentoriva} />
            <KmlDataSource show={showpontosatratriva} data={pontosatratriva} />
            <KmlDataSource show={showpontosdetratriva} data={pontosdetratriva} />
            <KmlDataSource show={showocupacaoriva} data={ocupacaoriva} />
            <KmlDataSource show={showrendariva} data={rendariva} />
            <KmlDataSource show={showpmedioriva} data={pmedioriva} />
            <KmlDataSource show={showtaxahabriva} data={taxahabriva} />
            <KmlDataSource show={showconcoriva} data={concoriva} />
            <KmlDataSource show={showvaziosriva} data={vaziosriva} />
            <KmlDataSource show={showimoveisanunriva} data={imoveisanunriva} />
            
            <KmlDataSource show={showlimitemacapaic} data={limitemacapaic} />
            <KmlDataSource show={showrecortemacapaic} data={recortemacapaic} />
            <KmlDataSource show={showvarejomacapaic} data={varejomacapaic} />
            <KmlDataSource show={showconcvarejomacapaic} data={concvarejomacapaic} />
            <KmlDataSource show={showconcfluxomacapaic} data={concfluxomacapaic} />
            <KmlDataSource show={showshoppingsmacapaic} data={shoppingsmacapaic} />
            <KmlDataSource show={showescolasmacapaic} data={escolasmacapaic} />
            <KmlDataSource show={showhospitaismacapaic} data={hospitaismacapaic} />
            <KmlDataSource show={showestacionamentosmacapaic} data={estacionamentosmacapaic} />
            <KmlDataSource show={showconcorrentesmacapaic} data={concorrentesmacapaic} />
            <KmlDataSource show={showuniversidadesmacapaic} data={universidadesmacapaic} />
            <KmlDataSource show={showassaimacapaic} data={assaimacapaic} />
            <KmlDataSource show={showrendamacapaic} data={rendamacapaic} />
            <KmlDataSource show={showdomiciliosmacapaic} data={domiciliosmacapaic} />
            <KmlDataSource show={showpopmacapaic} data={popmacapaic} />
            <KmlDataSource show={showimoveismacapaic} data={imoveismacapaic} />
            
            <KmlDataSource show={showcatimoveisriva} data={catimoveisriva} />
            
            <KmlDataSource show={showdomiciliosaureo} data={domiciliosaureo} />
            <KmlDataSource show={showempresasaureo} data={empresasaureo} />
            <KmlDataSource show={showlocinteresseaureo} data={locinteresseaureo} />
            <KmlDataSource show={showmarcasaureo} data={marcasaureo} />
            <KmlDataSource show={showpontosaureo} data={pontosaureo} />
            <KmlDataSource show={showraio1kmaureo} data={raio1kmaureo} />
            <KmlDataSource show={showraio2kmaureo} data={raio2kmaureo} />
            <KmlDataSource show={showrendaaureo} data={rendaaureo} />
            <KmlDataSource show={showviasaureo} data={viasaureo} />
            <KmlDataSource show={showzoneamentoaureo} data={zoneamentoaureo} />
            
            <KmlDataSource show={showpiscininha} data={piscininha} />
            <KmlDataSource show={showpiscinao} data={piscinao} />

            <KmlDataSource show={showimoveisvendidosemcp} data={imoveisvendidosemcp} />
            <KmlDataSource show={showoutrossetoresemcp} data={outrossetoresemcp} />
            <KmlDataSource show={showsetorescomimoveisemcp} data={setorescomimoveisemcp} />
            <KmlDataSource show={showvendasemcp} data={vendasemcp} />
            
            <KmlDataSource show={showcomerciosaureopet1} data={comerciosaureopet1} />
            <KmlDataSource show={showdomiciliosaureopet1} data={domiciliosaureopet1} />
            <KmlDataSource show={showempreendimentoaureopet1} data={empreendimentoaureopet1} />
            <KmlDataSource show={showlimiteaureopet1} data={limiteaureopet1} />
            <KmlDataSource show={showlocalizacaoaureopet1} data={localizacaoaureopet1} />
            <KmlDataSource show={showmarcasvareaureopet1} data={marcasvareaureopet1} />
            <KmlDataSource show={showr1kmaureopet1} data={r1kmaureopet1} />
            <KmlDataSource show={showr2kmaureopet1} data={r2kmaureopet1} />
            <KmlDataSource show={showrendaaureopet1} data={rendaaureopet1} />
            <KmlDataSource show={showviasacessoaureopet1} data={viasacessoaureopet1} />
            <KmlDataSource show={showcomerciosaureopet2} data={comerciosaureopet2} />
            <KmlDataSource show={showdomiciliosaureopet2} data={domiciliosaureopet2} />
            <KmlDataSource show={showempreendimentoaureopet2} data={empreendimentoaureopet2} />
            <KmlDataSource show={showlocalizacaoaureopet2} data={localizacaoaureopet2} />
            <KmlDataSource show={showr1kmaureopet2} data={r1kmaureopet2} />
            <KmlDataSource show={showr2kmaureopet2} data={r2kmaureopet2} />
            <KmlDataSource show={showrendaaureopet2} data={rendaaureopet2} />
            <KmlDataSource show={showviasacessoaureopet2} data={viasacessoaureopet2} />
            <KmlDataSource show={showzoneamentoaureopet2} data={zoneamentoaureopet2} />

            <KmlDataSource show={showhidrologiaaureopet1} data={hidrologiaaureopet1} />
            <KmlDataSource show={showtopografiaaureopet1} data={topografiaaureopet1} />
            <KmlDataSource show={showhidrologiaaureopet2} data={hidrologiaaureopet2} />
            <KmlDataSource show={showtopografiaaureopet2} data={topografiaaureopet2} />

            <KmlDataSource show={showtopografiaacc3} data={topografiaacc3} />
            <KmlDataSource show={showzoneamentoacc3} data={zoneamentoacc3} />
            <KmlDataSource show={showareapousoacc3} data={areapousoacc3} />
            <KmlDataSource show={showrotaaviaoacc3} data={rotaaviaoacc3} />
            <KmlDataSource show={showareascontacc3} data={areascontacc3} />
            <KmlDataSource show={showdutoviasacc3} data={dutoviasacc3} />
            <KmlDataSource show={showestabelecimentosacc3} data={estabelecimentosacc3} />
            <KmlDataSource show={showveiculosacc3} data={veiculosacc3} />
            <KmlDataSource show={showfluxoveicacc3} data={fluxoveicacc3} />
            <KmlDataSource show={showmovimentacaoacc3} data={movimentacaoacc3} />
            <KmlDataSource show={showrendaacc3} data={rendaacc3} />
            <KmlDataSource show={showpopulacaoacc3} data={populacaoacc3} />
            <KmlDataSource show={showdomiciliosacc3} data={domiciliosacc3} />
            <KmlDataSource show={showdomialuguelacc3} data={domialuguelacc3} />
            <KmlDataSource show={showareaurbaacc3} data={areaurbaacc3} />
            <KmlDataSource show={showspacc3} data={spacc3} />
            <KmlDataSource show={showguarulhosacc3} data={guarulhosacc3} />
            <KmlDataSource show={showtrechoacc3} data={trechoacc3} />
            
            <KmlDataSource show={showaeror2soroaureo} data={aeror2soroaureo} />
            <KmlDataSource show={showshoppingr2soroaureo} data={shoppingr2soroaureo} />
            <KmlDataSource show={showcondor2soroaureo} data={condor2soroaureo} />
            <KmlDataSource show={showdomicilior3soroaureo} data={domicilior3soroaureo} />
            <KmlDataSource show={showpopr3soroaureo} data={popr3soroaureo} />
            <KmlDataSource show={showvarejor3soroaureo} data={varejor3soroaureo} />
            <KmlDataSource show={showindustriar3soroaureo} data={industriar3soroaureo} />
            <KmlDataSource show={showdomicilior4soroaureo} data={domicilior4soroaureo} />
            <KmlDataSource show={showpopr4soroaureo} data={popr4soroaureo} />
            <KmlDataSource show={showvarejor4soroaureo} data={varejor4soroaureo} />
            <KmlDataSource show={showindustriar4soroaureo} data={industriar4soroaureo} />
            <KmlDataSource show={showdomicilior5soroaureo} data={domicilior5soroaureo} />
            <KmlDataSource show={showpopr5soroaureo} data={popr5soroaureo} />
            <KmlDataSource show={showvarejor5soroaureo} data={varejor5soroaureo} />
            <KmlDataSource show={showindustriar5soroaureo} data={industriar5soroaureo} />
            <KmlDataSource show={showsud1petrop1aureo} data={sud1petrop1aureo} />
            <KmlDataSource show={showsud2petrop1aureo} data={sud2petrop1aureo} />
            <KmlDataSource show={showsud1petrop2aureo} data={sud1petrop2aureo} />
            <KmlDataSource show={showsud2petrop2aureo} data={sud2petrop2aureo} />
            
            <KmlDataSource show={showareascontastacetesbtnd} data={areascontastacetesbtnd} />
            <KmlDataSource show={showareascontastasgmptnd} data={areascontastasgmptnd} />
            <KmlDataSource show={showlimitebairrosstatnd} data={limitebairrosstatnd} />
            <KmlDataSource show={showfichasstatnd} data={fichasstatnd} />
            <KmlDataSource show={showzoneamentostaeixotamandtnd} data={zoneamentostaeixotamandtnd} />
            <KmlDataSource show={showgalpoesjaguariaivaic} data={galpoesjaguariaivaic} />
            
            <KmlDataSource show={showzoneamentocuritibatrply} data={zoneamentocuritibatrply} />
            <KmlDataSource show={showpermissaocuritibatrply} data={permissaocuritibatrply} />
            <KmlDataSource show={showterrenoscuritibatrply} data={terrenoscuritibatrply} />
            <KmlDataSource show={showtopografiacuritibatrply} data={topografiacuritibatrply} />
            <KmlDataSource show={showlimitesambcuritibatrply} data={limitesambcuritibatrply} />
            <KmlDataSource show={showhidrografiacuritibatrply} data={hidrografiacuritibatrply} />
            <KmlDataSource show={showservidaocuritibatrply} data={servidaocuritibatrply} />
            <KmlDataSource show={showterrenospotenciascuritibatrply} data={terrenospotenciascuritibatrply} />
            <KmlDataSource show={showcrescimentocuritibatrply} data={crescimentocuritibatrply} />
            <KmlDataSource show={showdadossocioscuritibatrply} data={dadossocioscuritibatrply} />
            <KmlDataSource show={showpontosdeinteressecuritibatrply} data={pontosdeinteressecuritibatrply} />



            <CustomDataSource name="custom">
              {terrainsTL.map((terrain) => {
                return (
                  <Entity
                    id={terrain.codigo}
                    name={``}
                    description={`
                      <table style="border-collapse: collapse; width: 100%; text-align: center; margin-top: 20px;">
                        <thead>
                          <th style="border: 1px solid black; color: #fad414;">Cidade</th>
                          <th style="border: 1px solid black; color: #fad414;">UF</th>
                          <th style="border: 1px solid black; color: #fad414;">Valor</th>
                          <th style="border: 1px solid black; color: #fad414;">Área</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="border: 1px solid black; font-weight: bold;">${terrain.cidade}</td>
                            <td style="border: 1px solid black; font-weight: bold;">${terrain.uf}</td>
                            <td style="border: 1px solid black; font-weight: bold;">${(terrain.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            <td style="border: 1px solid black; font-weight: bold;">${terrain.area} m²</td>
                          </tr>
                        </tbody>
                      </table>
                      <p style="text-align: center; margin-top: 20px;">
                        <a href="https://www.terrenolivre.com" target="_blank" style="text-decoration: none;">
                          <button style="background-color: #fad414; color: #000; border: none; padding: 10px 20px; border-radius: 5px; cursor: pointer; transition: all 0.3s ease; font-weight: bold;">
                            Confira este terreno e outros no Terreno Livre
                          </button>
                        </a>
                      </p>
                    `}
                    key={terrain.codigo}
                    position={Cartesian3.fromDegrees(
                      terrain.longitude,
                      terrain.latitude,
                      0.0
                    )}
                    point={{
                      pixelSize: 50,
                      // scaleByDistance: new NearFarScalar(1000, 40, 5000, 3),
                      color: Color.YELLOW,
                      outlineColor: Color.GOLD,
                    }}
                  />
                );
              })}
            </CustomDataSource>
            {/* <KmlDataSource data={kmltPath} show={showKmlT} ref={kmlDataSourceRef}></KmlDataSource> */}
          </Viewer>
        </div>
        {/* {tlpopup && (
          <TLPopup
            isOpen={tlpopup}
            onClose={handleTLPopupChange}
            id={selectedTLId}
            terrainsTL={terrainsTL}
          ></TLPopup>
        )} */}
      </div>
    )
  );
}
