import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { auth } from "../../services/firebaseConnection";
import { AuthContext } from "../../contexts/auth";


export default function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [user, setUser] = useState(false);
  const [userDetail, setUserDetail] = useState({});

  const { signIn, signed } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem("@loggedAreaInsight");

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        navigate("/");
      }
    }

    loadStorage();
  }, []);

  async function handleLogin(e) {
    e.preventDefault();
    if (email !== "" && pass !== "") {
      await signIn(email, pass).then(() => {
        navigate("/");
      });
    }
  }

  return (
    <div>
      <div className="container-login">
        <h1 className="text-[20px] font-bold">Login</h1>
        <form onSubmit={handleLogin}>
          <label>Email</label>
          <input
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <label>Senha</label>
          <input
            value={pass}
            type="password"
            onChange={(e) => setPass(e.target.value)}
          ></input>

          <input type="submit" value="Acessar"></input>
        </form>
      </div>

      
    </div>
  );
}
